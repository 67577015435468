import React from "react";
import TextField from '@material-ui/core/TextField';
import withStyles from "@material-ui/core/styles/withStyles";

const MROTextField = withStyles(theme => ({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: theme.palette.primary.main,
            },
            '&:hover fieldset': {
                borderColor: theme.palette.primary.light,
            },
            '& .MuiOutlinedInput-input': {
                padding: '15px'
            }
        },
        '& label': {
            color: theme.palette.primary.main
        }
    },
}))(props => <TextField placeholder={props?.placeholder} fullWidth variant={"outlined"} margin={"normal"} color={"primary"} helperText={props.error ? `Please ${props.select ? 'select' : 'enter'} the ${props.label?.replace('*', "")}` : null} {...props} />);

export default MROTextField;