import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import { withRouter } from 'react-router-dom';
import AccountCircle from '@material-ui/icons/AccountCircle';
import NotificationsIcon from '@material-ui/icons/Notifications';
import AuthClient from "../../lib/services/api/auth_service";
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu'
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
const useStyles = makeStyles(theme => ({
    grow: {
        flexGrow: 1,
        webkitBoxFlex: 1,
        msFlexPositive: 1,
    },
    input: {
        margin: '0 25px',
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    search: {
        display: 'flex',
        alignItems: 'center',
        width: 500,
        marginLeft: '30%',
        borderRadius: '20px'
    }
}));
function Header(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const totalCount = sessionStorage.getItem('notifications_count');
    const handleMenu = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const noNavBarRoutes = ["signin", "forgot-password", "reset-password", 'auth'];
    for (let index in noNavBarRoutes) {
        if (noNavBarRoutes.hasOwnProperty(index)) {
            const route = noNavBarRoutes[index];
            if (props.location.pathname.includes(route)) {
                return null;
            }
        }
    }
    function handleLogout() {
        AuthClient.logout().then(() => {
            props.history.push('/auth/signin');
        }).catch(() => {
            sessionStorage.clear();
            props.history.push('/auth/signin');
        })
    }
    const handleNotification = () => {
        props.history.push('/push_notifications');
    }
    return (
        <div className={classes.grow}>
            <AppBar position="fixed" style={{ backgroundColor: '#E5E5E5' }} elevation={0}>
                <Toolbar>
                    <div style={{ textAlign: 'right', marginLeft: 700 }}>
                        <IconButton aria-label="show 17 new notifications" onClick={handleNotification} color="primary">
                            <Badge id='notification-count' badgeContent={totalCount} color="secondary">
                                <NotificationsIcon />
                            </Badge>
                        </IconButton>
                        <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="primary"
                        >
                            <AccountCircle fontSize="large" />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            open={open}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={(event) => {
                                props.history.push('/change_password')
                            }}>
                                <VpnKeyIcon />&nbsp;&nbsp;
                            <p>Change Password</p>
                            </MenuItem>
                            <MenuItem onClick={handleLogout}>
                                <ExitToAppIcon />&nbsp;&nbsp;
                            <p>Logout</p>
                            </MenuItem>
                        </Menu>
                    </div>
                </Toolbar>
            </AppBar>
        </div>
    );
}
export default withRouter(Header);