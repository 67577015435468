// import React from "react";
// import SnackbarContent from "@material-ui/core/SnackbarContent/SnackbarContent";
// import makeStyles from "@material-ui/core/styles/makeStyles";
// import { Offline } from "react-detect-offline";

// const useStyles = makeStyles(theme => ({
//     root: {
//         maxWidth: 600,
//     },
//     snackbar: {
//         margin: theme.spacing(1),
//     },
// }));

// let condition = navigator.onLine ? 'online' : 'offline';

// export default function Footer() {
//     const classes = useStyles();
//     return (
//         <div>
//             <span id="versionIndicator">v0.0.1</span>
//             { condition === 'offline' ?
//                 <div style={{ position: 'fixed', bottom: '0', zIndex: '99999' }}>
//                     <SnackbarContent anchorOrigin={{
//                         vertical: 'bottom',
//                         horizontal: 'center',
//                     }} className={classes.snackbar}
//                         message="You are currently offline. Connect to internet and try again." />
//                 </div>: null
//             }
//         </div>
//     )
// }

import React, { useState, useEffect } from 'react';

import SnackbarContent from "@material-ui/core/SnackbarContent/SnackbarContent";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: 600,
    },
    snackbar: {
        margin: theme.spacing(1),
    },
}));
export default function Footer() {
    
    const classes = useStyles();
    const [isOnline, setIsOnline] = useState(navigator.onLine);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setIsOnline(navigator.onLine);
        }, 2000); // Check online status every 2 seconds

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    if (isOnline) {
        return null;
    } else {
        return <div style={{ position: 'fixed', bottom: '0', zIndex: '99999' }}>
            <SnackbarContent anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }} className={classes.snackbar}
                message="You are currently offline. Connect to internet and try again." />
        </div>
    }
};
