import React, { useEffect } from 'react'
import { Typography, Box, makeStyles, IconButton } from "@material-ui/core";
import MROButton from "../../../components/buttons";
import ButtonGroupNavigation from "../../../components/button_group_navigation";
import { Route, Switch, useLocation, useHistory } from "react-router-dom";
import Data from './Data/Data';
import Users from './Users/users';
import SMS from './SMS/sms';




const useStyles = makeStyles(theme => ({
  subNavigation: {
    margin: '2% 3%',
    width: '85%',
  }
}));

const buttons = [
  { name: "Users", redirect: "/dashboard/usage/users" },
  { name: "Data", redirect: "/dashboard/usage/data" },
  { name: "SMS", redirect: "/dashboard/usage/sms" },
];



export default function Usage(props) {
  const classes = useStyles();

  const location = useLocation()
  const history = useHistory();


  return (
    <div>
      <div className={classes.subNavigation}>
        <ButtonGroupNavigation buttons={buttons} {...props} location={location} history={history} />
      </div>
      <Switch>
        <Route exact path={'/dashboard/usage/users'} render={rprops => <Users {...rprops} {...props} location={location} history={history} />} />
        <Route exact path={'/dashboard/usage/data'} render={rprops => <Data {...rprops} {...props} location={location} history={history} />} />

        <Route exact path={'/dashboard/usage/sms'} render={rprops => <SMS {...rprops} {...props} />} />
        {/* <Route component={Users} /> */}
      </Switch>
    </div>
  )
}

