import React, { useState, useEffect } from 'react'
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import { Dialog, DialogContent, DialogTitle, Box, MenuItem, Tabs, AppBar, ListItem, Typography, IconButton } from "@material-ui/core";
import ClearIcon from '@material-ui/icons/Clear';
import Tab from "@material-ui/core/Tab";
import { MessageServices } from '../../../lib/services/api/Messaging/messae_services'



const useStyles = makeStyles((theme) => ({
    formWrapper: {
        background: "#fff",
        width: "100%",

    },
    title: {
        display: "flex",
        justifyContent: "left",
        "& h2": {
            //   color: theme.palette.primary.main,
            fontSize: "40px",
            fontWeight: 40,
        },
    },
    subTitle: {
        fontSize: "16px",
        fontWeight: "bold",
        marginBottom: "20px",
        color: "#4D4F5C",
    },
    card: {
        padding: "8px 29px",
    },
    actionButtons: {
        paddingBottom: "33px",
        textAlign: "end",
        marginRight: "6px",
        marginTop: '21px',
        "& button:not(:last-child)": {
            marginRight: "20px",
        },
    },

    modal: {
        "& .MuiDialog-paperWidthMd": {
            width: "550px",
        },
    }
}));


function MessageDetails(props) {
    const classes = useStyles();
    const { handleClose, selectedTab, setSelectedTab, selectedId } = props
    const [sendUser, setSendUser] = useState([])
    const [unsendUser, setUnSendUser] = useState([])
    const [readUser, setReadUser] = useState([])
    const handleGroupMessageDetails = async () => {

        const params = {
            chat_id: selectedId,
            detail: "list"
        }
        await MessageServices.getGroupMessageDetails(params).then((response) => {
            setSendUser(response.data.sent)
            setUnSendUser(response.data.unread)
            setReadUser(response.data.read)
            // : 
            // {sent: 40, read: 0, unread: 40})
        }).catch((err) => {

        })

    }
    useEffect(() => {
        handleGroupMessageDetails()
    }, [selectedTab])
    return (
        <div>
            <Dialog
                maxWidth={"md"}
                open={true}
                className={classes.modal}
                onClose={handleClose}
                aria-labelledby="base-station-manage"
            >
                <DialogTitle>
                    <Grid style={{ textAlign: 'end', cursor: 'pointer' }}>
                        <ClearIcon onClick={() => handleClose()} style={{}} size='small' />
                    </Grid>
                </DialogTitle>
                <DialogContent style={{ paddingBottom: '20px', height: '50vh' }}>

                    <Tabs
                        value={selectedTab}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                        onChange={(e, tab) => { setSelectedTab(tab) }}
                        style={{ borderBottom: '1px solid #00000029' }}
                    >
                        <Tab className={classes.tab} label="SENT" />
                        <Tab className={classes.tab} label="READ" />
                        <Tab className={classes.tab} label="NOT READ" />
                    </Tabs>
                    <div>
                        {selectedTab === 0 && <Grid>
                            {sendUser.map((item, index) => (
                                <ListItem>
                                    <Typography variant="body2">{item}</Typography>
                                </ListItem>

                            ))


                            }

                        </Grid>}

                        {selectedTab === 1 && <Grid>

                            {readUser.map((item, index) => (
                                <ListItem>
                                    <Typography variant="body2">{item}</Typography>
                                </ListItem>

                            ))


                            }
                        </Grid>}

                        {selectedTab === 2 && <Grid>

                            {unsendUser.map((item, index) => (
                                <ListItem >
                                    <Typography variant="body2">{item
                                    }</Typography>
                                </ListItem>

                            ))


                            }
                        </Grid>}
                    </div>
                </DialogContent>

            </Dialog>

        </div>
    )
}

export default MessageDetails