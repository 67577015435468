import React, { useState, useEffect, useContext } from 'react';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import MROTextField from "../../components/form_components/TextField";
import MROButton from "../../components/buttons";
import Typography from "@material-ui/core/Typography";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import { ToastMessageContext } from "../../lib/contexts/message_context";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ChipInput from 'material-ui-chip-input';
import PositiveSwitch from "../../components/form_components/switch";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import LineReportClient from "../../lib/services/api/line_report";

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
const theme = createMuiTheme({
  palette: {
    primary: red,
  },
});
export default function EditField(props) {
  const classes = useStyles();
  const [open, setOpen] = [props.open, props.onClose];
  const message = useContext(ToastMessageContext);
  const [age, setAge] = React.useState('');
  const [error, setError] = useState({});
  const [view, setView] = useState(false);
  const [customField, setCustomField] = useState({});
  const [secondaryFields, setSecondaryFields] = useState({});
  const [mandatoryChecked, setMandatoryChecked] = useState(false);
  const [secondaryMandatoryChecked, setSecondaryMandatoryChecked] = useState(false);

  const [secondaryChecked, setSecondaryChecked] = useState(secondaryFields?.id);
  const [loading, setLoading] = useState(false);
  const [currentDropDown, setCurrentDropDown] = useState([]);
  const [dropDownValues, setDropDownValues] = useState('');
  const [secondaryDropDownValues, setSecondaryDropDwonValues] = useState('')

  const handleChange = (event) => {
    setAge(event.target.value);
    setCustomField({
      ...customField,
      field_type: event.target.value
    })
  };
  // For secondary field_type
  const handleSecondaryChange = (event) => {
    // setAge(event.target.value);
    setSecondaryFields({
      ...secondaryFields,
      field_type: event.target.value
    })
  };
  const handleClose = () => {
    setCustomField({})
    setOpen(false);
  };
  const handleChangeDropDown = (e) => {
    console.log('e.target.value', e.target.value)
    const val = e.target.value;
    setCurrentDropDown(val.split(','));

  }
  const handleSubmit = (e) => {
    e.preventDefault();
    let formData = new FormData(e.target);

    console.log(formData)
    for (const iterator of formData) {
      console.log(iterator)
    }

    formData.append('field_id', props.fieldId)
    // formData.append('secondary_field', secondaryChecked)
    formData.append('mandatory', mandatoryChecked)
    var object = {
      field_id: props.fieldId,
      mandatory: mandatoryChecked,
      secondary_field: secondaryChecked
    };
    let temp_object = [];
    formData.forEach((value, key) => { object[key] = value });
    object.mandatory = mandatoryChecked;
    object.secondary_field = secondaryChecked;

    temp_object = [{
      decimal_places: object?.secondary_decimal_places || null,
      dropdown_values: object?.secondary_dropdown_values || null,
      field_name: object?.secondary_field_name || null,
      field_type: object?.secondary_field_type || null,
      help_text: object?.secondary_help_text || null,
      field_id: secondaryFields?.id || null,
      mandatory: secondaryMandatoryChecked,
      max_length: object?.secondary_max_length || null
    }]
    //extra 
    let new_secondary_fields = [];
    new_secondary_fields = [{
      field_name: object?.secondary_field_name || null,
      field_type: object?.secondary_field_type || null,
      dropdown_values: object?.secondary_dropdown_values || null,
      mandatory: secondaryMandatoryChecked,
      field_parent_id: props.fieldId,
      section_id: customField?.section_id || null,
      max_length: object?.secondary_max_length || null,
      decimal_places: object?.secondary_decimal_places || null,
      help_text: object?.secondary_help_text || null,
    }]
    Object.keys(object).filter(key => key.startsWith('secondary_')).map(key => {
      delete object[key]
    })

    object.secondary_fields = temp_object

    object.secondary_field = secondaryChecked;
    console.log("secomdaryyyyy====", secondaryFields)
    if (secondaryFields !== undefined && secondaryFields.id === undefined) {
      object.new_secondary_field = new_secondary_fields
    }
    console.log(object)
    // let val = ''
    // currentDropDown.forEach(dd => {
    //   val = val + dd + ','
    //   console.log(val)
    // })
    // object.dropdown_values = val
    // currentDropDown.forEach(usr => formData.append("values",usr))
    // let val = [
    //   [...currentDropDown]
    // ]
    // console.log('currentDropDown', val)
    formData.append('dropdown_values', dropDownValues)
    LineReportClient.edit(object).then((res) => {
      handleClose()
      props.listFields()
      props.sectionList()
    })
  }
  useEffect(() => {
    if (props.fieldId) {
      customInfo()
    }
  }, [props.fieldId, props.open]);
  const customInfo = () => {
    setLoading(true);
    LineReportClient.fetchCustomFieldInfo(props.fieldId).then((res) => {
      if (res.custom_field) {
        setCustomField(res.custom_field)
        setSecondaryFields(res.custom_field.secondary_fields[0])
        setSecondaryChecked(res.custom_field.secondary_field)
        setAge(res.custom_field.field_type)
        setMandatoryChecked(res.custom_field.mandatory)
        setSecondaryMandatoryChecked(res.custom_field.secondary_fields[0].mandatory)

        setView(true)
      }
      setLoading(false);
      setView(false)
    }).catch(() => {
      setLoading(false);
      setView(false)
    })
  }

  const handleMandatoryChecked = (e) => {
    setMandatoryChecked(e.target.checked)
  }

  return (
    <React.Fragment>
      <div> <Dialog
        open={open}
        //   fullWidth={true}
        onClose={handleClose}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Edit Field</DialogTitle>
        <DialogContent>
          {!loading ? <React.Fragment>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={5} alignItems={"center"} justify={"flex-start"}>
                <Grid item xs={6}>
                  <MROTextField
                    name={"field_name"}
                    label={"Field Name"}
                    required={customField.mandatory}
                    InputLabelProps={{ shrink: true }}
                    defaultValue={customField.field_name}
                  />
                </Grid>
                <Grid item xs={6}>
                  <MROTextField
                    name={"help_text"}
                    label={"Help text in box"}
                    required
                    InputLabelProps={{ shrink: true }}
                    defaultValue={customField.help_text}
                  />
                </Grid>
                {/* <Grid item xs={6}>
                </Grid> */}
                <Grid item xs={6}>
                  <MROTextField
                    name={"field_type"}
                    label={"Field Type"}
                    select
                    autoFocus={error.manager}
                    error={error.manager}
                    helperText={error.manager}
                    // value={age}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    defaultValue={customField?.field_type?.replace(' ', '') || age}

                  >
                    <MenuItem value={'Yes/No'}>Yes/No</MenuItem>
                    <MenuItem value={'Numeric'}>Numeric</MenuItem>
                    <MenuItem value={'Freetext'}>Free text</MenuItem>
                    <MenuItem value={'Dropdown'}>Dropdown</MenuItem>
                  </MROTextField>
                </Grid>
                <Grid item xs={6}>
                </Grid>
                {/* <Grid item xs={6}>
                </Grid> */}
                {(age === 'Freetext' || customField.field_type === 'Freetext') && <Grid item xs={6}>
                  <MROTextField
                    name={"max_length"}
                    label={"Field Max Length"}
                    required
                    InputLabelProps={{ shrink: true }}
                    defaultValue={customField.max_length}
                  />
                </Grid>}
                {(age === 'Numeric' || customField.field_type === 'Numeric') && <Grid item xs={6}>
                  <MROTextField
                    name={"max_length"}
                    label={"Field Max Length"}
                    required
                    InputLabelProps={{ shrink: true }}
                    defaultValue={customField.max_length}
                  />
                </Grid>}
                {(customField.field_type === 'Numeric' || age === 'Numeric') && <Grid item xs={6}>
                  <MROTextField
                    name={"decimal_places"}
                    label={"Decimal Places"}
                    required
                    InputLabelProps={{ shrink: true }}
                    defaultValue={customField.decimal_places}
                  />
                </Grid>}

                {(customField.field_type === 'Dropdown' || age === 'Dropdown') ? <Grid item xs={6}>
                  <MROTextField
                    name={"dropdown_values"}
                    label={"Enter dropdown options"}
                    required
                    InputLabelProps={{ shrink: true }}
                    defaultValue={customField.dropdown_values}
                    onChange={(e) => setDropDownValues(e.target.value)}
                    helperText="Enter dropdown fields (comma separated)"
                  />
                  {/* <ChipInput
                    variant="filled"
                    onChange={(chips) => setCurrentDropDown(chips)}
                    defaultValue={customField.dropdown_values}
                    label="Enter dropdown options"
                    name={"dropdown_values"}
                    fullWidth
                  /> */}
                </Grid> : ''}


                {/* <Grid item xs={6}>
                        </Grid> */}
                <Grid item xs={8}>
                  <FormControlLabel
                    control={
                      <PositiveSwitch
                        checked={mandatoryChecked}
                        onChange={(e) => setMandatoryChecked(e.target.checked)}
                        name="mandatory"
                        defaultValue={customField.mandatory}
                      />
                    }
                    label="Is this a mandatory field?"
                    labelPlacement="start"
                    name={"mandatory"}
                  />
                </Grid>
                <Grid item xs={8}>
                  {
                    (
                      customField.field_type === 'Yes/No' ||
                      age === 'Yes/No') &&
                    <FormControlLabel
                      control={
                        <PositiveSwitch
                          checked={secondaryChecked}
                          onChange={(e) => setSecondaryChecked(e.target.checked)}
                          name="secondary_field"
                          defaultValue={customField.secondary_field || secondaryChecked}
                        />
                      }
                      label="Does it need a secondary field?"
                      labelPlacement="start"
                      name={"secondary_field"}
                    />}
                </Grid>
                <Grid item xs={6}>
                </Grid>
              </Grid>
              {/* ============================== Secondary Field ===========================> */}
              {(customField?.field_type === 'Yes/No' || age === 'Yes/No') && secondaryChecked &&
                <Grid container spacing={2} style={{ background: '#f5f5f5', marginBottom: 20 }} >
                  <Grid item xs={6}>
                    <MROTextField
                      name={"secondary_field_name"}
                      label={"Field Name"}
                      required
                      InputLabelProps={{ shrink: true }}
                      defaultValue={secondaryFields?.field_name}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <MROTextField
                      name={"secondary_help_text"}
                      label={"Help text in box"}
                      required
                      InputLabelProps={{ shrink: true }}
                      defaultValue={secondaryFields?.help_text}
                    />
                  </Grid>
                  {/* <Grid item xs={6}>
                  </Grid> */}
                  <Grid item xs={6}>
                    <MROTextField
                      name={"secondary_field_type"}
                      label={"Field Type"}
                      select
                      autoFocus={error.manager}
                      error={error.manager}
                      helperText={error.manager}
                      onChange={handleSecondaryChange}
                      InputLabelProps={{ shrink: true }}
                      defaultValue={secondaryFields?.field_type}
                    >
                      <MenuItem value={'Yes/No'}>Yes/No</MenuItem>
                      <MenuItem value={'Numeric'}>Numeric</MenuItem>
                      <MenuItem value={'Freetext'}>Free text</MenuItem>
                      <MenuItem value={'Dropdown'}>Dropdown</MenuItem>
                    </MROTextField>
                  </Grid>
                  <Grid item xs={6}>
                  </Grid>
                  {(secondaryFields?.field_type === 'Numeric' || secondaryFields?.field_type === 'Freetext') && <Grid item xs={6}>
                    <MROTextField
                      name={"secondary_max_length"}
                      label={"Field Max Length"}
                      required
                      InputLabelProps={{ shrink: true }}
                      defaultValue={secondaryFields?.max_length}
                    />
                  </Grid>}
                  {(secondaryFields?.field_type === 'Numeric') && <Grid item xs={6}>
                    <MROTextField
                      name={"secondary_decimal_places"}
                      label={"Decimal Places"}
                      required
                      InputLabelProps={{ shrink: true }}
                      defaultValue={secondaryFields?.decimal_places}
                    />
                  </Grid>}

                  {(secondaryFields?.field_type === 'Dropdown') ? <Grid item xs={6}>
                    <MROTextField
                      name={"secondary_dropdown_values"}
                      label={"Enter dropdown options"}
                      required
                      InputLabelProps={{ shrink: true }}
                      defaultValue={secondaryFields?.dropdown_values}
                      onChange={(e) => setSecondaryDropDwonValues(e.target.value)}
                      helperText="Enter dropdown fields (comma separated)"
                    />
                  </Grid> : ''}
                  {/* <Grid item xs={8}>
                
                  <Grid item xs={6}>
                  </Grid>
                  {/* <Grid item xs={6}>
                        </Grid> */}
                  <Grid item xs={8}>
                    <FormControlLabel
                      control={
                        <PositiveSwitch
                          checked={secondaryMandatoryChecked}
                          onChange={(e) => setSecondaryMandatoryChecked(e.target.checked)}
                          name="secondary_mandatory"
                          defaultValue={secondaryFields?.mandatory}
                        />
                      }
                      label="Is this a mandatory field?"
                      labelPlacement="start"
                      name={"secondary_mandatory"}
                    />
                  </Grid>
                  <Grid item xs={6}>
                  </Grid>

                </Grid>}
              <div style={{ textAlign: 'right' }}>
                <MROButton variant={"contained"} color="tertiary" onClick={handleClose}>Cancel</MROButton>&nbsp;&nbsp;
                <MROButton variant={"contained"} color="primary" type="submit">  Save</MROButton>
              </div>
            </form>
          </React.Fragment> : <div className={"center"} style={{ minHeight: '200px' }}>
            <CircularProgress color="primary" />
          </div>}
        </DialogContent>
      </Dialog>
      </div>
    </React.Fragment>
  );
}