import request, { authRequest } from "../../shared/api_client";
import { BASE_API_URL } from "../../shared/constants";

const url = BASE_API_URL;

function putData(formData, url) {
    return request({
        url: url,
        method: 'PUT',
        data: formData,
    });
}

function update(url, formData) {
    return request({
        url: url,
        method: 'PUT',
        data: formData
    });
}
function createCategories(formData) {
    return request({
        url: `/organisations/create_categories`,
        method: 'POST',
        data: formData
    })
}
function updateCategories(formData) {
    return request({
        url: `/organisations/update_categories`,
        method: 'PUT',
        data: formData
    })
}
function getList(status, search, page, searchKey, location) {
    let newUrl = `${url}/organisations?search=false&page=1`;
    if (searchKey && searchKey.length > 1) {
        newUrl = `${newUrl}&search=true&status=${status}&location=${location}search_keyword=${searchKey}`;
    }
    return request({
        url: newUrl,
        method: 'GET'
    })
}
function getData(url) {
    return request({
        url: url,
        method: 'GET',
    });
}
function getAllOrganisations(params) {
    return request({
        url: '/organisations',
        method: 'GET',
        params: params
    });
}
function getOrganisations(params) {
    return request({
        url: '/organisations',
        method: 'GET',
        params: params
    });
}
function postData(formData, url) {
    return request({
        url: url,
        method: 'POST',
        data: formData,
    });
}
function remove(url, id) {
    return request({
        url: `${url}/${id}`,
        method: 'DELETE',
    })
}

function getDetails(id) {
    return request({
        url: `/organisations/${id}`,
        method: 'GET',
    })
}
function getFrontEnd(id) {
    return request({
        url: `/organisations/get_front_end_categories?id=${id}`,
        method: 'GET',
    })
}
function getWeb(id) {
    return request({
        url: `/organisations/get_web_categories?id=${id}`,
        method: 'GET',
    })
}
function getAlternateAddress(params) {
    return request({
        url: `/organisations/listing_alt_address`,
        method: 'GET',
        params
    })
}
function getBases(params) {
    return request({
        url: `/organisations/listing_base`,
        method: 'GET',
        params
    })
}

function postAlternateAddressData(formData) {
    return request({
        url: `/organisations/create_organisation_addresses`,
        method: 'POST',
        data: formData,
    });
}
function updateAlternateAddressData(formData) {
    return request({
        url: `/organisations/edit_organisation_addresses`,
        method: 'PUT',
        data: formData,
    });
}

function updateAlternateBases(formData) {
    return request({
        url: `/organisations/map_base_addresses`,
        method: 'PUT',
        data: formData,
    });
}
function deleteAlternateAddressData(formData) {
    return request({
        url: `/organisations/delete_org_address`,
        method: 'DELETE',
        data: formData,
    });
}
function postMultipleAlternateAddressData(formData) {
    return request({
        url: `/organisations/create_multi_organisation_addresses`,
        method: 'POST',
        data: formData,
    });
}
const OrganisationApiClient = {
    putData, update, getFrontEnd, getList, remove, getDetails, postData, getData, getOrganisations, getWeb, updateCategories, createCategories, getAllOrganisations, postAlternateAddressData, postMultipleAlternateAddressData, getAlternateAddress, updateAlternateAddressData, deleteAlternateAddressData, getBases, updateAlternateBases
};

export default OrganisationApiClient;