import React, { useState,useContext } from 'react';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import {ToastMessageContext} from "../../lib/contexts/message_context";
import MROTextField from "../../components/form_components/TextField";
import MROButton from "../../components/buttons";
import Typography from "@material-ui/core/Typography";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import PositiveSwitch from "../../components/form_components/switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import LineReportClient from "../../lib/services/api/line_report";
import { validateFormData } from '../../lib/helper_function/HelperFunction';

const useStyles = makeStyles(theme => ({
    margin: {
      margin: theme.spacing(1),
    }, 
     formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
      dropbox:{
          height:'64px !important'
      }
  }));
  const theme = createMuiTheme({
    palette: {
      primary: red,
    },
  });
export default function AddNewField(props) {
  const classes = useStyles();
  const message = useContext(ToastMessageContext);
  const [open, setOpen] = [props.open,props.onClose]; 
   const [age, setAge] = React.useState('');   
    const [dropDownValues,setDropDownValues] = useState('');
    const [secMandatoryChecked, setSecMandatoryChecked] = useState('');
    const [loading,setLoading] = useState(false);
    const [mandatoryChecked,setMandatoryChecked] = useState(false);
    const [secondaryChecked,setSecondaryChecked] = useState(false); 
    const [secDropDownValues, setSecDropDownValues] = useState('');
    const [secondarySelect,setSecondarySelect] = useState(null);
    const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    resetErrorHandler(event.target.name)
    setAge(event.target.value);
  };
  const handleClose = () => {
      setOpen(false);
    };

    const resetErrorHandler = (name) => setErrors(err => ({...err, [name]: false}))

  const handleSubmit =(e) =>{

    e.preventDefault();
    let formData = new FormData(e.target);
    const { valid, error } = validateFormData(formData);

    console.log({error})

    if(!valid) {
      setErrors(error)
      return
    }


        formData.append('section_id',props.selectedIndex)
        // formData.append('secondary_field',secondaryChecked)
        formData.append('mandatory',mandatoryChecked)
        let spl = JSON.stringify(dropDownValues);
        let temp =spl.split(',');
        let final=[...temp]
        if(age === 'Dropdown'){
        formData.append('values',final)
        }
      LineReportClient.createData(formData).then((res)=>{
          if(res.success){
            message.showToastMessage({open:true,message:"New field successfully created",variant:"success"});
            handleClose()
            props.listFields()
            // props.setLoading(true);
            // setTimeout(()=>{
            //   props.setLoading(false);
            //   handleClose();
            // },300);
            props.sectionList()
          }
  else {
      message.showToastMessage({open:true,message:res.errors?res.errors.join(','):"Something went wrong. Try Again",variant:"error"});
      setLoading(false);
  }
}).catch(()=>{
  message.showToastMessage({open:true,message:"Something went wrong. Try Again",variant:"error"});
  setLoading(false);
})
   }

return (
   <React.Fragment>   
    <div> <Dialog 
      open={open}
    //   fullWidth={true}
       onClose={handleClose} 
       aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add New Field</DialogTitle>
        <DialogContent>
        <React.Fragment>
          <form noValidate onSubmit={handleSubmit}>
                            <Grid container spacing={5} alignItems={"center"} justify={"flex-start"}>
                    <Grid item xs={6}>
                     <MROTextField
                            name={"field_name"}
                            label={"Field Name"}
                            required
                            InputLabelProps={{ shrink: true }}
                            error={errors.field_name}
                            onChange={() => resetErrorHandler("field_name")}
                        />   
                         </Grid>
                        <Grid item xs={6}>
                        <MROTextField
                            name={"help_text"}
                            label={"Help text in box"}
                            required
                            InputLabelProps={{ shrink: true }}
                            error={errors.help_text}
                            onChange={() => resetErrorHandler("help_text")}
                        />   
                        </Grid>
                        {/* <Grid item xs={4}>
                        </Grid> */}
                        <Grid item xs={6}>
                           <MROTextField
                            name={"field_type"}
                            label={"Field Type"}
                            select
                           
                            error={errors.field_type}                            value={age}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                        >
                            <MenuItem value={'Yes/No'}>Yes/No</MenuItem>
                            <MenuItem value={'Numeric'}>Numeric</MenuItem>
                            <MenuItem value={'Free text'}>Free text</MenuItem>
                            <MenuItem value={'Dropdown'}>Dropdown</MenuItem>
                        </MROTextField>
                         </Grid>
                        {/* <Grid item xs={4}>  
                        </Grid>
                        <Grid item xs={4}>
                        </Grid> */}
                        {        age === 'Free text'  && <React.Fragment><Grid item xs={4}>
                     <MROTextField
                            type="number"
                            name={"max_length"}
                            label={"Field Max Length"}
                            required
                            InputLabelProps={{ shrink: true }}
                            error={errors.max_length}
                            onChange={() => resetErrorHandler("max_length")}
                        />   
                         </Grid>
                         {/* <Grid item xs={4}>
                        </Grid> */}
                        {/* <Grid item xs={4}>
                        </Grid> */}
                        </React.Fragment>  }
                        {        age === 'Numeric'  &&  <Grid item xs={6}>
                     <MROTextField
                            type="number"
                            name={"max_length"}
                            label={"Field Max Length"}
                            required
                            InputLabelProps={{ shrink: true }}
                            error={errors.max_length}
                            onChange={() => resetErrorHandler("max_length")}
                        />   
                         </Grid>}
                         {        age === 'Numeric'  &&  <Grid item xs={6}>
                        <MROTextField
                            type="number"
                            name={"decimal_places"}
                            label={"Decimal Places"}
                            required
                            InputLabelProps={{ shrink: true }}
                            error={errors.decimal_places}
                            onChange={() => resetErrorHandler("decimal_places")}
                        />   
                        </Grid>}
                      
                       { age === 'Dropdown' ?  <Grid item xs={6}>
                       <MROTextField
                            name={"dropdown_values"}
                            label={"Enter dropdown options"}
                            required
                            rows={4}
                            multiline
                            onChange={(e) => {setDropDownValues(e.target.value); resetErrorHandler("dropdown_values")}}
                            InputLabelProps={{ shrink: true }}
                        />   
                        <FormHelperText>Please enter the options as comma separated</FormHelperText>  
                        </Grid> :''}
                        <Grid item xs={12}>
                        <FormControlLabel
                                    control={
                                        <PositiveSwitch
                                            checked={mandatoryChecked}
                                            onChange={(e)=>setMandatoryChecked(e.target.checked)}
                                            name="mandatory"
                                        />
                                    }
                                    label="Is this a mandatory field?"
                                    labelPlacement="start"
                                />
                                </Grid>
                        {/* <Grid item xs={4}>
                        </Grid> */}
                        {/* <Grid item xs={4}>
                        </Grid> */}
                        <Grid item xs={12}>
                                  { age === 'Yes/No' &&<FormControlLabel
                                    control={
                                        <PositiveSwitch
                                            checked={secondaryChecked}
                                            onChange={(e)=>setSecondaryChecked(e.target.checked)}
                                            name="secondary_field"
                                        />
                                    }
                                    label="Does it need a secondary field?"
                                    labelPlacement="start"
                                    name={"secondary_field"}
                                />}
                                </Grid>
                                {/* <Grid item xs={4}>
                        </Grid> */}
                        </Grid> 
                      {age === 'Yes/No' && secondaryChecked &&  <Grid container spacing={2} style={{background:'#f5f5f5'}} >
                    <Grid item xs={6}>
                     <MROTextField
                            name={"secondary_field_name"}
                            label={"Field Name"}
                            required
                            InputLabelProps={{ shrink: true }}
                            error={errors.secondary_field_name}
                            onChange={() => resetErrorHandler("secondary_field_name")}
                        />   
                         </Grid>
                        <Grid item xs={6}>
                        <MROTextField
                            name={"secondary_help_text"}
                            label={"Help text in box"}
                            required
                            InputLabelProps={{ shrink: true }}
                            error={errors.secondary_help_text}
                            onChange={() => resetErrorHandler("secondary_help_text")}

                        />   
                        </Grid>
                        {/* <Grid item xs={4}>
                        </Grid> */}
                        <Grid item xs={6}>
                        <MROTextField
                            name={"secondary_field_type"}
                            label={"Field Type"}
                            select
                            autoFocus={errors.secondary_field_type}
                            error={errors.secondary_field_type}
                            value={secondarySelect}
                            onChange={(e) => {setSecondarySelect(e.target.value); resetErrorHandler(e.target.name)}}
                            InputLabelProps={{ shrink: true }}
                        >
                            <MenuItem value={'Yes/No'}>Yes/No</MenuItem>
                            <MenuItem value={'Numeric'}>Numeric</MenuItem>
                            <MenuItem value={'Free text'}>Free text</MenuItem>
                            <MenuItem value={'Dropdown'}>Dropdown</MenuItem>
                        </MROTextField>
                         </Grid>
                        {/* <Grid item xs={4}>  
                        </Grid>
                        <Grid item xs={4}>
                        </Grid> */}
                        {        secondarySelect === 'Free text'  &&  <Grid item xs={6}>
                     <MROTextField
                            name={"secondary_max_length"}
                            label={"Field Max Length"}
                            required
                            InputLabelProps={{ shrink: true }}
                            error={errors.secondary_max_length}
                            onChange={() => resetErrorHandler("secondary_max_length")}
                            type="number"
                        />   
                         </Grid>}
                        {        secondarySelect === 'Numeric'  &&  <Grid item xs={6}>
                     <MROTextField
                            name={"secondary_max_length"}
                            label={"Field Max Length"}
                            required
                            InputLabelProps={{ shrink: true }}
                            error={errors.secondary_help_text}
                            onChange={() => resetErrorHandler("secondary_help_text")}
                            type="number"
                        />   
                         </Grid>}
                         {        secondarySelect === 'Numeric'  &&  <Grid item xs={6}>
                        <MROTextField
                            name={"secondary_decimal_places"}
                            label={"Decimal Places"}
                            required
                            InputLabelProps={{ shrink: true }}
                            type="number"
                            error={errors.secondary_decimal_places}
                            onChange={() => resetErrorHandler("secondary_decimal_places")}
                        />   
                        </Grid>}
                      
                       { secondarySelect === 'Dropdown' ?  <Grid item xs={6}>
                       <MROTextField
                            name={"secondary_dropdown_values"}
                            label={"Enter dropdown options"}
                            required
                            rows={4}
                            multiline
                            onChange={(e) => {setSecDropDownValues(e.target.value); resetErrorHandler("secondary_dropdown_values")}}
                            InputLabelProps={{ shrink: true }}
                            error={errors.secondary_decimal_places}
                            
                        />   
                        <FormHelperText>Please enter the options as comma separated</FormHelperText>  
                        </Grid> :''}
                        {/* <Grid item xs={4}>
                        </Grid> */}
                             <Grid item xs={12}>
                        <FormControlLabel
                                    control={
                                        <PositiveSwitch
                                            checked={secMandatoryChecked}
                                            onChange={(e)=>setSecMandatoryChecked(e.target.checked)}
                                            name="mandatory"
                                        />
                                    }
                                    label="Is this a mandatory field?"
                                    labelPlacement="start"
                                    name={"mandatory"}
                                />
                                </Grid>
                                {/* <Grid item xs={4}>
                        </Grid>
                                <Grid item xs={4}>
                        </Grid> */}

                        </Grid> } <br />
                           <div style={{textAlign: 'right'}}>
                        <MROButton variant={"contained"} color="tertiary" onClick={handleClose}>Cancel</MROButton>&nbsp;&nbsp;
                        <MROButton variant={"contained"} color="primary" type="submit">  Save</MROButton>
                    </div>
                        </form>
     </React.Fragment>
        </DialogContent>
      </Dialog> 
    </div>
   </React.Fragment>
  );
}