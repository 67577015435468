import axios from "axios";
import { BASE_API_URL_BOXP } from "./constants";

export const requestp = function (options) {


    const authHeaders = JSON.parse(sessionStorage.getItem('authorization_headers'));

    const client = axios.create({
        baseURL: BASE_API_URL_BOXP,
        headers: authHeaders
    });

    const onSuccess = function (response) {
        if (options.handleHeaders === 1) {
            sessionStorage.setItem('authorization_headers', JSON.stringify(response.headers));
            // console.log('object', JSON.stringify(response.headers), respon)
            // sessionStorage.setItem('user', JSON.stringify(response.data.data));
            if (response && response.data && response.data.data && response.data.data['force_signin'] === 'true') {
                window.location.href = '/auth/reset-password';
            }
        }
        if (options.handleHeaders === 0) {
            sessionStorage.clear();
            window.location.href = '/auth/signin';
        }
        return response.data;
    };

    const onError = function (error) {
        if (options.handleHeaders === 0) {
            sessionStorage.clear();
        }
        if (error.response) {
            if ((error.response.status === 401 || error.response.status === 498) && options.handleHeaders !== 1) {
                window.location.href = '/auth/signin';
            }
            console.error('Status:', error.response.status);
            console.error('Data:', error.response.data);

        } else {
            console.error('Error Message:', error.message);
        }

        return Promise.reject(error.response ? error.response.data : { message: "something went wrong", success: false } || error.message || error.error);
    };

    return client(options)
        .then(onSuccess)
        .catch(onError);
};

export const authRequest = function (options) {

    const client = axios.create({
        baseURL: BASE_API_URL_BOXP,
        headers: options.headers
    });

    const onSuccess = function (response) {
        return response.data;
    };

    const onError = function (error) {
        if (error.response) {
            if (error.response.status === 401 && options.handleHeaders !== 1) {
                window.location.href = '/auth/signin';
            }
            console.error('Status:', error.response.status);
            console.error('Data:', error.response.data);

        } else {
            console.error('Error Message:', error.message);
        }

        return Promise.reject(error.response ? error.response.data : { message: "something went wrong", success: false } || error.message || error.error);
    };

    return client(options)
        .then(onSuccess)
        .catch(onError);
};


export default requestp;


