import React, { useState, useEffect, useContext, useReducer } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import Typography from "@material-ui/core/Typography";
import MROFullScreenRightDrawer from "../../components/fullwidth_dialog";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Popover from "@material-ui/core/Popover";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import Collapse from "@material-ui/core/Collapse";
import { ToastMessageContext } from "../../lib/contexts/message_context";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { ICON_COLOUR } from "../../lib/shared/style_constant";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import ManageSection from "./manage_section";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import LineReportClient from "../../lib/services/api/line_report";
import AddNewField from "./add_new_field";
import ManageField from "./manage_field";
import AddSection from "./add_section";
import AddSubSection from "./add_sub_section";
import PositiveSwitch from "../../components/form_components/switch";
import useIsGlobalReports from "../../lib/hooks/useIsGlobalReports";
import MROButton from "../../components/buttons";
import DeleteDialog from "../../components/Universal/delete_dialog";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "#fff",
    color: "#000000CC",
    boxShadow: "0px 3px 6px #0000001A",
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  listWrapper: {
    maxHeight: "80vh",
    overflow: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  list: {
    "& .listItem": {
      background: "#fff",
      margin: "40px 0",
      paddingLeft: "24px",
      cursor: "pointer",
    },
    "& .nested-listItem": {
      background: "#fff",
      paddingLeft: theme.spacing(3),
      cursor: "pointer",
    },
  },
  collapse: {
    marginTop: -theme.spacing(5),
  },
  detailWrapper: {
    minHeight: "300px",
    maxHeight: "80vh",
    overflow: "scroll",
  },
  catDetail: {
    background: "#fff",
    padding: theme.spacing(4),
    boxShadow: "0px 1px 3px #0000001A",
    marginTop: "50px",
  },
  circularProgress: {
    position: "absolute",
    left: "50%",
  },
  wrapper: {
    padding: "2em",
  },
}));

function WorkOrderTemplateDetails(props) {
  const classes = useStyles();
  const { data, selectedOrganisation } = props;
  const [isSub, setIsSub] = useState(false);
  const [loading, setLoading] = useState(false);
  const message = useContext(ToastMessageContext);
  const [sectionLoading, setSectionLoading] = useState(false);
  const [sections, setSections] = useState({});
  const [currentCategory, setCurrentCategory] = useState({});
  const [isExisting, setisExisting] = useState(false);
  const [fields, setFields] = useState([]);
  const [isSecondary, setIsSecondary] = useState(false);
  const [destroyFiledName, setDestroyFiledName] = useState(null);
  const [openSubCategory, setOpenSubcategory] = useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [categoryName, setCategoryName] = useState(null);
  const [currentSection, setCurrentSection] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [action, setAction] = useState(null);
  const [fieldAction, setFieldAction] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [editAnchorEl, setEditAnchorEl] = useState(null);
  const [fieldName, setFieldName] = useState(null);
  const [fieldId, setFieldId] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [subSection, setSubSection] = useState(false);
  const isGlobalReportTab = useIsGlobalReports();

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event, fieldname) => {
    setAnchorEl(event);
    setFieldName(fieldname);
  };
  const handleEditClick = (event, fieldname) => {
    setEditAnchorEl(event);
    setFieldName(fieldname);
  };
  useEffect(() => {
    setLoading(true);
    sectionList();
  }, []);
  const handleCloseSection = (data, id) => {
    console.log(data, id);
    let basesList = [];
    if (action === "create" && data && data.id) {
      basesList = [data].concat(sections);
      setSections(basesList);
    } else if (data && data.id) {
      basesList = sections.map((base) => {
        if (base.id === data.id) {
          return data;
        }
        return base;
      });
      setSections(basesList);
      setCategoryName(data.section_name);
    } else if (!data && id) {
      basesList = sections.filter((base) => base.id !== id);
      setSections(basesList);
    }
    setAction(null);
    // setSelectedIndex(null);
  };

  const sectionList = () => {
    let formData = new FormData();
    formData.append("report_name", "CallOut");
    LineReportClient.getData("/line_reports?report_name=CallOut")
      .then((res) => {
        if (res.sections) {
          const sections = res.sections;
          setSections(sections);
          const isExisting = sections.map((row) => row.existing_section);
          setisExisting(isExisting);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const [listItemClicked, setListItemClicked] = useState(true);
  const handleListItemClick = (event, index, category) => {
    setSelectedIndex(index);
    setCategoryName(category);
    setListItemClicked(true);
    const currentSection = sections.filter((row) => row.id === index);
    setCurrentSection(Object.values(currentSection));
    setTimeout(() => {
      setListItemClicked(false);
    }, 800);
    // console.log('currentSection', index,category)
  };
  const listFields = () => {
    let formData = new FormData();
    if (selectedIndex !== null) {
      formData.append("report_name", "CallOut");
      LineReportClient.getData(
       isGlobalReportTab ? `/line_reports/list_fields?section_id=${selectedIndex}`
        : `/line_reports/listing_org_fields?section_id=${selectedIndex}&organisation_id=${selectedOrganisation?.organisation_id}`
      )
        .then((res) => {
          if (res.fields) {
            const fields = res.fields;
            setFields(res.fields);
            const isSecondary = fields.map((row) => row.secondary_fields);
            setIsSecondary(isSecondary);
            const destoryFieldname = fields.map((row) => row.field_name);
            setDestroyFiledName(destoryFieldname);
          } else {
            setFields([]);
          }
          setSectionLoading(false);
        })
        .catch(() => {
          setSectionLoading(false);
        });
    }
  };
  const deleteField = () => {
    LineReportClient.deleteData(
      `/line_reports/destroy_method?field_id=${fieldId}`
    )
      .then((res) => {
        if (res.success) {
          handleClose();
          listFields();
          message.showToastMessage({
            open: true,
            message: "Successfully deleted",
            variant: "success",
          });
        } else {
          message.showToastMessage({
            open: true,
            message: res.errors
              ? res.errors.join(",")
              : "Something went wrong. Try Again",
            variant: "error",
          });
          setLoading(false);
        }
      })
      .catch(() => {
        message.showToastMessage({
          open: true,
          message: "Something went wrong. Try Again",
          variant: "error",
        });
        setLoading(false);
      });
  };

  useEffect(() => {
    setSectionLoading(true);
    listFields();
  }, [selectedIndex, currentSection]);
  const handleEditClose = (id) => {
    let basesList = [];
    if (action === "create" && data && data.id) {
      basesList = [data].concat(fields);
      setFields(basesList);
    } else if (data && data.id) {
      basesList = fields.map((base) => {
        if (base.id === data.id) {
          return data;
        }
        return base;
      });
      setFields(basesList);
    } else if (!data && id) {
      basesList = fields?.filter((base) => base?.id !== id);
      setFields(basesList);
    }
    setFieldAction(null);
    setFieldId("");
  };

  const handleStatusChange = (event, fieldId) => {
    LineReportClient.setOrgStatus(
      `/line_reports/org_specifics_action?custom_field_id=${fieldId}&organisation_id=${selectedOrganisation.organisation_id}
    `,
      {
        org_status: event.target.checked,
      }
    ).then((response) => {
      if (response.success) {
        listFields();
        message.showToastMessage({
          open: true,
          message: "Org status updated!",
          variant: "success",
        });
      } else {
        message.showToastMessage({
          open: true,
          message: response.errors
            ? response.errors.join(",")
            : "Something went wrong. Try Again",
          variant: "error",
        });
      }
    });
  };

  return (
    <div>
      <MROFullScreenRightDrawer>
        <AppBar position="static" className={classes.appBar} elevation={0}>
          <Toolbar>
            <IconButton
              onClick={props.handleClose}
              edge="start"
              className={classes.backButton}
              color="inherit"
              aria-label="back"
            >
              <BackIcon />
            </IconButton>
            <Typography variant="subtitle2" className={classes.title}>
              Line Report Editor - Edit &#40; Call Out &#41;
            </Typography>
          </Toolbar>
        </AppBar>
        {!loading ? (
          <div style={{ margin: "3%" }}>
            <Grid
              container
              spacing={5}
              alignItems="flex-start"
              justify="space-between"
            >
              <Grid item xs={3} className={classes.listWrapper}>
                <List className={classes.list}>
                  <div className={"vertical-dots"}></div>
                  {!loading && sections
                    ? Object.values(sections).map((cat, i) => {
                        return (
                          <div
                            key={cat.id}
                            style={
                              currentCategory.id === cat.id
                                ? {
                                    background: "#fff",
                                    borderLeft: "5px solid #26B4E8",
                                  }
                                : {}
                            }
                          >
                            <ListItem className={"listItem"} key={i}>
                              <ListItemText
                                onClick={(event) => {
                                  setOpenSubcategory(
                                    openSubCategory !== i ? i : i
                                  );
                                  handleListItemClick(
                                    event,
                                    cat.id,
                                    cat.section_name
                                  );
                                  setIsSub(false);
                                }}
                                key={i}
                                id={i}
                                primary={
                                  <Typography style={{ fontWeight: "bold" }}>
                                    {cat.section_name}
                                  </Typography>
                                }
                              />
                              {cat["sub_sections"] &&
                              cat["sub_sections"].length > 0 ? (
                                currentCategory.id === cat.id ? (
                                  <ExpandLess
                                    htmlColor={ICON_COLOUR}
                                    onClick={(event) => {
                                      setOpenSubcategory(openSubCategory({}));
                                      handleListItemClick(event, {});
                                    }}
                                  />
                                ) : (
                                  <ExpandMore
                                    htmlColor={ICON_COLOUR}
                                    onClick={(event) => {
                                      setOpenSubcategory(
                                        openSubCategory !== i ? i : ""
                                      );
                                      handleListItemClick(
                                        event,
                                        cat.id,
                                        cat.section_name
                                      );
                                      setIsSub(false);
                                    }}
                                  />
                                )
                              ) : null}
                            </ListItem>
                            {cat["sub_sections"] &&
                              cat["sub_sections"].length > 0 && (
                                <Collapse
                                  className={classes.collapse}
                                  in={openSubCategory === i}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  <List component="div" disablePadding dense>
                                    {cat &&
                                      cat.sub_sections &&
                                      cat.sub_sections.map((sub, j) => (
                                        <React.Fragment key={i}>
                                          {" "}
                                          <ListItem
                                            key={sub.id}
                                            selected={selectedIndex === i}
                                            button
                                            className={"nested-listItem"}
                                            onClick={(event) => {
                                              handleListItemClick(
                                                event,
                                                sub.id,
                                                sub.section_name
                                              );
                                              setIsSub(true);
                                            }}
                                          >
                                            <ListItemText
                                              primary={sub.section_name}
                                            />
                                          </ListItem>
                                          <List component="div" disablePadding>
                                            {sub &&
                                              sub.sub_sections &&
                                              sub.sub_sections.map(
                                                (underSub, j) => (
                                                  <ListItem
                                                    className={
                                                      "nested-listItem"
                                                    }
                                                    key={underSub.id}
                                                    selected={
                                                      selectedIndex === i
                                                    }
                                                    button
                                                    onClick={(event) => {
                                                      handleListItemClick(
                                                        event,
                                                        underSub.id,
                                                        underSub.section_name
                                                      );
                                                      setIsSub(true);
                                                    }}
                                                  >
                                                    <ListItemText
                                                      primary={
                                                        underSub.section_name
                                                      }
                                                    />
                                                  </ListItem>
                                                )
                                              )}
                                          </List>
                                        </React.Fragment>
                                      ))}
                                  </List>
                                </Collapse>
                              )}
                          </div>
                        );
                      })
                    : ""}
                </List>
                {isGlobalReportTab && (
                  <Button
                    className={classes.buttonpadding}
                    startIcon={<AddIcon />}
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      setShowModal("add-section");
                    }}
                  >
                    Add New Section
                  </Button>
                )}
              </Grid>
              <Grid item xs={9} className={classes.detailWrapper}>
                {!loading && selectedIndex !== null ? (
                  <div
                    className={classes.catDetail}
                    style={{ minHeight: "80vh" }}
                  >
                    {!loading && fields && selectedIndex !== null ? (
                      <List className={classes.list}>
                        <ListItem style={{ padding: 0 }}>
                          <Grid container alignItems="center">
                            <Grid item xs={4}>
                              <ListItemText
                                primary={
                                  selectedIndex !== null ? (
                                    <Typography style={{ fontWeight: "bold" }}>
                                      {" "}
                                      {categoryName}
                                    </Typography>
                                  ) : (
                                    " "
                                  )
                                }
                              />
                            </Grid>
                            {!isGlobalReportTab && (
                              <Grid item xs={4}>
                                <ListItemText
                                  style={{ textAlign: "right" }}
                                  primary={"Status"}
                                />
                              </Grid>
                            )}
                            {isGlobalReportTab && (
                              <>
                                <Grid item xs={3} container justify="flex-end">
                                  <IconButton
                                    onClick={() => {
                                      setAction("editSection");
                                    }}
                                  >
                                    <EditIcon />
                                  </IconButton>

                                  {/* <IconButton
                                    onClick={() => {
                                      setAction("delete");
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton> */}
                                </Grid>

                                <Grid item xs={5} container justify="flex-end">
                                  {selectedIndex !== null && !isSub && (
                                    <Button
                                      component={"span"}
                                      startIcon={<AddIcon />}
                                      color="secondary"
                                      variant="contained"
                                      onClick={() => {
                                        setSubSection(true);
                                      }}
                                    >
                                      {" "}
                                      Add Sub Section
                                    </Button>
                                  )}
                                  &nbsp;&nbsp;
                                  {selectedIndex !== null && isSub === true && (
                                    <Button
                                      style={{ float: "right" }}
                                      component={"span"}
                                      startIcon={<AddIcon />}
                                      color="secondary"
                                      variant="contained"
                                      onClick={() => {
                                        setFieldAction("create");
                                      }}
                                    >
                                      {" "}
                                      Add New Field
                                    </Button>
                                  )}
                                </Grid>
                              </>
                            )}
                          </Grid>
                        </ListItem>
                      </List>
                    ) : (
                      ""
                    )}

                    <List className={classes.list}>
                      <Typography style={{ fontWeight: "bold" }}>
                        Fields
                      </Typography>
                      {listItemClicked && (
                        <CircularProgress
                          className={classes.circularProgress}
                          color="primary"
                        />
                      )}
                      {!loading && fields ? (
                        Object.values(fields).map((field, i) => {
                          return (
                            <React.Fragment key={i}>
                              <ListItem disableGutters>
                                <ListItemText primary={field.field_name} />

                                <ListItemSecondaryAction
                                  style={{
                                    right: isGlobalReportTab ? "29%" : "33%",
                                  }}
                                >
                                  {!isGlobalReportTab && (
                                    <PositiveSwitch
                                      onChange={(e) =>
                                        handleStatusChange(e, field.id)
                                      }
                                      checked={field.org_status}
                                    />
                                  )}
                                  {isGlobalReportTab && (
                                    <>
                                      {field.existing_field === false && (
                                        <IconButton
                                          onClick={() => {
                                            handleEditClick(
                                              field.id,
                                              field.field_name
                                            );
                                            setFieldAction("update");
                                          }}
                                        >
                                          <EditIcon />
                                        </IconButton>
                                      )}
                                      {field.existing_field === false && (
                                        <React.Fragment>
                                          <IconButton
                                            onClick={() => {
                                              handleClick(
                                                field.id,
                                                field.field_name
                                              );
                                              setFieldId(field.id);
                                            }}
                                          >
                                            <DeleteIcon />
                                          </IconButton>
                                        </React.Fragment>
                                      )}
                                    </>
                                  )}
                                </ListItemSecondaryAction>
                              </ListItem>
                              <List
                                component="div"
                                className={classes.nestedList}
                              >
                                {field &&
                                  field.secondary_fields &&
                                  field.secondary_fields.map((sub, i) => (
                                    <ListItem>
                                      <ListItemText
                                        primary={sub.secondary_field_name}
                                      />
                                      {field.existing_field === false && (
                                        <ListItemSecondaryAction
                                          style={{ right: "29%" }}
                                        >
                                          <IconButton>
                                            <EditIcon />
                                          </IconButton>
                                          <IconButton
                                            type="submit"
                                            onClick={() => {
                                              handleClick(sub.secondary_id);
                                              setFieldId(sub.secondary_id);
                                            }}
                                          >
                                            <DeleteIcon />
                                          </IconButton>
                                        </ListItemSecondaryAction>
                                      )}
                                    </ListItem>
                                  ))}
                              </List>
                            </React.Fragment>
                          );
                        })
                      ) : (
                        <div
                          className={"center"}
                          style={{ minHeight: "200px" }}
                        >
                          <CircularProgress color="primary" />
                        </div>
                      )}
                    </List>
                  </div>
                ) : (
                  <div
                    className={`center ${classes.catDetail}`}
                    style={{ minHeight: "80vh" }}
                  >
                    <Typography variant={"subtitle1"}>
                      Select a group to configure details
                    </Typography>
                  </div>
                )}
              </Grid>
            </Grid>
          </div>
        ) : (
          <div className={"center"} style={{ minHeight: "200px" }}>
            <CircularProgress color="primary" />
          </div>
        )}
        {/* <Popover
          id={id}
          open={open}
          style={{ left: "34%" }}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
        >
          <div className={classes.wrapper}>
            <Typography>
              Confirm remove <b>{fieldName}</b> ? <br /> All associated data
              will be removed. Once removed cannot be undone.
            </Typography>
            <div className={classes.buttons}>
              <MROButton variant={"contained"} color="secondary" onClick={handleClose}>
                {" "}
                No{" "}
              </MROButton>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <MROButton
                variant={"outlined"}
                color={"primary"}
                onClick={deleteField}
              >
                {" "}
                Yes{" "}
              </MROButton>
            </div>
          </div>
        </Popover> */}
         <DeleteDialog
          open={open}
          handleClose={handleClose}
          title={
            <>
              Confirm remove <b>{fieldName}</b> ?
            </>
          }
          confirmHandler={deleteField}
        />
        {action && (
          <ManageSection
            fieldId={editAnchorEl}
            currentSection={currentSection}
            setSelectedIndex={setSelectedIndex}
            sectionList={sectionList}
            listFields={listFields}
            setLoading={setLoading}
            setFields={setFields}
            destroyFiledName={setDestroyFiledName}
            action={action}
            selectedIndex={selectedIndex}
            handleCloseSection={handleCloseSection}
          />
        )}
        {showModal === "add-field" && (
          <AddNewField
            open={showModal}
            listFields={listFields}
            sectionList={sectionList}
            selectedIndex={selectedIndex}
            setLoading={setLoading}
            onClose={setShowModal}
          />
        )}
        {fieldAction && (
          <ManageField
            setFieldId={setFieldId}
            action={fieldAction}
            open={showEdit}
            fields={fields}
            listFields={listFields}
            sectionList={sectionList}
            selectedIndex={selectedIndex}
            setLoading={setLoading}
            fieldId={editAnchorEl}
            handleEditClose={handleEditClose}
            onClose={setShowEdit}
          />
        )}
        {showModal === "add-section" && (
          <AddSection
            open={showModal}
            sectionList={sectionList}
            setLoading={setLoading}
            onClose={setShowModal}
          />
        )}
        {subSection && (
          <AddSubSection
            open={subSection}
            listFields={listFields}
            sectionList={sectionList}
            selectedIndex={selectedIndex}
            setLoading={setLoading}
            onClose={setSubSection}
          />
        )}
      </MROFullScreenRightDrawer>
    </div>
  );
}

export default WorkOrderTemplateDetails;
