import React, { useState, useContext } from 'react';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import MROButton from "../../components/buttons";
import { ToastMessageContext } from "../../lib/contexts/message_context";
import LineReportClient from "../../lib/services/api/line_report";

const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: theme.spacing(3),
    },
    buttons: {
        textAlign: 'right',
        margin: theme.spacing(2, 0)
    }
}));

function DeleteSection(props) {
    const { data, url } = props;
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = [props.open, props.onClose];
    const message = useContext(ToastMessageContext);
    const [loading, setLoading] = useState(false);
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(false);
    };

    // const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    // const handleDeleteUser =() =>{
    //     LineReportClient.deleteData(`/line_reports/destroy_method?section_id=${props.selectedIndex}`,).then((res) => {
    //         if(res.success){
    //             handleClose();
    //             props.setFields(null)
    //             props.listFields(null)
    //             props.sectionList(null)
    //             props.destroyFiledName(null)
    //         }
    //     })
    // };
    const handleDeleteUser = () => {
        setLoading(true);
        LineReportClient.deleteData(`/line_reports/destroy_method?section_id=${props.selectedIndex}`).then((res) => {
            if (res.success) {
                message.showToastMessage({ open: true, message: "Supplier successfully removed", variant: "success" });
                handleClose(null);
            }
            else {
                message.showToastMessage({ open: true, message: res.errors ? res.errors.join(',') : "Something went wrong. Try Again", variant: "error" });
                handleClose();
            }
        }).catch(() => {
            message.showToastMessage({ open: true, message: "Something went wrong. Try Again", variant: "error" });
            handleClose();
        })
    }
    return (
        <React.Fragment>
            {/* <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                style={{left: '34%'}}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
            >
                <div className={classes.wrapper}>
                    <Typography>
                        Confirm remove? <br/> All associated data will be removed. Once removed cannot be undone.
                    </Typography>
                    <div className={classes.buttons}>
                        <Button variant={"contained"} onClick={handleClose}> No </Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Button variant={"outlined"} color={"secondary"} onClick={handleDeleteUser}> Yes </Button>
                    </div>
                </div>
            </Popover> */}
            <div className={classes.formWrapper}>
                Confirm remove ? <br /> All associated data will be removed. Once removed cannot be undone.
                <div className={classes.actionButtons}>
                    <MROButton disabled={loading} type="button" variant={"contained"} onClick={handleClose}>Cancel</MROButton>
                    <MROButton disabled={loading} type="button" variant={"contained"} color="primary" onClick={handleDeleteUser}>Yes, Confirm</MROButton>
                </div>
            </div>
        </React.Fragment>
    )
}

export default DeleteSection;