import React, { useState, useEffect, useContext } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MROFullScreenRightDrawer from "../../components/fullwidth_dialog";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import BackIcon from '@material-ui/icons/KeyboardBackspace';
import { ToastMessageContext } from "../../lib/contexts/message_context";
import Grid from "@material-ui/core/Grid";
import MROTextField from '../../components/form_components/NewTextField'
import FormControlLabel from "@material-ui/core/FormControlLabel";
import BackupIcon from '@material-ui/icons/Backup';
import MROButton from "../../components/buttons";
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import PositiveSwitch from "../../components/form_components/switch";
import OrganisationApiClient from "../../lib/services/api/organisation_details";
import './new_organisation.css'
import CreateOrgSetup from "./create_organisation_setup";
import MaterialTable from "material-table";
import tableIcons, { options } from "../../components/Table/icons";
import { InputAdornment, MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import { theme } from "../../App";
import baseIcon from '../../assets/bases.svg';
import AddIcon from '@material-ui/icons/Add';
import { ICON_COLOUR } from "../../lib/constants/style_constants";
import { Delete, Edit } from "@material-ui/icons";
import CreateAlternateAddress from "./CreateAlternateAddress";
import _ from "lodash";

const useStyles = makeStyles(theme => ({
    content: {
        width: "calc(100% - 250px)"
    },
    appBar: {
        backgroundColor: "#fff",
        color: '#000000CC',
        boxShadow: "0px 3px 6px #0000001A",
    },
    backButton: {
        marginRight: theme.spacing(2),
    },
    wrapper: {
        margin: '3%',
        padding: '3%',
        background: '#fff',
        boxShadow: "0px 1px 4px #0000001A",
        '& form': {
            paddingTop: '25px',
            // width: '85%',
            '& .MuiGrid-spacing-xs-5': {
                '& .MuiGrid-item': {
                    paddingBottom: '0'
                }
            }
        }
    },
    actionButtons: {
        paddingTop: '20px',
        textAlign: 'right',
        '& button': {
            marginRight: '20px'
        }
    },
    tabs: {
        padding: '20px 10px',
        fontSize: '16px',
        color: theme.palette.grey[400],
        fontWeight: 500,
        cursor: 'pointer',
        '& .selected': {
            color: theme.palette.primary.main,
            fontWeight: 700,
            fontSize: '18px',
        }
    },
    message: {
        color: "red",
        fontSize: '20px'
    },
    textBlack: {
        color: `${theme.palette.common.black} !important`,
        fontSize: '14px',
    },
    formWrapper: {
        width: '85%'
    }
}
));

function ManageUsers(props) {
    const classes = useStyles();
    const [checked, setChecked] = useState('');
    const message = useContext(ToastMessageContext);
    const [webStatus, setWebStatus] = useState(null);
    const [organisationId, setOrganisationId] = useState({});
    const [statusChecked, setStatusChecked] = useState(false);
    const [alertMessage, setAlertMessage] = useState([]);
    const [open, setOpen] = [props.open, props.onClose];
    const [step, setStep] = useState(1);
    const [tempAction, setTempAction] = useState(null);
    const [webChecked, setWebChecked] = useState({});
    const [completedSteps, setCompletedSteps] = useState(1);
    const [loading, setLoading] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [state, setState] = useState({
        right: false,
    });
    const [currentTimeZone, setCurrentTimeZone] = useState('');
    const [currentSelection, setCurrentSelection] = useState({})
    const [action, setAction] = useState({})
    const [alternateData, setAlternateData] = useState([])
    const [tableLoading, setTableLoading] = useState(false)
    const [primaryColor, setPrimaryColor] = useState('')
    const [error, setError] = useState({});
    const [orgId, setOrgId] = useState(null)
    const toggleDrawer = () => {
        props.handleClose();
    };
    const toggleTempDrawer = () => {
        setTempAction('edit')
    };
    const toggleWebAppln = () => {
        setWebStatus('web')
    };
    const handleNext = () => {
        const newStep = step + 1;
        if (step >= completedSteps) {
            setCompletedSteps(newStep)
        }
        setStep(newStep);
    };

    useEffect(() => {
        setStep(1);
    }, [open]);
    const toggleSidebar = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const handleAlternateAddress = (id) => {
        const formData = {}
        const altAddress =
            alternateData?.length &&
            alternateData.map((item) => {
                return {
                    name: item.name,
                    line_1: item?.line_1,
                    line_2: item?.line_2,
                    line_3: item?.line_3,
                    city: item?.city,
                    postcode: item?.postcode,
                    country: item?.country
                };
            });

        formData["organisation_id"] = id
        formData["data"] = altAddress


        OrganisationApiClient.postMultipleAlternateAddressData(formData).then((res) => {

        })

    }


    const handleSubmit = (e) => {
        e.preventDefault();
        let formData = new FormData(e.target);



        let valid = true;
        let errors = {};

        for (let pair of formData.entries()) {

            const value = pair[1];
            const key = pair[0];
            if (key !== "address_line_2" && key !== "address_line_3" && key !== "notification_time") {
                if (!value) {
                    errors[key] = true;
                    valid = false;
                }
            }
        }
        if (!valid) {
            setError(errors);
            return;
        }
        formData.append('status', statusChecked)
        const primarycode = formData.get("primary_color_code")
        const secondarycode = formData.get("secondary_color_code")
        formData.delete("primary_color_code")
        formData.delete("secondary_color_code")

        formData.append("primary_color_code", `#${primarycode}`)
        formData.append("secondary_color_code", `#${secondarycode}`)
        // if(step === 1 ){
        // OrganisationApiClient.postData(formData,`/organisations/create_organisation_details`).then((res)=>{
        //    let organisationId = res.organisation.id;
        //    setOrganisationId(organisationId);
        //    handleNext()
        //     }).catch(()=>{
        //         setLoading(false);
        //     });
        // }
        setLoading(true);
        if (alternateData.length > 0) {
            console.log("alternatedata==", alternateData)
            // handleAlternateAddress(8)
        }
        OrganisationApiClient.postData(formData, `/organisations/create_organisation_details`).then((res) => {
            if (res.organisation) {
                let organisationId = res.organisation.id;
                setOrganisationId(organisationId);
                setOrgId(res.organisation.id)
                if (alternateData.length > 0) {
                    handleAlternateAddress(res.organisation.id)
                }
                props.organisationList()
                handleNext()
                message.showToastMessage({ message: "New Organisation Successfully created", variant: "success" })
            }
            else if (res.errors) {
                message.showToastMessage({ message: res.errors ? res.errors.toString() : "Something went wrong. Try again", variant: "error" })
            }
        }).catch(() => {
            message.showToastMessage({ message: "Something went wrong. Try again", variant: "error" })
        }).finally(() => setLoading(false))
    }
    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            let reader = new FileReader();

            reader.onload = (e) => {
                setPreviewImage(e.target.result)
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    }
    const handleCheckedCategory = (offeredServices) => {
        setChecked(offeredServices)
    };
    const handleWebCategory = (webServices) => {
        setWebChecked(webServices)
    };

    const tableOptions = {
        ...options,
        paging: false,
        search: false,
        toolbar: true
    }

    const _theme = createMuiTheme({
        ...theme,
        overrides: {
            ...theme.overrides,

            MuiTableCell: {
                ...theme.overrides.MuiTableCell,
                root: {
                    ...theme.overrides.MuiTableCell.root,
                    paddingLeft: 20
                }
            },
            MuiIconButton: {
                root: {
                    color: '#fff',
                    padding: "12px",
                    overflow: "visible",
                    fontSize: "1.5rem",
                    textAlign: "center",
                    borderRadius: '10%',
                    width: '23px',
                    background: theme.palette.secondary.main,
                    height: '23px',
                    '&:hover': {
                        backgroundColor: 'none',
                    }
                }
            },
        }
    });


    const handleDeleteAltAddress = (rowData) => {
        setTableLoading(true);
        const currentId = rowData?.id;
        const newData = alternateData.filter((item) => {
            const itemId = item?.id ?? item?.row_id;
            return itemId !== currentId;
        });


        setTimeout(() => {
            setAlternateData([...newData]);
            setAction('')
            setTableLoading(false);
        }, 2000);
        message.showToastMessage({ message: "Address deleted successfully", variant: "success" })

    };
    const columns = [
        {
            title: 'Name',
            field: 'name',
            width: '20%',
            headerStyle: {
                textAlign: 'left'
            },
            cellStyle: {
                textAlign: 'left',
            }
        },
        {
            title: 'Address',
            field: 'line',
            width: '60%',
            headerStyle: {
                textAlign: 'left'
            },
            cellStyle: {
                textAlign: 'left',
            },
            render: (rowData) => _.concat(rowData?.line_1, rowData?.line_2 ? "," : "", rowData?.line_2, rowData?.line_3 ? "," : "", rowData?.line_3, ",", rowData?.city, ",", rowData?.postcode, ",", rowData?.country)
        },
        {
            title: "Action",
            field: '',
            render: (rowData) =>
                <div>
                    <img src={baseIcon} height={'16px'} style={{
                        marginTop: "-11px",
                        marginRight: "5px",
                        cursor: "pointer"
                    }} onClick={() => {

                        message.showToastMessage({ message: "Create organisation and Bases first!", variant: "error" })

                    }} />
                    <Edit fontSize="small" htmlColor={ICON_COLOUR} style={{ cursor: 'pointer' }} onClick={() => {

                        setAction("update_address")
                        setCurrentSelection(rowData)
                    }} />
                    <Delete fontSize="small" htmlColor={ICON_COLOUR} style={{ cursor: 'pointer' }} onClick={() => {

                        setAction("delete")
                        handleDeleteAltAddress(rowData)
                    }} />
                </div>
        }





    ];


    return (
        <MROFullScreenRightDrawer onClose={toggleDrawer}>
            <AppBar position="static" className={classes.appBar} elevation={0}>
                <Toolbar>
                    <IconButton onClick={toggleDrawer} edge="start" className={classes.backButton} color="inherit" aria-label="back">
                        <BackIcon />
                    </IconButton>
                    <Typography variant="subtitle2" className={classes.title}>
                        Create New Organisation
                    </Typography>
                </Toolbar>
            </AppBar>
            <div className={classes.wrapper}>
                <Grid container spacing={5} alignItems={"center"} justify={"flex-start"}>
                    <Grid item xs={8}>

                        <ul class="steps">
                            <li className={step === 1 ? "active" : "undone"} onClick={() => { setStep(1) }}> <span > Organisation Details </span>  </li> &nbsp;
                            <li className={step === 2 ? "active" : "undone"} onClick={() => { setStep(2) }}> <span style={{ marginLeft: -4 }}> Feature Setup </span> </li>
                        </ul>
                    </Grid>
                    <Grid item xs={4} style={{ display: step !== 1 ? 'none' : 'block' }}>
                        <FormControlLabel
                            control={
                                <PositiveSwitch
                                    checked={statusChecked}
                                    onChange={step === 1 ? (e) => setStatusChecked(e.target.checked) : ''}
                                    name="status"
                                />
                            }
                            label="Status"
                            labelPlacement="start"
                            name={"status"}
                        />
                    </Grid>
                </Grid>
                <div style={{ display: step === 1 ? 'block' : 'none' }} >
                    <form onSubmit={handleSubmit}>
                        <div className={classes.formWrapper}>

                            <Grid item xs={12}>
                                <Button variant="contained" disabled className={classes.textBlack}>Company Details</Button>
                            </Grid>
                            <br />
                            <Grid container spacing={5} alignItems={"center"} justify={"flex-start"}>


                                <Grid item xs={4}>
                                    <MROTextField
                                        name={"company_name"}
                                        label={"Company Name *"}
                                        InputLabelProps={{ shrink: true }}
                                        error={error?.company_name}
                                        helperText={
                                            error.company_name ? "*please enter Company name" : null
                                        }
                                        onChange={(e) => { setError({ ...error, company_name: false }); }}
                                        inputProps={{ maxLength: 40 }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <MROTextField
                                        name={"mro_id"}
                                        label={"MRO ID *"}
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ maxLength: 10 }}
                                        error={error?.mro_id}
                                        helperText={
                                            error.mro_id ? "*please enter MRO ID" : null
                                        }
                                        onChange={(e) => { setError({ ...error, mro_id: false }); }}

                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <MROTextField
                                        id="time"
                                        name="notification_time"
                                        label="Notification Time"
                                        type="time"
                                        defaultValue="00:00"
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>

                            </Grid>
                            <Grid container spacing={5} alignItems={"center"} justify={"flex-start"}>

                                <Grid item xs={8}>
                                    <MROTextField
                                        name={"address_line_1"}
                                        label={"Address Line 1 *"}
                                        InputLabelProps={{ shrink: true }}
                                        error={error?.address_line_1}
                                        helperText={
                                            error.address_line_1 ? "*please enter address line 1" : null
                                        }
                                        onChange={(e) => { setError({ ...error, address_line_1: false }); }}
                                        inputProps={{ maxLength: 40 }}
                                    />
                                </Grid>

                                <Grid item xs={4} >
                                    <MROTextField
                                        name={"city"}
                                        label={"City *"}
                                        InputLabelProps={{ shrink: true }}
                                        error={error?.city}
                                        helperText={
                                            error.city ? "*please enter city" : null
                                        }
                                        onChange={(e) => { setError({ ...error, city: false }); }}
                                        inputProps={{ maxLength: 40 }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={5} alignItems={"center"} justify={"flex-start"}>
                                <Grid item xs={8}>
                                    <MROTextField
                                        name={"address_line_2"}
                                        label={"Address Line 2"}
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ maxLength: 40 }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <MROTextField
                                        name={"post_code"}
                                        label={"Postcode *"}
                                        InputLabelProps={{ shrink: true }}
                                        error={error?.post_code}
                                        helperText={
                                            error.post_code ? "*please enter post code" : null
                                        }
                                        onChange={(e) => { setError({ ...error, post_code: false }); }}
                                        inputProps={{ maxLength: 40 }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={5} alignItems={"center"} justify={"flex-start"}>

                                <Grid item xs={8}>
                                    <MROTextField
                                        name={"address_line_3"}
                                        label={"Address Line 3"}
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ maxLength: 40 }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <MROTextField
                                        name={"country"}
                                        label={"Country *"}
                                        InputLabelProps={{ shrink: true }}
                                        error={error?.country}
                                        helperText={
                                            error.name ? "*please enter country" : null
                                        }
                                        onChange={(e) => { setError({ ...error, country: false }); }}
                                        inputProps={{ maxLength: 40 }}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                        <br />
                        <br />
                        <Grid item xs={12} style={{ paddingBottom: '15px' }}>
                            <Button variant="contained" disabled className={classes.textBlack}>Alternate Address</Button>
                        </Grid>
                        <br />
                        <Grid>

                            <MuiThemeProvider theme={_theme}>
                                <MaterialTable
                                    style={{ boxShadow: '0px 1px 3px #00000033' }}
                                    icons={tableIcons}
                                    title={""}
                                    columns={columns}
                                    data={alternateData || []}
                                    isLoading={tableLoading}
                                    options={tableOptions}

                                    actions={[

                                        {
                                            icon: () =>
                                                <AddIcon fontSize="small" style={{ filter: 'invert(1)' }} />,

                                            tooltip: 'Add',
                                            isFreeAction: true,
                                            onClick: (event, rowData) => {
                                                setCurrentSelection(rowData);
                                                setAction('add_address')
                                            }
                                        },
                                    ]}
                                />
                            </MuiThemeProvider>

                        </Grid>
                        <br />
                        <br />
                        <Grid item xs={6}>
                            {/* <TimeZone currentTimeZone={currentTimeZone} setCurrentTimeZone={setCurrentTimeZone} /> */}
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" disabled className={classes.textBlack}>Company Colors & Logo details</Button>
                        </Grid>

                        <Grid item xs={12} style={{ marginTop: '10px', marginBottom: '20px' }} spacing={5} container>
                            <Grid item xs={4}>
                                <MROTextField
                                    name={"primary_color_code"}
                                    label={"Primary Color Code *"}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 10 }}
                                    value={primaryColor}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Typography
                                                    color="primary"
                                                    style={{ marginRight: "6px", fontWeight: "bold" }}
                                                >
                                                    #
                                                </Typography>
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={(e) => {
                                        setPrimaryColor(e.target.value)
                                        setError({ ...error, primary_color_code: false });
                                    }}
                                    error={error?.primary_color_code}
                                    helperText={
                                        error.primary_color_code ? "*please enter primary color" : null
                                    }
                                />

                            </Grid>
                            <Grid item xs={4}>
                                <MROTextField
                                    name={"secondary_color_code"}
                                    label={"Secondary Color Code *"}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 10 }}
                                    onChange={(e) => {
                                        setError({ ...error, secondary_color_code: false });
                                    }}
                                    error={error?.secondary_color_code}
                                    helperText={
                                        error.secondary_color_code ? "*please enter secondary color " : null
                                    }
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Typography
                                                    color="primary"
                                                    style={{ marginRight: "6px", fontWeight: "bold" }}
                                                >
                                                    #
                                                </Typography>
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                            </Grid>

                        </Grid>
                        <br />
                        {primaryColor || previewImage ?
                            <Grid style={{ background: primaryColor ? `#${primaryColor}` : '', height: 'max-content' }} item xs={7} spacing={5}>
                                <Grid alignItems={"center"} style={{ padding: '22px' }}>
                                    {previewImage ? <img id="target" alt="" style={{ width: '22%', height: '20%' }} src={previewImage} /> : ''} <br />
                                </Grid>
                            </Grid> : ""}
                        <Grid item xs={12}>
                            <FormHelperText><i>Logo should be 715 pixels wide</i></FormHelperText>
                        </Grid>

                        <Grid item xs={4}>
                            <MROButton
                                component="label"
                                startIcon={<BackupIcon />}
                                color="secondary"
                                variant="contained"
                            >
                                Upload File
                                <input
                                    type="file"
                                    accept="avatar/*"
                                    name="avatar"
                                    onChange={onImageChange}
                                    style={{ display: "none" }}
                                // inputProps={{accept:"application/pdf",required:true}}
                                />
                                {/* <InputBase
                        name={"image"}
                        label={"Upload"}
                        type="file"
                        accept="application/pdf"
                        onChange={onImageChange}
                        required
                        inputProps={{accept:"application/pdf",required:true}}
                    /> 
                                     <InputBase
                        name={"calibration_certificate"}
                        label={"Upload"}
                        type="file"
                        accept="application/pdf"
                        onChange={onImageChange}
                        required
                        inputProps={{accept:"application/pdf"}}
                    />                          */}
                                <br />
                                {/* {  previewImage===''  ?  <FormHelperText>Choose the file to upload</FormHelperText> :'' } */}
                                {/* {data.calibration_certificate ?  <a href={"https://preprod-api.mro-pro.com/:443/" + data.calibration_certificate} target="_blank" rel="noopener noreferrer">Uploaded File</a>:''} */}

                            </MROButton> <br />

                        </Grid>


                        <Grid item xs={4}>
                        </Grid>
                        <Grid item xs={4}>
                        </Grid>

                        <Grid item xs={4}>
                        </Grid>
                        <Grid item xs={4}>
                        </Grid>


                        {step === 1 && <div className={classes.actionButtons}>
                            <MROButton disabled={loading} variant={"contained"} color="tertiary" onClick={toggleDrawer}>Cancel</MROButton>
                            <MROButton disabled={loading} variant={"contained"} color="primary" type="submit">  Save & Next</MROButton>
                        </div>}
                    </form>
                </div>
                <Typography variant="subtitle1" gutterBottom className={classes.message}>{alertMessage.alertMessage}</Typography>
                {
                    step === 2 && <div className={classes.formWrapper} style={{ display: step === 2 ? 'block' : 'none' }}>
                        <CreateOrgSetup organisationList={props.organisationList} organisationId={organisationId} handleClose={props.handleClose} />
                    </div>
                }
                {action && action === "add_address" && <CreateAlternateAddress action={action} handleClose={() => {
                    setAction('')
                }} setAlternateData={setAlternateData} alternateData={alternateData} />}
                {action && action === "update_address" && <CreateAlternateAddress action={action} handleClose={() => {
                    setAction('')
                }} setAlternateData={setAlternateData}
                    alternateData={alternateData}
                    currentSelection={currentSelection}
                />}
            </div >
        </MROFullScreenRightDrawer >
    )
}

export default ManageUsers;