import React,{useState,useEffect} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MRORightDrawer from "../../components/temporary_sidebar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Switch from "@material-ui/core/Switch";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import PositiveSwitch from "../../components/form_components/switch";
import OrganisationApiClient from "../../lib/services/api/organisation_details";

const useStyles = makeStyles(theme => ({
    list:{
        paddingLeft:0,
        '& .MuiListItem-gutters':{
            paddingLeft:0
        },
        '& .MuiListItemIcon-root':{
            minWidth:'35px',
            cursor:'pointer'
        }
    },
    nestedList:{
        background: '#f5f5f5',
        padding:theme.spacing(1.5)
    }
}));

function WebCategories(props){
    const classes = useStyles();
    const [openSubCategory,setOpenSubcategory] = useState(null);
    const [webCategory, setWebCategory] = useState({});
    const [statusChecked,setStatusChecked] = useState(false);
    const [category,setCategory] = useState([]);
    const [changed,setChanged] = useState(false);

    const toggleDrawer = () => {
        props.webApplnClose();
    };

    const organisationList = ()=>{
      OrganisationApiClient.getData(`/web_categories`).then((res)=>{
         setWebCategory(res)
      })
  }
    useEffect(() => {
      organisationList()
    }, [])

    const toggleChecked = (checked,id) => {
        setChanged(true);
        let temp=[''];
       temp= category.concat(id);
        setCategory(temp)
        if(changed){
          props.handleCheckedCategory(temp);
      }
    };
    return (
        <MRORightDrawer onClose={toggleDrawer}>
            <div className={"align-left-space-between"}>
                <div>
                    <Typography variant="subtitle2" gutterBottom>Web applications</Typography>
                    <Typography color="secondary" variant="subtitle2" gutterBottom>{"name"}</Typography>
                </div>
                <IconButton onClick={toggleDrawer}>
                    <CloseIcon fontSize={"small"}/>
                </IconButton>
            </div>
            <div>
                <List className={classes.list}>
                    <ListItem>
                        <ListItemText><div className={"align-left-space-between"}><Typography variant="subtitle2" gutterBottom>Module Name</Typography><Typography variant="subtitle2" gutterBottom>Yes/No</Typography></div></ListItemText>
                    </ListItem>
                    {Object.values(webCategory).map((item,i)=>{
                        return(
                            <React.Fragment>
                                <ListItem>
                                    <ListItemIcon onClick={()=> setOpenSubcategory(openSubCategory !== i?i:null)}>
                                        {openSubCategory === i ? <ExpandLess/> : <ExpandMore />}
                                    </ListItemIcon>
                                    <ListItemText primary={item.name} />
                                    <ListItemSecondaryAction>
                                        <PositiveSwitch
                                            edge="end"
                                            checked={category[item.id]}
                                            onChange={(e)=>toggleChecked(e.target.checked,item.id)}
                                            inputProps={{ 'aria-labelledby': 'on-or-off' }}
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Divider/>
                                <Collapse in={openSubCategory === i} timeout="auto">
                                    <List component="div" className={classes.nestedList}>
                                        {item.sub_categories?item.sub_categories.map((sub,i)=> <ListItem>
                                            <ListItemText primary={sub.name} />
                                            <ListItemSecondaryAction>
                                                <PositiveSwitch
                                                    edge="end"
                                                    checked={category[item.id]}
                                                    onChange={(e)=>toggleChecked(e.target.checked,sub.id)}
                                                    inputProps={{ 'aria-labelledby': 'on-or-off' }}
                                                />
                                            </ListItemSecondaryAction>
                                        </ListItem>):'no sub categories'}
                                    </List>
                                </Collapse>
                            </React.Fragment>
                        )
                    })}
                </List>
            </div>

        </MRORightDrawer>
    )
}

export default WebCategories;