import React, { useState, useEffect, useContext } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ToastMessageContext } from "../../../lib/contexts/message_context";
import StationService from '../../../lib/services/api/station_service';
import MROTextField from "../../../components/form_components/TextField";
import Typography from "@material-ui/core/Typography";
import MROButton from "../../../components/buttons";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles(theme => ({
    list: {
        paddingLeft: 0,
        '& .MuiListItem-gutters': {
            paddingLeft: 0
        },
        '& .MuiListItemIcon-root': {
            minWidth: '35px',
            cursor: 'pointer'
        },
        maxHeight: '90vh',
        overflow: 'scroll'
    },
    title: {
        paddingBottom: theme.spacing(2)
    },
    actionButtons: {
        paddingTop: '20px',
        textAlign: 'right',
        '& button': {
            marginRight: '20px'
        }
    },
    nestedList: {
        background: '#f5f5f5',
        paddingLeft: theme.spacing(4)
    },
    alignCenter: {
        display: 'flex',
        alignItems: 'center',
    },
    center: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}));
let updateOccured = false;

function ManageQuestions(props) {
    const classes = useStyles();
    const { action, handleClose, data } = props;
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState('day');

    const message = useContext(ToastMessageContext);

    const handleradioChange = (event) => {
        const radioValue = event.target.value;
        setValue(radioValue);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let formData = new FormData(e.target);
        StationService.createStationList(formData).then((res) => {
            if (res.data) {
                handleClose()
                props.setLoading(true);
                setTimeout(() => {
                    props.setLoading(false);
                    props.StationList()
                }, 300);
                message.showToastMessage({ open: true, message: "New Station added successfully", variant: "success" });
            }
            else {
                message.showToastMessage({ open: true, message: res.errors ? res.errors.join(',') : "Something went wrong. Try Again", variant: "error" });
                setLoading(false);
            }
        }).catch(() => {
            message.showToastMessage({ open: true, message: "Something went wrong. Try Again", variant: "error" });
            setLoading(false);
        })
    }

    return (
        <Dialog
            maxWidth={'sm'}
            open={true}
            onClose={handleClose}
            aria-labelledby="info-manage"
        >
            <DialogContent>
                <Typography variant="subtitle2" color="secondary" className={classes.title}>
                    New Station Check
            </Typography>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={1} alignItems={"center"} justify={"flex-start"}>
                        <Grid item xs={6}>
                            <MROTextField
                                name={"title"}
                                label={"Station Check Name"}
                                InputLabelProps={{ shrink: true }}
                                required
                                inputProps={{ maxLength: 20 }}
                            />
                        </Grid>
                        <Grid item xs={6} />
                        <Grid item xs={1.5}>
                            <FormHelperText>Repeat every</FormHelperText>
                        </Grid>
                        <Grid item xs={3}>
                            <MROTextField
                                type="number"
                                name={"number"}
                                required
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl component="fieldset">
                                <Typography style={{opacity: "0.5"}}>Days</Typography>
                                {/* <RadioGroup row aria-label="item" name="duration" onChange={handleradioChange}> */}
                                    {/* <FormControlLabel value="day" control={<Radio required />} label="Days" /> */}
                                    {/* <FormControlLabel value="week" control={<Radio required />} label="Week" />
                                    <FormControlLabel value="month" control={<Radio required />} label="Month" /> */}
                                {/* </RadioGroup> */}
                            </FormControl>
                        </Grid>
                    </Grid>
                    <div className={classes.actionButtons}>
                        <MROButton type="button" variant={"contained"} onClick={handleClose}>Cancel</MROButton>
                        <MROButton type="submit" variant={"contained"} color="primary">Save</MROButton>
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    )
}

export default ManageQuestions;