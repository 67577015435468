import React, { useEffect, useState } from "react";
import MROButton from "../buttons";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import FileIcon from "@material-ui/icons/AttachFile";
import { ReactComponent as UploadIcon } from "../../assets/icons/UploadDoc.svg";


const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    input: {
        display: 'none',
    },
}));


function UploadButton(props) {
    const classes = useStyles();
    const { accept, name, action, multiple, defaultValue, process, id } = props;
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);

    const fileList = Array.from(files || []);


    const handleChange = (e) => {
        console.log(e.target.files)
        setFiles([...files, e.target.files])
    }

    useEffect(() => {
        props.setFiles([...files])
    }, [files.length])
    return (
        <div>
            <input
                accept={accept || "*"}
                className={classes.input}
                id="upload-file"
                multiple={multiple || false}
                type="file"
                name={name}
                onChange={handleChange}
            />
            <label htmlFor="upload-file">
                <MROButton variant="contained" color="secondary" component="span" startIcon={<UploadIcon />}>
                    {action ? action : "Upload"}
                </MROButton>
            </label>
            <div style={{ padding: '15px 10px' }}>
                {defaultValue && defaultValue.length > 0 && <Typography variant={"subtitle2"} color="secondary">Existing Files:</Typography>}
                {defaultValue && defaultValue.map((file, i) => <div key={`file-${i}`}>
                    {file.name ? <Typography style={{ textDecoration: 'none', color: 'inherit' }} component={"a"} href={file.url ? file.url : file.document_url || "#"} target={file.url ? "_blank" : null} className={"card-tile-secondary"}>
                        <FileIcon />&nbsp;&nbsp;<img width={70} src={file.url} /></Typography> : "No Files found"}
                </div>)}
                {fileList && fileList.length > 0 && <Typography variant={"subtitle2"} color="secondary">Chosen Files(new):</Typography>}
                {fileList && fileList.map(file => <div key={file[0].lastModified}>
                    <Typography component={"div"} className={"card-tile-secondary"}><FileIcon />&nbsp;&nbsp;{file[0].name}</Typography>
                </div>)}
            </div>
        </div>
    )
}

export default UploadButton;