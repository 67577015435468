import request, { authRequest } from "../../shared/api_client";
import { BASE_API_URL } from "../../shared/constants";

const url = BASE_API_URL;

function create(formData, url) {
    return request({
        url: url,
        method: 'POST',
        data: formData,
    });
}

function update(formData, url) {
    return request({
        url: url,
        method: 'PUT',
        data: formData,
    });
}

function getList(page, per_page, searchKey) {
    let newUrl = `${url}/admin_users/?page=${page}&per_page=${per_page}`;
    if (searchKey && searchKey.length > 1) {
        newUrl = `${newUrl}&search_keyword=${searchKey}`;
    }
    return request({
        url: newUrl,
        method: 'GET'
    })
}

function getData(url) {
    return request({
        url: url,
        method: 'GET',
    });
}

function getOrgzSections(url) {
    return request({
        url: url,
        method: 'GET'
    })
}

function remove(url, id) {
    return request({
        url: `${url}/${id}`,
        method: 'DELETE',
    })
}
function fetchCustomFieldInfo(id) {
    return request({
        url: `/line_reports/fetch_custom_field_info?id=${id}`,
        method: 'GET',
    });
}
function fetchCustomSection(id) {
    return request({
        url: `/line_reports/fetch_section_info?id=${id}`,
        method: 'GET',
    });
}
function requestResetPassword(formData, url) {
    return authRequest({
        url: url,
        method: 'POST',
        data: formData,
    });
}
function createData(formData) {
    return request({
        url: '/line_reports/create_field',
        method: 'POST',
        data: formData
    });
}
function edit(formData, params) {
    return request({
        url: '/line_reports/update_method',
        method: 'PUT',
        data: formData,
        params: params
    });
}
function deleteData(url) {
    return request({
        url: url,
        method: 'DELETE'
    })
}

function setOrgStatus(url, formData) {
    return request({
        url: url,
        method: 'PUT',
        data: formData
    })
}

const LineReportClient = {
    create, update, getList, remove, requestResetPassword, getData, getOrgzSections,
    createData, deleteData, edit, fetchCustomFieldInfo, fetchCustomSection,
    setOrgStatus
};

export default LineReportClient;