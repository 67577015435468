import React, { useState, useEffect, useContext } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MROFullScreenRightDrawer from "../../components/fullwidth_dialog";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import Grid from "@material-ui/core/Grid";
import { ToastMessageContext } from "../../lib/contexts/message_context";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import LineReportClient from "../../lib/services/api/line_report";
import Stepper from "@material-ui/core/Stepper";
import { Paper } from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import AddIcon from "@material-ui/icons/Add";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import WorkOrderDetail from "./work_order_details";
import AddSection from "./add_section";
import { useParams, useHistory } from "react-router-dom";
import useIsGlobalReports from "../../lib/hooks/useIsGlobalReports";

const useStyles = makeStyles((theme) => ({
  content: {
    width: "calc(100% - 250px)",
  },
  appBar: {
    backgroundColor: "#fff",
    color: "#000000CC",
    boxShadow: "0px 3px 6px #0000001A",
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  wrapper: {
    margin: "3%",
    padding: "3%",
    background: "#fff",
    boxShadow: "0px 1px 4px #0000001A",
    "& form": {
      paddingTop: "25px",
      width: "85%",
      "& .MuiGrid-spacing-xs-5": {
        "& .MuiGrid-item": {
          paddingBottom: "0",
        },
      },
    },
    "& .align-left-space-between": {
      paddingBottom: theme.spacing(1),
    },
    "& .MuiTypography-root": {
      fontSize: "14px",
    },
  },
  actionButtons: {
    paddingTop: "20px",
    textAlign: "right",
    "& button": {
      marginRight: "20px",
    },
  },
  paperWidth: {
    //   padding:'7% !important',
    width: "121%",
    maxWidth: "121%",
    marginBottom: "2rem",
  },
  paperRoot: {
    boxShadow: "none",
  },
  dialogBg: {
    backgroundColor: "#F7F8FA",
  },
  listWrapper: {
    maxHeight: "80vh",
    overflow: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  list: {
    margin: "15px 23px",
    "& .listItem": {
      background: "#fff",
      margin: "40px 0",
      paddingLeft: "24px",
      cursor: "pointer",
    },
    "& .nested-listItem": {
      background: "#fff",
      paddingLeft: theme.spacing(3),
      cursor: "pointer",
    },
  },
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  buttonpadding: {
    margin: "23px",
  },
  disableHover: {
    fontWeight: "bold",
  },
}));

function WorkOrder(props) {
  const classes = useStyles();
  const message = useContext(ToastMessageContext);
  const [loading, setLoading] = useState(false);
  const [sections, setSections] = useState({});
  const { handleClose, selectedOrganisation } = props;
  const [categoryName, setCategoryName] = useState(null);
  const [openSubCategory, setOpenSubcategory] = useState(null);
  const [expandopen, setExpandopen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [currentSection, setCurrentSection] = useState({});
  const [isExisting, setisExisting] = useState(false);
  const isGlobalReportTab = useIsGlobalReports();

  useEffect(() => {
    setLoading(true);
    sectionList();
    // LineReportClient.getOrgzSections("/line_reports/listing_org_sections").then(
    //   (response) => {
    //     console.log(response.data);
    //   }
    // );
  }, []);

  const sectionList = () => {
    let formData = new FormData();
    formData.append("report_name", "WorkOrder");
    LineReportClient.getData("/line_reports?report_name=WorkOrder")
      .then((res) => {
        if (res.sections) {
          const sections = res.sections;
          setSections(sections);
          const isExisting = sections.map((row) => row.existing_section);
          setisExisting(isExisting);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        message.showToastMessage({
          open: true,
          message: "Something went wrong. Try Again!",
          variant: "error",
        });
      });
  };

  const handleClick = () => {
    setExpandopen(!expandopen);
  };
  const handleListItemClick = (event, index, category) => {
    setSelectedIndex(index);
    setCategoryName(category);
    const currentSection = sections.filter((row) => row.id === index);
    setCurrentSection(Object.values(currentSection));
    // console.log('currentSection', index,category)
  };
  return (
    <React.Fragment>
      <MROFullScreenRightDrawer>
        <AppBar position="static" className={classes.appBar} elevation={0}>
          <Toolbar>
            <IconButton
              onClick={handleClose}
              edge="start"
              className={classes.backButton}
              color="inherit"
              aria-label="back"
            >
              <BackIcon />
            </IconButton>
            <Typography variant="subtitle2" className={classes.title}>
              Line Report Editor - Edit &#40; Work Order &#41;
            </Typography>
          </Toolbar>
          <Typography
            variant="subtitle1"
            color="secondary"
            style={{ paddingLeft: "76px" }}
            className={classes.title}
          >
            {selectedOrganisation?.company_name}
          </Typography>
        </AppBar>
        <Grid container spacing={3}>
          <Grid className={classes.listWrapper} item md={3}>
            <List className={classes.list} orientation="vertical">
              {!loading && <div className={"vertical-dots"}></div>}
              {/* <Paper className={classes.paperWidth}>
            <List className={classes.list}>
                    {(!loading && sections ) ? Object.values(sections).map((category,i)=>{
                        return(
                            <React.Fragment key={i}>
                                    <ListItem style={category.sub_categories && category.sub_categories.length >0?{}:{visibility:'hidden'}} onClick={()=> setOpenSubcategory(openSubCategory !== i?i:i)}>
                                        {openSubCategory === i ? <ExpandLess/> : <ExpandMore />}
                                    <ListItemText primary={category.section_name} />
                                </ListItem>
                                <Collapse in={openSubCategory === i} timeout="auto">
                                    <List component="div" className={classes.nestedList}>
                                        {category && category.sub_sections && category.sub_sections.map((sub,i)=>
                                         <ListItem key={i}>
                                            <ListItemText primary={sub.section_name} />
                                        </ListItem>
                                        )}
                                    </List>
                                </Collapse>
                            </React.Fragment>
                        )
                    }):<div className={"center"} style={{minHeight:'200px'}}>
                        <CircularProgress color="primary"/>
                    </div>}
                </List>
            </Paper> */}
              {!loading && sections ? (
                Object.values(sections).map((category, i) => {
                  return (
                    <Paper
                      elevation={0}
                      classes={classes.paperRoot}
                      className={classes.paperWidth}
                    >
                      <List
                        className={classes.root}
                        component="div"
                        disablePadding
                      >
                        <React.Fragment key={i}>
                          <ListItem
                            button
                            onClick={(event) => {
                              setOpenSubcategory(openSubCategory !== i ? i : i);
                              handleListItemClick(
                                event,
                                category.id,
                                category.section_name
                              );
                            }}
                            selected={selectedIndex === category.id}
                          >
                            <ListItemText
                              primary={
                                <Typography style={{ fontWeight: "bold" }}>
                                  {category.section_name}
                                </Typography>
                              }
                            />
                            {openSubCategory === i ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )}
                          </ListItem>
                          <Collapse
                            in={openSubCategory === i}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List component="div" disablePadding>
                              {category &&
                                category.sub_sections &&
                                category.sub_sections.map((sub, j) => (
                                  <React.Fragment key={i}>
                                    {" "}
                                    <ListItem
                                      key={sub.id}
                                      selected={selectedIndex === sub.id}
                                      button
                                      className={classes.nested}
                                      onClick={(event) => {
                                        handleListItemClick(
                                          event,
                                          sub.id,
                                          sub.section_name
                                        );
                                      }}
                                    >
                                      <ListItemText
                                        primary={sub.section_name}
                                      />
                                    </ListItem>
                                    <List component="div" disablePadding>
                                      {sub &&
                                        sub.sub_sections &&
                                        sub.sub_sections.map((underSub, j) => (
                                          <ListItem
                                            key={underSub.id}
                                            selected={
                                              selectedIndex === underSub.id
                                            }
                                            button
                                            className={classes.nested}
                                            onClick={(event) => {
                                              handleListItemClick(
                                                event,
                                                underSub.id,
                                                underSub.section_name
                                              );
                                            }}
                                          >
                                            <ListItemText
                                              primary={underSub.section_name}
                                            />
                                          </ListItem>
                                        ))}
                                    </List>
                                  </React.Fragment>
                                ))}
                            </List>
                          </Collapse>
                        </React.Fragment>
                      </List>
                    </Paper>
                  );
                })
              ) : (
                <div className={"center"} style={{ minHeight: "200px" }}>
                  <CircularProgress color="primary" />
                </div>
              )}
            </List>
            {isGlobalReportTab && (
              <Button
                className={classes.buttonpadding}
                startIcon={<AddIcon />}
                color="secondary"
                variant="contained"
                onClick={() => {
                  setShowModal(true);
                }}
              >
                Add New Section
              </Button>
            )}
          </Grid>
          <Grid item md={9}>
            <WorkOrderDetail
              selectedOrganisation={selectedOrganisation}
              isExisting={isExisting}
              currentSection={currentSection}
              setSelectedIndex={setSelectedIndex}
              sectionList={sectionList}
              setCategoryName={setCategoryName}
              categoryName={categoryName}
              selectedIndex={selectedIndex}
              isGlobal={isGlobalReportTab}
            />
          </Grid>
          <Grid item md={4}></Grid>
        </Grid>
      </MROFullScreenRightDrawer>
      {showModal && (
        <AddSection
          open={showModal}
          sectionList={sectionList}
          setLoading={setLoading}
          onClose={setShowModal}
        />
      )}
    </React.Fragment>
  );
}

export default WorkOrder;
