import { makeStyles } from "@material-ui/core/styles";

const useAuthStyles = makeStyles(theme => ({
    wrapper: {
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundImage: "url('/login_bg.png')",
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
    },
    form: {
        color: '#fff',
        '& h6': {
            marginBottom: '20px'
        },
        [theme.breakpoints.down("sm")]: {
            width: '75%',
            margin: 'auto'
        }
    },
    orgLogo: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& img': {
            maxWidth: '200px',
            maxHeight: '80px'
        }
    },
    formField: {
        margin: '20px 0',
        '& input': {
            color: '#fff'
        },
        '& .MuiInputBase-root': {
            backgroundColor: "#ffffff1c"
        },
        '& .MuiFormHelperText-root': {
            color: '#f96161'
        }
    },
    overlay: {
        background: "transparent linear-gradient(180deg, #163E6E 0%, #0B1F37 100%) 0% 0% no-repeat padding-box",
        position: "absolute",
        top: 0,
        bottom: 0,
        width: "100vw",
        opacity: "0.95"
    },
    submit: {
        backgroundColor: "#fff",
        color: theme.palette.primary.main,
        fontWeight: '400',
        '&:hover': {
            backgroundColor: "#fff",
            color: theme.palette.primary.main,
            fontWeight: '500',
        }
    },
    eye: {
        cursor: "pointer"
    },
    brandLogo: {
        position: "absolute",
        bottom: '25px',
        '& img': {
            width: '200px'
        }
    },
    colorWhite: {
        color: '#fff'
    }
}));

export default useAuthStyles;