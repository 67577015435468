import MRORightDrawer from "../../components/temporary_sidebar";
import React, { useContext, useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import PositiveSwitch from "../../components/form_components/switch";
import LoadingIndicator from "../../components/Universal/loading_indicator";
import OrganisationApiClient from "../../lib/services/api/organisation_details";
import { ToastMessageContext } from "../../lib/contexts/message_context";
import { FormControlLabel } from "@material-ui/core";



export default function ManageBases(props) {
    const { actions, currentSelection, handleClose, id } = props;
    const [loading, setLoading] = useState(false);
    const [bases, setBases] = useState([])
    const message = useContext(ToastMessageContext)


    const getBaseDetails = () => {
        setLoading(true)
        const params = {
            organisation_id: currentSelection?.
                organisation_id,
            organisation_address_id: currentSelection?.id,

        }

        OrganisationApiClient.getBases(params).then((res) => {
            setBases(res.data)
        }).catch(() => {
            message.showToastMessage({ message: "Something Went Wrong", variant: 'error', success: false });
        }).finally(() => {
            setLoading(false)
        })

    }

    useEffect(() => {
        getBaseDetails()
    }, [])
    const handleChange = (data, checked) => {
        // setLoading(true)
        const formData = {
            organisation_id: id,
            organisation_address_id: currentSelection?.id,
            data: [
                {
                    base_station_id: data.id,
                    status: Boolean(checked)
                }
            ]

        }
        OrganisationApiClient.updateAlternateBases(formData).then((res) => {
            if (res.success) {
                message.showToastMessage({ message: "Bases updated", variant: 'success', success: true });
                getBaseDetails()
                // setLoading(false);
            }
        }).catch(() => {
            message.showToastMessage({ message: "Something Went Wrong", variant: 'error', success: false });
        })
    }

    return (
        <MRORightDrawer onClose={() => handleClose()}>
            <div style={{ maxHeight: '85vh', overflow: 'scroll' }}>
                <div className={"align-left-space-between"}>
                    <div style={{
                        fontSize: '15px',
                        fontWeight: '600'
                    }}>Applicable Bases</div>
                    <IconButton onClick={() => handleClose()}>
                        <CloseIcon fontSize={"small"} />
                    </IconButton>
                </div>
            </div>
            <List >
                <ListItem>
                    <ListItemText >
                        <div className={"align-left-space-between"} style={{ marginLeft: '-6px' }}><Typography variant="subtitle2" gutterBottom>Bases</Typography>
                            <Typography variant="subtitle2" gutterBottom style={{ marginLeft: '-6px' }} >On/Off</Typography></div>
                    </ListItemText>
                </ListItem>
            </List>
            {!loading ? <List>
                {bases.map((d, i) => {
                    return (
                        <ListItem key={i}>
                            <ListItemText primary={d.station} />
                            <ListItemSecondaryAction>
                                {/* <PositiveSwitch
                                        edge="end"
                                        checked={d.status}
                                        inputProps={{ 'aria-labelledby': 'on-or-off' }}
                                        onChange={(e) => handleChange(d, e.target.checked)}
                                    /> */}
                                <FormControlLabel
                                    control={
                                        <PositiveSwitch
                                            edge="end"
                                            checked={d.status}
                                            inputProps={{ 'aria-labelledby': 'on-or-off' }}
                                            value={d.id}
                                            onChange={(e) => handleChange(d, e.target.checked)}
                                        />
                                    }
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                    )
                })}
            </List> : <div style={{ height: '75vh' }}><LoadingIndicator variant={"contained"} /></div>}
        </MRORightDrawer>
    )
}
