import FlightIcon from '@material-ui/icons/FlightTakeoffOutlined';
import React, { Suspense, lazy, useReducer, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, useLocation, useHistory } from "react-router-dom";
import TopNavigationBar from "../../components/tab_component_with_panel";
import IssueReporting from "./IssueReporting";
import Usage from "./Usage";



export default function Dashboard(props) {



    const topNavMenu = [
        {
            label: (<div>
                <FlightIcon className="nav_icon" />
                &nbsp;Usage
            </div>), component: Usage, redirect: '/dashboard/usage/users', section: '/usage/users'
        },
        {
            label: (<div> <FlightIcon className="nav_icon" />
                &nbsp;Issue Reporting</div>), component: IssueReporting, redirect: '/dashboard/issue_reporting', section: '/dashboard/issue_reporting'
        }
    ];
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        if (location.pathname === "/dashboard") {
            history.push("/dashboard/usage/users");
        }
    });

    return (
        <div>
            <TopNavigationBar {...props} tabProperties={topNavMenu} />
            <Router>
                <Switch>

                    <Route exact path={'/dashboard/usage/users'} element={<Usage />} />
                    <Route path={'/dashboard/issue_reporting'} render={() => (<IssueReporting {...props} />)} />
                    {/* <Route component={PageNotFound} /> */}

                </Switch>
            </Router>
        </div>
    )
}