import React, { useState, useEffect, useContext } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from '@material-ui/core/TextField';
import CircularProgress from "@material-ui/core/CircularProgress";
import { ToastMessageContext } from "../../../lib/contexts/message_context";
import StationQuestionService from '../../../lib/services/api/station_questions_services';
import MROTextField from "../../../components/form_components/TextField";
import Typography from "@material-ui/core/Typography";
import MROButton from "../../../components/buttons";
import Grid from "@material-ui/core/Grid";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import PositiveSwitch from "../../../components/form_components/switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { capitalize } from 'lodash';

const useStyles = makeStyles(theme => ({
    list: {
        paddingLeft: 0,
        '& .MuiListItem-gutters': {
            paddingLeft: 0
        },
        '& .MuiListItemIcon-root': {
            minWidth: '35px',
            cursor: 'pointer'
        },
        maxHeight: '90vh',
        overflow: 'scroll'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 220,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    title: {
        paddingBottom: theme.spacing(2)
    },
    nestedList: {
        background: '#f5f5f5',
        paddingLeft: theme.spacing(4)
    },
    dialogWidth: {
        width: '40%'
    },
    actionButtons: {
        paddingTop: '20px',
        textAlign: 'right',
        '& button': {
            marginRight: '20px'
        }
    }
}));
let updateOccured = false;

function ManageQuestions(props) {
    const classes = useStyles();
    const { action, handleClose, currentSelection, listingData, selectedOrganisation } = props;
    const [loading, setLoading] = useState(false);
    const [permissions, setPermissions] = useState([]);
    const [mandatoryChecked, setMandatoryChecked] = useState(false);
    const [secMandatoryChecked, setSecMandatoryChecked] = useState(currentSelection.mandatory);
    const [dropDownValues, setDropDownValues] = useState('');

    const message = useContext(ToastMessageContext);
    const [age, setAge] = React.useState('');
    const [fieldType, setFieldType] = React.useState(currentSelection.field_type);


    const handleChange = (event) => {
        setAge(event.target.value);
        setFieldType('')
    };

    const handleCreate = (formData) => {
        setLoading(true);
        formData.append('id', listingData.id)
        formData.append('mandatory', secMandatoryChecked || false)
        formData.append('parent_organisation', true)
        formData.append('organisation_id', selectedOrganisation.organisation_id)
        StationQuestionService.createStationQuestions(formData).then((res) => {
            if (res.data) {
                handleClose(res.data);
                message.showToastMessage({ message: "New Question added successfully", variant: "success" });
                props.setLoading(true);
                setTimeout(() => {
                    props.setLoading(false);
                    props.VehicleList()
                }, 300);
            }
            else {
                message.showToastMessage({ message: res.errors ? res.errors.join(',') : "Something went wrong. Try Again", variant: "error" });
                setLoading(false);
            }
        }).catch(() => {
            message.showToastMessage({ message: "Something went wrong. Try Again", variant: "error" });
            setLoading(false);
        })
    }

    const handleUpdate = (formData) => {
        setLoading(true);
        formData.append('question_id', currentSelection.question_id || currentSelection.id)
        formData.append('mandatory', secMandatoryChecked)
        formData.append('organisation_status', false)
        StationQuestionService.updateStationQuestions(formData).then((res) => {
            if (res.data) {
                handleClose(res.data);
                message.showToastMessage({ message: "Question successfully updated", variant: "success" });
                // props.setLoading(true);
                // setTimeout(()=>{
                //   props.setLoading(false);
                props.VehicleList()
                // },300);
            }
            else {
                message.showToastMessage({ message: res.errors ? res.errors.join(',') : "Something went wrong. Try Again", variant: "error" });
                setLoading(false);
            }
        }).catch(() => {
            message.showToastMessage({ message: "Something went wrong. Try Again", variant: "error" });
            setLoading(false);
        })
    }

    const handleDelete = () => {
        setLoading(true);
        StationQuestionService.deleteStationQuestions({
            question_id: currentSelection.question_id ?
                currentSelection.question_id : currentSelection.id
        }).then((res) => {
            if (res.success) {
                // handleClose()
                message.showToastMessage({ message: "Question successfully removed", variant: "success" });
                handleClose(null, currentSelection.question_id);
                props.setLoading(true);
                setTimeout(() => {
                    props.setLoading(false);
                    props.VehicleList()
                }, 300);
            }
            else {
                message.showToastMessage({ message: res.errors ? res.errors.join(',') : "Something went wrong. Try Again", variant: "error" });
                handleClose();
            }
        }).catch(() => {
            message.showToastMessage({ message: "Something went wrong. Try Again", variant: "error" });
            handleClose();
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const email = formData.get('station_email');

        if (action === 'create') {
            handleCreate(formData);
        }
        else if (action === 'update') {
            handleUpdate(formData);
        }
        else {
            handleDelete(formData)
        }
    }

    return (
        <div className={classes.dialogWidth}>
            <Dialog
                open={true}
                fullWidth
                maxWidth="sm"
                onClose={handleClose}
                aria-labelledby="info-manage"
            >
                <DialogContent>
                    {action !== 'delete' ? <div className={classes.formWrapper}>
                        <Typography variant="subtitle2" color="secondary" className={classes.title}>
                            {capitalize(action)} question
            </Typography>
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={1} alignItems={"center"} justify={"flex-start"}>
                                <Grid item xs={12}>
                                    <TextField
                                        id="outlined-multiline-static"
                                        placeholder="Type question"
                                        multiline
                                        required
                                        fullWidth
                                        rows={4}
                                        name="question"
                                        variant="outlined"
                                        defaultValue={currentSelection.question}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <MROTextField
                                        name={"field_type"}
                                        select
                                        onChange={handleChange}
                                        defaultValue={fieldType}
                                    >
                                        <MenuItem value={'yes/no'}>Yes/No</MenuItem>
                                        <MenuItem value={'numeric'}>Numeric</MenuItem>
                                        <MenuItem value={'text'}>Free text</MenuItem>
                                        <MenuItem value={'dropdown'}>Dropdown</MenuItem>
                                    </MROTextField>
                                </Grid>
                                <Grid item xs={6}></Grid>
                                {(fieldType === 'numeric' || age === 'numeric') && <React.Fragment> <Grid item xs={6}>
                                    <MROTextField
                                        name={"max_length"}
                                        label={"Field Max Length"}
                                        required
                                        InputLabelProps={{ shrink: true }}
                                        defaultValue={currentSelection.max_length}
                                    />
                                </Grid><Grid item xs={6}></Grid></React.Fragment>}
                                {(fieldType === 'numeric' || age === 'numeric') && <React.Fragment><Grid item xs={6}>
                                    <MROTextField
                                        name={"decimal_places"}
                                        label={"Decimal Places"}
                                        required
                                        InputLabelProps={{ shrink: true }}
                                        defaultValue={currentSelection.decimal_places}
                                    />
                                </Grid><Grid item xs={6}></Grid></React.Fragment>}
                                {(fieldType === 'text' || age === 'text') && <React.Fragment> <Grid item xs={6}>
                                    <MROTextField
                                        name={"max_length"}
                                        label={"Field Max Length"}
                                        required
                                        InputLabelProps={{ shrink: true }}
                                        defaultValue={currentSelection.max_length}
                                    />
                                </Grid> <Grid item xs={6}></Grid></React.Fragment>}
                                {(fieldType === 'dropdown' || age === 'dropdown') ? <React.Fragment> <Grid item xs={6}>
                                    <MROTextField
                                        name={"dropdown_values"}
                                        label={"Enter dropdown options"}
                                        onChange={(e) => setDropDownValues(e.target.value)}
                                        required
                                        rows={4}
                                        multiline
                                        InputLabelProps={{ shrink: true }}
                                        defaultValue={currentSelection.dropdown_values}
                                    />
                                    {/* <ChipInput
                              onChange={(chips) => setCurrentDropDown(chips)}
                              defaultValue={data.dropdown_values }
                              label="Enter dropdown options"
                              name={"dropdown_values"}
                              fullWidth
                            /> */}
                                    <FormHelperText>Please enter the options as comma separated</FormHelperText>
                                </Grid> <Grid item xs={6}></Grid></React.Fragment> : ''}
                                <Grid item xs={6}>
                                    <FormControlLabel
                                        control={
                                            <PositiveSwitch
                                                checked={secMandatoryChecked}
                                                onChange={(e) => setSecMandatoryChecked(e.target.checked)}
                                                name="mandatory"
                                                defaultValue={currentSelection.mandatory}
                                            />
                                        }
                                        label="Is this a mandatory field?"
                                        labelPlacement="start"
                                        name="mandatory"

                                    />
                                </Grid><Grid item xs={6} />
                            </Grid>
                            <div className={classes.actionButtons}>
                                <MROButton type="button" disabled={loading} variant={"contained"} color="tertiary" onClick={handleClose}>Cancel</MROButton>
                                <MROButton type="submit" disabled={loading} variant={"contained"} color="primary">{action === 'create' ? "Create" : "Update"}</MROButton>
                            </div>
                        </form></div> : <div className={classes.formWrapper}>
                            Confirm removal of Question. Once Deleted all associated data will be lost and couldn't be  restored.
                <div className={classes.actionButtons}>
                                <MROButton disabled={loading} type="button" variant={"contained"} onClick={handleClose}>Cancel</MROButton>
                                <MROButton disabled={loading} type="button" variant={"contained"} color="primary" onClick={handleDelete}>Yes, Confirm</MROButton>
                            </div>
                        </div>}
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default ManageQuestions;
