import React, { useEffect, useState } from "react";
import {
    KeyboardDatePicker,
    DatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from "moment";
import { TextField } from "@material-ui/core";
import MROTextField from "./TextField";

function DateField(props) {

    const [selectedDate, handleDateChange] = useState(new Date());
    const { label, name, defaultValue, variant, onChange, value } = props;


    useEffect(() => {
        if (defaultValue) {
            handleDateChange(moment(defaultValue, "DD-MM-YYYY"))
        }
    }, [defaultValue]);

    console.log(selectedDate)

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
                autoOk
                variant="inline"
                inputVariant="outlined"
                label="Date Completed"
                size="medium"
                format="DD-MM-YYYY"
                value={selectedDate}
                InputAdornmentProps={{ position: "end" }}
                onChange={date => { handleDateChange(date); onChange(moment(date).format("DD-MM-YYYY")) }}
            />
            <MROTextField type={"hidden"} name={name || "date"} value={moment(selectedDate).format("DD-MM-YYYY")} />

        </MuiPickersUtilsProvider>
    )
}

export default DateField;