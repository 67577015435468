import request from "../../../shared/api_client";


const getUsersDetails = (params) => {
    return request({
        url: "/dashboards/users_reports",
        method: "GET",
        params,
    })
}
const getDataDetails = (params) => {
    return request({
        url: "/dashboards/data_reports",
        method: "GET",
        params,
    })
}
const getSMSDetails = (params) => {
    return request({
        url: "/dashboards/sms_reports",
        method: "GET",
        params,
    })
}

const getAllActiveOrganization = (params) => {
    return request({
        url: "station_checks/fetch_active_organisations",
        method: "GET",
        params,
    })
}
export const DashboardServices = {
    getUsersDetails,
    getDataDetails,
    getSMSDetails,
    getAllActiveOrganization
}