import React, { useState, useEffect } from 'react'
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import { Dialog, DialogContent, DialogTitle, Box, MenuItem, Tabs, AppBar, ListItem, Typography, IconButton, CircularProgress } from "@material-ui/core";
import ClearIcon from '@material-ui/icons/Clear';
import Tab from "@material-ui/core/Tab";
import { MessageServices } from '../../../lib/services/api/Messaging/messae_services'


const useStyles = makeStyles((theme) => ({
    formWrapper: {
        background: "#fff",
        width: "100%",

    },
    title: {
        display: "flex",
        justifyContent: "left",
        "& h2": {
            //   color: theme.palette.primary.main,
            fontSize: "40px",
            fontWeight: 40,
        },
    },
    subTitle: {
        fontSize: "16px",
        fontWeight: "bold",
        marginBottom: "20px",
        color: "#4D4F5C",
    },
    card: {
        padding: "8px 29px",
    },
    actionButtons: {
        paddingBottom: "33px",
        textAlign: "end",
        marginRight: "6px",
        marginTop: '21px',
        "& button:not(:last-child)": {
            marginRight: "20px",
        },
    },

    modal: {
        "& .MuiDialog-paperWidthMd": {
            width: "550px",
        },
    },
    tab: {
        fontWeight: 600
    }
}));



function DependencyDetails(props) {
    const classes = useStyles();
    const { handleClose, selectedTab, setSelectedTab, users } = props

    return (
        <div>

            <Dialog
                maxWidth={"md"}
                open={true}
                className={classes.modal}
                onClose={handleClose}
                aria-labelledby="base-station-manage"
            >
                <DialogTitle>
                    <Grid style={{ textAlign: 'end', cursor: 'pointer' }}>
                        <ClearIcon onClick={() => handleClose()} style={{}} size='small' />
                    </Grid>
                </DialogTitle>
                <DialogContent style={{ paddingBottom: '20px', height: '50vh' }}>

                    <Tabs
                        value={selectedTab}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                        onChange={(e, tab) => { setSelectedTab(tab) }}
                        style={{ borderBottom: '1px solid #00000029', fontWeight: 600 }}
                    >
                        <Tab className={classes.tab} label="USER GROUPS" />
                        <Tab className={classes.tab} label="MESSAGE GROUPS" />
                        <Tab className={classes.tab} label="BASES" />
                    </Tabs>

                    {selectedTab === 0 ? <Grid>

                        {users.user_group.map((item, index) => (
                            <ListItem key={item.id}>
                                <Typography variant="body2">{item.name}</Typography>
                            </ListItem>

                        ))


                        }
                    </Grid> : <Typography > No record found </Typography>}

                    {selectedTab === 1 ? <Grid>

                        {users.message_group.map((item, index) => (
                            <ListItem key={item.id}>
                                <Typography variant="body2">{item.group_name}</Typography>
                            </ListItem>

                        ))


                        }
                    </Grid> : <Typography > No record found </Typography>}


                    {selectedTab === 2 ? <Grid>

                        {users.base_station.map((item, index) => (
                            <ListItem key={item.id}>
                                <Typography variant="body2">{item.station}</Typography>
                            </ListItem>

                        ))


                        }
                    </Grid> : <Typography > No record found </Typography>}

                </DialogContent>

            </Dialog>


        </div>
    )
}

export default DependencyDetails