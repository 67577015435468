import React, {useEffect} from "react";
import MaterialTable from "material-table";
import tableIcons from "../../components/Table/icons";
import {makeStyles} from "@material-ui/core/styles";
import StatusDot from "../../components/Universal/status_dots";
import AddIcon from '@material-ui/icons/AddBox';
import EditIcon from '@material-ui/icons/Edit';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
    tableWrapper:{
        width:'45%',
        // margin:'3%',
    },
    subTitle: {
        fontSize: theme.spacing(2),
        fontWeight: 500,
    },
}))

const iconColor = "#00000099";
 const options = {
    actionsColumnIndex: -1,
    selectableRowsOnClick: false,
    pageSize: 10,
    pageSizeOptions: [10, 20, 30, 50],
    rowStyle:{
        textAlign:'center'
    },
    headerStyle:{
        textAlign:'center'
    },
    cellStyle:{
        textAlign:'center'
    },
    draggable:false,
    searchable:true,
    sorting:false,
    emptyRowsWhenPaging:false
};
function ReportGroups(props) {
    const classes = useStyles();

    useEffect(()=>{
        console.log("Mounted")
    },[])

    const columns = [
        {
            title: 'Station',
            field: 'station_name',
        },
      
    ];

    const data = [
        {station_name:"MAN","station_address":"0634 Hipolito Turnpike, Port Elliott, NJ, GY",manager:"Joe Root",station_check:true,handovers_per_day:0},
        {station_name:"MAN","station_address":"0634 Hipolito Turnpike, Port Elliott, NJ, GY",manager:"Joe Root",station_check:true,handovers_per_day:0},
        {station_name:"MAN","station_address":"0634 Hipolito Turnpike, Port Elliott, NJ, GY",manager:"Joe Root",station_check:false,handovers_per_day:1},
        {station_name:"MAN","station_address":"0634 Hipolito Turnpike, Port Elliott, NJ, GY",manager:"Joe Root",station_check:true,handovers_per_day:0},
        {station_name:"MAN","station_address":"0634 Hipolito Turnpike, Port Elliott, NJ, GY",manager:"Joe Root",station_check:true,handovers_per_day:4},
        {station_name:"MAN","station_address":"0634 Hipolito Turnpike, Port Elliott, NJ, GY",manager:"Joe Root",station_check:false,handovers_per_day:2},
        {station_name:"MAN","station_address":"0634 Hipolito Turnpike, Port Elliott, NJ, GY",manager:"Joe Root",station_check:true,handovers_per_day:7},
    ]

    return (
        <div className={classes.tableWrapper}>
            <MaterialTable
                style={{boxShadow:'0px 1px 3px #00000033'}}
                icons={tableIcons}
                title={<Typography variant="h5" component="h5" className={classes.subTitle}>
                Report Groups
                 </Typography>}
                columns={columns}
                data={data}
                isLoading={false}
                options={options}
                localization={{
                    toolbar: {
                        searchPlaceholder: 'Search anything'
                    },
                    body: {
                        emptyDataSourceMessage: 'No Bases Added Yet',
                        filterRow: {
                            filterTooltip: 'Filter'
                        },
                    },
                    header:{
                        actions:"Actions"
                    }
                }}
                actions={[
                    {
                        icon: ()=><EditIcon fontSize="small" htmlColor={iconColor}/>,
                        tooltip: 'Edit',
                        onClick: (event, rowData) => console.log(rowData)
                    },
                    {
                        icon: ()=><AddIcon color="secondary"/>,
                        tooltip: 'Add',
                        isFreeAction:true,
                        onClick: (event) => console.log("Add clicked")
                    }
                ]}
            />
        </div>
    )
}

export default ReportGroups;
