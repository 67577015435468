import { Grid, makeStyles, IconButton, Typography, Button, ButtonBase, ListItem, FormControlLabel, InputBase, Box, Menu, MenuItem, CircularProgress, Card, TextField, InputAdornment, alpha } from '@material-ui/core'
import React, { useState, useContext } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import EditTitle from './EditTitle';
import MROButton from '../../../components/buttons'
import ArchiveIcon from '../../../assets/messagng/archive.png'
import EditIcon from "@material-ui/icons/Edit";
import VerticalIcon from '@material-ui/icons/MoreVert';
import FileIcon from "@material-ui/icons/AttachFile";
import { useEffect } from 'react';
import { MessageServices } from '../../../lib/services/api/Messaging/messae_services'
import FileUpload from '../fileUpload';
import { useMemo } from 'react';
// import { getOrganisationId } from 'lib/utils/common_utils';
import { ToastMessageContext } from "../../../lib/contexts/message_context";
import { Visibility as ViewIcon } from '@material-ui/icons';
import ViewParticipant from './ViewParticipant';
import moment from 'moment';
import { usePageVisibility } from 'react-page-visibility';
import PageVisibility from 'react-page-visibility';
import Refresh from '../../../assets/messagng/Refresh.png'
import ChatLoader from './ChatLoader';
import Skeleton from '@material-ui/lab/Skeleton';
import SendIcon from '../../../assets/messagng/sendmessage.svg'
const useStyles = makeStyles((theme) => ({


    main: {
        position: 'relative', height: '95vh',
        overflow: 'scroll',
        marginTop: '-136px', paddingLeft: '20px',
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
            width: '0.0em',

        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 1px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 1px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            // outline: '1px solid slategrey'
        },

    },

    scrollableItem: {
        height: '60vh',
        maxHeight: '60vh',
        overflow: 'scroll',
        display: 'flex',
        flexDirection: 'column-reverse',
        scrollBehavior: 'smooth',

        '&::-webkit-scrollbar': {
            width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            // outline: '1px solid slategrey'
        },

    },

    org1: {
        background: alpha(theme.palette.primary.main, 0.20),
        maxWidth: '85%',
        textAlign: 'justify',
        padding: '20px',
        borderRadius: '20px 20px 20px 0px',
        fontSize: '15px',
        display: 'inline-block',
        whiteSpace: 'break-spaces'
        // inlineSize: '345px',
        // wordBreak: 'break-all'

    },
    org2: {
        background: theme.palette.primary.main,
        maxWidth: '85%',
        textAlign: 'justify',
        padding: '20px',
        borderRadius: '20px 20px 0px 20px',
        color: '#ffffff',
        fontSize: '15px',
        display: 'inline-block',
        whiteSpace: 'break-spaces'
        // inlineSize: '345px',
        // wordBreak: 'break-all'

    },
    sendDiv: {
        backgroundColor: '#F5F6FA',
        padding: '15px',
        marginBottom: '10px',
        marginTop: '5px',
        height: 'max-content',
        borderRadius: '5px',
    },
    mainFile: {
        backgroundColor: '#F5F6FA',
        padding: '5px',
        height: '15vh',
        overflow: 'scroll',
    },
    dateTime: {
        // paddingBottom: '10px',
        width: '36%',
        textAlign: 'left',
        color: '#707070',
        fontSize: '10px'
    },
    loggedDateTime: {
        // paddingBottom: '10px',
        textAlign: 'end',
        color: '#707070',
        fontSize: '10px'
    },
    menu: {
        '& .MuiMenu-paper': {
            position: 'fixed',
            marginLeft: '-107px',
            marginTop: '26px'
        }
    },



}));


function ChatDetails(props) {
    const classes = useStyles();
    const { selectedChat, getMesssagesList, setSelectedChat, getTotalUnReadCount, setArchiveState, setMsgLoading } = props
    const [action, setAction] = useState('')
    const [anchorEl, setAnchorEl] = useState(false);
    const [chatData, setChatData] = useState([])
    const [errors, setErrors] = useState({});
    const [open, setOpen] = useState(false)
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false)
    const message = useContext(ToastMessageContext)
    const [value, setValue] = useState('')
    let userDetails = JSON.parse(sessionStorage.getItem("user"));
    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(10)
    const ITEM_HEIGHT = 48;
    const [prevPage, setPrevPage] = useState(0)
    const [noMoreData, setNoMoreData] = useState(true)
    const [totalCount, setTotalCount] = useState(0)
    const [unReadData, setUnReadData] = useState([])
    const [chatLoading, setChatLoading] = useState(false)
    const isVisible = usePageVisibility()
    const [toatUnreadMessages, setTotalUnReadMessages] = useState(0)
    const [unReadCount, setUnReadCount] = useState(0)
    const [title, setTitle] = useState('')

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false)
    };

    const getChatDetails = async () => {
        setChatLoading(true)
        const pages = page + 1
        const params = {
            chat_id: selectedChat.chat_id,
            page: page + 1,
            limit: pageSize,
            user_id: userDetails?.user?.id
        }
        await MessageServices.getChatMessage(params).then((response) => {
            if (response.success) {
                setPrevPage(pages)
                setChatData(response.data.chat_messages)
                setUnReadCount(response.data.unread_count)
                setTitle(response.data.chat_name === null ? selectedChat?.chat_name : response.data.chat_name)
                let data = response.data.chat_messages.filter((item) => item.read_status === false)
                setUnReadData(data)
                setNoMoreData(true)

            }
        }).catch(() => {

        }).finally(() => {
            setChatLoading(false)
        })
    }

    useMemo(() => {
        setPage(0)
        getChatDetails()
    }, [selectedChat, page])


    const resetErrorsHandler = (name) => {
        setErrors((e) => ({ ...e, [name]: false }));
    };


    useEffect(() => {
        if (selectedChat && isVisible && unReadData.length > 0) {

            if (unReadCount > 0) {

                const formData = {

                    chat_id: selectedChat.chat_id,
                    user_id: userDetails?.user?.id,
                }
                unReadData.forEach((item, index) => {
                    let key1 = "message_ids[" + index + "]"
                    formData[key1] = item.message_id
                })
                MessageServices.updateReadStatusById(formData).then((res) => {
                    setMsgLoading(null)
                    getTotalUnReadCount()
                    getMesssagesList()
                    getScrollList(prevPage)

                }).catch(() => {
                    message.showToastMessage({
                        message: "Something went wrong. Try again!",
                        variant: "error",
                    });


                })
            }
            else {
                console.log("return")
            }
        }
    }, [unReadData])

    useEffect(() => {
        setTotalUnReadMessages(selectedChat?.unread_messages)
    }, [selectedChat, loading])
    const handleArchiveState = () => {
        const formData = {

            chat_id: selectedChat.chat_id,
            user_id: userDetails?.user?.id,
            status: selectedChat.archive === true ? "unarchive" : "archive",

        }

        MessageServices.updateArchiveState(formData).then((response) => {
            if (response.success) {
                setSelectedChat("")
                getMesssagesList()
                setArchiveState(false)
            }
        }).catch(() => {
            message.showToastMessage({
                message: "Something went wrong. Try again!",
                variant: "error",
            });

        })


    }
    const convertToLocal = (dates) => {

        var testDateUtc = moment.utc(dates, 'DD MMM YYYY, HH:mm A');
        var localDate = moment(testDateUtc).local().format('DD MMM YYYY, HH:mm A')

        return localDate
    }
    const handleReplyMessage = async (e) => {
        e.preventDefault()
        const formData = new FormData(e.target)
        let valid = true
        let error = {
            message: false,

        }
        if (formData && formData.get("message")?.length === 0) {
            error.message = true;
            valid = false;
        }
        if (!valid) {
            setErrors(error);
            return;
        }
        // formData.append('organisation_id', getOrganisationId())
        formData.append("sender_id", userDetails?.user?.id);
        formData.append("chat_id", selectedChat.chat_id)
        formData.delete('"attachments[]"')
        files.forEach(file => formData.append("attachments[]", file))
        setLoading(true)
        await MessageServices.updateReplyMessage(formData).then((response) => {
            if (response.success) {
                setValue('')
                setPage(0)
                getChatDetails()
            }
        }).catch(() => {
            message.showToastMessage({
                message: "Something went wrong. Try again!",
                variant: "error",
            });

            getChatDetails()
        }).finally(() => {
            setLoading(false)
        })
    }
    //infinite scroll

    const getScrollList = async (pageR) => {
        const pages = pageR ? pageR : prevPage + 1
        const params = {
            chat_id: selectedChat.chat_id,
            page: pages,
            limit: pageSize,
            user_id: userDetails?.user?.id,

        }

        await MessageServices.getChatMessage(params).then((response) => {
            if (response.success) {
                setPrevPage(pages)
                setTotalCount(response.total_count)
                setUnReadCount(response.data.unread_count)
                let data = response.data.chat_messages.filter((item) => item.read_status === false)
                setUnReadData(data)
                if (response.data.chat_messages !== [] && response.data.chat_messages.length !== 0) {
                    if (!pageR) { setChatData([...chatData, ...response.data.chat_messages]) }
                    else {
                        console.log("unreaaaaaaa", pageR)
                    }

                } else {

                    setNoMoreData(false)
                }

            }
        }).catch(() => {


        }).finally(() => {

        })

    }
    // console.log("pages=========", page, prevPage)
    const fetchMoreData = async () => {

        await getScrollList()
        // setData((ps) => ([...ps, ...newData]))

    }

    //handleunreadmsg
    const handleUnReadMessages = async () => {
        const formData = {

            chat_id: selectedChat.chat_id,
            user_id: userDetails?.user?.id,
        }
        await MessageServices.updateUnReadMessageStatus(formData).then(() => {

            message.showToastMessage({
                message: "All Messages Read",
                variant: "success",
            });
            setSelectedChat("")
            setMsgLoading(null)
            getMesssagesList()
            getTotalUnReadCount()


        }).catch(() => {
            message.showToastMessage({
                message: "Something went wrong. Try again!",
                variant: "error",
            });

        })

    }



    return (
        <>
            <Grid xs={7} className={classes.main}>
                <Grid container xs={12} style={{ position: 'sticky', top: 0, backgroundColor: 'white', borderBottom: '1px solid #70707099', paddingBottom: '14px' }}>
                    <Grid item xs={9}>
                        <Typography variant='h6' style={{ fontSize: '14px', fontWeight: 600 }}>{chatLoading ? <Skeleton animation="wave" height={20} width="40%" /> : title}</Typography>

                    </Grid>
                    <Grid item xs={3} style={{ textAlign: 'end' }}>
                        <IconButton onClick={(event) => {
                            setAnchorEl(true);
                            setOpen(true)

                        }}>
                            <VerticalIcon size="small" />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid>
                    {unReadCount > 0 ?
                        <Grid style={{ paddingTop: '4px', textAlign: 'center' }}>
                            <MROButton color='primary' variant='contained' style={{ borderRadius: '15px' }} >You have {unReadCount} unread messages !<span style={{ textDecoration: 'underline', paddingLeft: '2px' }} onClick={() => {
                                handleUnReadMessages()
                            }}>Mark all as read?</span></MROButton>
                        </Grid> : <Grid style={{ paddingBottom: '20px' }}>{" "}</Grid>
                    }
                    <Grid id="scrollableChat" className={classes.scrollableItem} >
                        <InfiniteScroll
                            dataLength={chatData.length}
                            next={fetchMoreData}
                            style={{
                                display: 'flex', flexDirection: 'column-reverse',
                                overflow: 'hidden',
                            }}
                            hasMore={noMoreData}
                            inverse={true}
                            loader={chatLoading ? "" : <Grid style={{ textAlign: 'center', paddingTop: '10px' }}><CircularProgress size={20} color='primary' /></Grid>}
                            scrollableTarget="scrollableChat"

                        >
                            {chatLoading ? <>

                                <ChatLoader />
                            </> : chatData.map((item) => (
                                <PageVisibility>
                                    <div style={{ paddingRight: '10px' }}>
                                        <Typography variant='h6' style={{ textAlign: item.sender_id === userDetails?.user?.id ? 'right' : '', paddingTop: '5px', fontSize: '14px', fontWeight: 600 }}>{item.sender_name}</Typography>
                                        <Box style={{ paddingTop: '10px' }} display='flex' justifyContent={item.sender_id === userDetails?.user?.id ? 'right' : 'left'}>
                                            <Typography variant='body1' className={item.sender_id === userDetails?.user?.id ? classes.org2 : classes.org1}>{item.message}</Typography>


                                        </Box>
                                        {item.attachments !== undefined & item.attachments !== [] && item.attachments.length > 0 && <Box style={{ paddingTop: '10px' }} display='flex' justifyContent={item.sender_id === userDetails?.user?.id ? 'right' : 'left'}>
                                            <FileUpload APIUrl={""}
                                                defaultValue={item.attachments !== undefined
                                                    && item.attachments.map((file) => ({
                                                        filename: file?.file_name,
                                                        url: file?.path,
                                                        id: file?.id,
                                                    }))
                                                }
                                                showBorderOnNoFiles={false}
                                                uploadButtonView={true}
                                                multiple={true}
                                                name={"attachments[]"}
                                                action={"Upload"}
                                                messageLoading={loading}
                                                chatLoading={chatLoading}
                                            />

                                        </Box>}
                                        <Typography className={item.sender_id === userDetails?.user?.id ? classes?.loggedDateTime : classes.dateTime}>{convertToLocal(item.date_time)}</Typography>
                                    </div>
                                </PageVisibility>
                            ))}
                        </InfiniteScroll>
                    </Grid>
                    <Grid style={{ textAlign: 'right', paddingRight: '223px', paddingBottom: '10px' }}>
                        <a onClick={() => getChatDetails()} > <img src={Refresh} height={'13px'} /><span style={{
                            position: 'absolute', textDecoration: 'underline', paddingTop: '2px',
                            paddingLeft: '6px'
                        }}> Refresh / show new messages</span> </a>
                    </Grid>
                    <form onSubmit={handleReplyMessage}>
                        {/* {selectedChat?.allow_user_to_reply === false && selectedChat?.chat_type === "group" ?
                            <Grid className={classes.sendDiv}>
                                <Typography style={{ textAlign: 'center', height: '10vh' }}></Typography>

                            </Grid>



                            : */}
                        <Grid >
                            <InputBase
                                className={classes.sendDiv}
                                fullWidth
                                multiline
                                name="message"
                                maxRows={5}
                                // error={errors.message}
                                value={value}
                                onChange={(e) => {
                                    setValue(e.target.value)
                                    // resetErrorsHandler(e.target.name);
                                }}
                                placeholder="Type your message here…"
                                inputProps={{ 'aria-label': 'Type your message here…', maxLength: 2000 }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        {value.length > 0 && <IconButton type='submit' loading={loading}>
                                            {loading ? <CircularProgress size={20} /> : <img src={SendIcon} height={'20px'} />}
                                        </IconButton>}
                                    </InputAdornment>
                                }

                            />
                            <FileUpload APIUrl={""}
                                setFiles={(file) => setFiles(file)}
                                showBorderOnNoFiles={false}
                                uploadButtonView={false}
                                multiple={true}
                                name={"attachments[]"}
                                action={"Upload"}
                                startIcon={<FileIcon />}
                                component={'message'}
                                messageLoading={loading}
                            />

                            {/* <Grid item xs={12} style={{ textAlign: 'end', marginTop: '-41px' }}>
                                    <MROButton variant='contained' color='primary' loading={loading}>

                                        Send
                                    </MROButton></Grid> */}
                        </Grid>

                    </form>
                </Grid>


            </Grid >

            {open &&

                <Menu id={`long-menu`}
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    className={classes.menu}
                    onClose={handleClose}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    transformOrigin={{ horizontal: "right" }}

                    PaperProps={{
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: "25ch",
                        },
                    }}
                >

                    <MenuItem onClick={() => {
                        handleArchiveState()
                    }}>
                        <img src={ArchiveIcon} style={{ height: '18px' }} />
                        <Typography style={{ paddingLeft: '4px' }} >{selectedChat.archive === true ? "Un-Archive" : "Archive"}</Typography>
                    </MenuItem>
                    {selectedChat?.chat_type === "group" &&
                        <>
                            <MenuItem onClick={() => {
                                setOpen(false)
                                setAction('edit_title')
                            }}>
                                <EditIcon size='small' style={{ height: '18px' }} />
                                <Typography style={{ paddingLeft: '2px' }} >Subject title</Typography>
                            </MenuItem>
                            <MenuItem onClick={() => {
                                setAction('view_part')
                            }}>
                                <ViewIcon size='small' style={{ height: '18px' }} />
                                <Typography style={{ paddingLeft: '2px' }} >View Participant</Typography>
                            </MenuItem>
                        </>
                    }
                </Menu >


            }

            {action && action === 'edit_title' && <EditTitle handleClose={() => setAction(null)} selectedChat={selectedChat} getMesssagesList={getMesssagesList} getChatDetails={getChatDetails} title={title} />}
            {action && action === 'view_part' && <ViewParticipant handleClose={() => setAction(null)} selectedChat={selectedChat} getMesssagesList={getMesssagesList} getChatDetails={getChatDetails} />}
        </>
    )
}

export default ChatDetails