import { BASE_API_URL } from "../shared/constants"

export const validateFormData = (formData, optionalFields = []) => {
  let valid = true;
  let error = {};

  for (const pair of formData.entries()) {
    const key = pair[0];
    const value = pair[1];

    if (value === "" && !optionalFields.includes(key)) {
      error[key] = true;
      valid = false;
    }
  }

  return { valid, error };
};

export const downloadFileFromUrl = (fileUrl) => {
  const link = document.createElement("a");
  link.href = `${BASE_API_URL}${fileUrl}`;
  document.body.append(link);
  link.click();
  document.body.removeChild(link);
};