import request from "../../shared/api_client";

function getStationQuestions(id) {
    return request({
        url: `/station_questions?id=${id}`,
        method: 'GET',
    });
}

function getOrgStationQuestions(data) {
    console.log(data)
    const { organisation_id, id } = data;
    return request({
        url: `/station_questions/client_sq_indexing?organisation_id=${organisation_id}&id=${id}`,
        method: 'GET',
        data: data
    })
}

function createStationQuestions(formData) {
    return request({
        url: `/station_questions`,
        method: 'POST',
        data: formData
    })
}
function getOrganisations() {
    return request({
        url: '/station_checks/fetch_organisations',
        method: 'GET',
    });
}
function updateStationQuestions(formData) {
    return request({
        url: `/station_questions/update_question`,
        method: 'PUT',
        data: formData
    })
}

function deleteStationQuestions(formData) {
    return request({
        url: `/station_questions/destroy_question`,
        method: 'DELETE',
        data: formData
    })
}

function mapOrganisationQuestion(formData) {
    return request({
        url: `/station_questions/map_organisation`,
        method: 'POST',
        data: formData
    })
}


const StationQuestionService = {
    getStationQuestions, getOrgStationQuestions, createStationQuestions, updateStationQuestions,
    deleteStationQuestions, getOrganisations,
    mapOrganisationQuestion
};

export default StationQuestionService;