import React, { useCallback, useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import BrowserAppRoutes from "./views/Routes";
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core'
import { Planets } from "react-preloaders";
import ToastMessageProvider from "./lib/contexts/message_context";
import { deepPurple, purple, blue, common, yellow, lightBlue, deepOrange, lime } from '@material-ui/core/colors'
import AuthClient from './lib/services/api/auth_service';

const defaultTheme = createMuiTheme();

export const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#004A74",
        },
        secondary: {
            main: "#26B4E8",
        },
    },
    typography: {
        fontFamily: [
            'Source Sans Pro',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            'Oxygen',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        subtitle2: {
            fontWeight: 'bold'
        },
        subtitle1: {
            fontSize: '1rem'
        },
    },
    overrides: {
        MuiListItem: {
            button: {
                // backgroundColor: '#005686',
                '&:hover': {
                    backgroundColor: 'none',
                }
            },
        },
        MuiFormLabel: {
            asterisk: {
                color: '#db3131',
                '&$error': {
                    color: '#db3131'
                },
            }
        },
        MTableToolbar: {
            searchField: {
                width: '100% !important'
            }
        },
        MuiTypography: {
            subtitle1: {
                fontWeight: 700
            }
        },
        MuiButton: {
            root: {
                textTransform: "none",
                fontWeight: 500,
                minWidth: 100,
                fontSize: '0.7rem',
                color: '#ffffff'
            },
            containedPrimary: {
                color: '#ffffff'
            },
            containedSecondary: {
                color: '#ffffff'
            },
            //     disabled:{
            //        color:'black !important'
            //    }
        },

        MuiTab: {
            root: {
                textTransform: 'none'
            }
        },
        MuiDialog: {
            paperFullScreen: {
                left: '20%'
            },
        },
        MuiPaper: {
            root: {
                boxShadow: '0px 1px 3px #0000001A'
            }
        },
        MuiSelect: {
            root: {
                fontSize: "14px",
                padding: defaultTheme.spacing(1)
            }
        },
        MuiTableCell: {
            root: {
                padding: '5px',
                [defaultTheme.breakpoints.down("xs")]: {
                    padding: '16px'
                }
            },
            head: {
                fontWeight: 'bold'
            }
        },
        // MuiBackdrop:{
        //     root:{
        //     left:'20%'
        // }
        // },

        MuiDialog: {
            root: {
                // left: GetMatches() ? '250px !important' : ""
            },
            paperFullScreen: {
                left: '20%'
            }
        },
        MuiStepConnector: {
            lineVertical: {
                borderLeftStyle: 'dashed'
            }
        },
        MuiPickersDateRangePickerInput: {
            root: {
                width: '25rem'
            }
        },
    }
});
function App() {

    const [loading, setLoading] = useState(true);
    const matches = useMediaQuery('(min-width:959px)');

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, [])
    // Setting up modal to center
    let _theme = {
        ...theme
    };
    _theme.overrides.MuiDialog = {
        root: {
            left: matches ? '250px !important' : ""
        }
    }
    // Configuration for timeouts
    const sessionTimeout = 2880 * 60 * 1000; // 48 hourse
    let logoutTimer;
    // Reset activity timers
    const resetTimers = useCallback(() => {
        clearTimeout(logoutTimer);
        // Set a new logout timer
        logoutTimer = setTimeout(() => {
            handleLogout(); // Auto-logout the user
        }, sessionTimeout);
    }, [sessionTimeout]);
    // Handle logout
    const handleLogout = () => {
        let path = window.location.pathname;
        const routesToCheck = ["/", "/auth/signin", "/auth/two-factor-auth", "/auth/", "/auth/reset-password", "/auth/set-password", "/auth/set-pin", "/auth/forgot-password"];
        // Check if none of the routes in routesToCheck match the current path
        if (!routesToCheck.some((route) => route === path)) {
            AuthClient.logout().then(() => alert("Session Expired. Sign In Again."))
        }
    };
    useEffect(() => {
        // Start the initial timers
        resetTimers();
        // Cleanup event listeners and timers on component unmount
        return () => {
            clearTimeout(logoutTimer);
        };
    }, [resetTimers]);

    return (
        <Router onUpdate={() => document.getElementById('main_content').scrollTo(10, 10)}>
            {loading && <Planets background={theme.palette.primary.dark} color={"#fff"} />}
            <ThemeProvider theme={_theme}>
                <ToastMessageProvider>
                    <BrowserAppRoutes />
                </ToastMessageProvider>
            </ThemeProvider>
        </Router>
    );
}

export default App;
