import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(theme => ({
    loadingWrapper:{
        textAlign:'center',
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        width:'100%'
    },
    contained:{
        width:'100%',
        height:'100%'
    },
    block:{
        height:'100px'
    },
    overlay:{
        position:'fixed',
        top:0,
        bottom:0,
        left:0,
        right:0,
        zIndex:2500,
        pointerEvents:'none',
        backgroundColor: "rgba(255, 252, 252, 0.34)"
    },
    page: {
        position:'fixed',
        top:0,
        bottom:0,
        left:0,
        right:0,
        zIndex:2500,
        pointerEvents:'none',
        backgroundColor: theme.palette.primary.main
    }
}));

function LoadingIndicator(props){
    const classes = useStyles();
    const {children,variant,LoaderProps,Component} = props;

    return (
        <div className={`${classes.loadingWrapper} ${classes[variant]}`}>
            {Component?Component:<CircularProgress color="primary" {...LoaderProps}/>}
            {children}
        </div>
    )
}

export default LoadingIndicator;