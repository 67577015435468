import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Switch from "@material-ui/core/Switch";

const PositiveSwitch = withStyles(theme=> ({
    switchBase: {
        '&$checked': {
            color: "#4FC605",
        },
        '&$checked + $track': {
            backgroundColor: "#4FC605",
        },
    },
    checked: {},
    track: {},
}))(Switch);

export default PositiveSwitch;