import React, { useState, useEffect, useContext } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MROFullScreenRightDrawer from "../../components/fullwidth_dialog";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import BackIcon from '@material-ui/icons/KeyboardBackspace';
import EditIcon from '@material-ui/icons/Edit';
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { ToastMessageContext } from "../../lib/contexts/message_context";
import Button from "@material-ui/core/Button";
import StatusDot from "../../components/Universal/status_dots";
import MROButton from "../../components/buttons";
import CircularProgress from "@material-ui/core/CircularProgress";
import OrganisationApiClient from "../../lib/services/api/organisation_details";

const useStyles = makeStyles(theme => ({
    content: {
        width: "calc(100% - 250px)"
    },
    appBar: {
        backgroundColor: "#fff",
        color: '#000000CC',
        boxShadow: "0px 3px 6px #0000001A",
    },
    backButton: {
        marginRight: theme.spacing(2),
    },
    wrapper: {
        margin: '3%',
        padding: '3%',
        background: '#fff',
        boxShadow: "0px 1px 4px #0000001A",
        '& form': {
            paddingTop: '25px',
            width: '85%',
            '& .MuiGrid-spacing-xs-5': {
                '& .MuiGrid-item': {
                    paddingBottom: '0'
                }
            }
        },
        '& .align-left-space-between': {
            paddingBottom: theme.spacing(1)
        },
        '& .MuiTypography-root': {
            fontSize: '14px'
        },
        '& .address-wrapper': {
            paddingTop: '10px',
            paddingLeft: '20px'
        }
    },
    actionButtons: {
        paddingTop: '20px',
        textAlign: 'right',
        '& button': {
            marginRight: '20px'
        }
    }
}));

function UserDetails(props) {
    const classes = useStyles();
    const message = useContext(ToastMessageContext);
    const [loading, setLoading] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const { action, handleClose, handleEdit } = props;
    const [organisationData, setOrganisationData] = useState({});


    useEffect(() => {
        setLoading(true);
        OrganisationApiClient.getDetails(props.data.id).then((res) => {
            setPreviewImage(res.avatar)
            if (res.organisation) {
                setOrganisationData(res.organisation);
                setLoading(false)
            }
        }).catch(() => {
            message.showToastMessage({ message: "Something went wrong. Try again", variant: "error" })
        })
    }, [action]);
    // console.log('hallo', organisationData.company_name)
    return (
        <MROFullScreenRightDrawer>
            <AppBar position="static" className={classes.appBar} elevation={0}>
                <Toolbar>
                    <IconButton onClick={handleClose} edge="start" className={classes.backButton} color="inherit" aria-label="back">
                        <BackIcon />
                    </IconButton>
                    <Typography variant="subtitle2" className={classes.title}>
                        View Organisation Details
                    </Typography>
                </Toolbar>
            </AppBar>
            {!loading && organisationData ? <div className={classes.wrapper}>
                <div style={{ textAlign: 'right' }}>
                    <MROButton variant="contained" color="primary" startIcon={<EditIcon size="small" />} onClick={handleEdit}>Edit</MROButton>
                </div>
                <Typography variant={"subtitle2"} color={"secondary"}>Organisation Details</Typography>
                <Divider />
                <br /><br />
                <Grid container spacing={10} alignItems="flex-start" justify={"space-around"}>
                    <Grid item xs={12} sm={6} md={6}>
                        <div className="align-left-space-between">
                            <Typography color="primary" component="div">Company Name</Typography>
                            <Typography variant="subtitle2" component="div">{organisationData.company_name || "-"}</Typography>
                        </div>
                        <div className="a">
                            <Typography color="primary" component="div">Company Address  </Typography>
                            <div className="address-wrapper">
                                <div className="align-left-space-between">
                                    <Typography color="primary" component="div">Address Line 1</Typography>
                                    {organisationData.address_line_1 || "-"}
                                </div>
                                <div className="align-left-space-between">
                                    <Typography color="primary" component="div">Address Line 2</Typography>
                                    {organisationData.address_line_2 || "-"}
                                </div>
                                <div className="align-left-space-between">
                                    <Typography color="primary" component="div">Address Line 3</Typography>
                                    {organisationData.address_line_3 || "-"}
                                </div>
                            </div>
                        </div>

                        <div className="align-left-space-between">
                            <Typography color="primary" component="div">Country</Typography>
                            <Typography variant="subtitle2" component="div">{organisationData.country || "-"}</Typography>
                        </div>
                        <div className="align-left-space-between">
                            <Typography color="primary" component="div">City</Typography>
                            <Typography variant="subtitle2" component="div">{organisationData.city || "-"}</Typography>
                        </div>
                        <div className="align-left-space-between">
                            <Typography color="primary" component="div">Post Code</Typography>
                            <Typography variant="subtitle2" component="div">{organisationData.post_code || "-"}</Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                        <Typography variant={"subtitle2"} color={"secondary"}>Company Logo Details</Typography>
                        <div className={classes.orgLogo}>
                            {previewImage === null ? '' : <img id="target" alt="" style={{ width: '100%', height: '20%', marginTop: '20px' }}
                                src={`${process.env.REACT_APP_BASE_API_URL}` + previewImage} />}
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Typography variant={"subtitle2"} color={"secondary"}>Organisation HEX Colour Codes</Typography>
                        <div className="align-left-space-between">
                            <Typography color="primary" component="div">Primary Color Code</Typography>
                            <Typography variant="subtitle2" component="div">{organisationData.primary_color_code || "-"}</Typography>
                        </div>
                        <div className="align-left-space-between">
                            <Typography color="primary" component="div">Secondary Color Code</Typography>
                            <Typography variant="subtitle2" component="div">{organisationData.secondary_color_code || "-"}</Typography>
                        </div>
                        <div className="align-left-space-between">
                            <Typography color="primary" component="div">Status</Typography>
                            <Button size="small" variant={"outlined"} className={organisationData.status ? "positive" : "negative"}><StatusDot size={8} variant={organisationData.status ? "positive" : "negative"} />&nbsp; {organisationData.status ? "Active" : "In Active"}</Button>
                        </div>
                    </Grid>
                </Grid>
                <Grid container spacing={5} alignItems={"center"} justify={"flex-start"}>
                    <Grid item xs={12} sm={6} md={4}>
                        <br />
                        <Typography variant={"subtitle2"} color={"secondary"}>Feature Setup</Typography>
                        <Divider />
                        <br />
                        <div className="align-left-space-between">
                            <Typography color="primary" component="div">Delete period</Typography>
                            <Typography variant="subtitle2" component="div">{organisationData.delete_period || "-"}</Typography>
                        </div>
                        <div className="align-left-space-between">
                            <Typography color="primary" component="div">Individual messaging - On/Off</Typography>
                            <Button size="small" variant={"outlined"} className={organisationData.individual_messaging ? "positive" : "negative"}><StatusDot size={8} variant={organisationData.individual_messaging ? "positive" : "negative"} />&nbsp;
                             {/* {organisationData.individual_messaging ? "Active" : "In Active"} */}
                            </Button>
                        </div>

                        <div className="align-left-space-between">
                            <Typography color="primary" component="div">'Add Unknown Part - On/Off</Typography>
                            <Button size="small" variant={"outlined"} className={organisationData.unknown_part ? "positive" : "negative"}><StatusDot size={8} variant={organisationData.unknown_part? "positive" : "negative"} />&nbsp; 
                            {/* {organisationData.unknown_part? "Active" : "In Active"} */}
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </div> : <div className={"center"} style={{ height: '75vh' }}>
                    <CircularProgress color="primary" />
                </div>
            }
        </MROFullScreenRightDrawer >
    )
}

export default UserDetails;