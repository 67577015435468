import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import MROButton from "../buttons";

export default function DeleteDialog(props) {
  const { open, handleClose, title, description, confirmHandler } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" color="default">
            {description ||
              "All associated data will be removed. Once removed cannot be undone"}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ padding: "16px 24px" }}>
          <MROButton onClick={handleClose} variant="contained">
            No
          </MROButton>
          <MROButton
            onClick={confirmHandler}
            color="primary"
            style={{ marginLeft: "18px" }}
            variant="contained"
          >
            Yes
          </MROButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
