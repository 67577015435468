import React, { useState, useEffect, useContext } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { ToastMessageContext } from "../../lib/contexts/message_context";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import MROTextField from "../../components/form_components/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Fade from "@material-ui/core/Fade";
import BackupIcon from "@material-ui/icons/Backup";
import MROButton from "../../components/buttons";
import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";
import EditIcon from "@material-ui/icons/Edit";
import Drawer from "@material-ui/core/Drawer";
import ManageCategories from "./manage_categories";
import WebCategories from "./web_applications";
import PositiveSwitch from "../../components/form_components/switch";
import OrganisationApiClient from "../../lib/services/api/organisation_details";
import "./new_organisation.css";
import UpdateCategories from "./update_categories";
import CreateWebCategories from "./create_web_category";
import UpdateWebCategories from "./update_web_category";
import CustomToggleSwitch from "../../components/Universal/active_toggle_switch";

const useStyles = makeStyles((theme) => ({
  content: {
    width: "calc(100% - 250px)",
  },
  appBar: {
    backgroundColor: "#fff",
    color: "#000000CC",
    boxShadow: "0px 3px 6px #0000001A",
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  wrapper: {
    margin: "3%",
    padding: "3%",
    background: "#fff",
    boxShadow: "0px 1px 4px #0000001A",
    "& form": {
      paddingTop: "25px",
      width: "85%",
      "& .MuiGrid-spacing-xs-5": {
        "& .MuiGrid-item": {
          paddingBottom: "0",
        },
      },
    },
  },
  actionButtons: {
    paddingTop: "20px",
    textAlign: "right",
    "& button": {
      marginRight: "20px",
    },
  },
  tabs: {
    padding: "20px 10px",
    fontSize: "16px",
    color: theme.palette.grey[400],
    fontWeight: 500,
    cursor: "pointer",
    "& .selected": {
      color: theme.palette.primary.main,
      fontWeight: 700,
      fontSize: "18px",
    },
  },
}));

function CreateOrgSetup(props) {
  const classes = useStyles();
  const message = useContext(ToastMessageContext);
  const [checked, setChecked] = useState("");
  const [webStatus, setWebStatus] = useState(null);
  const [companyChecked, setCompanyChecked] = useState(false);
  const [taskChecked, setTaskChecked] = useState(false);
  const [toolingChecked, setToolingChecked] = useState(false);
  const [metricsChecked, setMetricsChecked] = useState(false);
  const [recencyChecked, setRecencyChecked] = useState(false);
  const [open, setOpen] = [props.open, props.onClose];
  const [step, setStep] = useState(1);
  const [tempAction, setTempAction] = useState(null);
  const [webChecked, setWebChecked] = useState({});
  const [completedSteps, setCompletedSteps] = useState(1);
  const [loading, setLoading] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [state, setState] = useState({
    right: false,
  });
  const [addUnkonwPart, setAddUnkonwPart] = useState(false);
  const individual_messaging = props?.organisationData?.individual_messaging;

  useEffect(() => {
    setCompanyChecked(individual_messaging);
    // setStep(1);
    // console.log({ individual_messaging })
  }, [individual_messaging]);
  const toggleSetup = () => {
    props.handleClose();
  };

  useEffect(() => {
    if (props.organisationData?.unknown_part) {
      setAddUnkonwPart(props.organisationData.unknown_part);
    }
  }, [props.organisationData?.unknown_part]);
  useEffect(() => {
    if (props.organisationData?.user_tooling) {
      setToolingChecked(props.organisationData.user_tooling);
    }
  }, [props.organisationData?.user_tooling]);
  useEffect(() => {
    if (props.organisationData?.metric_units) {
      setMetricsChecked(props.organisationData.metric_units);
    }
  }, [props.organisationData?.metric_units]);

  useEffect(() => {
    if (props.organisationData?.direct_task_library) {
      setTaskChecked(props.organisationData.direct_task_library);
    }
  }, [props.organisationData?.direct_task_library]);

  useEffect(() => {
    if (props.organisationData?.app_recency) {
      setRecencyChecked(props.organisationData.app_recency);
    }
  }, [props.organisationData?.app_recency]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let formData = new FormData(e.target);
    formData.append("individual_messaging", companyChecked);
    formData.append("unknown_part", addUnkonwPart);
    formData.append("direct_task_library", taskChecked);
    formData.append("user_tooling", toolingChecked);
    formData.append("metric_units", metricsChecked);
    formData.append("app_recency", recencyChecked);
    OrganisationApiClient.update(
      `/organisations/update_organisation_setup?organisation_id=${props.organisationId}`,
      formData
    )
      .then((res) => {
        if (res.organisation) {
          toggleSetup();
          props.organisationList();
          message.showToastMessage({
            message: "Feature setup successfully updated",
            variant: "success",
          });
        }
      })
      .catch((error) => {
        console.log(error)
        message.showToastMessage({
          message: "Something went wrong. Try again",
          variant: "error",
        });
      });
  };
  const toggleTempDrawer = () => {
    setTempAction("edit");
  };
  const updateDrawer = () => {
    setTempAction("editFrontEnd");
  };
  const toggleWebAppln = () => {
    setTempAction("webCreate");
  };
  const updateWebAppln = () => {
    setTempAction("webEdit");
  };
  const handleCheckedCategory = (offeredServices) => {
    setChecked(offeredServices);
  };
  const handleWebCategory = (webServices) => {
    setWebChecked(webServices);
  };
  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <Grid
          container
          spacing={5}
          alignItems={"center"}
          justify={"flex-start"}
        >

          <Grid item xs={12}>
            <Button variant="contained" disabled style={{ color: "#000", fontSize: '14px' }}>
              Company Settings
            </Button>
          </Grid>
          <br />



          <Grid item xs={12} container>
            <Grid item xs={3}>
              <span style={{
                fontSize: '15px',
                position: 'relative',
                top: '7px'
              }}>Individual messaging ? </span>
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel

                control={
                  <PositiveSwitch
                    edge="end"
                    // value={companyChecked}
                    defaultChecked={individual_messaging}
                    inputProps={{ "aria-labelledby": "on-or-off" }}
                    onChange={(e) => setCompanyChecked(e.target.checked)}
                  />
                }

              />
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>

          <Grid item xs={12} container>
            <Grid item xs={3}>
              <span style={{
                fontSize: '15px',
                position: 'relative',
                top: '7px'
              }}>Add unknown Part?</span>
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel

                control={
                  <PositiveSwitch
                    checked={addUnkonwPart}
                    onChange={(e) => setAddUnkonwPart(e.target.checked)}
                  />
                }

              />
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
          <Grid item xs={12} container>
            <Grid item xs={3}>
              <span style={{
                fontSize: '15px',
                position: 'relative',
                top: '7px'
              }}>Add Tasks Direct to Library?</span>
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel

                control={
                  <PositiveSwitch
                    checked={taskChecked}
                    onChange={(e) => setTaskChecked(e.target.checked)}
                  />
                }

              />
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
          <Grid item xs={12} container>
            <Grid item xs={3}>
              <span style={{
                fontSize: '15px',
                position: 'relative',
                top: '7px'
              }}>Add direct to User tooling?</span>
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel

                control={
                  <PositiveSwitch
                    checked={toolingChecked}
                    onChange={(e) => setToolingChecked(e.target.checked)}
                  />
                }

              />
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
          <Grid item xs={12} container>
            <Grid item xs={3}>
              <span style={{
                fontSize: '15px',
                position: 'relative',
                top: '7px'
              }}>Metric units?(Statute default)</span>
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel

                control={
                  <PositiveSwitch
                    checked={metricsChecked}
                    onChange={(e) => setMetricsChecked(e.target.checked)}
                  />
                }

              />
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
          <Grid item xs={12} container>
            <Grid item xs={3}>
              <span style={{
                fontSize: '15px',
                position: 'relative',
                top: '7px'
              }}>APP 'Email Recency' option?</span>
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel

                control={
                  <PositiveSwitch
                    checked={recencyChecked}
                    onChange={(e) => setRecencyChecked(e.target.checked)}
                  />
                }

              />
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
          {/* <Grid item xs={4}>
            <Button variant="contained" disabled style={{ color: "#000" }}>
              Delete Period (W/O + Messages)
            </Button>
            <MROTextField
              name={"delete_period"}
              label={"Days"}
              placeholder={"Days"}
              required
              InputLabelProps={{ shrink: true }}
              defaultValue={
                props.organisationData === undefined
                  ? ""
                  : props.organisationData.delete_period
              }
            />
          </Grid> */}
          {/* <Grid  style={{marginBottom:'-32px'}} item xs={2}>
                    (Days)
                </Grid> */}
          <Grid item xs={4}></Grid>
          <Grid item xs={4}></Grid>

          {/* <FormControlLabel
                                control={
                                    <PositiveSwitch
                                        checked={companyChecked}
                                        onChange={(e)=>setCompanyChecked(e.target.checked)}
                                        name="individual_messaging"
                                    />
                                }
                                label="Individual messaging - On/Off"
                                labelPlacement="start"
                                name={"individual_messaging"}
                            /> */}
          {/* <FormControlLabel
            control={
              <PositiveSwitch
                edge="end"
                // value={companyChecked}
                defaultChecked={individual_messaging}
                inputProps={{ "aria-labelledby": "on-or-off" }}
                onChange={(e) => setCompanyChecked(e.target.checked)}
              />
            }
            label="Individual messaging - On/Off"
            labelPlacement="start"
            name={"individual_messaging"}
          />

          <Grid item xs={12}>
            <FormControlLabel
              style={{ marginLeft: 0 }}
              control={
                <PositiveSwitch
                  checked={addUnkonwPart}
                  onChange={(e) => setAddUnkonwPart(e.target.checked)}
                />
              }
              label="Add unknown Part?"
              labelPlacement="start"
            />
          </Grid> */}

          <Grid item xs={12}>
            <Button variant="contained" disabled style={{ color: "#000", fontSize: '14px' }}>
              Function SetUp
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Typography>WEB-APP </Typography>
          </Grid>
          <Grid item xs={4}>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={
                props.organisationData === undefined
                  ? toggleWebAppln
                  : updateWebAppln
              }
            >
              <EditIcon />
            </IconButton>
          </Grid>
          <Grid item xs={5}></Grid>
          <Grid item xs={3}>
            <Typography>APP </Typography>
          </Grid>
          <Grid item xs={4}>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={
                props.organisationData === undefined
                  ? toggleTempDrawer
                  : updateDrawer
              }
            >
              <EditIcon />
            </IconButton>
          </Grid>
          <Grid item xs={5}></Grid>

        </Grid>{" "}
        <div className={classes.actionButtons}>
          <MROButton
            variant={"contained"}
            color="tertiary"
            onClick={toggleSetup}
          >
            Cancel
          </MROButton>
          <MROButton variant={"contained"} color="primary" type="submit">
            {" "}
            Save
          </MROButton>
        </div>
      </form>
      {props.organisationData === undefined
        ? tempAction === "edit" && (
          <ManageCategories
            organisationDetail={props.organisationList}
            organisationId={props.organisationId}
            handleCheckedCategory={handleCheckedCategory}
            handleSideClose={() => {
              setTempAction(null);
            }}
          />
        )
        : tempAction === "editFrontEnd" && (
          <UpdateCategories
            organisationId={props.organisationId}
            handleSideClose={() => {
              setTempAction(null);
            }}
          />
        )}
      {props.organisationData === undefined
        ? tempAction === "webCreate" && (
          <CreateWebCategories
            organisationDetail={props.organisationList}
            organisationId={props.organisationId}
            handleCheckedCategory={handleCheckedCategory}
            handleSideClose={() => {
              setTempAction(null);
            }}
          />
        )
        : tempAction === "webEdit" && (
          <UpdateWebCategories
            organisationId={props.organisationId}
            handleSideClose={() => {
              setTempAction(null);
            }}
          />
        )}
      {webStatus === "web" && (
        <WebCategories
          handleCheckedCategory={handleWebCategory}
          webApplnClose={() => {
            setWebStatus(null);
          }}
        />
      )}
    </React.Fragment>
  );
}

export default CreateOrgSetup;
