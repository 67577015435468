import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

function a11yProps(index) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar: {
        backgroundColor: "#fff",
        color: theme.palette.primary.main,
        padding: "1% 1% 0 1%"
    }
}));

const MROTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        fontWeight: 400,
        color: "#70707099",
        '& .nav_icon': {
            visibility: 'hidden'
        },
        '&$selected': {
            color: theme.palette.primary.main,
            fontWeight: 500,
            '& .nav_icon': {
                visibility: "visible"
            }
        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />)

export default function TopNavigationBar(props) {
    const classes = useStyles();
    const [currentTab, setCurrentTab] = useState(0);
    const tabProp = props.tabProperties || [];
    const handleChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    const currentPath = props.location.pathname;
    const getTabIndex = () => {
        const tabIndex = tabProp.findIndex(tab => currentPath.indexOf(tab.section) !== -1);
        return tabIndex >= 0 ? tabIndex : 0;
    }

    useEffect(() => {
        setCurrentTab(getTabIndex())
    }, [])

    return (
        <div className={classes.root}>
            <AppBar position="static" className={classes.appBar} elevation={0}>
                <Tabs value={currentTab} onChange={handleChange} aria-label="top navigation bar" indicatorColor={"primary"} TabIndicatorProps={{ style: { height: '3px' } }}>
                    {tabProp.map((tab, i) =>
                        <MROTab key={i} label={tab.label} {...a11yProps(i)} onClick={() => { props.history.push(tab.redirect) }} />
                    )}
                </Tabs>
            </AppBar>
        </div>
    );
}
