import { Dialog, DialogContent, makeStyles, Grid, DialogTitle, CircularProgress } from '@material-ui/core'
import React, { useContext, useState } from 'react'
import MROTextField from '../../components/form_components/NewTextField'
import MROButton from '../../components/buttons';
import OrganisationApiClient from '../../lib/services/api/organisation_details';
import { ToastMessageContext } from '../../lib/contexts/message_context';

const useStyles = makeStyles(theme => ({
    actionButtons: {
        paddingTop: '20px',
        paddingBottom: '33px',
        textAlign: 'center',
        '& button:not(:last-child)': {
            marginRight: '20px'
        }
    },
    modal: {
        '& .MuiDialog-paperWidthMd': {
            maxWidth: '450px',
        }
    },
    title: {
        fontSize: '18px',
        '& h2': {
            color: theme.palette.secondary.main,
            textAlign: 'center',

        }
    },
    formLabel: {

        '& span:last-child': {
            fontSize: '14px',
        }
    }

}));

export default function CreateAlternateAddress(props) {

    const classes = useStyles();
    const { action, handleClose, setAlternateData, alternateData, currentSelection } = props;
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false)
    const message = useContext(ToastMessageContext)
    const handleSubmit = async (e) => {
        e.preventDefault()
        // console.table("formdata=====", e.target)
        const formdata = new FormData(e.target)
        let valid = true;
        let errors = {
            name: false,
            line_1: false,
            city: false,
            postcode: false,
            country: false,

        };

        for (let pair of formdata.entries()) {

            const value = pair[1];
            const key = pair[0];
            if (key !== "line_2" && key !== "line_3") {
                if (!value) {
                    errors[key] = true;
                    valid = false;
                }
            }
        }
        if (!valid) {
            setError(errors);
            return;
        }

        if (action === "add_address") {
            setLoading(true)
            let newData = [{
                id: +new Date(),
                name: formdata.get("name"),
                line_1: formdata.get('line_1'),
                line_2: formdata.get('line_2'),
                line_3: formdata.get('line_3'),
                city: formdata.get("city"),
                postcode: formdata.get("postcode"),
                country: formdata.get("country")
            }]
            setAlternateData([...alternateData, ...newData])
            message.showToastMessage({ message: "Address created successfully ", variant: "success" })
            setLoading(false)
            handleClose()
        }
        if (action === "update_address") {
            setLoading(true);
            let newData = {
                id: currentSelection?.id,
                name: formdata.get("name"),
                line_1: formdata.get('line_1'),
                line_2: formdata.get('line_2'),
                line_3: formdata.get('line_3'),
                city: formdata.get("city"),
                postcode: formdata.get("postcode"),
                country: formdata.get("country")
            }

            const updateData = alternateData.map((item, index) => {

                if (item.id === newData.id)
                    return newData
                return item
            }
            );

            setTimeout(() => {
                setAlternateData(updateData)
                message.showToastMessage({
                    open: true,
                    message: "Address successfully updated",
                    variant: "success",
                });
                handleClose()
                setLoading(false);
            }, 2000);


        }



    }
    const resetErrorsHandler = (name) => {
        setError((e) => ({ ...e, [name]: false }));
    };

    return (
        <div>



            <Dialog
                maxWidth={"md"}
                fullWidth={true}
                open={true}
                className={classes.modal}
                onClose={handleClose}
                aria-labelledby="indirect-jobs"
            >
                <DialogTitle variant="subtitle2" color="secondary"
                    className={classes.title}><span >Leave Request</span>
                </DialogTitle>

                <DialogContent className={classes.card}>
                    <form onSubmit={handleSubmit}>



                        <Grid style={{ paddingBottom: '10px' }}>
                            <MROTextField
                                name={"name"}
                                label={"Company Name *"}
                                InputLabelProps={{ shrink: true }}
                                // InputProps={{ style: { height: '53px', width: "100%" } }}
                                inputProps={{ maxLength: 40 }}
                                defaultValue={currentSelection?.name || ""}
                                placeholder={"Company Name"}
                                error={error?.name}
                                helperText={
                                    error.name ? "*please enter Company name" : null
                                }
                                onChange={(e) => { setError({ ...error, name: false }); }}


                            />

                            <MROTextField
                                name={"line_1"}
                                label={"Address Line 1 *"}
                                placeholder={"Line 1"}
                                inputProps={{ maxLength: 40 }}
                                defaultValue={currentSelection?.line_1 || ""}
                                InputLabelProps={{ shrink: true }}
                                autoFocus={error.line_1}
                                error={error.line_1}
                                helperText={
                                    error.line_1 ? "*please enter a address_line_1" : null
                                }
                                onChange={(e) => {
                                    setError({ ...error, line_1: false });
                                }}
                            />
                            <MROTextField
                                name={"line_2"}
                                label={"Address Line 2"}
                                inputProps={{ maxLength: 40 }}
                                placeholder={"Line 2"}
                                defaultValue={currentSelection?.line_2 || ""}
                                InputLabelProps={{ shrink: true }}
                            />
                            <MROTextField
                                name={"line_3"}
                                label={"Address Line 3"}
                                autoFocus
                                placeholder={"Line 3"}
                                inputProps={{ maxLength: 40 }}
                                defaultValue={currentSelection?.line_3 || ""}
                                InputLabelProps={{ shrink: true }}
                            />
                            <MROTextField
                                name={"city"}
                                label={"City *"}
                                placeholder={"City"}
                                defaultValue={currentSelection?.city || ""}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ maxLength: 40 }}
                                autoFocus={error.city}
                                error={error.city}
                                helperText={error.city ? "*please enter a city" : null}
                                onChange={(e) => {
                                    setError({ ...error, city: false });
                                }}
                            />
                            <MROTextField
                                name={"postcode"}
                                label={"Post Code *"}
                                placeholder={"Post Code"}
                                //   type="number"
                                inputProps={{
                                    maxLength: 40,
                                }}
                                defaultValue={currentSelection?.postcode || ""}
                                InputLabelProps={{ shrink: true }}
                                autoFocus={error.postcode}
                                error={error.postcode}
                                helperText={error.postcode ? "*please enter a postcode" : null}
                                onChange={(e) => {
                                    setError({ ...error, postcode: false });
                                }}
                            />
                            <MROTextField
                                name={"country"}
                                label={"Country *"}
                                placeholder={"Country"}
                                defaultValue={currentSelection?.country || ""}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ maxLength: 40 }}
                                autoFocus={error.country}
                                error={error.country}
                                helperText={error.country ? "*please enter a country" : null}
                                onChange={(e) => {
                                    setError({ ...error, country: false });
                                }}
                            />
                        </Grid>
                        <div className={classes.actionButtons}>
                            <MROButton
                                disabled={loading}
                                type="button"
                                variant={"contained"}
                                onClick={handleClose}
                            >
                                Cancel
                            </MROButton>
                            <MROButton
                                loading={loading}
                                type="submit"
                                variant={"contained"}
                                color="primary"

                            >
                                {loading ? <CircularProgress size="20px" color='priimary' /> : "Save"}
                            </MROButton>

                        </div>
                    </form>
                </DialogContent>

            </Dialog>


        </div>
    )
}
