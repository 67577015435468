import React, { useState, useContext, useEffect } from "react";
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { ToastMessageContext } from "../../lib/contexts/message_context";
import MROTextField from "../../components/form_components/TextField";
import MROButton from "../../components/buttons";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import PositiveSwitch from "../../components/form_components/switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import LineReportClient from "../../lib/services/api/line_report";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  dropbox: {
    height: "64px !important",
  },
  actionButtons: {
    paddingTop: "20px",
    textAlign: "right",
    "& button": {
      marginRight: "20px",
    },
  },
}));
const theme = createMuiTheme({
  palette: {
    primary: red,
  },
});
let updateOccured = false;
export default function AddNewField(props) {
  const classes = useStyles();
  const message = useContext(ToastMessageContext);
  const [open, setOpen] = [props.open, props.onClose];
  const [age, setAge] = React.useState("");
  const { action, handleCloseSection, data } = props;
  const [error, setError] = useState({});
  const [dropDownValues, setDropDownValues] = useState("");
  const [values, setValues] = useState({});
  const [sectionName, setSectionName] = useState(null);
  const [secondaryChecked, setSecondaryChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [customSection, setCustomSection] = useState({});

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  // const handleSubmit =(e) =>{
  //     e.preventDefault();
  //     let formData = new FormData(e.target);
  //       formData.append('section_id',props.selectedIndex)
  //       formData.append('secondary_field',secondaryChecked)
  //       formData.append('mandatory',mandatoryChecked)
  //       let spl = JSON.stringify(dropDownValues);
  //       let temp =spl.split(',');
  //       let final=[...temp]
  //       console.log('temp', final)
  //       // split =dropDownValues.split(',');
  //       console.log('split',  typeof split, JSON.stringify(dropDownValues))
  //       formData.append('values',final)
  //     LineReportClient.createData(formData).then((res)=>{
  //         if(res.success){
  //           handleClose()
  //           props.listFields()
  //           props.sectionList()
  //         }
  //   })
  //  }
  useEffect(() => {
    // const editField= props.currentSection.length > 0 && props.currentSection ?props.currentSection.filter(row=>row.id === props.selectedIndex)  :'';
    //    console.log('editField1', editField)
    // //    setEditField(editField)
  }, []);
  useEffect(() => {
    customSectionInfo();
  }, [props.selectedIndex]);
  const customSectionInfo = () => {
    setLoading(true);
    if (props.selectedIndex !== null) {
      LineReportClient.fetchCustomSection(props.selectedIndex)
        .then((res) => {
          if (res.section) {
            setCustomSection(res.section);
            console.log("object", res.section);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };
  const handleCreate = (formData) => {
    setLoading(true);
    formData.append("report_name", "WorkOrder");
    LineReportClient.create(formData, "/line_reports")
      .then((res) => {
        if (res.data) {
          handleCloseSection(res.data);
          message.showToastMessage({
            message: "New Section created Successfully",
            variant: "success",
          });
        }
      })
      .catch(() => {
        message.showToastMessage({
          message: "Something went wrong. Try again",
          variant: "error",
        });
      });
  };

  const handleSubSection = (formData) => {
    setLoading(true);
    formData.append("section_id", props.selectedIndex);
    LineReportClient.create(formData, "/line_reports")
      .then((res) => {
        if (res.sub_section) {
          message.showToastMessage({
            open: true,
            message: "Supplier successfully updated",
            variant: "success",
          });
          handleCloseSection(res.sub_section);
          props.sectionList(null);
        } else {
          message.showToastMessage({
            open: true,
            message: res.errors
              ? res.errors.join(",")
              : "Something went wrong. Try Again",
            variant: "error",
          });
          setLoading(false);
        }
      })
      .catch(() => {
        message.showToastMessage({
          open: true,
          message: "Something went wrong. Try Again",
          variant: "error",
        });
        setLoading(false);
      });
  };
  const handleEditSection = (formData) => {
    setLoading(true);
    formData.append("section_id", props.selectedIndex);
    LineReportClient.edit(formData, "/line_reports")
      .then((res) => {
        // if (res.data) {
          //   message.showToastMessage({open:true,message:"Supplier successfully updated",variant:"success"});
          handleCloseSection(res.data);
          setLoading(true);
          setTimeout(() => {
            setLoading(false);
            props.sectionList();
            // handleCloseSection(null,props.selectedIndex);
          }, 300);
          message.showToastMessage({
            message: "Section Updated Successfully",
            variant: "success",
          });
        // }
      })
      .catch(() => {
        message.showToastMessage({
          message: "Something went wrong. Try again",
          variant: "error",
        });
      });
  };
  const handleDelete = () => {
    setLoading(true);
    LineReportClient.deleteData(
      `/line_reports/destroy_method?section_id=${props.selectedIndex}`
    )
      .then((res) => {
        if (res.success) {
          handleCloseSection(null, props.selectedIndex);
          props.setSelectedIndex(null);
          props.setFields([]);
          props.listFields({});
          props.sectionList();
          props.destroyFiledName(null);
          message.showToastMessage({
            message: "Section deleted Successfully",
            variant: "success",
          });
        }
      })
      .catch(() => {
        message.showToastMessage({
          message: "Something went wrong. Try again",
          variant: "error",
        });
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const email = formData.get("station_email");
    const handover = formData.get("hand_over");

    if (action === "section") {
      handleCreate(formData);
    } else if (action === "editSection") {
      handleEditSection(formData);
    } else {
      handleDelete();
    }
  };
  return (
    <React.Fragment>
      <div>
        <Dialog
          fullWidth={true}
          open={true}
          onClose={handleCloseSection}
          aria-labelledby="base-station-manage"
        >
          <DialogContent>
            {action !== "delete" ? (
              !loading ? (
                <div className={classes.formWrapper}>
                  <Typography
                    variant="subtitle2"
                    color="secondary"
                    className={classes.title}
                  >
                    {action === "editSection" ? "Edit Section Name" : ""}
                    {action === "subSection" ? "Add Sub-Section Name" : ""}
                    {/* {action === 'editSection' ? 'Edit Sub-Section Name' :''}  */}
                  </Typography>
                  <form onSubmit={handleSubmit}>
                    <MROTextField
                      variant="outlined"
                      margin="dense"
                      placeholder="Section Name"
                      name="section_name"
                      fullWidth
                      required
                      autoComplete="off"
                      defaultValue={customSection.section_name}
                    />
                    <div className={classes.actionButtons}>
                      <MROButton
                        disabled={loading}
                        type="button"
                        variant={"contained"}
                        onClick={handleCloseSection}
                      >
                        Cancel
                      </MROButton>
                      <MROButton
                        disabled={loading}
                        type="submit"
                        variant={"contained"}
                        color="primary"
                      >
                        {action === "create" ? "Create" : "Save"}
                      </MROButton>
                    </div>
                  </form>
                </div>
              ) : (
                  <div className={"center"} style={{ minHeight: "200px" }}>
                    <CircularProgress color="primary" />
                  </div>
                )
            ) : (
                <div className={classes.formWrapper}>
                  Confirm remove ? <br /> All associated data will be removed.
                Once removed cannot be undone.
                  <div className={classes.actionButtons}>
                    <MROButton
                      disabled={loading}
                      type="button"
                      variant={"contained"}
                      onClick={handleCloseSection}
                    >
                      Cancel
                  </MROButton>
                    <MROButton
                      disabled={loading}
                      type="button"
                      variant={"contained"}
                      color="primary"
                      onClick={handleDelete}
                    >
                      Yes, Confirm
                  </MROButton>
                  </div>
                </div>
              )}
          </DialogContent>
        </Dialog>
      </div>
    </React.Fragment>
  );
}
