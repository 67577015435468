import React, { useState, useEffect, useContext } from "react";
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import MROTextField from "../../components/form_components/TextField";
import MROButton from "../../components/buttons";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import { ToastMessageContext } from "../../lib/contexts/message_context";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import ChipInput from "material-ui-chip-input";
import PositiveSwitch from "../../components/form_components/switch";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import LineReportClient from "../../lib/services/api/line_report";
import { validateFormData } from "../../lib/helper_function/HelperFunction";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
const theme = createMuiTheme({
  palette: {
    primary: red,
  },
});
export default function EditField(props) {
  const classes = useStyles();
  const { action, handleEditClose, data } = props;
  const [open, setOpen] = [props.open, props.onClose];
  const message = useContext(ToastMessageContext);
  const [age, setAge] = React.useState("");
  // const [error, setError] = useState({});
  const [view, setView] = useState(false);
  const [customField, setCustomField] = useState({});
  const [mandatoryChecked, setMandatoryChecked] = useState(false);
  const [SecondaryMandatory, setSecondaryMandatory] = useState(false);
  const [secondaryChecked, setSecondaryChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentDropDown, setCurrentDropDown] = useState([]);
  const [fieldType, setFieldType] = useState(customField.field_type);
  const [secFieldType, setSecFieldType] = useState("");
  const [dropDownValues, setDropDownValues] = useState("");
  const [secFieldTyp, setSecFieldTyp] = useState("");
  const [fieldName, setFieldName] = useState("");
  const [helpText, setHelpText] = useState("");
  const [maxLength, setMaxLength] = useState("");
  const [secondaryInChecked, setSecondaryInChecked] = useState("");
  const [secDecimal, setSecDecimalPlace] = useState("");
  const [secDropDownValues, setSecDropDownValues] = useState("");
  const [secMandatoryChecked, setSecMandatoryChecked] = useState("");
  const [secName, setSecFieldName] = useState("");
  const [secHelp, setSecHelp] = useState("");
  const [secMaxLength, setSecMaxLength] = useState("");
  const [decimalPlaces, setDecimalPlaces] = useState("");

  const [errors, setErrors] = useState({})  

  useEffect(() => {
    if (action === "update") {
      setAge(customField.field_type);
      setFieldName(customField.field_name)
      setHelpText(customField.help_text)
      setMaxLength(customField.max_length)
    }
  }, [action, customField]);

  const handleChange = (event) => {
    setAge(event.target.value);
    // setFieldType("");
  };
  const handleSecChange = (event) => {
    setSecFieldType(event.target.value);
    // setFieldType("");
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChangeDropDown = (e) => {
    console.log("e.target.value", e.target.value);
    const val = e.target.value;
    setCurrentDropDown(val.split(","));
  };
  const handleCreate = (formData) => {
    //   formData.append('section_id',props.selectedIndex)
    //   formData.append('secondary_field',secondaryChecked)
    //   formData.append('mandatory',mandatoryChecked)
    //   formData.append('secondary_mandatory',SecondaryMandatory)

    LineReportClient.createData(formData)
      .then((res) => {
        if (res.success) {
          handleEditClose(res.data);
          props.listFields();
          //   props.setLoading(true);
          //   setTimeout(()=>{
          //     props.setLoading(false);
          //     handleEditClose();
          //   },300);
          props.sectionList();
          message.showToastMessage({
            message: "Field created Successfully",
            variant: "success",
          });
        }
      })
      .catch(() => {
        message.showToastMessage({
          message: "Something went wrong. Try again",
          variant: "error",
        });
      });
  };
  const handleUpdate = (formData) => {
    LineReportClient.edit(formData)
      .then((res) => {       
          handleEditClose(res.data);
          props.listFields();
          props.sectionList();
          customInfo()
          console.log("runing...");
          message.showToastMessage({
            message: "Field updated Successfully",
            variant: "success",
          });     
      })
      .catch((err) => {
        console.log('error', err)
        message.showToastMessage({
          message: "Something went wrong. Try again",
          variant: "error",
        });
      });
  };
  useEffect(() => {
    if (!props.fieldId) return;
    customInfo();
  }, [props.fieldId]);
  const customInfo = () => {
    setLoading(true);
    if (props.selectedIndex !== null) {
      LineReportClient.fetchCustomFieldInfo(props.fieldId)
        .then((res) => {
          if (res.custom_field) {
            setCustomField(res.custom_field);
            setMandatoryChecked(res.custom_field?.mandatory);
            setSecondaryChecked(res.custom_field?.secondary_field);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const FD = new FormData(e.target);

    const { valid, error } = validateFormData(FD);

    if(!valid) {
      setErrors(error);
      return
    }

    let spl = JSON.stringify(dropDownValues);
    let temp = spl.split(",");
    let final;
    if (age === "Dropdown") {
      final = [...temp];
    }
    const formData = {
      section_id: props.selectedIndex,
      secondary_field: secondaryChecked,
      mandatory: mandatoryChecked,
      dropdown_values: final,
      field_name: fieldName,
      field_type: age,
      help_text: helpText,
      max_length: Number(maxLength),

      // Adding secondary_fields field dynmicall if secMandatoryChecked is true; secondary_fields will be added to the formdata obj
      ...(secMandatoryChecked && {
        secondary_fields: {
          sec_field_name: secName,
          sec_field_type: secFieldTyp,
          sec_help_text: secHelp,
          sec_max_length: secMaxLength,
          sec_decimal_places: secDecimal,
          sec_mandatory: secMandatoryChecked,
          sec_secondary_field: secondaryInChecked,
        },
      }),
    };

    if (action === "create") {
      handleCreate(formData);
    } else if (action === "update") {
      handleUpdate(formData);
    }
  };
  return (
    <React.Fragment>
      <div>
        <Dialog
          maxWidth={"sm"}
          open={true}
          onClose={handleEditClose}
          aria-labelledby="base-station-manage"
        >
          <DialogTitle
            id="form-dialog-title"
            style={{ textTransform: "capitalize" }}
          >
            {action} Field
          </DialogTitle>
          <DialogContent>
            {!loading ? (
              <React.Fragment>
                <form noValidate onSubmit={handleSubmit}>
                <Grid
                  container
                  spacing={5}
                  alignItems={"center"}
                  justify={"flex-start"}
                >
                  <Grid item xs={6}>
                    <MROTextField
                      name={"field_name"}
                      label={"Field Name"}
                      onChange={(e) => {
                        setFieldName(e.target.value);
                        setErrors(e => ({...e, field_name: false}))
                      }}
                      // helperText={errors.field_name && "Please Enter the Field name."}
                      error={errors.field_name}
                      required
                      InputLabelProps={{ shrink: true }}
                      defaultValue={customField.field_name}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <MROTextField
                      name={"help_text"}
                      label={"Help text in box"}
                      required
                      onChange={(ev) => {
                        setHelpText(ev.target.value);
                        setErrors(e => ({...e, help_text: false}))
                      }}
                      // helperText={errors.help_text && "Please Enter the Help Text."}
                      error={errors.help_text}
                      InputLabelProps={{ shrink: true }}
                      defaultValue={customField.help_text}
                    />
                  </Grid>
                  {/* <Grid item xs={4}>
                </Grid> */}
                  <Grid item xs={6}>
                    <MROTextField
                      name={"field_type"}
                      label={"Field Type"}
                      select
                      value={age}
                      onChange={ev => {
                        handleChange(ev)
                        setErrors(e => ({...e, field_type: false}))
                      }}
                      // helperText={errors.field_type && "Please Enter the Field type."}
                      error={errors.field_type}
                      InputLabelProps={{ shrink: true }}
                      // defaultValue={customField.field_type}
                    >
                      <MenuItem value={"Yes/No"}>Yes/No</MenuItem>
                      <MenuItem value={"Numeric"}>Numeric</MenuItem>
                      <MenuItem value={"Freetext"}>Free text</MenuItem>
                      <MenuItem value={"Dropdown"}>Dropdown</MenuItem>
                    </MROTextField>
                  </Grid>
                  {/* <Grid item xs={4}>
                </Grid>
                <Grid item xs={4}>
                </Grid> */}
                  {/* {   age === 'Freetext'  &&  <Grid item xs={4}>
                     <MROTextField
                            name={"max_length"}
                            label={"Field Max Length"}
                            required
                            onChange={(e) => {setMaxLength(e.target.value)}}
                            InputLabelProps={{ shrink: true }}
                            defaultValue ={customField.max_length }
                        />   
                         </Grid>} */}
                  {age === "Freetext" && (
                    <Grid item xs={6}>
                      <MROTextField
                        type="number"
                        name={"max_length"}
                        label={"Field Max Length"}
                        required
                        onChange={(e) => {
                          setMaxLength(e.target.value);
                          setErrors(e => ({...e, max_length:false}))
                        }}
                        // helperText={errors.max_length && "Please Enter the Field Max Length."}
                        error={errors.max_length}
                        InputLabelProps={{ shrink: true }}
                        defaultValue={customField.max_length}
                      />
                    </Grid>
                  )}
                  {(fieldType === "Numeric" || age === "Numeric") && (
                    <Grid item xs={6}>
                      <MROTextField
                        type="number"
                        name={"decimal_places"}
                        label={"Decimal Places"}
                        required
                        onChange={(e) => {
                          setDecimalPlaces(e.target.value);
                          setErrors(e => ({...e, decimal_places: false}))
                        }}
                        // helperText={errors.decimal_places && "Please Enter the Decimal Places."}
                        error={errors.decimal_places}
                        InputLabelProps={{ shrink: true }}
                        defaultValue={customField.decimal_places}
                      />
                    </Grid>
                  )}
                  {fieldType === "Dropdown" || age === "Dropdown" ? (
                    <Grid item xs={6}>
                      <MROTextField
                        name={"dropdown_values"}
                        label={"Enter dropdown options"}
                        onChange={(e) => {
                          setDropDownValues(e.target.value);
                          setErrors(e => ({...e, dropdown_values: false}))
                        }}
                        // helperText={errors.dropdown_values && "Please Enter the dropdown values."}
                        error={errors.dropdown_values}
                        required
                        rows={4}
                        multiline
                        InputLabelProps={{ shrink: true }}
                        defaultValue={fieldType}
                      />
                      <FormHelperText>
                        Please enter the options as comma separated
                      </FormHelperText>
                    </Grid>
                  ) : (
                    ""
                  )}
                  {/* {( customField.field_type=== 'Dropdown' ||  age === 'Dropdown') ?  <Grid item xs={4}>
                       {/* <MROTextField
                            name={"dropdown_values"}
                            label={"Enter dropdown options"}
                            onChange={handleChangeDropDown}
                            required
                            InputLabelProps={{ shrink: true }}
                            defaultValue ={customField.dropdown_values }
                        />    */}
                  {/* <ChipInput
                              onChange={(chips) => setCurrentDropDown(chips)}
                              defaultValue={customField.dropdown_values }
                              label="Enter dropdown options"
                              name={"dropdown_values"}
                              rows={4}
                              multiline
                              fullWidth
                            />
                            <FormHelperText>Please enter the options as comma separated</FormHelperText>  
                        </Grid> :''} */}
                  <Grid item xs={6}>
                    {(fieldType === "Yes/No" || age === "Yes/No") && (
                      <FormControlLabel
                        control={
                          <PositiveSwitch
                            checked={secondaryChecked}
                            onChange={(e) =>
                              setSecondaryChecked(e.target.checked)
                            }
                            name="secondary_field"
                            defaultValue={customField.secondary_field}
                          />
                        }
                        label="Does it need a secondary field?"
                        labelPlacement="start"
                        name={"secondary_field"}
                      />
                    )}
                  </Grid>
                  {/* <Grid item xs={4}>
                </Grid>
                <Grid item xs={4}>
                </Grid> */}
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <PositiveSwitch
                          checked={mandatoryChecked}
                          onChange={(e) =>
                            setMandatoryChecked(e.target.checked)
                          }
                          name="mandatory"
                          defaultValue={customField.mandatory}
                        />
                      }
                      label="Is this a mandatory field?"
                      labelPlacement="start"
                      name={"mandatory"}
                    />
                  </Grid>
                  {/* <Grid item xs={4}>
                </Grid> */}
                </Grid>
                {(fieldType === "Yes/No" || age === "Yes/No") &&
                  secondaryChecked && (
                    <Grid
                      container
                      spacing={2}
                      style={{ background: "#f5f5f5" }}
                    >
                      <Grid item xs={4}>
                        <MROTextField
                          name={"secondary_field_name"}
                          label={"Field Name"}
                          required
                          onChange={(e) => {
                            setSecFieldName(e.target.value);
                            setErrors(e => ({...e, secondary_field_name:false}))
                          }}
                          InputLabelProps={{ shrink: true }}
                          // helperText={errors.secondary_field_name && "Please Enter the Field Name."}
                          error={errors.secondary_field_name}
                          // defaultValue={customField.field_name}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <MROTextField
                          name={"secondary_help_text"}
                          label={"Help text in box"}
                          required
                          onChange={(e) => {
                            setSecHelp(e.target.value);
                            setErrors(e => ({...e, secondary_help_text:false}))
                          }}
                          InputLabelProps={{ shrink: true }}
                          // helperText={errors.secondary_help_text && "Please Enter the Help text."}
                          error={errors.secondary_help_text}
                          // defaultValue={customField.help_text}
                        />
                      </Grid>
                      <Grid item xs={4}></Grid>
                      <Grid item xs={4}>
                        <MROTextField
                          name={"secondary_field_type"}
                          label={"Field Type"}
                          select
                          value={secFieldType}
                          onChange={(e) => {
                            handleSecChange(e)
                            setErrors(e => ({...e, secondary_field_type: false}))
                          }}
                          InputLabelProps={{ shrink: true }}
                          // helperText={errors.secondary_field_type && "Please Enter the secondary field type."}
                          error={errors.secondary_field_type}
                          // defaultValue={customField.field_type}
                          
                        >
                          <MenuItem value={"Yes/No"}>Yes/No</MenuItem>
                          <MenuItem value={"Numeric"}>Numeric</MenuItem>
                          <MenuItem value={"Freetext"}>Free text</MenuItem>
                          <MenuItem value={"Dropdown"}>Dropdown</MenuItem>
                        </MROTextField>
                      </Grid>
                      {/* <Grid item xs={4}></Grid> */}
                      {/* <Grid item xs={4}></Grid> */}
                      {secFieldType === "Freetext" && (
                        <Grid item xs={4}>
                          <MROTextField
                            type="number"
                            name={"secondary_max_length"}
                            label={"Field Max Length"}
                            required
                            onChange={(e) => {
                              setSecMaxLength(e.target.value);
                              setErrors(e => ({...e, secondary_max_length: false}))
                            }}
                            // helperText={errors.secondary_max_length && "Please Enter the Field Max Length."}
                            error={errors.secondary_max_length}
                            InputLabelProps={{ shrink: true }}
                            // defaultValue={customField.max_length}
                          />
                        </Grid>
                      )}
                      {secFieldType === "Numeric" && (
                        <Grid item xs={4}>
                          <MROTextField
                            type="number"
                            name={"secondary_decimal_places"}
                            label={"Decimal Places"}
                            required
                            onChange={(e) => {
                              setSecDecimalPlace(e.target.value);
                              setErrors(e => ({...e, secondary_decimal_places: false}))
                            }}
                            // helperText={errors.secondary_decimal_places && "Please Enter the Decimal Places."}
                            error={errors.secondary_decimal_places}
                            InputLabelProps={{ shrink: true }}
                            // defaultValue={customField.decimal_places}
                          />
                        </Grid>
                      )}

                      {secFieldType === "Dropdown" || age === "Dropdown" ? (
                        <Grid item xs={4}>
                          <MROTextField
                            name={"dropdown_values"}
                            label={"Enter dropdown options"}
                            onChange={(e) =>{
                              setSecDropDownValues(e.target.value)
                              setErrors(e => ({...e, dropdown_values: false}))
                            }}
                            // helperText={errors.dropdown_values && "Please Enter the dropdown values."}
                            error={errors.dropdown_values}
                            required
                            rows={4}
                            multiline
                            InputLabelProps={{ shrink: true }}
                            // defaultValue={customField.dropdown_values}
                          />
                        </Grid>
                      ) : (
                        ""
                      )}
                      {/* <Grid item xs={8}>
                        {(secFieldType === "Yes/No" ) && (
                          <FormControlLabel
                            control={
                              <PositiveSwitch
                                checked={secondaryChecked}
                                onChange={(e) =>
                                  setSecondaryChecked(e.target.checked)
                                }
                                name="secondary_field"
                                defaultValue={customField.secondary_field}
                              />
                            }
                            label="Does it need a secondary field?"
                            labelPlacement="start"
                            name={"secondary_field"}
                          />
                        )}
                      </Grid> */}
                      {/* <Grid item xs={4}></Grid> */}
                      {/* <Grid item xs={4}>
                        </Grid> */}
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <PositiveSwitch
                              checked={SecondaryMandatory}
                              onChange={(e) =>
                                setSecondaryMandatory(e.target.checked)
                              }
                              name="mandatory"
                              defaultValue={customField.mandatory}
                            />
                          }
                          label="Is this a mandatory field?"
                          labelPlacement="start"
                          name={"mandatory"}
                        />
                      </Grid>
                      <Grid item xs={4}></Grid>
                    </Grid>
                  )}
                <div style={{ textAlign: "right" }}>
                  <MROButton
                    variant={"contained"}
                    color="tertiary"
                    onClick={handleEditClose}
                  >
                    Cancel
                  </MROButton>
                  &nbsp;&nbsp;
                  <MROButton
                    variant={"contained"}
                    color="primary"
                    type="submit"
                    // onClick={handleSubmit}
                  >
                    {" "}
                    Save
                  </MROButton>
                </div>
                </form>
              </React.Fragment>
            ) : (
              <div className={"center"} style={{ minHeight: "200px" }}>
                <CircularProgress color="primary" />
              </div>
            )}
          </DialogContent>
        </Dialog>
      </div>
    </React.Fragment>
  );
}
