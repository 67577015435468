import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import MROButton from "../../components/buttons";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";

const useStyles = makeStyles(theme => ({
    formWrapper: {
        background: '#fff',
        width: '400px',
        padding: theme.spacing(3)
    },
    title: {
        textTransform: 'capitalize',
        paddingBottom: theme.spacing(2)
    },
    actionButtons: {
        paddingTop: '20px',
        textAlign: 'right',
        '& button': {
            marginRight: '20px',
            textTransform: 'capitalize',
        }
    },
}));

function ManageDataDialog(props) {
    const classes = useStyles();
    const { handleClose, handleSubmit, children, loading, title, type, maxWidth, files } = props;

    const handleFormSubmit = (e) => {
        e.preventDefault();

        if (files) {
            const formData = new FormData(e.target);
            if (Array.isArray(files)) {
                for (let i = 0; i < files.length - 1; i++) {
                    formData.append('documents[]', files[i][0])
                }
                return handleSubmit(formData);
            }
            formData.append('documents[]', files)
            return handleSubmit(formData);
        }
        if (type === 'update') {
            const formData = new FormData(e.target);
            return handleSubmit(formData)
        }
    }

    return (
        <Dialog
            maxWidth={maxWidth || "sm"}
            open={true}
            onClose={handleClose}
            aria-labelledby="create-form"
        >
            <DialogContent>
                {type !== 'delete' ? <div className={classes.formWrapper} style={maxWidth ? { width: 'auto' } : {}}>
                    <Typography variant="subtitle2" color="secondary" className={classes.title}>
                        {title || "Add Data"}
                    </Typography>
                    <form onSubmit={handleFormSubmit}>
                        {children}
                        <div className={classes.actionButtons}>
                            <MROButton disabled={loading} type="button" variant={"contained"}
                                onClick={() => handleClose()}>Cancel</MROButton>
                            {
                                type !== 'view' &&
                                <MROButton disabled={loading} type="submit" variant={"contained"} color="primary">{type}</MROButton>
                            }
                        </div>
                    </form>
                </div> :
                    <div className={classes.formWrapper}>
                        {children}
                        <div className={classes.actionButtons}>
                            <MROButton disabled={loading} type="button" variant={"contained"} onClick={handleClose}>Cancel</MROButton>
                            <MROButton disabled={loading} type="button" variant={"contained"} color="primary" onClick={handleSubmit}>Yes, Confirm</MROButton>
                        </div>
                    </div>
                }
            </DialogContent>
        </Dialog>
    )
}

export default ManageDataDialog;