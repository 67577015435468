import React, { useState, useEffect, useContext } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MRORightDrawer from "../../components/temporary_sidebar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import DataClient from "../../lib/services/api/get_data_service";
import PositiveSwitch from "../../components/form_components/switch";
import { ToastMessageContext } from "../../lib/contexts/message_context";
import { flattenObjectToArray } from "../../lib/common_utils"
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(theme => ({
    list: {
        paddingLeft: 0,
        '& .MuiListItem-gutters': {
            paddingLeft: 0
        },
        '& .MuiListItemIcon-root': {
            minWidth: '35px',
            cursor: 'pointer'
        }
    },
    nestedList: {
        background: '#f5f5f5',
        padding: theme.spacing(1.5)
    }
}));
let updateOccured = false;
function GroupPermissions(props) {
    const classes = useStyles();
    const { data } = props;
    const [openSubCategory, setOpenSubcategory] = useState(null);
    const [permissions, setPermissions] = useState({});
    const [loading, setLoading] = useState(false);
    const message = useContext(ToastMessageContext);
    const toggleDrawer = () => {
        props.handleClose(updateOccured);
    };
    const reportDetails = () => {

        DataClient.getData(`/report_groups/${data.id}`).then((res) => {
            if (res.report_group_permissions) {
                setPermissions(res.report_group_permissions)
            }
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        })
    }
    useEffect(() => {
        setLoading(true);
        reportDetails()
    }, []);


    const handleAccessChangeAll = (category) => ({ target }) => {

        DataClient.updatePermissions({
            permissions: category?.sub_categories?.map(sub => ({
                category_id: sub.id,
                category_name: sub.name,
                status: target.checked
            })), group_name: data.group_name
        }, data.id).then((res) => {
            if (res.success) {
                updateOccured = true;
                reportDetails();
                message.showToastMessage({ message: "Updated Status of group permissions", variant: "success" })
            }
        }).catch(() => {
            message.showToastMessage({ message: "Something went wrong. Try again", variant: "error" })
        })
    }

    const handleAccessChange = (category, checked, rootCategory) => {
        let permissionValue = [];
        let updateCategory = {
            category_id: category.id,
            category_name: category.name,
            status: checked
        };
        permissionValue.push(updateCategory);
        if (category.sub_categories && category.sub_categories.length > 0 && !checked) {
            category.sub_categories.forEach(sub => permissionValue.push({ ...sub, status: false }))
        }
        // if (rootCategory) {
        //     let updatedRootCategory = {};
        //     let currentCategories = rootCategory.sub_categories.filter(cat => cat.category_id !== category.category_id)
        //     if (currentCategories && currentCategories.length > 0) {
        //         let getStatuses = flattenObjectToArray(currentCategories, "status");
        //         if (getStatuses.indexOf(true) !== -1 || checked) {
        //             updatedRootCategory = {
        //                 category_id: rootCategory.category_id,
        //                 category_name: 'Work Order',
        //                 status: true
        //             }
        //         }
        //         else {
        //             updatedRootCategory = {
        //                 category_id: rootCategory.category_id,
        //                 category_name: 'Work Order',
        //                 status: false
        //             }
        //         }
        //     }
        //     else {
        //         updatedRootCategory = {
        //             category_id: rootCategory.category_id,
        //             category_name: 'Work Order',
        //             status: checked
        //         }
        //     }

        //     permissionValue.push(updatedRootCategory);
        // }
        DataClient.updatePermissions({ permissions: permissionValue, group_name: data.group_name }, data.id).then((res) => {
            if (res.success) {
                updateOccured = true;
                reportDetails();
                message.showToastMessage({ message: "Updated Status of group permissions", variant: "success" })
            }
        }).catch(() => {
            message.showToastMessage({ message: "Something went wrong. Try again", variant: "error" })
        })

    }

    const checkAllSubCategoriesStatus = (sub_categories) => {
        return sub_categories.some(sub => sub.status === true)
    }

    return (
        <MRORightDrawer onClose={toggleDrawer}>
            <div className={"align-left-space-between"}>
                <div>
                    <Typography variant="subtitle2" gutterBottom>Email Edit</Typography>
                    <Typography color="secondary" variant="subtitle2" gutterBottom>{data.group_name}</Typography>
                </div>
                <IconButton onClick={toggleDrawer}>
                    <CloseIcon fontSize={"small"} />
                </IconButton>
            </div>
            <div>
                <List className={classes.list}>
                    <ListItem>
                        <ListItemText><div className={"align-left-space-between"}><Typography variant="subtitle2" gutterBottom>Module Name</Typography><Typography variant="subtitle2" gutterBottom>Yes/No</Typography></div></ListItemText>
                    </ListItem>
                    {(!loading && permissions) ? Object.values(permissions).map((category, i) => {
                        return (
                            <React.Fragment key={i}>
                                <ListItem>
                                    <ListItemIcon style={category.sub_categories && category.sub_categories.length > 0 ? {} : { visibility: 'hidden' }} onClick={() => setOpenSubcategory(openSubCategory !== i ? i : null)}>
                                        {openSubCategory === i ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemIcon>
                                    <ListItemText primary={category.name} />
                                    <PositiveSwitch
                                        edge="end"
                                        checked={checkAllSubCategoriesStatus(category.sub_categories)}
                                        inputProps={{ 'aria-labelledby': 'on-or-off' }}
                                        onChange={handleAccessChangeAll(category)}
                                    />
                                </ListItem>
                                <Divider />
                                <Collapse in={openSubCategory === i} timeout="auto">
                                    <List component="div" className={classes.nestedList}>
                                        {category && category.sub_categories && category.sub_categories.map((sub, i) => <ListItem key={i}>
                                            <ListItemText primary={sub.name} />
                                            <ListItemSecondaryAction>
                                                <PositiveSwitch
                                                    edge="end"
                                                    checked={sub.status}
                                                    inputProps={{ 'aria-labelledby': 'on-or-off' }}
                                                    onChange={(e) => handleAccessChange(sub, e.target.checked, category)}
                                                />
                                            </ListItemSecondaryAction>
                                        </ListItem>)}
                                    </List>
                                </Collapse>
                            </React.Fragment>
                        )
                    }) : <div className={"center"} style={{ minHeight: '200px' }}>
                            <CircularProgress color="primary" />
                        </div>}
                </List>
            </div>

        </MRORightDrawer>
    )
}

export default GroupPermissions;