import React, { useState,useEffect,useContext } from 'react';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import MROTextField from "../../components/form_components/TextField";
import MROButton from "../../components/buttons";
import Typography from "@material-ui/core/Typography";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import {ToastMessageContext} from "../../lib/contexts/message_context";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ChipInput from 'material-ui-chip-input';
import PositiveSwitch from "../../components/form_components/switch";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import LineReportClient from "../../lib/services/api/line_report";

const useStyles = makeStyles(theme => ({
    margin: {
      margin: theme.spacing(1),
    }, 
     formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
  }));
  const theme = createMuiTheme({
    palette: {
      primary: red,
    },
  });
export default function EditField(props) {
  const classes = useStyles();
  const [open, setOpen] = [props.open,props.onClose]; 
  const message = useContext(ToastMessageContext);
   const [age, setAge] = React.useState('');   
    const [error,setError] = useState({});   
    const [view,setView] = useState(false);
    const [customField,setCustomField] = useState({});
    
    const [mandatoryChecked,setMandatoryChecked] = useState(false);
    const [secondaryChecked,setSecondaryChecked] = useState(false);
    const [loading,setLoading] = useState(false);
    const [secFieldTyp, setSecFieldTyp] = useState('');
    const [secName, setSecName] = useState('');
    const [secHelp, setSecHelp] = useState('');
    const [secMaxLength, setSecMaxLength] = useState('');
    const [secondaryInChecked, setSecondaryInChecked] = useState('');
    const [secDecimal, setSecDecimal] = useState('');
    const [secDropDownValues, setSecDropDownValues] = useState('');
    const [secMandatoryChecked, setSecMandatoryChecked] = useState('');
    const [dropDownValues,setDropDownValues] = useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const handleSecFieldType = (event) => {
    setSecFieldTyp(event.target.value);
  };
  const handleClose = () => {
      setOpen(false);
    };
  //   const handleChangeDropDown = (e)=>{
  //     console.log('e.target.value', e.target.value)
  //     const val = e.target.value;
  //     setCurrentDropDown(val.split(','));
    
  // }
  const handleSubmit =(e) =>{
      e.preventDefault();
      let formData = new FormData(e.target);
      formData.append('field_id',props.fieldId);
      formData.append('section_id', props.selectedIndex)
      formData.append('secondary_field',secondaryChecked)
      formData.append('mandatory',mandatoryChecked)
      let spl = JSON.stringify(dropDownValues);
      let temp =spl.split(',');
      let final=[...temp]
      // formData.append('values',final)
      const secondary_fields = {
        field_name:secName,
        field_type:secFieldTyp,     
        help_text:secHelp,
        max_length:secMaxLength,
        decimal_places:secDecimal,
        mandatory:secMandatoryChecked,
        secondary_field:secondaryInChecked
    }
    var object = {};
    formData.forEach((value, key) => {object[key] = value});
    var json = JSON.stringify(object);
    console.log('json', json);
    secondaryChecked &&   formData.append('secondary_fields',json)
      LineReportClient.edit(formData).then((res)=>{
        if(res.success){
        message.showToastMessage({open:true,message:"Field successfully updated",variant:"success"});
        handleClose()
        props.listFields()
        // props.setLoading(true);
        // setTimeout(()=>{
        //   props.setLoading(false);
        //   handleClose();
        // },300);
        props.sectionList()
      }
else {
  message.showToastMessage({open:true,message:res.errors?res.errors.join(','):"Something went wrong. Try Again",variant:"error"});
  setLoading(false);
}
}).catch(()=>{
message.showToastMessage({open:true,message:"Something went wrong. Try Again",variant:"error"});
setLoading(false);
})
   }
useEffect(()=>{
  if(!props.fieldId) return
  customInfo()
},[props]);
const customInfo = () => {
  setLoading(true);  
  if(props.selectedIndex !== null){ 
  LineReportClient.fetchCustomFieldInfo(props.fieldId).then((res)=>{
    if(res.custom_field){
      const customField = res.custom_field;
      setCustomField(customField)
      setAge(customField.secondary_field)
      setView(true)
    }
      setLoading(false);
      setView(false)
  }).catch(()=>{
      setLoading(false);
      setView(false)
  })
}
}

useEffect(() => {
  setAge(customField.field_type?.replace(' ', ''))
}, [customField])

return (
   <React.Fragment>   
    <div> <Dialog 
      open={open}
    //   fullWidth={true}
       onClose={handleClose} 
       aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Edit Field</DialogTitle>
        <DialogContent>
       {!loading  ? <React.Fragment>
          <form onSubmit={handleSubmit}>
                            <Grid container spacing={5} alignItems={"center"} justify={"flex-start"}>
                    <Grid item xs={4}>
                     <MROTextField
                            name={"field_name"}
                            label={"Field Name"}
                            required
                            // onChange={(e) => {setName(e.target.value)}}
                            InputLabelProps={{ shrink: true }}
                            defaultValue ={customField.field_name}
                        />   
                         </Grid>
                        <Grid item xs={4}>
                        <MROTextField
                            name={"help_text"}
                            label={"Help text in box"}
                            required
                            // onChange={(e) => {setHelp(e.target.value)}}
                            InputLabelProps={{ shrink: true }}
                            defaultValue ={customField.help_text}
                        />   
                        </Grid>
                        <Grid item xs={4}>
                        </Grid>
                        <Grid item xs={4}>
                           <MROTextField
                            name={"field_type"}
                            label={"Field Type"}
                            select
                            autoFocus={error.manager}
                            error={error.manager}
                            helperText={error.manager}
                            value={age}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            // defaultValue ={customField.field_type?.replace(' ', '')}

                        >
                            <MenuItem value={'Yes/No'}>Yes/No</MenuItem>
                            <MenuItem value={'Numeric'}>Numeric</MenuItem>
                            <MenuItem value={'Freetext'}>Free text</MenuItem>
                            <MenuItem value={'Dropdown'}>Dropdown</MenuItem>
                        </MROTextField>
                         </Grid>
                        {/* <Grid item xs={4}>  
                        </Grid> */}
                        {/* <Grid item xs={4}>
                        </Grid> */}
                        {/* {   ( customField.field_type=== 'Freetext' ||   age === 'Freetext' ) &&  <Grid item xs={4}>
                     <MROTextField
                            name={"max_length"}
                            label={"Field Max Length"}
                            required
                            onChange={(e) => {setMaxLength(e.target.value)}}
                            InputLabelProps={{ shrink: true }}
                            defaultValue ={customField.max_length }
                        />   
                         </Grid>} */}
                        {  (  age === 'Freetext')  &&  <Grid item xs={4}>
                     <MROTextField
                            type="number"
                            name={"max_length"}
                            label={"Field Max Length"}
                            required
                            InputLabelProps={{ shrink: true }}
                            defaultValue ={customField.max_length }
                        />   
                         </Grid>}
                         {    (     age === 'Numeric')  &&  <Grid item xs={4}>
                        <MROTextField
                        type="number"
                            name={"decimal_places"}
                            label={"Decimal Places"}
                            required
                            InputLabelProps={{ shrink: true }}
                            defaultValue ={customField.decimal_places }
                        />   
                        </Grid>}
                      
                       {(   age === 'Dropdown') ?  <Grid item xs={6}>
                       <MROTextField
                            name={"dropdown_values"}
                            label={"Enter dropdown options"}
                            onChange={(e) => setDropDownValues(e.target.value)}
                            required
                            rows={4}
                            multiline
                            InputLabelProps={{ shrink: true }}
                            defaultValue ={customField.dropdown_values }
                        />   
                            <FormHelperText>Please enter the options as comma separated</FormHelperText>  
                        </Grid> :''}
                        <Grid item xs={8}>
                        {(   age === 'Yes/No') &&<FormControlLabel
                                    control={
                                        <PositiveSwitch
                                            checked={secondaryChecked}
                                            onChange={(e)=>setSecondaryChecked(e.target.checked)}
                                            name="secondary_field"
                                            defaultValue ={customField.secondary_field }
                                        />
                                    }
                                    label="Does it need a secondary field?"
                                    labelPlacement="start"
                                    name={"secondary_field"}
                                />}
                                </Grid>
                        <Grid item xs={4}>
                        </Grid>
                        {/* <Grid item xs={4}>
                        </Grid> */}
                        <Grid item xs={8}>
                        <FormControlLabel
                                    control={
                                        <PositiveSwitch
                                            checked={mandatoryChecked}
                                            onChange={(e)=>setMandatoryChecked(e.target.checked)}
                                            name="mandatory"
                                            defaultValue ={customField.mandatory }
                                        />
                                    }
                                    label="Is this a mandatory field?"
                                    labelPlacement="start"
                                    name={"mandatory"}
                                />
                                </Grid>
                                <Grid item xs={4}>
                        </Grid>
                        </Grid> 
                      { (  age === 'Yes/No') && secondaryChecked && 
                       <Grid container spacing={2} style={{background:'#f5f5f5'}} >
                    <Grid item xs={4}>
                     <MROTextField
                            name={"field_name"}
                            label={"Field Name"}
                            required
                            onChange={(e) => {setSecName(e.target.value)}}
                            InputLabelProps={{ shrink: true }}
                            // defaultValue ={customField.field_name }
                        />   
                         </Grid>
                        <Grid item xs={4}>
                        <MROTextField
                            name={"help_text"}
                            label={"Help text in box"}
                            required
                            onChange={(e) => {setSecHelp(e.target.value)}}
                            InputLabelProps={{ shrink: true }}
                            // defaultValue ={customField.help_text }
                        />   
                        </Grid>
                        <Grid item xs={4}>
                        </Grid>
                        <Grid item xs={4}>
                        <MROTextField
                            name={"field_type"}
                            label={"Field Type"}
                            select
                            autoFocus={error.manager}
                            error={error.manager}
                            helperText={error.manager}
                            onChange={handleSecFieldType}
                            InputLabelProps={{ shrink: true }}
                            value={secFieldTyp}
                            // defaultValue ={customField.field_type }
                        >
                            <MenuItem value={'Yes/No'}>Yes/No</MenuItem>
                            <MenuItem value={'Numeric'}>Numeric</MenuItem>
                            <MenuItem value={'Freetext'}>Free text</MenuItem>
                            <MenuItem value={'Dropdown'}>Dropdown</MenuItem>
                        </MROTextField>
                         </Grid>
                        {/* <Grid item xs={4}>  
                        </Grid>
                        <Grid item xs={4}>
                        </Grid> */}
                        {     (   secFieldTyp === 'Freetext')  &&  <Grid item xs={4}>
                     <MROTextField
                     type="number"
                            name={"max_length"}
                            label={"Field Max Length"}
                            required
                            onChange={(e) => {setSecMaxLength(e.target.value)}}
                            InputLabelProps={{ shrink: true }}
                            // defaultValue ={customField.max_length }
                        />   
                         </Grid>}
                         {    (    secFieldTyp === 'Numeric')  &&  <Grid item xs={4}>
                        <MROTextField
                        type="number"
                            name={"decimal_places"}
                            label={"Decimal Places"}
                            onChange={(e) => {setSecDecimal(e.target.value)}}
                            required
                            InputLabelProps={{ shrink: true }}
                            // defaultValue ={customField.decimal_places }
                        />   
                        </Grid>}
                      
                       { ( secFieldTyp === 'Dropdown') ?  <Grid item xs={4}>
                       <MROTextField
                       type="number"
                            name={"decimal_places"}
                            label={"Enter dropdown options"}
                            required
                            rows={4}
                            multiline
                            onChange={(e) => setSecDropDownValues(e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            defaultValue ={customField.decimal_places }
                        />   
                              {/* <ChipInput
                              defaultValue={customField.decimal_places }
                              onChange={(chips) => setCurrentDropDown(chips)}
                              label="Enter dropdown options"
                              name={"secondary_decimal_places"}
                              fullWidth
                            /> */}
                            <FormHelperText>Please enter the options as comma separated</FormHelperText>  
                        </Grid> :''}
                        {/* <Grid item xs={8}>
                        {(  secFieldTyp === 'Yes/No') &&<FormControlLabel
                                    control={
                                        <PositiveSwitch
                                            checked={secondaryInChecked}
                                            onChange={(e)=>setSecondaryInChecked(e.target.checked)}
                                            name="secondary_field"
                                            defaultValue ={customField.secondary_field }
                                        />
                                    }
                                    label="Does it need a secondary field?"
                                    labelPlacement="start"
                                    name={"secondary_field"}
                                />}
                                </Grid> */}
                        <Grid item xs={4}>
                        </Grid>
                        {/* <Grid item xs={4}>
                        </Grid> */}
                        <Grid item xs={8}>
                        <FormControlLabel
                                    control={
                                        <PositiveSwitch
                                            checked={secMandatoryChecked}
                                            onChange={(e)=>setSecMandatoryChecked(e.target.checked)}
                                            name="mandatory"
                                            defaultValue ={customField.mandatory }
                                        />
                                    }
                                    label="Is this a mandatory field?"
                                    labelPlacement="start"
                                    name={"mandatory"}
                                />
                                </Grid>
                                <Grid item xs={4}>
                        </Grid>

                        </Grid> }
                           <div style={{textAlign: 'right'}}>
                        <MROButton variant={"contained"} color="tertiary" onClick={handleClose}>Cancel</MROButton>&nbsp;&nbsp;
                        <MROButton variant={"contained"} color="primary" type="submit">  Save</MROButton>
                    </div>
                        </form>
     </React.Fragment>:<div className={"center"} style={{minHeight:'200px'}}>
                        <CircularProgress color="primary"/>
                    </div>}
        </DialogContent>
      </Dialog> 
    </div>
   </React.Fragment>
  );
}