import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Typography from "@material-ui/core/Typography/Typography";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import LockIcon from "@material-ui/icons/LockOutlined";
import useAuthStyles from "./auth_styles";

import AuthClient from "../../lib/services/api/auth_service"
import {getParams} from "../../lib/common_utils";
export default function ResetPassword(props) {
    const classes = useAuthStyles();
    const [password, handlePasswordChange] = useState();
    const [passwordConfirmation, handleConfirmPassword] = useState();
    const [message, setMessage] = useState(null);
    const [loading,setLoading] = useState(false);
    const [error, setError] = useState();
    let headers = getParams(props.location.search);

    useEffect(()=>{
        if(!headers || !headers['access-token']){
            props.history.push('/auth/forgot-password');
        }
    },[]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (password !== passwordConfirmation) {
            setError(true);
        } else {
            setLoading(true);
            const formData = {"password": password, "password_confirmation": passwordConfirmation};
            AuthClient.changePassword(formData, headers).then((res) => {
                setMessage(res);
                handleSuccess();
            }).catch(() => {
                setLoading(false);
                setMessage({message: "Something went wrong. Please try again!", success: false});
            })
        }
    };
    const handleSuccess = () => {
        setTimeout(() => {
            props.history.replace('/auth/signin');
        }, 5000);
    };

    return (
        <div className={classes.wrapper}>
            <div className={classes.overlay}></div>
            <div style={{zIndex: '99'}}>
                {!!!message ? <form className={classes.form} onSubmit={handleSubmit} autoComplete="custom-form">
                    <Typography component={"h6"} variant={"h6"}>Change Password</Typography>
                    <div>Choose new strong password </div>
                    <FormControl fullWidth className={classes.formField}>
                        <OutlinedInput
                            required
                            name="password"
                            type="password"
                            autoComplete="new-password"
                            placeholder="Password"
                            autoFocus
                            onChange={(e) => {handlePasswordChange(e.target.value)}}
                            onFocus={()=>setError(false)}
                            startAdornment={<InputAdornment position="start">
                                <LockIcon htmlColor={"#ffffff80"}/>
                            </InputAdornment>
                            }
                        />
                    </FormControl>
                    <FormControl fullWidth className={classes.formField}>
                        <OutlinedInput
                            required
                            name="password_confirmation"
                            autoComplete="new-password"
                            placeholder="Password confirmation"
                            type="password"
                            id="password-confirmation"
                            error={error}
                            autoFocus
                            helperText={error?"Passwords do not match":""}
                            onChange={(e) => {handleConfirmPassword(e.target.value)}}
                            onFocus={()=>setError(false)}
                            startAdornment={<InputAdornment position="start">
                                <LockIcon htmlColor={"#ffffff80"}/>
                            </InputAdornment>
                            }
                        />
                    </FormControl>
                    <div style={{margin: '20px 0'}}>
                        <Button
                            type="submit"
                            className={classes.submit}
                            disabled={loading}
                            fullWidth
                        >
                            Submit
                        </Button>
                    </div>

                </form> : <div className={`${classes.form} ${message.success ? classes.success : classes.error}`}>
                    <Typography variant="subtitle1" gutterBottom
                                className={classes.message}>
                        {message.message}
                    </Typography>
                    {!message.success && <Button
                        fullWidth
                        className={classes.submit}
                        onClick={() => setMessage(null)}
                    >
                        Retry
                    </Button>}
                </div>}
            </div>
            <div className={classes.brandLogo}>
                <img src={'/mro-pro-logo.png'} alt={"mro pro logo"}/>
            </div>
        </div>
    );
}
