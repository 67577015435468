import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/AddBox';
import IconButton from '@material-ui/core/IconButton';
import DelayReport from "../Delay_report";
import WorkOrder from "../Work_order";
import CallOut from "../Call_out";
import Grid from "@material-ui/core/Grid";
import VechicleReport from "./vechicle_report";
import StationChecks from "./station_checks";
import StationCheckQuestion from "./station_check_question"
import { ToastMessageContext } from "../../lib/contexts/message_context";
import StationService from '../../lib/services/api/station_service';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 690,
    backgroundColor: theme.palette.background.paper,
    margin: '3%',
  },
}));

export default function GlobalReports() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [delayReport, setUpdateDelayReport] = useState(false);
  const [workOrder, setWorkOrder] = useState(false);
  const [updateAction, setUpdateAction] = useState('');
  const [callOutModal, setCallOutModal] = useState('');
  const [addAction, setAddAction] = useState('');
  const [addQuesAction, setAddQuestionAction] = useState('');
  const message = useContext(ToastMessageContext);
  const [listVehicle, setListing] = useState({});
  const [currentSelection, setCurrentSelection] = useState({});

  useEffect(() => {
    setLoading(true);
    StationList()
  }, []);
  const StationList = () => {
    StationService.getStationList().then((res) => {
      if (res.data) {
        const listVehicle = res.data;
        setListing(listVehicle)
      }
      setLoading(false);
    }).catch(() => {
      setLoading(false);
      message.showToastMessage({ open: true, message: "Something went wrong. Try Again!", variant: "error" })
    })
  }
  return (
    <List component="nav" className={classes.root} aria-label="mailbox folders">
      <ListItem >
        {/* <ListItemText primary="Line Report Editor"  /> */}
        {/* <MROButton className={classes.addNewButton} component={"span"} startIcon={<EditIcon/>} color="secondary" variant="contained"
                                               onClick={()=>{setUpdateAction('updateLine')}}>
                            Work Order</MROButton> &nbsp;  <MROButton className={classes.addNewButton} component={"span"} startIcon={<EditIcon/>} color="secondary" variant="contained"
                                               onClick={()=>{setShowModal(true)}}>
                            Call Out</MROButton> &nbsp;  <MROButton className={classes.addNewButton} component={"span"} startIcon={<EditIcon/>} color="secondary" variant="contained"
                                               onClick={()=>{setDelayReport(true)}}>
                            Delay Report</MROButton>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
        <Grid container spacing={2} >
          <Grid item xs={3}>
            <ListItemText primary="Line Report Editor" />
          </Grid>
          <Grid item xs={2.5}>
            <Button fullWidth component={"span"} startIcon={<EditIcon />} color="secondary" variant="contained" onClick={() => { setUpdateAction('updateLine') }}>Work Order  &nbsp;&nbsp;</Button>
          </Grid>
          <Grid item xs={2.5}>
            <Button fullWidth component={"span"} startIcon={<EditIcon />} color="secondary" variant="contained" onClick={() => { setCallOutModal('updateCallout') }}>Call Out   &nbsp;&nbsp;&nbsp;</Button>
          </Grid>
          <Grid item xs={2.5}>
            <Button fullWidth component={"span"} startIcon={<EditIcon />} color="secondary" variant="contained" onClick={() => { setUpdateDelayReport('updateDelayReport') }}>Delay Report</Button>
          </Grid>
        </Grid>
      </ListItem>
      <Divider />
      <ListItem >
        <Grid container spacing={2} >
          <Grid item xs={3}>
            <ListItemText primary="Vehicle Report Editor" />
          </Grid>
          <Grid item xs={5}>
          </Grid>
          <Grid item xs={2.5}>
            <Button style={{ marginLeft: '-21px' }} component={"span"} startIcon={<EditIcon />} color="secondary" variant="contained" onClick={() => { setShowModal('vechicle') }}>Edit Template</Button>
          </Grid>
        </Grid>
      </ListItem>
      <Divider />
      <ListItem >
        <Grid container spacing={1} >
          <Grid item xs={3}>
            <ListItemText primary="Station Checks Editor" />
          </Grid>
          {listVehicle[0] && !loading ? listVehicle.map(row => (
            <Grid item xs={2.5}>
              <Button fullWidth component={"span"} startIcon={<EditIcon />} color="secondary" variant="contained" onClick={() => { setAddQuestionAction('stationQues'); setCurrentSelection(row) }}>{row.title}  &nbsp;&nbsp;</Button>
            </Grid>
          )) : ''}
          <Grid item xs={0.5}>
            <IconButton style={{ marginLeft: '7px' }} color="secondary" onClick={() => { setAddAction('addAction') }}  >
              <AddIcon />
            </IconButton>
          </Grid>
        </Grid>
      </ListItem>
      {updateAction === 'updateLine' && <WorkOrder handleClose={() => setUpdateAction(null)} onClose={setWorkOrder} />}
      {callOutModal === 'updateCallout' && <CallOut handleClose={() => setCallOutModal(null)} onClose={setWorkOrder} />}
      {delayReport === 'updateDelayReport' && <DelayReport handleClose={() => setUpdateDelayReport(null)} onClose={setWorkOrder} />}
      {showModal === 'vechicle' && <VechicleReport handleClose={() => setShowModal(null)} onClose={setWorkOrder} />}
      {addAction === 'addAction' && <StationChecks data={currentSelection} StationList={StationList} setLoading={setLoading} handleClose={() => setAddAction(null)} onClose={setWorkOrder} />}
      {addQuesAction === 'stationQues' && <StationCheckQuestion data={currentSelection} StationList={StationList} setLoading={setLoading} handleClose={() => setAddQuestionAction(null)} onClose={setWorkOrder} />}
    </List>
  );
}
