import React, { useState, useEffect } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { ICON_COLOUR } from "../lib/constants/style_constants";
import Divider from "@material-ui/core/Divider";
import { ClearAll, Clear } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    wrapper: {
        backgroundColor: "#fff",
        padding: theme.spacing(1, 2),
        border: "1px solid #d5d5d5",
        margin: "1% 0",
    },
    divider: {
        margin: '8px 0'
    },
    filters: {
        padding: "10px 24px",
        '& .filter': {
            paddingRight: theme.spacing(3),
        }
    }
}));

function SearchBarWithFilters(props) {
    const classes = useStyles();
    const { handleSearch, searchText } = props;

    const clearSearch = () => {
        handleSearch('')
    }

    return (
        <div className={classes.wrapper}>
            <InputBase
                fullWidth
                placeholder={"Search anything"}
                onChange={(e) => handleSearch(e.target.value)}
                value={searchText}
                startAdornment={<InputAdornment position="start"><SearchIcon htmlColor={ICON_COLOUR} /></InputAdornment>}
                endAdornment={!!searchText?.length ? <IconButton title="Clear Search" onClick={clearSearch} size="medium">
                    <Clear fontSize="small" htmlColor={ICON_COLOUR} />
                </IconButton> : null}
            />
            <Divider variant="fullWidth" className={classes.divider} />
            <div className={classes.filters}>
                <Typography variant="subtitle2" color="secondary" gutterBottom>Filters</Typography>
                {props.children}
            </div>
        </div>
    )
}

export default SearchBarWithFilters;