import { Typography } from '@material-ui/core'
import React, { useState, useMemo } from 'react'
import ReactApexChart from 'react-apexcharts'



export default function PieChart(props) {

    const { data } = props

    const values = []
    const labels = []
    const colors = []

    useMemo(() => {
        if (data.length > 0 && data != [] || undefined) {

            for (let i = 0;i < data.length;i++) {
                values.push(data[i].message_count)
                labels.push(data[i].mro_id)
                colors.push(data[i].primary_color_code)
            }
        }
    }, [data])




    const [state, setState] = useState({
        series: values,
        options: {
            chart: {
                width: 450,
                type: 'pie',
            },
            labels: labels,
            colors: colors,
            legend: {
                show: false
            },
            dataLabels: {
                formatter(val, opts) {
                    const name = opts.w.globals.labels[opts.seriesIndex]
                    return [name, opts.w.config.series[opts.seriesIndex]]
                },
            },
            noData: {
                text: 'No Data Available'
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        show: false
                    }
                }
            }]
        },





    })
    return (
        <>
            <ReactApexChart options={state.options} series={state.series} type="pie" width={450} />
        </>
    )
}
