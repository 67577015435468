import React, { useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/HomeOutlined';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import ProfileIcon from '@material-ui/icons/PersonOutlined';
import BookingsIcon from '@material-ui/icons/DateRangeOutlined';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import RoomServiceIcon from '@material-ui/icons/RoomService';
import RateReviewIcon from '@material-ui/icons/RateReview';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import PaymentIcon from '@material-ui/icons/Payment';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { ReactComponent as MyClientsIcon } from "../../assets/My clients.svg";
import { ReactComponent as SetupIcon } from "../../assets/Setup.svg";
import { ReactComponent as DashboardIcon } from "../../assets/Dashboard.svg";
import AuthClient from "../../lib/services/api/auth_service";
import AppRoutes from "../Routes/route_map";
import { withRouter } from 'react-router-dom';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Grid } from '@material-ui/core';
import { ReactComponent as MessageIcon } from "../../assets/Messaging.svg";
const drawerWidth = 250;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            maxWidth: '25%',
            flexShrink: 0,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        background: `transparent linear-gradient(204deg, ${theme.palette.primary.main} 0%, #0A2D4D 100%) 0% 0% no-repeat padding-box`,
        color: '#fff',
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            maxWidth: '25%',
            flexShrink: 0,
        },
    },
    content: {
        flexGrow: 1,
        width: '75%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    orgLogo: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        left: '40px',
        top: '25px',
        right: '40px',
        '& img': {
            maxWidth: '200px',
            maxHeight: '75px'
        }
    },
    navList: {
        opacity: '0.70',
        color: '#fff',
        '&:hover': {
            color: '#fff',
            borderLeft: `3px solid ${theme.palette.secondary.main}`,
            opacity: '0.80'
        },
        '&.Mui-selected': {
            color: '#fff',
            borderLeft: `3px solid ${theme.palette.secondary.main}`,
            opacity: '0.95'
        }
    },
    logout: {
        color: '#fff',
        position: 'absolute',
        bottom: 15,
        left: 15,
        display: 'flex',
        alignItems: 'center',
        fontSize: 16,
        cursor: 'pointer'
    }
}));

function MainApplication(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const user = sessionStorage.getItem('role');
    const userRole = user ? user : null;

    useEffect(() => {
        const availableMenu = ["dashboard", "my_clients", "messaging", "setup"];
        for (let i = 0;i < availableMenu.length;i++) {
            const route = availableMenu[i];
            if (props.location.pathname.includes(route)) {
                setSelectedIndex(i);
                if (props.location.pathname.includes("profile")) {
                    setOpen(true);
                }
                return;
            }
        }
    }, [props.location.pathname]);

    function handleLogout() {
        AuthClient.logout().then(() => {
            props.history.push('/auth/signin');
        }).catch(() => {
            sessionStorage.clear();
            props.history.push('/auth/signin');
        })
    }
    const handleClick = () => {
        setOpen(!open);
    };

    const noLayoutRoutes = ["signin", "forgot-password", "reset-password", 'auth'];
    for (let index in noLayoutRoutes) {
        if (noLayoutRoutes.hasOwnProperty(index)) {
            const route = noLayoutRoutes[index];
            if (props.location.pathname.includes(route)) {
                return (<AppRoutes />);
            }
        }
    }

    const drawer = (
        <div>
            <div className={classes.toolbar}>
                <div className={classes.orgLogo}>
                    <img alt="mro-pr0" src={'/mro-pro-logo.png'} />
                </div>
            </div>
            <Divider />
            <List>
                <ListItem selected={selectedIndex === 0} button onClick={(event) => {
                    props.history.push('/dashboard')
                }}>
                    <ListItemIcon> <DashboardIcon htmlColor={'#fff'} /> </ListItemIcon>
                    <ListItemText primary={'Dashboard'} />
                </ListItem>
                <ListItem selected={selectedIndex === 1} button onClick={(event) => {
                    props.history.push('/my_clients')
                }}>
                    <ListItemIcon> <MyClientsIcon htmlColor={'#fff'} /> </ListItemIcon>
                    <ListItemText primary={'My Clients'} />
                </ListItem>
                <ListItem selected={selectedIndex === 2} button onClick={(event) => {
                    props.history.push('/messaging')
                }}>
                    <ListItemIcon> <MessageIcon htmlColor={'#fff'} /> </ListItemIcon>
                    <ListItemText primary={'Messaging'} />
                </ListItem>
                <ListItem selected={selectedIndex === 3} button classes={{ selected: classes.selected }} onClick={(event) => {
                    props.history.push('/setup/global_reports')
                }}>
                    <ListItemIcon> <SetupIcon htmlColor={'#fff'} /> </ListItemIcon>
                    <ListItemText primary={'Setup'} />
                </ListItem>
                <ListItem button classes={{ selected: classes.selected }} onClick={(event) => {
                    handleLogout()
                }}>
                    <ListItemIcon> <ExitToAppIcon htmlColor={'#fff'} /> </ListItemIcon>
                    <ListItemText primary={'Logout'} />
                </ListItem>
            </List>
            <Divider />
        </div>
    );

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Grid container spacing={1}>
                {/* <Grid item xs={3} md={3} lg={3}> */}
                <nav className={classes.drawer} aria-label="mailbox folders">
                    <Hidden smDown implementation="css">
                        <Drawer
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            variant="permanent"
                            open
                        >
                            {drawer}
                        </Drawer>
                    </Hidden>
                </nav>
                {/* </Grid> */}
                {/* <Grid item xs={9} md={9} lg={9}> */}
                <main className={classes.content}>
                    {/* <div className={classes.toolbar}/> */}
                    <AppRoutes />
                </main>
                {/* </Grid> */}
            </Grid>
        </div>
    );
}


export default withRouter(MainApplication);
