import request from "../../shared/api_client";

function getStationList(params) {
    return request({
        url: '/station_checks',
        method: 'GET',
        params:params
    });
}

function createStationList(formData) {
    return request({
        url:`/station_checks`,
        method: 'POST',
        data:formData
    })
}

function updateStationList(formData) {
    return request({
        url:`/station_checks/update_station_check`,
        method: 'PUT',
        data:formData
    })
}

function deleteStationList(id) {
    return request({
        url:`/station_checks/destroy_station_check?id=${id}`,
        method: 'DEL',
    })
}

const StationService = {
    getStationList,createStationList, updateStationList, deleteStationList
};

export default StationService;