import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { requireAuth } from "../Auth/hoc/require_auth";
import Header from "../../components/Universal/header";
import Footer from "../../components/Universal/footer";
import MainApplication from "../Layout";
import ToastMessage from '../../components/Universal/status_messages';


export default function BrowserAppRoutes(props) {

    return (
        <Router>
            {/* <Header/> */}
            <MainApplication />
            <Footer />
            <ToastMessage />
        </Router>
    )
}

