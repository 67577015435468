import { Grid, Divider, makeStyles, Paper, IconButton, Typography, Button, ButtonBase, ListItem, FormControlLabel, InputBase, Box, Menu, MenuItem, CircularProgress, useTheme, alpha } from '@material-ui/core'
import MROButton from '../../../components/buttons'
import React, { useState } from 'react'
import AddIcon from "@material-ui/icons/Add";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import Collapse from '@material-ui/core/Collapse';
import CardContent from '@material-ui/core/CardContent';
import RedEye from '../../../assets/messagng/redEye.svg'
import ArrowIcon from '../../../assets/messagng/sent.svg'
import ViewIcon from "@material-ui/icons/Visibility";
import MessageDetails from './MessageDetails';
import CreateNewMessage from '../Messaging_New/CreateNewMessage'
import ArchiveIcon from '../../../assets/messagng/archive.png'
import { MessageServices } from '../../../lib/services/api/Messaging/messae_services'
import { useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import ReactTimeAgo from 'react-time-ago'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import moment from 'moment';
import ChatDetail from './ChatDetails';
import GroupImg from '../../../assets/messagng/groupimg.png'
import { useMemo } from 'react';
import { ToastMessageContext } from "../../../lib/contexts/message_context";
import { useContext } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import ConversationLoader from './ConversationLoader';



const useStyles = makeStyles((theme) => ({

    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginTop: '-33px',
        marginLeft: '11px',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
        ' &:hover': {
            backgroundColor: "transparent"
        }

    },
    expandOpen: {
        transform: 'rotate(180deg)',
        ' &:hover': {
            backgroundColor: "transparent"
        }
    },
    avatar: {
        width: theme.spacing(6),
        height: theme.spacing(6),
    },
    badge: {
        width: '24px',
        height: '24px',
        borderRadius: '14px',
        // backgroundColor: 'red',
        fontSize: '14px',
        marginTop: '-20px'
    },
    org1: {
        background: alpha(theme.palette.primary.main, 0.20),
        width: '36%',
        textAlign: 'center',
        padding: '15px',
        // borderRadius: '20px 20px 20px 0px',
        fontSize: '15px'

    },
    org2: {
        background: theme.palette.primary.main,
        width: '46%',
        textAlign: 'left',
        padding: '15px',
        // borderRadius: '20px 20px 0px 20px',
        color: '#ffffff',
        fontSize: '15px'

    },
    selectedChat: {
        background: alpha(theme.palette.primary.main, 0.1)
    },
    archiveItem: {
        background: "#70707040"
    },
    sendDiv: {
        backgroundColor: '#F5F6FA',
        padding: '5px',
        marginBottom: '5px',
        marginTop: '5px',
        height: '110px',
        marginRight: '15px',
        // borderRadius: '5px'
    },
    dateTime: {
        // paddingBottom: '10px',
        width: '36%',
        textAlign: 'end',
        color: '#707070',
        fontSize: '13px'
    },
    loggedDateTime: {
        // paddingBottom: '10px',
        textAlign: 'end',
        color: '#707070',
        fontSize: '13px'
    },
    menu: {
        '& .MuiMenu-paper': {
            position: 'fixed',
            top: '20px',
            left: '0px'
        }
    },
    cardContent: {
        backgroundColor: '#DCDDE0', marginTop: '-22px',
        borderRadius: '5px',

        height: '37px', paddingTop: '6px'
    },
    unsend: {
        textDecoration: 'underline', color: '#707070', fontWeight: 600
    },
    send: {
        textDecoration: 'underline', color: '#8CDA5C', fontWeight: 600
    },
    oldmsg: {
        textDecoration: 'underline', color: '#FF5A5A', fontWeight: 600
    },
    timeStamp: {
        fontSize: '13px', paddingRight: '7px', paddingTop: '6px', color: '#43425D'
    },
    buttonState: {
        textAlign: 'center', paddingTop: '8px', paddingBottom: '5px', bottom: '5px'
    }

}));




export default function ListMessaging(props) {
    const { debounceVal, messageRefresh, setMessageRefresh, getTotalUnReadCount } = props
    const classes = useStyles();
    const [expanded, setExpanded] = useState({});
    const [selectedTab, setSelectedTab] = useState()
    const [value, setValue] = useState(0)
    const [action, setAction] = useState('')
    const [anchorEl, setAnchorEl] = useState(false);
    const theme = useTheme()
    const [data, setData] = useState([])
    const [page, setPage] = useState(0)
    const [prevPage, setPrevPage] = useState(0)
    const [pageSize, setPageSizes] = useState(20)
    const [noMoreData, setNoMoreData] = useState(true)
    const [groupCount, setGroupCount] = useState({})
    const [selectedChat, setSelectedChat] = useState('')
    const [selectedId, setSelectedId] = useState('')
    const [archiveState, setArchiveState] = useState(false)
    const [totalCount, setTotalCount] = useState(0)
    let userDetails = JSON.parse(sessionStorage.getItem("user"));
    const [loading, setLoading] = useState('')
    const message = useContext(ToastMessageContext)

    const [groupCountLoading, setGroupCountLoading] = useState(false)

    const handleExpandClick = (id) => {
        // console.log("index===", checked, index, value)

        setExpanded({
            ...expanded,
            [id]: !expanded[id]
        });


    }


    const getMesssagesList = async () => {
        const pages = page + 1

        const params = {
            user_id: userDetails?.user?.id,
            message_type: "message",
            page: pages,
            limit: pageSize,
            archive: archiveState,
            search: debounceVal

        }

        await MessageServices.getListingConversation(params).then((response) => {
            if (response.success) {
                setData(response.data)
                setPrevPage(pages)
                setTotalCount(response.total_count)
                setMessageRefresh(false)


            }
        }).catch(() => {
            message.showToastMessage({
                message: "Something went wrong. Try again!",
                variant: "error",
            });

        }).finally(() => {
            setLoading(null)
        })

    }

    useMemo(() => {
        setPage(0)
        setLoading('busy')
        if (messageRefresh) {
            setSelectedChat('')
            setExpanded(!expanded)
        }
        getMesssagesList()
    }, [page, pageSize, archiveState, debounceVal, messageRefresh])

    const getScrollList = async (e) => {
        const pages = prevPage + 1
        const params = {
            user_id: userDetails?.user?.id,
            message_type: "message",
            page: pages,
            limit: pageSize,
            archive: archiveState,
            search: debounceVal
        }

        await MessageServices.getListingConversation(params).then((response) => {
            if (response.success) {
                setPrevPage(pages)
                setTotalCount(response.total_count)
                if (response.data !== [] && response.data.length !== 0) {

                    setData([...data, ...response.data])

                } else {

                    setNoMoreData(false)
                }

            }
        }).catch(() => {

            message.showToastMessage({
                message: "Something went wrong. Try again!",
                variant: "error",
            });
        }).finally(() => {

        })

    }





    //timeago calculation

    TimeAgo.addDefaultLocale(en)
    const convertToLocal = (dates) => {

        var testDateUtc = moment.utc(dates);
        var localDate = testDateUtc.local()

        return localDate
    }

    const fetchMoreData = async () => {
        await getScrollList()
        // setData((ps) => ([...ps, ...newData]))

    }

    //Grup details 
    const handleGroupMessageDetails = async (id) => {
        setGroupCountLoading(true)
        const params = {
            chat_id: id,
            detail: "count"
        }
        MessageServices.getGroupMessageDetails(params).then((response) => {
            setGroupCount(response.data)
            // : 
            // {sent: 40, read: 0, unread: 40})
        }).catch((err) => {
            message.showToastMessage({
                message: "Something went wrong. Try again!",
                variant: "error",
            })
        }).finally(() => {
            setGroupCountLoading(false)
        })

    }

    useEffect(() => {
        if (totalCount > data.length && !noMoreData) {
            var rect = document.getElementById("scrollableDiv").getBoundingClientRect();
            var bottom = rect.bottom;
            if (bottom <= window.innerHeight) {

                // setNoMoreData(true)
                fetchMoreData()

            }

        }

    }, [totalCount, archiveState])



    return (
        <div  >

            <Grid container direction='column' xs={12}>
                <Grid item xs={4} className={classes.buttonState}>
                    <MROButton color='primary' variant="contained" type='button' style={{ width: '90%' }} onClick={() => {
                        setAction('create')
                        setSelectedChat('')
                    }}>

                        <AddIcon fontSize='small' /> New message
                    </MROButton></Grid>

                {/* listing messages */}


                <Grid container direction='row' xs={12}  >

                    <Grid item xs={4} id="scrollableDiv" style={{
                        height: 'calc(100vh - 175px)',
                        maxHeight: 'calc(100vh - 175px)',
                        overflow: 'scroll',
                        display: 'flex',
                        flexDirection: 'column',
                        scrollBehavior: 'smooth',

                    }}>
                        <InfiniteScroll
                            dataLength={data.length}
                            next={fetchMoreData}
                            style={{
                                display: 'flex', flexDirection: 'column',
                                overflow: 'hidden',
                            }}
                            hasMore={noMoreData}
                            loader={loading === 'busy' ? "" : <Grid style={{ textAlign: 'center' }}><CircularProgress size={20} color='primary' /></Grid>}
                            scrollableTarget="scrollableDiv"
                        >

                            {loading === 'busy' ? <div>
                                <ConversationLoader />
                                <ConversationLoader />
                                <ConversationLoader />
                                <ConversationLoader />
                            </div> : data.map((item, index) => {
                                return (

                                    <Card className={item.chat_id === selectedChat.chat_id ? classes.selectedChat : item.archive === true ? classes.archiveItem : ''} key={index} onClick={() => {
                                        setSelectedChat(item)
                                        setValue(index)
                                        handleGroupMessageDetails(item.chat_id)
                                    }} style={{ cursor: 'pointer', borderLeft: item.chat_id === selectedChat.chat_id ? `5px solid ${theme.palette.primary.main} ` : '', borderBottom: '1px solid #00000029', paddingTop: '20px', }}>
                                        <CardHeader style={{ marginBottom: '0px' }}
                                            avatar={<div key={index} style={{ marginTop: '-10px' }}>
                                                <Avatar aria-label="user" className={classes.avatar} src={item.chat_type === "group" ? GroupImg : item.profile_image}>

                                                </Avatar>
                                                {item.chat_type === "group" && item.chat_id === selectedChat.chat_id && <FormControlLabel
                                                    className={clsx(classes.expand, {
                                                        [classes.expandOpen]: expanded[item.chat_id] && value === index,
                                                    })}
                                                    value={expanded[item.chat_id]}
                                                    checked={expanded[item.chat_id]}
                                                    onClick={(e) => {
                                                        handleExpandClick(selectedChat.chat_id)

                                                    }}
                                                    control={<ExpandMoreIcon htmlColor="#9B9B9C" />}
                                                    aria-expanded={expanded[item.chat_id]}
                                                    aria-label="show more"
                                                />}
                                            </div>
                                            }
                                            action={

                                                item.unread_messages > 0 && <IconButton size="small" style={{
                                                    backgroundColor: item.unread_message_color === "amber" ? "#FFB300" : "#FF0202"
                                                }} className={classes.badge}>{item.unread_messages}</IconButton>
                                            }
                                            title=
                                            <Grid container style={{ marginTop: ' -25px' }}>
                                                <Grid xs={9} style={{ marginTop: '0px' }}>  <Typography variant='subtitle1' style={{ color: '#2D2D2D', fontWeight: 600, }}> {item.chat_name}</Typography></Grid>

                                                <Grid xs={3} >
                                                    <Typography className={classes.timeStamp}> <ReactTimeAgo date={convertToLocal(item.message_time)} /></Typography></Grid>

                                            </Grid>
                                            subheader={item.message !== null && item.message.length > 30 ? `${item.message.substring(0, 30)}` + '...' : item.message}

                                        />
                                        {item.chat_type === "group" && <Collapse in={expanded[item.chat_id] && value === index} timeout="auto" mountOnEnter unmountOnExit >
                                            {expanded[item.chat_id] && item.chat_id === selectedChat.chat_id && <CardContent>
                                                <Grid container xs={12} className={classes.cardContent}>
                                                    <Grid item xs={4} container>
                                                        <Grid item xs={6} style={{ textAlign: 'center' }}>
                                                            <img src={ArrowIcon} height={'18px'} /></Grid>
                                                        <Grid item xs={6}>
                                                            <a className={classes.unsend} onClick={() => {
                                                                setAction('view_details')
                                                                setSelectedTab(0)
                                                                setSelectedId(item.chat_id)
                                                            }}>{groupCountLoading ? 0 : groupCount.sent}</a></Grid>
                                                    </Grid>
                                                    <Grid item xs={4} container>
                                                        <Grid item xs={6} style={{ textAlign: 'center' }}>

                                                            <ViewIcon htmlColor={'#8CDA5C'} style={{ height: '21px' }} />
                                                        </Grid>
                                                        <Grid item xs={6}><a className={classes.send} onClick={() => {
                                                            setAction('view_details')
                                                            setSelectedTab(1)
                                                            setSelectedId(item.chat_id)
                                                        }}>{groupCountLoading ? 0 : groupCount.read}</a></Grid>
                                                    </Grid>
                                                    <Grid item xs={4} container>
                                                        <Grid item xs={6} style={{ textAlign: 'center' }}><img src={RedEye} height={'18px'} /> </Grid>
                                                        <Grid item xs={6}><a className={classes.oldmsg} onClick={() => {
                                                            setAction('view_details')
                                                            setSelectedTab(2)
                                                            setSelectedId(item.chat_id)
                                                        }}>{groupCountLoading ? 0 : groupCount.unread}</a></Grid>
                                                    </Grid>

                                                </Grid>

                                            </CardContent>}
                                        </Collapse>}

                                    </Card>

                                )
                            })}
                            {!loading && <Grid item xs={12} className={classes.buttonState}>
                                <MROButton color='#F5F6FA' variant="contained" type='button' startIcon={<img src={ArchiveIcon} height={'18px'} />} style={{ width: '90%' }} onClick={() => {
                                    setArchiveState(!archiveState)
                                    setSelectedChat('')
                                }}>
                                    {archiveState === true ? " Hide archived messages" : " Show archived messages "}
                                </MROButton></Grid>
                            }

                        </InfiniteScroll>




                    </Grid>

                    <Divider orientation="vertical" variant="middle" style={{ position: 'relative', marginTop: '-158px', marginLeft: '2px' }} flexItem />
                    {selectedChat && <ChatDetail selectedChat={selectedChat} getMesssagesList={getMesssagesList} setSelectedChat={setSelectedChat} getTotalUnReadCount={getTotalUnReadCount} setArchiveState={setArchiveState} setMsgLoading={setLoading} />}
                </Grid>
            </Grid>


            {action && action === 'view_details' && <MessageDetails selectedTab={selectedTab} handleClose={() => setAction(null)} setSelectedTab={setSelectedTab} selectedId={selectedId} />}
            {action && action === 'create' && <CreateNewMessage handleClose={() => setAction(null)} getMesssagesList={getMesssagesList} setSelectedChat={setSelectedChat} />}
        </div >
    )
}
