import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { requireAuth } from "../Auth/hoc/require_auth";
import SignIn from "../Auth/signin";
import ForgotPassword from "../Auth/forgot_password_request";
import ResetPassword from "../Auth/reset_password";
import Setup from "../Setup"
import ToastMessage from "../../components/Universal/status_messages";
import MyClients from "../My_clients";
import ReportGroups from "../Setup/report_groups"
import Dashboard from '../Dashboard';
import setupSection from "./setup"
import TwoFactorAuth from '../Auth/two_factor_auth';
import Messaging from '../Messaging';


export default function AppRoutes() {
    const ADMIN = requireAuth(['admin']);
    return (
        <div>
            <Switch>
                <Route exact path={'/auth/signin'} component={SignIn} />
                <Route exact path={'/auth'} component={SignIn} />
                <Route exact path={'/auth/forgot-password'} component={ForgotPassword} />
                <Route exact path={'/auth/reset-password'} component={ResetPassword} />
                <Route path={'/dashboard'} component={Dashboard} />
                <Route path={'/messaging'} component={Messaging} />
                <Route exact path={'/my_clients'} component={MyClients} />
                <Route path={'/auth/two-factor-auth'} component={TwoFactorAuth} />
                {/* <Route path={'/setup/report_groups'} component={ReportGroups} /> */}
                {/* <Route exact path={'/setup'} component={Setup}/> */}
                <Route path={'/setup'} component={setupSection} />
                <Redirect from={'/'} to={'/auth/signin'} />
            </Switch>
            <ToastMessage />
        </div>
    )
}
