import React, {useEffect, useState} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import ReportGroups from '../Report_groups';
import GlobalReports from '../Global_reports';
import ClientReports from '../Client_reports';
import Box from '@material-ui/core/Box';

import Typography from "@material-ui/core/Typography";

function a11yProps(index) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width:'100%'
    },
    appBar:{
        backgroundColor: "#fff",
        color:theme.palette.primary.main,
        padding:"1% 1% 0 1%"
    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

export default function TopNavigationBar(props) {
    const classes = useStyles();
    const tabProp = props.tabProperties || [];
    const [currenttab, setCurrentTab] = useState(0);  
    const handleChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    const currentPath = props.location.pathname;
    const getTabIndex = () =>{
        const tabIndex = tabProp.findIndex(tab => currentPath.indexOf(tab.redirect) !== -1);
        return tabIndex >=0 ? tabIndex : 0;
    }
    
    useEffect(()=>{
        setCurrentTab(getTabIndex())
    },[])
    const tabs = [
        {id:"0",title:"Global Reports"},
        {id:"1",title:"Client Reports"},
        {id:"2",title:"Report Groups"},
    ];
    return (
        <div className={classes.root}>
             <AppBar position="static" color="white">
            <Tabs
          value={currenttab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
        //   variant="fullWidth"
          aria-label="full width tabs example"
        >
            {tabs.map((value, i) => {
            return <Tab label={value.title} {...a11yProps(value.id)} /> 
        })}
        </Tabs>
      </AppBar>
      <TabPanel value={currenttab} index={0}>
          <GlobalReports />
      </TabPanel>
      <TabPanel value={currenttab} index={1}>
        <ClientReports />
      </TabPanel>
      <TabPanel value={currenttab} index={2}>
       <ReportGroups />
      </TabPanel>
        </div>
    );
}
