import request, {authRequest} from "../../shared/api_client";

function signUp(formData) {
    return request({
        url: '/auth/',
        method: 'POST',
        data: formData,
        handleHeaders: 2
    });
}

function login(formData) {
    return request({
        url: '/auth/sign_in',
        method: 'POST',
        data: formData,
        handleHeaders: 1,
    });
}

function logout() {
    return request({
        url: '/auth/sign_out',
        method: 'DELETE',
        handleHeaders: 0,
    });
}

function changePassword(formData, headers) {
    return request({
        url: '/users/create_password',
        method: 'POST',
        data: formData,
        headers: headers
    });
}

function requestReset(formData) {
    return request({
        url: '/auth/password',
        method: 'POST',
        data: formData,
    });
}

function updatePassword(formData) {
    return request({
        url:'/users/change_password',
        method: 'POST',
        data:formData
    })
}

function verifyOtpEmail(params) {
    return request({
        url: '/users/verify_by_email',
        method: 'POST',
        params
    });
}
function verifyOtpPhone(params) {
    return request({
        url: '/users/verify_by_number',
        method: 'POST',
        params
    });
}
function verifyTOTPCode(params) {
    return request({
        url: '/users/verify_totp_code',
        method: 'POST',
        params
    });
}
function verifyTOTPQR(params) {
    return request({
        url: '/users/verify_qr_code',
        method: 'POST',
        params
    });
}
function sendOtpByEmail(params) {
    return request({
        url: '/users/send_otp_by_email',
        method: 'GET',
        params
    });
}
function sendOtpByPhone(params) {
    return request({
        url: '/users/send_otp_by_num',
        method: 'GET',
        params
    });
}
function sendQRCodeLink(params) {
    return request({
        url: '/users/send_otp_by_qr',
        method: 'GET',
        params
    });
}

const AuthClient = {
    sendOtpByEmail,verifyOtpEmail, verifyOtpPhone, sendOtpByPhone, verifyTOTPCode, verifyTOTPQR, sendQRCodeLink, signUp, login, logout, changePassword, requestReset, updatePassword
};

export default AuthClient;