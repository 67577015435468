import request from "../../shared/api_client";

function getVehicleQuestions(params) {
    return request({
        url: '/vehicle_questions',
        method: 'GET',
        params:params
    });
}
function fetchVechicleBase(id) {
    return request({
        url: `/vehicle_questions/client_vq_indexing?organisation_id=${id}`,
        method: 'GET',
    });
}
function createVehicleQuestions(formData) {
    return request({
        url:`/vehicle_questions`,
        method: 'POST',
        data:formData
    })
}
function mapBaseStations(formData) {
    return request({
        url:`/vehicle_questions/map_base_stations`,
        method: 'POST',
        data:formData
    })
}
function mapVehicleOrg(formData) {
    return request({
        url:`/vehicle_questions/map_organisation`,
        method: 'POST',
        data:formData
    })
}
function updateVehicleQuestions(formData) {
    return request({
        url:`/vehicle_questions/update_question`,
        method: 'PUT',
        data:formData
    })
}

function deleteVehicleQuestions(formData) {
    return request({
        url:'/vehicle_questions/destroy_question',
        method: 'DELETE',
        data:formData
    })
}

const VechicleService = {
    getVehicleQuestions,mapVehicleOrg,createVehicleQuestions, updateVehicleQuestions, deleteVehicleQuestions,fetchVechicleBase,mapBaseStations
};

export default VechicleService;