import React, { useEffect, useContext, useState } from "react";
import { makeStyles, Paper, Switch } from "@material-ui/core";
import Typography from "@material-ui/core/Typography/Typography";
import { ToastMessageContext } from "../../lib/contexts/message_context";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid/Grid";
import LineReportClient from "../../lib/services/api/line_report";
import AddNewField from "./add_new_field";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";
import AddSubSection from "./add_sub_section";
import DeleteSection from "./delete_section";
import EditField from "./edit_field";
import ManageSection from "./manage_section";
import {
  DetailsTwoTone,
  DetailsOutlined,
  ArrowForwardIosOutlined,
  LensRounded,
} from "@material-ui/icons";
import MROButton from "../../components/buttons";
import PositiveSwitch from "../../components/form_components/switch";
import DeleteDialog from "../../components/Universal/delete_dialog";

const useStyles = makeStyles((theme) => ({
  paper: {
    // boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    // minHeight: '600px',
    padding: "3% !important",
    margin: "3% !important",
  },
  wrapper: {
    padding: theme.spacing(3),
  },
  buttons: {
    marginTop: 25,
    display: "flex",
    justifyContent: "flex-end",
  },
  detailWrap: {
    padding: "20px",
  },
  divider: {
    margin: "15px 0",
  },
  alignText: {
    paddingTop: "38%",
    margin: "auto",
    width: "45%",
  },
  addNewButton: {
    float: "right",
  },
  nestedList: {
    paddingLeft: "60px",
  },
  list: {
    paddingLeft: 0,
    "& .MuiListItem-gutters": {
      paddingLeft: 0,
    },
    "& .MuiListItemIcon-root": {
      minWidth: "35px",
      cursor: "pointer",
    },
  },
}));

export default function WorkOrderDetail(props) {
  const classes = useStyles();
  const message = useContext(ToastMessageContext);
  const {
    selectedIndex,
    categoryName,
    setCategoryName,
    isGlobal,
    currentSection,
    selectedOrganisation,
  } = props;
  const [currentSelection, setCurrentSelection] = useState({});
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState({});
  const [isSecondary, setIsSecondary] = useState(false);
  const [fieldId, setFieldId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [subSection, setSubSection] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [editAnchorEl, setEditAnchorEl] = useState(null);
  const [fieldName, setFieldName] = useState(null);
  const [destroyFiledName, setDestroyFiledName] = useState(null);
  const [action, setAction] = useState(null);
  const handleClick = (event, fieldname) => {
    setAnchorEl(event);
    setFieldName(fieldname);
  };
  const handleEditClick = (event, fieldname) => {
    setEditAnchorEl(event);
    setFieldName(fieldname);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const listFields = () => {
    let formData = new FormData();
    formData.append("report_name", "WorkOrder");
    LineReportClient.getData(
      `/line_reports/${isGlobal ? "list_fields" : "listing_org_fields"
      }?section_id=${selectedIndex}&organisation_id=${selectedOrganisation?.organisation_id
      }`
    )
      .then((res) => {
        if (res.fields) {
          const fields = res.fields;
          setFields(res.fields);
          const isSecondary = fields.map((row) => row.secondary_fields);
          setIsSecondary(isSecondary);
          const destoryFieldname = fields.map((row) => row.field_name);
          setDestroyFiledName(destoryFieldname);
        }
        // setLoading(false);
        else {
          setFields([]);
        }

        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        // message.showToastMessage({open:true,message: "Something went wrong. Try Again!",variant:"error"})
      });
  };
  useEffect(() => {
    if (selectedIndex) {
      setLoading(true);
      listFields();
    }
  }, [selectedIndex, props.currentSection]);
  const handleDeleteUser = () => {
    LineReportClient.deleteData(
      `/line_reports/destroy_method?field_id=${fieldId}`
    ).then((res) => {
      if (res.success) {
        handleClose();
        listFields();
      } else {
        message.showToastMessage({
          open: true,
          message: res.errors
            ? res.errors.join(",")
            : "Something went wrong. Try Again",
          variant: "error",
        });
      }
    });
  };
  const handleCloseSection = (data, id) => {
    let basesList = [];
    if (action === "create" && data && data.id) {
      basesList = [data].concat(fields);
      setFields(basesList);
    } else if (data && data.id) {
      basesList = fields.map((base) => {
        if (base.id === data.id) {
          return data;
        }
        return base;
      });
      setFields(basesList);
      setCategoryName(data.section_name);
    } else if (!data && id) {
      basesList = fields.filter((base) => base.id !== id);
      setFields(basesList);
    }
    setAction(null);
    // setCurrentSelection({});
  };

  const handleStatusChange = (event, fieldId) => {
    LineReportClient.setOrgStatus(
      `/line_reports/org_specifics_action?custom_field_id=${fieldId}&organisation_id=${selectedOrganisation.organisation_id}
        `,
      {
        org_status: event.target.checked,
      }
    ).then((response) => {
      if (response.success) {
        listFields();
        message.showToastMessage({
          open: true,
          message: "Org status updated!",
          variant: "success",
        });
      } else {
        message.showToastMessage({
          open: true,
          message: response.errors
            ? response.errors.join(",")
            : "Something went wrong. Try Again",
          variant: "error",
        });
      }
    });
  };

  const HeaderToogles = ["Maint Completed", "Aircraft Deferred Defects (ADDs)"];

  const HeaderNoAction = [
    "Tooling",
    "Tools used table",
    "Tool checks",
    "Aircraft deferred defects (ADDs)",
    "Status",
    "Reason ADDs Open",
    "ADD MEL CAT",
  ].map((titles) => titles.toLowerCase());

  console.log(categoryName, HeaderNoAction);

  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        {selectedIndex === null && (
          <Typography style={{ fontWeight: "bold" }}>
            No category selected
          </Typography>
        )}

        {!loading && fields && selectedIndex !== null ? (
          <List className={classes.list}>
            <ListItem>
              <Grid container alignItems="center">
                <Grid item xs={4}>
                  {/* <ListItemText primary={selectedIndex !== null ? categoryName : ' '} /> */}
                  <Typography style={{ fontWeight: "bold" }}>
                    {selectedIndex !== null ? categoryName : " "}
                  </Typography>
                </Grid>
                {!isGlobal && (
                  <Grid item xs={4}>
                    <React.Fragment>
                      <Typography style={{ fontWeight: "bold" }}>
                        Status
                      </Typography>
                    </React.Fragment>
                  </Grid>
                )}
                {!HeaderNoAction.includes(categoryName?.toLowerCase()) && (
                  <Grid item xs={3} container justify="flex-end">
                    {isGlobal && (
                      <>
                        <IconButton
                          onClick={() => {
                            setAction("editSection");
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <React.Fragment>
                          {fields.length === 0 &&
                            !currentSection[0]?.existing_section ? (
                            HeaderToogles.includes(categoryName) ? (
                              ""
                            ) : (
                              <IconButton
                                onClick={() => {
                                  setAction("delete");
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            )
                          ) : (
                            ""
                          )}
                        </React.Fragment>
                      </>
                    )}
                  </Grid>
                )}

                {isGlobal && (
                  <Grid item xs={5} container justify="flex-end">
                    {!loading && fields && currentSection[0] ? (
                      <React.Fragment>
                        {" "}
                        <Button
                          size="medium"
                          startIcon={<AddIcon />}
                          color="secondary"
                          variant="contained"
                          onClick={() => {
                            setSubSection(true);
                          }}
                        >
                          {" "}
                          Add Sub Section
                        </Button>{" "}
                        &nbsp;&nbsp;
                      </React.Fragment>
                    ) : (
                      ""
                    )}
                    {!loading &&
                      currentSection.length < 1 &&
                      !HeaderNoAction.includes(categoryName?.toLowerCase()) && (
                        <Button
                          size="medium"
                          startIcon={<AddIcon />}
                          color="secondary"
                          variant="contained"
                          onClick={() => {
                            setShowModal(true);
                          }}
                        >
                          {" "}
                          Add New Field
                        </Button>
                      )}
                  </Grid>
                )}
              </Grid>
            </ListItem>
            <ListItem></ListItem>
          </List>
        ) : (
          ""
        )}

        <List className={classes.list}>
          {!loading && fields ? (
            Object.values(fields).map((field, i) => {
              return (
                <>
                  <Grid container key={i}>
                    <Grid
                      item
                      xs={isGlobal ? 12 : 12}
                      container
                      direction="row"
                    >
                      <Grid item container alignItems="center">
                        <Grid item xs={isGlobal ? 8 : 4}>
                          <Typography style={{ fontWeight: "bold" }}>
                            Field
                          </Typography>
                          <ListItem>
                            <ListItemText primary={field.field_name} />
                            {isGlobal && (
                              <ListItemSecondaryAction style={{ right: "8%" }}>
                                {field.existing_field === false && (
                                  <IconButton
                                    onClick={() => {
                                      handleEditClick(
                                        field.id,
                                        field.field_name
                                      );
                                      setShowEdit(true);
                                    }}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                )}
                                {field.existing_field === false && (
                                  <React.Fragment>
                                    <IconButton
                                      onClick={() => {
                                        handleClick(field.id, field.field_name);
                                        setFieldId(field.id);
                                      }}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </React.Fragment>
                                )}
                              </ListItemSecondaryAction>
                            )}
                          </ListItem>
                        </Grid>
                        {!isGlobal && (
                          <Grid item xs={4}>
                            <PositiveSwitch
                              onChange={(e) => handleStatusChange(e, field.id)}
                              checked={field.org_status}
                            />
                          </Grid>
                        )}
                      </Grid>
                      <Grid item xs={8}>
                        <List component="div" className={classes.nestedList}>
                          {field &&
                            field.secondary_fields &&
                            field.secondary_fields.map((sub, i) => (
                              <ListItem>
                                <ListItemText
                                  primary={sub.secondary_field_name}
                                />
                                {isGlobal && field.existing_field === false && (
                                  <ListItemSecondaryAction
                                    style={{ right: "9%" }}
                                  >
                                    {/* <IconButton onClick={() => { handleEditClick(sub.secondary_id, sub.secondary_field_name); setShowEdit(true) }} >
                                                                <EditIcon />
                                                            </IconButton>
                                                            <IconButton type="submit" onClick={() => { handleClick(sub.secondary_id); setFieldId(sub.secondary_id) }} >
                                                                <DeleteIcon />
                                                            </IconButton> */}
                                  </ListItemSecondaryAction>
                                )}
                              </ListItem>
                            ))}
                        </List>
                      </Grid>
                    </Grid>
                    <Grid item xs={5}></Grid>
                  </Grid>
                </>
              );
            })
          ) : (
            <div className={"center"} style={{ minHeight: "200px" }}>
              <CircularProgress color="primary" />
            </div>
          )}
        </List>
      </Paper>
      {/* <Popover
        id={id}
        open={open}
        style={{ left: "34%" }}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        <div className={classes.wrapper}>
          <Typography>
            Confirm remove <b>{fieldName}</b> ? <br /> All associated data will
            be removed. Once removed cannot be undone.
          </Typography>
          <div className={classes.buttons}>
            <MROButton
              disabled={loading}
              type="button"
              variant={"contained"}
              color=""
              onClick={handleClose}
            >
              {" "}
              No{" "}
            </MROButton>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <MROButton
              disabled={loading}
              type="button"
              variant={"contained"}
              color="primary"
              onClick={handleDeleteUser}
            >
              {" "}
              Yes{" "}
            </MROButton>
          </div>
        </div>
      </Popover> */}
      <DeleteDialog
        open={open}
        handleClose={handleClose}
        title={
          <>
            Confirm remove <b>{fieldName}</b> ?
          </>

        }
        confirmHandler={handleDeleteUser}
      />
      <AddNewField
        open={showModal}
        listFields={listFields}
        sectionList={props.sectionList}
        selectedIndex={selectedIndex}
        setLoading={setLoading}
        onClose={setShowModal}
      />
      <EditField
        open={showEdit}
        fields={fields}
        listFields={listFields}
        sectionList={props.sectionList}
        selectedIndex={selectedIndex}
        setLoading={setLoading}
        fieldId={editAnchorEl}
        onClose={setShowEdit}
      />
      <AddSubSection
        open={subSection}
        listFields={listFields}
        sectionList={props.sectionList}
        selectedIndex={selectedIndex}
        setLoading={setLoading}
        onClose={setSubSection}
      />
      {/* <DeleteSection setFields={setFields} destroyFiledName={setDestroyFiledName} open={deleteSection} sectionList={props.sectionList} listFields={listFields} selectedIndex={selectedIndex} setLoading={setLoading}  onClose={setDeleteSection} />   */}
      {action && (
        <ManageSection
          fieldId={editAnchorEl}
          currentSection={props.currentSection}
          setSelectedIndex={props.setSelectedIndex}
          sectionList={props.sectionList}
          listFields={listFields}
          setFields={setFields}
          destroyFiledName={setDestroyFiledName}
          action={action}
          selectedIndex={selectedIndex}
          handleCloseSection={handleCloseSection}
        />
      )}
    </React.Fragment>
  );
}
