import React, { useState } from 'react';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import MROTextField from "../../components/form_components/TextField";
import MROButton from "../../components/buttons";
import Typography from "@material-ui/core/Typography";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import PositiveSwitch from "../../components/form_components/switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import LineReportClient from "../../lib/services/api/line_report";

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  dropbox: {
    height: '64px !important'
  },
  marginTop20: {
    marginTop: 20
  },
  marginBottom20: {
    marginBottom: 20
  }
}));
const theme = createMuiTheme({
  palette: {
    primary: red,
  },
});
export default function AddNewField(props) {
  const classes = useStyles();
  const [open, setOpen] = [props.open, props.onClose];
  const [age, setAge] = React.useState('');
  const [ageSec, setAgeSec] = React.useState('');
  const [error, setError] = useState({});
  const [dropDownValues, setDropDownValues] = useState('');
  const [secondaryDropDownValues, setSecondaryDropDownValues] = useState('');

  // setSecondaryDropDownValues
  const [values, setValues] = useState({});
  const [mandatoryChecked, setMandatoryChecked] = useState(false);
  const [secondaryChecked, setSecondaryChecked] = useState(false);


  const [secMandatoryChecked, setSecMandatoryChecked] = useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const handleChangeSecondary = (event) => {
    setAgeSec(event.target.value);
  };
  const handleClose = () => {
    setOpen(false);
    resetState()
  };

  const resetState = () => {
    setAge('')
    setDropDownValues('')
    setMandatoryChecked(false)
    setSecondaryChecked(false)
    setSecMandatoryChecked('')
  }
  //    const originalString = "One, Two, Three, Four, Five"; 
  // const separatedArray = []; 

  // // index of end of the last string  
  // let previousIndex = 0; 
  //  if(originalString.length > 0){
  // for(var i = 0; i < originalString.length; i++) { 

  //     // check the character for a comma 
  //     if (originalString[i] === ', ') { 

  //         // split the string from the last index 
  //         // to the comma 
  //       const  separated = originalString.slice(previousIndex, i); 
  //         separatedArray.push(separated); 

  //         // update the index of the last string 
  //         previousIndex = i + 1; 
  //     } 
  // } 

  // // push the last string into the array 
  // separatedArray.push(originalString.slice(previousIndex, i)); 
  //  }
  //  console.log('object', separatedArray)
  const handleSubmit = (e) => {

    e.preventDefault();
    let formData = new FormData(e.target);

    var object = {
      section_id: props.selectedIndex,
      mandatory: mandatoryChecked,
      secondary_field: secondaryChecked
    };
    formData.forEach((value, key) => { object[key] = value });
    object.mandatory = mandatoryChecked;

    if (secondaryChecked) {
      object['secondary_field'] = secondaryChecked;
      object.secondary_fields = {
        field_name: object.secondary_field_name,
        field_type: object.secondary_field_type,
        help_text: object.secondary_help_text,
        dropdown_values: object.secondary_dropdown_values,
        max_length: object.secondary_max_length,
        decimal_places: object.secondary_decimal_places,
        mandatory: secMandatoryChecked
      }
      delete object.secondary_decimal_places
      delete object.secondary_field_name
      delete object.secondary_field_type
      delete object.secondary_help_text
      delete object.secondary_max_length
      delete object.secondary_dropdown_values

    }

    LineReportClient.createData(object).then((res) => {
      if (res.success) {
        handleClose()
        props.listFields()
        props.setLoading(true);
        setTimeout(() => {
          props.setLoading(false);
          handleClose();
        }, 300);
        props.sectionList()
      }
    })
  }

  return (
    <React.Fragment>
      <div> <Dialog
        open={open}
        //   fullWidth={true}
        onClose={handleClose}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add New Field</DialogTitle>
        <DialogContent>
          <React.Fragment>
            <form onSubmit={handleSubmit}>
              {/* ============================ Primary Fields =============================> */}
              <Grid container spacing={5} alignItems={"center"} justify={"flex-start"}>
                <Grid item xs={6}>
                  <MROTextField
                    name={"field_name"}
                    label={"Field Name"}
                    required
                    inputProps={{ autoFocus: true }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <MROTextField
                    name={"help_text"}
                    label={"Help text in box"}
                    required
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                {/* <Grid item xs={6}>
                </Grid> */}
                <Grid item xs={6}>
                  <MROTextField
                    name={"field_type"}
                    label={"Field Type"}
                    select
                    autoFocus={error.manager}
                    error={error.manager}
                    helperText={error.manager}
                    value={age}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                  >
                    <MenuItem value={'Yes/No'}>Yes/No</MenuItem>
                    <MenuItem value={'Numeric'}>Numeric</MenuItem>
                    <MenuItem value={'Freetext'}>Free text</MenuItem>
                    <MenuItem value={'Dropdown'}>Dropdown</MenuItem>
                  </MROTextField>
                </Grid>
                {/* <Grid item xs={6}>
                </Grid> */}
                <Grid item xs={6}>
                </Grid>
                {age === 'Freetext' && <React.Fragment><Grid item xs={6}>
                  <MROTextField
                    name={"max_length"}
                    label={"Field Max Length"}
                    required
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                  <Grid item xs={6}>
                  </Grid>
                  {/* <Grid item xs={6}>
                  </Grid> */}
                </React.Fragment>}
                {age === 'Numeric' && <Grid item xs={6}>
                  <MROTextField
                    name={"max_length"}
                    label={"Field Max Length"}
                    required
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>}
                {age === 'Numeric' && <Grid item xs={6}>
                  <MROTextField
                    name={"decimal_places"}
                    label={"Decimal Places"}
                    required
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>}

                {age === 'Dropdown' ? <Grid
                  //style={{ height: '64px', marginBottom: 20 }}
                  // className={classes.marginBottom20}
                  item xs={6}>
                  <MROTextField
                    name={"dropdown_values"}
                    label={"Enter dropdown options"}
                    required
                    onChange={(e) => setDropDownValues(e.target.value)}
                    // placeholder="Enter dropdown fields (comma separated)"
                    helperText="Enter dropdown fields (comma separated)"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid> : ''}
                <Grid item xs={8} >
                  <FormControlLabel
                    // className={classes.marginTop20}
                    control={
                      <PositiveSwitch
                        checked={mandatoryChecked}
                        onChange={(e) => setMandatoryChecked(e.target.checked)}
                        name="mandatory"
                      />
                    }
                    label="Is this a mandatory field?"
                    labelPlacement="start"
                    name={"mandatory"}
                  />
                </Grid>
                {/* <Grid item xs={6}>
                </Grid> */}
                {/* <Grid item xs={6}>
                        </Grid> */}
                <Grid item xs={8}>
                  {age === 'Yes/No' && <FormControlLabel
                    control={
                      <PositiveSwitch
                        checked={secondaryChecked}
                        onChange={(e) => setSecondaryChecked(e.target.checked)}
                        name="secondary_field"
                      />
                    }
                    label="Does it need a secondary field?"
                    labelPlacement="start"
                    name={"secondary_field"}
                  />}
                </Grid>
                <Grid item xs={6}>
                </Grid>
              </Grid>
              {/* ============================ End of primary Fields =====================> */}
              {/* ============================ Secondary Fields ==========================> */}
              {age === 'Yes/No' && secondaryChecked &&
                <Grid container spacing={2} style={{ background: '#f5f5f5' }} >
                  <Grid item xs={6}>
                    <MROTextField
                      name={"secondary_field_name"}
                      label={"Field Name"}
                      required
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <MROTextField
                      name={"secondary_help_text"}
                      label={"Help text in box"}
                      required
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  {/* <Grid item xs={6}>
                </Grid> */}
                  <Grid item xs={6}>
                    <MROTextField
                      name={"secondary_field_type"}
                      label={"Field Type"}
                      select
                      autoFocus={error.manager}
                      error={error.manager}
                      helperText={error.manager}
                      value={ageSec}
                      onChange={handleChangeSecondary}
                      InputLabelProps={{ shrink: true }}
                    >
                      <MenuItem value={'Yes/No'}>Yes/No</MenuItem>
                      <MenuItem value={'Numeric'}>Numeric</MenuItem>
                      <MenuItem value={'Freetext'}>Free text</MenuItem>
                      <MenuItem value={'Dropdown'}>Dropdown</MenuItem>
                    </MROTextField>
                  </Grid>
                  {/* <Grid item xs={6}>
                </Grid> */}
                  {/* <Grid item xs={6}>
                </Grid> */}
                  {ageSec === 'Freetext' && <Grid item xs={6}>
                    <MROTextField
                      name={"secondary_max_length"}
                      label={"Field Max Length"}
                      required
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>}
                  {ageSec === 'Numeric' && <Grid item xs={6}>
                    <MROTextField
                      name={"secondary_max_length"}
                      label={"Field Max Length"}
                      required
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>}
                  {ageSec === 'Numeric' && <Grid item xs={6}>
                    <MROTextField
                      name={"secondary_decimal_places"}
                      label={"Decimal Places"}
                      required
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>}

                  {ageSec === 'Dropdown' ? <Grid item xs={6}>
                    {/* <MROTextField
                    name={"decimal_places"}
                    label={"Enter dropdown options"}
                    required
                    placeholder="Enter dropdown fields (comma separated)"
                    InputLabelProps={{ shrink: true }}
                  /> */}
                    <MROTextField
                      name={"secondary_dropdown_values"}
                      label={"Enter dropdown options"}
                      required
                      onChange={(e) => setSecondaryDropDownValues(e.target.value)}
                      // placeholder="Enter dropdown fields (comma separated)"
                      helperText="Enter dropdown fields (comma separated)"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid> : ''}
                  {/* <Grid item xs={6}>
                        </Grid> */}
                  <Grid item xs={8}>
                    <FormControlLabel
                      control={
                        <PositiveSwitch
                          checked={secMandatoryChecked}
                          onChange={(e) => setSecMandatoryChecked(e.target.checked)}
                          name="mandatory"
                        />
                      }
                      label="Is this a mandatory field?"
                      labelPlacement="start"
                      name={"secondary_mandatory"}
                    />
                  </Grid>
                  <Grid item xs={6}>
                  </Grid>

                </Grid>
              }
              {/* ============================= End of Secondary Fields =========================> */}
              <br />
              <div style={{ textAlign: 'right' }}>
                <MROButton variant={"contained"} color="tertiary" onClick={handleClose}>Cancel</MROButton>&nbsp;&nbsp;
                        <MROButton variant={"contained"} color="primary" type="submit">  Save</MROButton>
              </div>
            </form>
          </React.Fragment>
        </DialogContent>
      </Dialog>
      </div>
    </React.Fragment>
  );
}