import React, { useContext,useState } from 'react';
import {  makeStyles } from '@material-ui/core/styles';
import {ToastMessageContext} from "../../lib/contexts/message_context";
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import LineReportClient from "../../lib/services/api/line_report";

const useStyles = makeStyles(theme => ({
    margin: {
      margin: theme.spacing(1),
    },
  }));
export default function AddSection(props) {
  const classes = useStyles();
  const message = useContext(ToastMessageContext);
  const [loading,setLoading] = useState(false);
  const [open, setOpen] = [props.open,props.onClose];
  const handleClose = () => {
      setOpen(false);
    };
  const handleSubmit =(e) =>{
      e.preventDefault();
      let formData = new FormData(e.target);
      formData.append('report_name','Delay')
      LineReportClient.create(formData,'/line_reports').then((res)=>{
          if(res.section){
            handleClose()
            props.sectionList()
            message.showToastMessage({open:true,message:"New Section successfully created",variant:"success"});
            handleClose()
          }
  else {
      message.showToastMessage({open:true,message:res.errors?res.errors.join(','):"Something went wrong. Try Again",variant:"error"});
      setLoading(false);
  }
}).catch(()=>{
  message.showToastMessage({open:true,message:"Something went wrong. Try Again",variant:"error"});
  setLoading(false);
})
   }
return (
   <React.Fragment>   
    <div> <Dialog 
      open={open}
      fullWidth={true}
       onClose={handleClose} 
       aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add New Section</DialogTitle>
        <DialogContent>
            <form onSubmit={handleSubmit}>
            <TextField
                variant="outlined" 
                margin="dense"
                placeholder="Section Name"
                name="section_name"
                fullWidth
                required
                autoComplete="off"
            />
            <div style={{textAlign: 'right'}}>
            <Button variant="contained" color="primary" type="submit" className={classes.margin}>
            Add
            </Button>
        <Button variant="outlined" color="primary" onClick={handleClose}> Cancel</Button>
             </div>       
         </form>   
        </DialogContent>
      </Dialog> 
    </div>
   </React.Fragment>
  );
}