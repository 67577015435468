/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/AddBox';
import IconButton from '@material-ui/core/IconButton';
import Grid from "@material-ui/core/Grid";
import VechicleReport from "./vechicle_report";
import StationChecks from "./station_checks";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import StationCheckQuestion from "./station_check_question";
import MapStationChecks from "./map_station_checks";
import CircularProgress from "@material-ui/core/CircularProgress";
import LoadingIndicator from "../../components/Universal/loading_indicator";
import { ToastMessageContext } from "../../lib/contexts/message_context";
import StationOrgService from '../../lib/services/api/organisation_based_stations';
import StationQuestionService from '../../lib/services/api/station_questions_services';
import WorkOrder from '../Work_order';
import DelayReport from "../Delay_report";
import CallOut from "../Call_out";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 690,
    backgroundColor: theme.palette.background.paper,
    margin: '3%',
  },
}));

export default function GlobalReports() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [delayReport, setUpdateDelayReport] = useState(false);
  const [workOrder, setWorkOrder] = useState(false);
  const [updateAction, setUpdateAction] = useState('');
  const [callOutModal, setCallOutModal] = useState('');
  const [addAction, setAddAction] = useState('');
  const message = useContext(ToastMessageContext);
  const [listVehicle, setListing] = useState({});
  const [organisations, setOrganisations] = useState({});
  const [selectedOrganisation, setSelectedOrganisation] = useState();
  const [currentSelection, setCurrentSelection] = useState({});

  useEffect(() => {
    setLoading(true);
    getOrganisations()
    if (selectedOrganisation !== '') {
      StationList()
    }
  }, []);
  useEffect(() => {
    setLoading(true);
    if (selectedOrganisation !== '') {
      StationList()
    }
  }, [selectedOrganisation]);
  const getOrganisations = () => {
    StationQuestionService.getOrganisations().then((res) => {
      if (res.data) {
        const organisations = res.data;
        // const organisationLength = organisations.length > 0 ? organisations.map(row=>row.company_name):'';
        setOrganisations(organisations)
      }
      setLoading(false);
    }).catch(() => {
      setLoading(false);
      message.showToastMessage({ message: "Something went wrong. Try Again!", variant: "error" })
    })
  }
  const StationList = () => {
    // if(selectedOrganisation !== ''){
    selectedOrganisation && StationOrgService.getFetchStationChecks(selectedOrganisation.organisation_id).then((res) => {
      if (res.data) {
        const listVehicle = res.data;
        setListing(listVehicle)
      }
      setLoading(false);
    }).catch(() => {
      setLoading(false);
      message.showToastMessage({ message: "Something went wrong. Try Again!", variant: "error" })
    })
    // }
  }
  console.log('selectedOrganisation', selectedOrganisation !== '' ? selectedOrganisation : '')
  return (
    <React.Fragment>
      {organisations && organisations.length > 0 ? <Autocomplete
        style={{ width: '20%', margin: '35px' }}
        getOptionSelected={(option, value) => option.id === value.id}
        value={(selectedOrganisation && selectedOrganisation.organisation_id) ? selectedOrganisation : null}
        // onChange={(event,value) => handleCustomerSelect(value)}  
        onChange={(event, value) => { setSelectedOrganisation(value); }}
        id="tags-standard"
        options={organisations}
        loading={loading}
        getOptionLabel={(option) => option.company_name}
        renderInput={(params) => (
          <TextField
            {...params}
            style={{ backgroundColor: '#fff' }}
            variant="outlined"
            label="Select Organisation"
            margin={"dense"}
          />
        )}
      /> : <LoadingIndicator variant="block" />}
      <List component="nav" className={classes.root} aria-label="mailbox folders">
        <ListItem >
          <Grid container spacing={2} >
            <Grid item xs={3}>
              <ListItemText primary="Line Report Editor" />
            </Grid>
            {
              selectedOrganisation && selectedOrganisation.organisation_id && <>
                <Grid item xs={2.5}>
                  <Button fullWidth component={"span"} startIcon={<EditIcon />} color="secondary" variant="contained" onClick={() => { setUpdateAction('updateLine') }}>Work Order  &nbsp;&nbsp;</Button>
                </Grid>
                <Grid item xs={2.5}>
            <Button fullWidth component={"span"} startIcon={<EditIcon />} color="secondary" variant="contained" onClick={() => { setCallOutModal('updateCallout') }}>Call Out   &nbsp;&nbsp;&nbsp;</Button>
          </Grid>
          <Grid item xs={2.5}>
            <Button fullWidth component={"span"} startIcon={<EditIcon />} color="secondary" variant="contained" onClick={() => { setUpdateDelayReport('updateDelayReport') }}>Delay Report</Button>
          </Grid>
              </>
            }
          </Grid>
        </ListItem>
        <Divider />
        <ListItem>
          <Grid container spacing={2} >
            <Grid item xs={3}>
              <ListItemText primary="Vehicle Report Editor" />
            </Grid>
            <Grid item xs={5}>
            </Grid>
            {selectedOrganisation && selectedOrganisation.organisation_id && <Grid item xs={2.5}>
              <Button style={{ marginLeft: '-21px' }} component={"span"} startIcon={<EditIcon />} color="secondary" variant="contained" onClick={() => { setShowModal('vechicle') }}>Edit Template</Button>
            </Grid>}
          </Grid>
        </ListItem>
        <Divider />
        <ListItem >
          <Grid container spacing={1} >
            <Grid item xs={3}>
              <ListItemText primary="Station Checks Editor" />
            </Grid>
            <Grid item xs={5}>
            </Grid>
            {/* <Grid item xs={2.5}> 
          </Grid> */}
            {listVehicle[0] && !loading && selectedOrganisation && selectedOrganisation.organisation_id ? listVehicle.map(row => (
              <Grid item xs={2.5}>
                <Button fullWidth component={"span"} startIcon={<EditIcon />} color="secondary" variant="contained" onClick={() => { setAddAction('station'); setCurrentSelection(row) }}>{row.title}  &nbsp;&nbsp;</Button>
              </Grid>
            )) : ''}
            {selectedOrganisation && selectedOrganisation.organisation_id && <Grid item xs={0.5}>
              <IconButton style={{ marginLeft: '7px' }} onClick={() => { setAddAction('addAction') }}  >
                <EditIcon />
              </IconButton>
            </Grid>}
          </Grid>
        </ListItem>
        {updateAction === 'updateLine' && <WorkOrder selectedOrganisation={selectedOrganisation !== '' ? selectedOrganisation : ''} handleClose={() => setUpdateAction(null)} onClose={setWorkOrder} />}
        {callOutModal === 'updateCallout' && <CallOut selectedOrganisation={selectedOrganisation !== '' ? selectedOrganisation : ''} handleClose={() => setCallOutModal(null)} onClose={setWorkOrder} />}
        {delayReport === 'updateDelayReport' && <DelayReport selectedOrganisation={selectedOrganisation !== '' ? selectedOrganisation : ''} handleClose={() => setUpdateDelayReport(null)} onClose={setWorkOrder} />}
        {showModal === 'vechicle' && <VechicleReport selectedOrganisation={selectedOrganisation !== '' ? selectedOrganisation : ''} handleClose={() => setShowModal(null)} onClose={setWorkOrder} />}
        {addAction === 'addAction' && <MapStationChecks data={currentSelection} selectedOrganisation={selectedOrganisation !== '' ? selectedOrganisation : ''} StationList={StationList} setLoading={setLoading} handleClose={() => setAddAction(null)} onClose={setWorkOrder} />}
        {addAction === 'station' && <StationCheckQuestion data={currentSelection} organisations={organisations} selectedOrganisation={selectedOrganisation !== '' ? selectedOrganisation : ''} StationList={StationList} setLoading={setLoading} handleClose={() => setAddAction(null)} onClose={setWorkOrder} />}
      </List></React.Fragment>
  );
}
