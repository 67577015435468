import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Typography from "@material-ui/core/Typography";
// import AuthClient from ".../../lib/services/api/auth_service";
import AuthClient from "../../lib/services/api/auth_service"
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import PasswordVisible from "@material-ui/icons/Visibility";
import PasswordHide from "@material-ui/icons/VisibilityOff";
import { validateEmail } from "../../lib/common_utils";
import UserIcon from "@material-ui/icons/PersonOutlined";
import LockIcon from "@material-ui/icons/LockOutlined";
import useAuthStyles from "./auth_styles";

export default function SignIn(props) {
    const classes = useAuthStyles();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({});
    const [mask, setIsMasked] = useState(true);

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const email = formData.get('eid');
        const password = formData.get('secret_phrase');
        if (!validateEmail(email.trim())) {
            setError({ email: "invalid format, provide valid email" });
            return;
        }
        setLoading(true);
        AuthClient.login({ email: email, password: password, application: "web",  app_type: "admin_app", success: false }).then((response) => {
            sessionStorage.setItem("user", JSON.stringify(response));
            props.history.push({
                pathname: '/auth/two-factor-auth',
                email: email,
                password: password,
                entitySid: response?.user?.totp_entity_sid
            });
        }).catch((e) => {
            setLoading(false);
            setError({ message: e.errors ? e.errors.join(" ,") : "Something went wrong" });
        });
    };


    useEffect(() => {
        const user = JSON.parse(sessionStorage.getItem('user'));
        if (user && user.email) {
            AuthClient.logout().then(() => sessionStorage.clear()).catch(() => sessionStorage.clear());
        }
        else {
            sessionStorage.clear();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={classes.wrapper}>
            <div className={classes.overlay}></div>
            <div style={{ zIndex: '99' }}>
                <div className={classes.orgLogo}>
                    <img src={'/mro-pro-logo.png'} alt={"mro pro logo"} />
                </div>
                <form className={classes.form} onSubmit={handleSubmit} autoComplete="custom-form">
                    <Typography className={classes.colorWhite} component={"h6"} variant={"h6"}>Login</Typography>
                    <FormControl fullWidth className={classes.formField}>
                        <OutlinedInput
                            required
                            name="eid"
                            autoComplete="new-password"
                            placeholder="Username"
                            autoFocus
                            error={!!error.email}
                            onFocus={() => { setError(false) }}
                            startAdornment={<InputAdornment position="start">
                                <UserIcon htmlColor={"#ffffff80"} />
                            </InputAdornment>
                            }
                        />
                        {!!error.email && <FormHelperText >{error.email ? error.email : null}</FormHelperText>}
                    </FormControl>
                    <FormControl fullWidth className={classes.formField}>
                        <OutlinedInput
                            required
                            startAdornment={<InputAdornment position="start">
                                <LockIcon htmlColor={"#ffffff80"} />
                            </InputAdornment>
                            }
                            endAdornment={
                                <InputAdornment position="end">
                                    {!mask ? <PasswordVisible htmlColor={"#ffffff80"} className={classes.eye} onClick={() => setIsMasked(!mask)} /> :
                                        <PasswordHide htmlColor={"#ffffff80"} className={classes.eye} onClick={() => setIsMasked(!mask)} />}
                                </InputAdornment>
                            }
                            name="secret_phrase"
                            type={mask ? "password" : "text"}
                            placeholder={"Password"}
                            autoComplete="new-password"
                            onFocus={() => setError(false)}
                        />
                    </FormControl>
                    <div style={{ margin: '20px 0' }}>
                        <span style={{ cursor: 'pointer' }} onClick={() => props.history.push('/auth/forgot-password')}>
                            Forgot password?
                        </span>
                    </div>
                    <Button
                        fullWidth
                        type="submit"
                        className={classes.submit}
                        disabled={loading}
                    >
                        Sign In
                    </Button>
                    <div style={{ textAlign: "center", marginTop: '15px' }}>
                        <span style={{ marginTop: '20px', color: 'red' }}>{error.message || null}</span>
                    </div>
                </form>
            </div>
            {/* <div className={classes.brandLogo}>
                <img src={'/mro-pro-logo.png'} alt={"mro pro logo"}/>
            </div> */}
        </div>
    );
}