import React, { useState, useEffect } from 'react'
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import { Dialog, DialogContent, DialogTitle, Box, MenuItem, Tabs, AppBar, ListItem, Typography, IconButton } from "@material-ui/core";
import MROTextField from '../../../components/form_components/TextField'
import MROButton from '../../../components/buttons'
import { MessageServices } from '../../../lib/services/api/Messaging/messae_services'
import { ToastMessageContext } from "../../../lib/contexts/message_context";
import { useContext } from 'react';


const useStyles = makeStyles((theme) => ({
    formWrapper: {
        background: "#fff",
        width: "100%",

    },
    title: {
        display: "flex",
        justifyContent: "left",
        "& h2": {
            //   color: theme.palette.primary.main,
            fontSize: "40px",
            fontWeight: 40,
        },
    },
    subTitle: {
        fontSize: "16px",
        fontWeight: "bold",
        marginBottom: "20px",
        color: "#4D4F5C",
    },
    card: {
        padding: "8px 29px",
    },
    actionButtons: {
        paddingBottom: "33px",
        textAlign: "end",
        marginRight: "6px",
        marginTop: '21px',
        "& button:not(:last-child)": {
            marginRight: "20px",
        },
    },

    modal: {
        "& .MuiDialog-paperWidthMd": {
            width: "400px",
        },
    }
}));




function EditTitle(props) {
    const classes = useStyles();
    const { handleClose, selectedChat, getChatDetails, getMesssagesList, title } = props
    const [errors, setErrors] = useState({});
    const message = useContext(ToastMessageContext)
    const [loading, setLoading] = useState(false)



    const handleSubmit = async (e) => {
        e.preventDefault()
        const formData = new FormData(e.target)
        let valid = true;
        let error = {
            subject: false,

        };
        if (formData && formData.get("subject")?.length === 0) {
            error.subject = true;
            valid = false;
        }


        if (!valid) {
            setErrors(errors);
            return;
        }

        formData.append("chat_id", selectedChat?.chat_id)
        setLoading(true)
        await MessageServices.updateSubjectTitle(formData).then((response) => {
            if (response.success) {
                message.showToastMessage({
                    message: "Updated successfully.",
                    variant: "success",
                });
                handleClose()

                getMesssagesList()
                getChatDetails()
            }
            else if (response.success === false) {
                message.showToastMessage({
                    message: "Something Went Wrong ",
                    variant: "error",
                })
                handleClose(true)
            }

        }).catch((res) => {
            handleClose(true)
            message.showToastMessage({
                message: "Something Went Wrong ",
                variant: "error",
            });

        }).finally(() => {
            setLoading(false)
        })


    }



    return (
        <div>
            <Dialog
                maxWidth={"md"}
                open={true}
                className={classes.modal}
                onClose={handleClose}
                aria-labelledby="base-station-manage"
            >

                <DialogTitle>
                    <Typography color='primary' variant='subtitle1'>Edit</Typography>
                </DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit} noValidate >
                        <Grid >
                            <MROTextField
                                name={"subject"}
                                label={"Subject"}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ style: { height: 'max-content', width: "100%" } }}
                                inputProps={{ maxLength: 30 }}
                                helperText={errors.subject ? "*please enter Subject title" : null}
                                onChange={(e) => { setErrors({ ...errors, subject: false }); }}

                                defaultValue={title ? title : ''}
                            />
                        </Grid>
                        <div className={classes.actionButtons}>
                            <MROButton
                                //   disabled={loading}
                                type="button"
                                variant={"contained"}
                                onClick={handleClose}
                            >
                                Cancel
                            </MROButton>
                            <MROButton
                                //   disabled={loading}
                                type="submit"
                                variant={"contained"}
                                color="primary"
                                loading={loading}
                            >
                                Save
                            </MROButton>

                        </div>
                    </form>
                </DialogContent>
            </Dialog>


        </div>
    )
}

export default EditTitle