import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
    root:{
        display:'flex',
        alignItems:'flex-start',
        flexDirection:'row',
        justifyContent:'flex-start',
        flexWrap:"wrap",
        '& button':{
            margin:theme.spacing(1.5,3,1.5,0),
            '&.active':{
                backgroundColor: theme.palette.primary.main,
                borderColor: theme.palette.primary.dark,
                boxShadow: 'none',
                color:'#fff'
            }
        }
    },
}));

const MROButton = withStyles(theme=> ({
    root:{
        fontWeight: 400,
        textTransform: 'none',
        fontSize: 14,
        color:theme.palette.primary.main,
        backgroundColor:`${theme.palette.primary.main}1A`,
        '&:hover, &:active': {
            backgroundColor: theme.palette.primary.main,
            borderColor: theme.palette.primary.dark,
            boxShadow: 'none',
            color:'#fff'
        },
    }
}))((props)=><Button disableElevation disableRipple disableFocusRipple {...props}/>);


function ButtonGroupNavigation(props){
    const classes = useStyles();
    const buttons = props.buttons || [];

    const currentPath = props.location.pathname;
    const isCurrentSection = (listValue) =>{
        return (currentPath.indexOf(listValue) !== -1);
    }

    return (
        <div className={classes.root}>
            {buttons.map((button,i)=> <MROButton className={isCurrentSection(button.redirect)?"active":""} key={i} variant="contained" onClick={()=>props.history.push(button.redirect)}>{button.name}</MROButton> )}
        </div>
    )
}

export default ButtonGroupNavigation;