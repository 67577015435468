import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';

import Skeleton from '@material-ui/lab/Skeleton';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    card: {
        maxWidth: 450,
        height: '18vh',
        // margin: theme.spacing(2),
    },
    media: {
        height: '15vh',
    },
    rightSkeleteton: {
        position: 'absolute',
        right: '4px',
        marginTtop: '5px',
        marginBottom: 6
    }
}));
function ChatLoader() {

    const classes = useStyles();
    return (
        <Grid container xs={12} direction=''>

            <Grid item xs={12} >
                <Skeleton animation="wave" height={20} width="80%" style={{ marginBottom: 6 }} />

            </Grid>
            <Grid item xs={12} style={{ textAlign: 'left' }} >

                <Skeleton animation="wave" height={20} width="80%" style={{ marginBottom: 6 }} />
                <Skeleton animation="wave" height={20} width="60%" style={{ marginBottom: 6 }} />
                <Skeleton animation="wave" height={20} width="40%" style={{ marginBottom: 6 }} />
            </Grid>
        </Grid>
    )
}

export default ChatLoader