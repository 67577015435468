import React, { useState, useEffect, useContext } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MRORightDrawer from "../../components/temporary_sidebar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import DataClient from "../../lib/services/api/get_data_service";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import PositiveSwitch from "../../components/form_components/switch";
import { ToastMessageContext } from "../../lib/contexts/message_context";
import { flattenObjectToArray } from "../../lib/common_utils"
import OrganisationApiClient from "../../lib/services/api/organisation_details";

const useStyles = makeStyles(theme => ({
    list: {
        paddingLeft: 0,
        '& .MuiListItem-gutters': {
            paddingLeft: 0
        },
        '& .MuiListItemIcon-root': {
            minWidth: '35px',
            cursor: 'pointer'
        }
    },
    nestedList: {
        background: '#f5f5f5',
        paddingLeft: theme.spacing(4)
    }
}));
let updateOccured = false;

function UpdateWebCategories(props) {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [openSubCategory, setOpenSubcategory] = useState(null);
    const [frontEndCategory, setFrontEndCategory] = useState({});
    const { action, handleClose, handleEdit } = props;
    const message = useContext(ToastMessageContext);
    const toggleDrawer = () => {
        props.handleSideClose(updateOccured);
    };

    const organisationList = () => {
        OrganisationApiClient.getWeb(props.organisationId).then((res) => {
            if (res.data) {
                setFrontEndCategory(res.data);
            }
            setLoading(false);
        }).catch(() => {
            setLoading(false);
            message.showToastMessage({ open: true, message: "Something went wrong. Try Again!", variant: "error" })
        })
    }
    useEffect(() => {
        setLoading(true);

    }, [action]);
    useEffect(() => {
        setLoading(true);
        // getAccessListOfGroup();
        organisationList()
    }, []);

    const handleAccessChange = (category, checked, rootCategory) => {
        let permissions = [];
        let updateCategory = {
            category_id: category.category_id,
            status: checked
        };
        permissions.push(updateCategory);
        if (category.sub_categories && category.sub_categories.length > 0) {
            category.sub_categories.forEach(sub => permissions.push({ category_id: sub.category_id, status: checked }))
        }
        if (rootCategory) {
            let updatedRootCategory = {};
            const currentCategories = rootCategory.sub_categories.filter(cat => cat.category_id !== category.category_id && cat.status === true);
            if (currentCategories && currentCategories.length > 0) {
                updatedRootCategory = {
                    category_id: rootCategory.category_id,
                    status: currentCategories.length > 0 || checked
                }
            }
            else {
                updatedRootCategory = {
                    category_id: rootCategory.category_id,
                    status: checked
                }
            }
            permissions.push(updatedRootCategory);
        }
        OrganisationApiClient.updateCategories({ web_categories: permissions, organisation_id: props.organisationId }).then((res) => {
            if (res.success) {
                updateOccured = true;
                organisationList();
                message.showToastMessage({ message: "Updated Access rights for Web category", variant: "success" })
            }
        }).catch(() => {
            message.showToastMessage({ message: "Something went wrong. Try again", variant: "error" })
        })

    }
    return (
        <MRORightDrawer onClose={toggleDrawer}>
            <div className={"align-left-space-between"}>
                <div>
                    <Typography variant="subtitle2" gutterBottom>Web applications</Typography>
                    {/* <Typography color="secondary" variant="subtitle2" gutterBottom>{data.name}</Typography> */}
                </div>
                <IconButton onClick={toggleDrawer}>
                    <CloseIcon fontSize={"small"} />
                </IconButton>
            </div>
            <div>
                <List className={classes.list}>
                    <ListItem>
                        <ListItemText><div className={"align-left-space-between"}><Typography variant="subtitle2" gutterBottom>Module Name</Typography><Typography variant="subtitle2" gutterBottom>On/Off</Typography></div></ListItemText>
                    </ListItem>
                    {(!loading && frontEndCategory) ? Object.values(frontEndCategory).map((category, i) => {
                        return (
                            <React.Fragment key={i}>
                                <ListItem>
                                    <ListItemIcon style={category.sub_categories && category.sub_categories.length > 0 ? {} : { visibility: 'hidden' }} onClick={() => setOpenSubcategory(openSubCategory !== i ? i : null)}>
                                        {openSubCategory === i ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemIcon>
                                    <ListItemText primary={category.name} />
                                    <ListItemSecondaryAction>
                                        <PositiveSwitch
                                            edge="end"
                                            checked={category.status}
                                            inputProps={{ 'aria-labelledby': 'on-or-off' }}
                                            onChange={(e) => handleAccessChange(category, e.target.checked)}
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Divider />
                                <Collapse in={openSubCategory === i} timeout="auto">
                                    <List component="div" className={classes.nestedList}>
                                        {category && category.sub_categories && category.sub_categories.map((sub, i) => <ListItem key={i}>
                                            <ListItemText primary={sub.name} />
                                            <ListItemSecondaryAction>
                                                <PositiveSwitch
                                                    edge="end"
                                                    checked={sub.status}
                                                    inputProps={{ 'aria-labelledby': 'on-or-off' }}
                                                    onChange={(e) => handleAccessChange(sub, e.target.checked, category)}
                                                />
                                            </ListItemSecondaryAction>
                                        </ListItem>)}
                                    </List>
                                </Collapse>
                            </React.Fragment>
                        )
                    }) : <div className={"center"} style={{ minHeight: '200px' }}>
                            <CircularProgress color="primary" />
                        </div>}
                </List>
            </div>

        </MRORightDrawer>
    )
}

export default UpdateWebCategories;