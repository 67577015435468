import React from "react";
import Drawer from '@material-ui/core/Drawer';
import withStyles from "@material-ui/core/styles/withStyles";


const MRORightDrawer = withStyles(theme => ({
    paperAnchorRight: {
        width: "350px",
        padding: theme.spacing(3)
    },
}))((props) => <Drawer transitionDuration={600} elevation={2} anchor={"right"} open={true} {...props}>{props.children}</Drawer>)

export default MRORightDrawer;