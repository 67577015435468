
import React, { useState, useEffect, useContext, useMemo } from 'react'
import { Box, Typography, Divider, Grid, Paper, useTheme, makeStyles, FormControl, InputBase, MenuItem, Select, alpha, Button, Menu, IconButton, FormControlLabel } from '@material-ui/core'

import SearchBarWithFilters from '../../../../components/search_with_filters_new'
import ArrowDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import CloudDownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import { DashboardServices } from '../../../../lib/services/api/Dashboard/dashboard';
import MaterialTable from "material-table";
import tableIcons, { options } from "../../../../components/Table/icons";
import StationQuestionService from '../../../../lib/services/api/station_questions_services';
import PositiveSwitch from '../../../../components/form_components/switch';
import _without from "lodash/without";
import ReactApexChart from 'react-apexcharts';
import _ from 'lodash';
import moment from "moment";
import { downloadFileFromUrl } from '../../../../lib/helper_function/HelperFunction';

import ToastMessageContext from '../../../../lib/contexts/message_context'
import ChartLoader from '../ChartLoader';


const useStyles = makeStyles((theme) => ({
    root: {
        width: "94%",
        margin: "4% 2.5%",
        paddingLeft: '4px',
        '&.MuiStepConnector-root': {
            borderTopWidth: '10px'
        }
    },
    title: {
        fontSize: "14px",
        color: "#2D2D2D",
        fontWeight: 600
    },

}))


export default function Users(props) {


    const classes = useStyles()
    const [anchorClient, setAnchorClient] = useState(null);
    const [baseButton, setBaseButton] = useState(false)
    const [action, setAction] = useState(false)
    const [clientDetails, setClientDetails] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [checkedCustState, setCheckedCustState] = useState(new Array(clientDetails.length).fill(false));
    const [filterData, setFilterData] = useState({ months: 12 })
    const [userData, setUserData] = useState([])
    const [totalUser, setTotalUser] = useState(0)
    const message = useContext(ToastMessageContext)
    const [loading, setLoading] = useState(false)

    const [state, setState] = useState({
        series: [],
        options: {
            chart: {
                height: 550,
                width: "100%",
                type: "line",
                stacked: false,

                dropShadow: {
                    enabled: false,
                    color: "#000",
                    top: 18,
                    left: 7,
                    blur: 10,
                    opacity: 0.2,
                },
                toolbar: {
                    show: false,
                },
            },

            // colors: ["#FFB300", "#145999", "#26B4E8", "#4FC605", "#FF0202", "#000000", "#A4BFD8", "#707070"],
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: 'smooth',
                width: [2, 2, 2, 2, 2],
            },

            grid: {
                row: {
                    colors: ['transparent', 'transparent'],
                    opacity: 0.5
                },
                column: {
                    colors: ['transparent', 'transparent'],
                },
                xaxis: {
                    lines: {
                        show: true
                    }
                }
            },
            markers: {
                size: 0,
            },
            xaxis: {
                categories: [],

            },



        }
    })
    const tableOptions = {
        ...options,

        search: false,
        paging: false,
        rowStyle: { height: 42 },
    };

    const columns = [
        {
            title: 'Client',
            field: 'name',
            width: '70%',
            headerStyle: {
                textAlign: 'left',
                paddingLeft: '20px'
            },
            cellStyle: {
                textAlign: 'left',
                width: '70%',
                paddingLeft: '20px'
            }
        },
        {
            title: 'Last Month',
            field: 'previous_month',
            headerStyle: {
                textAlign: 'center',
            },
            cellStyle: {
                textAlign: 'center',
            }
        },
        {
            title: 'This Month',
            field: 'current_month',
            headerStyle: {
                textAlign: 'center',
            },
            cellStyle: {
                textAlign: 'center',
            }
        },
        {
            title: 'Variance',
            field: 'variance',

            headerStyle: {
                textAlign: 'center',
            },
            cellStyle: {
                textAlign: 'center',
            }
        },


    ];

    const handleBaseClick = (event) => {
        setAnchorClient(event.currentTarget);
        setBaseButton(!baseButton)
    };
    const handleClose = (event) => {
        setAnchorClient(null);
        setBaseButton(!baseButton)
    };
    // console.log("data======", props)

    const getOrganisations = () => {
        DashboardServices.getAllActiveOrganization().then((res) => {
            if (res.data) {
                const organisations = res.data;

                setClientDetails(organisations)
            }
        }).catch(() => {

            // message.showToastMessage({ message: "Something went wrong. Try Again!", variant: "error" })
        })
    }
    useEffect(() => {
        getOrganisations()
    }, [])




    const getUserData = async () => {

        setLoading(true)
        const params = {

            organisation_ids: selectedFilters.join(','),
            ...filterData

        }

        await DashboardServices.getUsersDetails(params).then((response) => {
            setUserData(response.user_reports[0])
            setTotalUser(response.total_avg_user)



            if (response.user_reports[0] !== [] && response.user_reports[0].length > 0) {
                let colors = response.user_reports[0].map((item) => item.primary_color_code)
                setState((prevState) => {
                    const updatedSate = _.clone(prevState)
                    return {
                        ...updatedSate,

                        series: response.user_reports[0].map((item) => ({
                            name: item.mro_id,
                            data: item.data,
                        })),
                        options: {
                            xaxis: {
                                categories: response.user_reports[1].avg_month,
                                labels: {
                                    rotateAlways: true,
                                }
                            },
                            // labels: response.user_reports[0].mro_id,

                            colors: colors,
                            legend: {
                                show: true,
                                position: "bottom",
                                horizontalAlign: "center",

                                offsetY: 8,
                                markers: {
                                    width: [15, 15, 15, 15, 15, 15, 15, 15],
                                    height: [15, 15, 15, 15, 15, 15, 15, 15],
                                    strokeWidth: 1,
                                    strokeColor: '#fff',
                                    borderRadius: '5px',
                                    radius: [2, 2, 2, 2, 2, 2, 2, 2],


                                },


                            },

                        }

                    }


                })
            }

        }).catch(() => {
            message.showToastMessage({ message: "Something went wrong. Try Again!", variant: "error" })
        }).finally(() => {

            setLoading(false)
        })

    }

    useEffect(() => {
        getUserData()

    }, [selectedFilters, filterData])

    const ITEM_HEIGHT = 78;

    //filters

    const filterClientChangeHandler = (e) => {
        const { name, value, checked } = e.target

        setSelectedFilters((prevState) => [...prevState, value])
        if (!checked) {
            setSelectedFilters((current) => _without(current, value));
        }

    }

    const handleDownloadCsv = async () => {
        const params = {
            csv: true,
            organisation_ids: selectedFilters.join(','),
            ...filterData
        }
        await DashboardServices.getUsersDetails(params).then((response) => {
            downloadFileFromUrl(response.file)
        }).catch(() => {
            message.showToastMessage({ message: "Something went wrong. Try Again!", variant: "error" })
        }).finally(() => {

        })

    }

    return (
        <div className={classes.root}>
            <SearchBarWithFilters noSearch padding={'4px 16px'}
            >
                <FormControl>
                    <Button variant={anchorClient ? "contained" : "text"} size="small" aria-haspopup="true" onClick={handleBaseClick} endIcon={baseButton ? <ArrowDropUp /> : <ArrowDown />}
                        style={{ fontSize: "14px", backgroundColor: anchorClient ? '#efefef' : "", color: 'black' }}
                    >
                        {selectedFilters.length > 0 ? `Client(${selectedFilters.length})` : 'Client(All)'}

                    </Button>
                    <Menu
                        id="Client"
                        anchorEl={anchorClient}
                        keepMounted
                        open={anchorClient}
                        onClose={handleClose}
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                        transformOrigin={{ vertical: "top", horizontal: "left" }}
                        style={{ marginTop: '41px' }}
                        TransitionProps={false}
                        PaperProps={{
                            style: {
                                maxHeight: ITEM_HEIGHT * 4.2,
                                width: '40ch',
                                position: 'fixed',
                                top: '237px',
                                transition: 'none',


                            },
                        }}
                    >
                        {/* <MenuItem value="" style={{ marginLeft: '7px' }}>Customer(All)</MenuItem> */}
                        {clientDetails?.map((option, index) => {
                            return <Box>
                                <Grid container spacing={2}>
                                    <Grid item xs={1}></Grid>
                                    <Grid item xs={9} style={{
                                        display: "flex", alignItems: "center", fontSize: "14px",

                                    }}>
                                        <label>{option.company_name}</label>
                                    </Grid>
                                    <Grid item xs={2} style={{ marginLeft: '-23px' }}>
                                        <FormControlLabel

                                            control={<PositiveSwitch
                                                checked={checkedCustState[index]}
                                                value={option.organisation_id}
                                                onChange={(e) => { filterClientChangeHandler(e) }}
                                                name={option.company_name} />}
                                        />
                                    </Grid>

                                </Grid>
                                <Grid><Divider variant="middle" /></Grid>
                            </Box>
                        })}
                    </Menu>
                </FormControl>

                <FormControl className={"filter"} style={{ paddingLeft: "17px" }}>
                    <Select
                        onChange={(e) => {
                            setFilterData((prevState) => ({ ...prevState, months: e.target.value }))
                        }}
                        defaultValue={12}

                        input={<InputBase />} >
                        <MenuItem value={12} >12-Months</MenuItem>
                        <MenuItem value={24}>24-Months</MenuItem>
                        <MenuItem value={36}> 36-Months</MenuItem>
                    </Select>
                </FormControl>

                <FormControl style={{ float: 'right', marginTop: '-7px', paddingRight: '30px' }}>

                    <Box
                        minWidth="250px"
                        bgcolor="#fff"
                        height="40px"
                        border="0.5px solid #A2A2A2"
                        borderLeft="3px solid #0EC3F7"
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        paddingX="17px"
                    >
                        <Typography variant="body1" className={classes.title} style={{ paddingRight: '5px', }}>
                            Average Users
                        </Typography>
                        <Typography
                            variant="body1"
                            className={classes.title}
                            style={{
                                color: "#0EC3F7",
                                paddingLeft: "22px",
                                borderLeft: ".5px solid #BCBCBC",
                                fontSize: '17px'
                            }}
                        >
                            {totalUser}
                        </Typography>
                    </Box>

                </FormControl>
            </SearchBarWithFilters>
            <Paper style={{ width: "100%", padding: "30px 15px", height: '560px' }}>
                <Box width="100%" display="flex" justifyContent="space-between">
                    <Typography color="primary" variant="h6" style={{ fontSize: '14px', fontWeight: 600 }}>
                        Max Active Users
                    </Typography>

                    <IconButton style={{ marginTop: '-3px', paddingRight: '36px' }} onClick={handleDownloadCsv} >
                        <CloudDownloadIcon />
                    </IconButton>

                </Box>


                <Box width="100%" display="flex" >
                    <Box width="98%">

                        <ReactApexChart
                            options={state.options}
                            series={state.series}
                            type="line"
                            height={450}
                        />
                    </Box>
                </Box>


            </Paper>
            <br />
            <Box>
                <MaterialTable
                    style={{ boxShadow: "0px 1px 3px #00000033", paddingBottom: "48px" }}
                    icons={tableIcons}
                    title={""}
                    columns={columns}
                    data={userData}
                    isLoading={loading}
                    options={tableOptions}
                />

            </Box>

        </div>
    )
}

