import Adapter from "@date-io/luxon";
import { FormControl, IconButton, InputAdornment, InputBase, MenuItem, Select, TextField, Tooltip } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { Clear as ClearIcon, DateRangeRounded } from '@material-ui/icons';
import { uniq } from 'lodash';
import { Settings } from "luxon";
import moment from "moment";
import React, { useEffect, useState } from 'react';
import { Switch } from 'react-router-dom';
import { DatePicker } from 'antd';
import SearchBarWithFilters from '../../../components/search_with_filters';

import TableIssueReporting from './Issue_list';
import 'antd/dist/antd.css';
import { IssueReportingService } from "../../../lib/services/api/issue_reporting/issue_report";
Settings.defaultLocale = "en";
const { RangePicker } = DatePicker;

export const category = ['issue', 'development']
export const level = [
    { type: 'issue', value: 'less_severe' }, { type: 'issue', value: 'moderate' }, { type: 'issue', value: 'more_severe' },
    { type: 'development', value: 'level_1' }, { type: 'development', value: 'level_2' }, { type: 'development', value: 'level_3' },
]
const status = ['open', 'on_hold', 'completed']

const useStyles = makeStyles(theme => ({
    tableWrapper: {
        width: '94%',
        margin: '3%',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}))


const IssueReporting = (props) => {

    const classes = useStyles();
    const [searchText, setSearchText] = useState("");
    const [data, setData] = useState([]);
    const [_data, _setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filterState, setFilterState] = useState({ organisation: '', level: '', category: '', status: '' })
    const [value, setValue] = React.useState([null, null]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [levels, setLevel] = useState(level);
    const [categories, setCategory] = useState(category);

    const [locale, setLocale] = React.useState(Settings.defaultLocale);

    useEffect(() => {
        getIssueList()
    }, [])

    const getIssueList = () => {
        IssueReportingService.getIssueReports().then(response => {
            const reports = response.issue_reports;

            setData(response.issue_reports);
            _setData(response.issue_reports);
        })
    }
    const isObjectEmpty = (obj) => {
        return Object.values(obj).every(value => (value === null || value === ''))
    }

    const handleFilterChange = target => e => {

        const val = e.target.value;

        setLoading(true);

        setTimeout(function (target, val) {

            setLoading(false);

            if (!val) {
                return emptyFilterState()
            }
            if (target == 'category') {
                setLevel(level.filter(lev => lev.type === val))
            }
            filterData(target, val)

        }, 400, target, val)
    }

    const emptyFilterState = () => {
        setFilterState({
            category: '',
            organisation: '',
            level: '',
            status: '',
            // target: ''
        })
    }

    const filterData = (target, val) => {

        switch (target) {
            case 'organisation':
                return setFilterState({
                    ...filterState,
                    organisation: val
                })
            case 'level':
                return setFilterState({
                    ...filterState,
                    level: val
                })
            case 'category':
                return setFilterState({
                    ...filterState,
                    category: val
                })
            case 'status':
                return setFilterState({
                    ...filterState,
                    status: val
                })
            case 'date':
                return setFilterState({
                    ...filterState,
                    ...val
                })
            case 'search':
                return setFilterState({
                    ...filterState,
                    search_keyword: val
                })
            default:
                break;
        }
    }


    const handleDateChange = value => {
        setValue(value)
        if (value) {
            setStartDate(value[0])
            setEndDate(value[1])
        }
    }

    const handleClearFilter = () => {
        setValue(null);
        setStartDate(null);
        setEndDate(null);
        emptyFilterState();
        getIssueList();
    }

    useEffect(() => {
        if (startDate && endDate) {
            filterData('date', {
                start_date: moment(startDate).format('DD-MM-YYYY'),
                end_date: moment(endDate).format('DD-MM-YYYY')
            })
        }
    }, [startDate, endDate])

    useEffect(() => {
        filterData('search', searchText)
    }, [searchText, setSearchText])

    useEffect(() => {

        setLevel([])

    }, [])

    return (
        <div className={classes.tableWrapper}>
            <SearchBarWithFilters handleSearch={setSearchText} searchText={searchText}>
                <FormControl className="filter">
                    <RangePicker format="DD/MM/YYYY"
                        value={value}
                        onChange={handleDateChange}
                        allowClear={false}
                    />
                </FormControl>

                <FormControl size="medium" className={"filter"}>
                    <Select
                        fullWidth
                        displayEmpty
                        onChange={handleFilterChange('category')}
                        value={filterState.category ? filterState.category : ''}
                        defaultValue={''}
                        input={<InputBase />}
                    >
                        <MenuItem value={''}>
                            Category
                        </MenuItem>
                        {category && category.map((category, i) => <MenuItem key={i} value={category}>
                            {category}
                        </MenuItem>)}
                    </Select>
                </FormControl>

                <FormControl size="medium" className={"filter"}>
                    <Select
                        fullWidth
                        displayEmpty
                        onChange={handleFilterChange('level')}
                        value={filterState['level'] ? filterState['level'] : ''}
                        input={<InputBase />}
                    >
                        <MenuItem value={''} >
                            Level
                        </MenuItem>
                        {levels && levels.map((level, i) => <MenuItem key={i} value={level.value}>
                            {level.value}
                        </MenuItem>)}
                    </Select>
                </FormControl>

                <FormControl size="medium" className={"filter"}>
                    <Select
                        fullWidth
                        defaultValue={undefined}
                        displayEmpty
                        onChange={handleFilterChange('status')}
                        value={filterState['status'] ? filterState['status'] : ''}
                        input={<InputBase />}
                    >
                        <MenuItem value={''} >
                            Status
                        </MenuItem>
                        {status && status.map((status, i) => <MenuItem key={i} value={status}>
                            {status}
                        </MenuItem>)}
                    </Select>
                </FormControl>
                {
                    !isObjectEmpty(filterState) && <FormControl>
                        <IconButton onClick={handleClearFilter} size="medium" >
                            <Tooltip title="Cleat Filters"><ClearIcon fontSize="small" /></Tooltip>
                        </IconButton>
                    </FormControl>
                }
            </SearchBarWithFilters>
            <Switch>
                <TableIssueReporting isLoading={loading} data={_data} filterState={filterState} />
            </Switch>
        </div>
    )
}

export default IssueReporting
