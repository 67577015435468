import { Button, IconButton, Menu, MenuItem } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import clxc from 'clsx';
import { capitalize } from 'lodash';
import MaterialTable from 'material-table';
import * as moment from 'moment';
import React, { useEffect, useState } from 'react';
import tableIcons, { options } from '../../../components/Universal/table_attributes';
import { IssueReportingService } from '../../../lib/services/api/issue_reporting/issue_report';
import ManageIssue from './manage_issue';
import { truncate } from 'lodash';

const useStyle = makeStyles((theme) => ({
    button: {
        padding: theme.spacing(0.25)
    },

}))

const ITEM_HEIGHT = 48;
const options1 = [
    'View item',
    'Edit item',
];

const TableIssueReporting = (props) => {
    const classes = useStyle();
    const [issueList, setIssueList] = useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [loading, setLoading] = useState(false);
    const [action, setAction] = useState(null);
    const [currentSelection, setCurrentSelection] = useState({});


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
        setCurrentSelection({})
    };

    useEffect(() => {
        IssueList();
    }, [props.filterState]);

    const IssueList = () => {
        setLoading(true)
        IssueReportingService.getIssueReports(props.filterState).then(response => {
            setIssueList(response.issue_reports)
            setLoading(false)
        })
    }

    const statusMap = {
        Overdue: 'button-negative',
        Neardue: 'button-warning',
        Open: 'button-negative',
        Completed: 'button-positive',
        On_hold: 'button-warning'
    }


    const columns = [

        {
            title: '',
            field: 'id',
            width: '4%',
            render: rowData => <span>&nbsp;</span>
        },
        {
            title: 'SI#',
            field: 'issue_number',
            headerStyle: {
                textAlign: 'left'
            },
            cellStyle: {
                textAlign: 'left',
                padding: '15px 0'
            }
        },
        {
            title: 'Date/Time',
            field: 'date_created',
            headerStyle: {
                textAlign: 'left'
            },
            cellStyle: {
                textAlign: 'left',
                padding: '15px 0'
            },
            render: rowData => moment(rowData.date_created, "DD-MM-YYYY").format('DD/MM/YYYY')
        },
        {
            title: 'Organisation',
            field: 'organisation',
            width: '15%',
            headerStyle: {
                textAlign: 'center'
            },
            cellStyle: {
                textAlign: 'center',
                padding: '15px 0'
            }
        },
        {
            title: 'User',
            field: 'user',
            headerStyle: {
                textAlign: 'center'
            },
            cellStyle: {
                textAlign: 'center',
                padding: '15px 0'
            }
        },
        {
            title: 'Base',
            field: 'base',
            headerStyle: {
                textAlign: 'center'
            },
            cellStyle: {
                textAlign: 'center',
                padding: '15px 0'
            }
        },
        {
            title: 'Subject',
            field: 'subject',
            headerStyle: {
                textAlign: 'center'
            },
            cellStyle: {
                textAlign: 'center',
                padding: '15px 0'
            },
            render: data => truncate(data.subject, { length: 10 })
        },
        {
            title: 'Category',
            field: 'category',
            headerStyle: {
                textAlign: 'center'
            },
            cellStyle: {
                textAlign: 'center',
                padding: '15px 0'
            }
        },
        {
            title: 'Level',
            field: 'level',
            headerStyle: {
                textAlign: 'center'
            },
            cellStyle: {
                textAlign: 'center',
                padding: '15px 0'
            }
        },
        {
            title: 'Status',
            field: 'status',
            headerStyle: {
                textAlign: 'left'
            },
            cellStyle: {
                textAlign: 'center',
                padding: '15px 0'
            },
            render: rowData => <Button size="small" className={clxc(classes.button, statusMap[capitalize(rowData.status)])} variant="outlined"><b>{capitalize(rowData.status)}</b></Button>
        },
        {
            title: 'Date Completed',
            field: 'date_completed',
            headerStyle: {
                textAlign: 'center'
            },
            cellStyle: {
                textAlign: 'center',
                padding: '15px 0'
            },
            render: rowData => rowData.date_completed ? moment(rowData.date_completed, "DD-MM-YYYY").format('DD/MM/YYYY') : '-'
        },
        {
            title: 'Open Time',
            field: 'open_time',
            headerStyle: {
                textAlign: 'center'
            },
            cellStyle: {
                textAlign: 'center',
                padding: '15px 0'
            }
        },
        {
            title: 'Actions',
            field: 'id',
            render: rowData => <div id={`div-${rowData.id}`}>
                <IconButton
                    size="small"
                    aria-label="more"
                    aria-controls={`long-menu-${rowData.id}`}
                    aria-haspopup="true"
                    onClick={(e) => { setCurrentSelection(rowData); handleClick(e) }}
                >
                    <MoreVertIcon fontSize={'12px'} />
                </IconButton>
            </div>
        },
    ]



    const handleOption = (option) => {
        //setDropDownOption(option)
        if (option === 'View item') {
            setAction('view')
        }
        if (option === 'Edit item') {
            setAction('update')
        }
        if (option === 'Delete item') {
            setAction('delete')
        }
    }

    const handleClose = (data, id) => {
        let basesList = [];
        if (action === 'create' && data && data.id) {
            basesList = [data].concat(issueList);
            setIssueList(basesList);
        }
        else if (data && data.id) {
            basesList = issueList.map(base => {
                if (base.id === data.id) {
                    return data;
                }
                return base;
            })
            setIssueList(basesList);
        }
        else if (!data && id) {
            basesList = issueList.filter(base => base.id !== id);
            setIssueList(basesList);
        }
        setAction(null);
        setCurrentSelection({});
    }

    useEffect(() => {
        if (!!props.data.length) {
            return setIssueList(props.data)
        }
    }, [])
    return (
        <>

            <MaterialTable
                isLoading={props.isLoading ? props.loading : loading}
                title="Issue Reporting"
                style={{ boxShadow: '0px 1px 3px #00000033', width: '100%', fontSize: '0.8rem' }}
                data={issueList}
                columns={columns}
                options={{
                    ...options,
                    search: false
                }}
                icons={tableIcons}
                localization={{
                    body: {
                        emptyDataSourceMessage: 'No Issues Found',
                    },
                    header: {
                        actions: ""
                    }
                }}
            />

            {action && <ManageIssue handleCloseMenu={handleCloseMenu} IssueList={IssueList} action={action} data={currentSelection} handleClose={handleClose} />}
            <Menu
                id={`long-menu`}
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleCloseMenu}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '15ch',
                    },
                }}
            >
                {
                    options1.map((option) => (
                        <MenuItem key={option} style={{ fontSize: '12px' }} onClick={() => { handleOption(option); }}>
                            {option}
                        </MenuItem>))
                }
            </Menu>
        </>
    )
}



export default React.memo(TableIssueReporting);