import React, { useState, useEffect, useContext } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MROFullScreenRightDrawer from "../../../components/fullwidth_dialog";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import BackIcon from '@material-ui/icons/KeyboardBackspace';
import Grid from "@material-ui/core/Grid";
import { ToastMessageContext } from "../../../lib/contexts/message_context";
import MROTextField from "../../../components/form_components/TextField";
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import MROButton from "../../../components/buttons";
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CircularProgress from "@material-ui/core/CircularProgress";
import StationQuestionService from '../../../lib/services/api/station_questions_services';
import StationService from '../../../lib/services/api/station_service';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import { Paper } from '@material-ui/core';
import ManageQuestions from "./manage_station_questions"
import { SaveAltOutlined, SaveRounded, PhotoCamera } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
    content: {
        width: "calc(100% - 250px)"
    },
    appBar: {
        backgroundColor: "#fff",
        color: '#000000CC',
        boxShadow: "0px 3px 6px #0000001A",
    },
    backButton: {
        marginRight: theme.spacing(2),
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    wrapper: {
        margin: '3%',
        padding: '3%',
        background: '#fff',
        boxShadow: "0px 1px 4px #0000001A",
        '& form': {
            paddingTop: '25px',
            width: '85%',
            '& .MuiGrid-spacing-xs-5': {
                '& .MuiGrid-item': {
                    paddingBottom: '0'
                }
            }
        },
        '& .align-left-space-between': {
            paddingBottom: theme.spacing(1)
        },
        '& .MuiTypography-root': {
            fontSize: '14px'
        }
    },
    actionButtons: {
        paddingTop: '20px',
        paddingBottom: 30,
        textAlign: 'right',
        '& button': {
            marginRight: '16%'
        }
    },
    paperWidth: {
        margin: '50px !important',
        width: '80%',
    },
    AppBarWidth: {
        backgroundColor: '#70707033',
        color: '#000000CC',
        padding: '2%'
    },
    dialogBg: {
        backgroundColor: '#F7F8FA'

    },
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    buttonpadding: {
        margin: '23px'
    },
    disableHover: {
        fontWeight: 'bold'
    },
    alignCenter: {
        display: 'flex',
        alignItems: 'center',
    },
    center: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}));
const DarkerDisabledTextField = withStyles({
    root: {
        marginRight: 8,
        "& .MuiInputBase-root.Mui-disabled": {
            color: "rgba(0, 0, 0, 1.6)" // (default alpha is 0.38)
        }
    }
})(MROTextField);
function VechicleReport(props) {
    const classes = useStyles();
    const message = useContext(ToastMessageContext);
    const [loading, setLoading] = useState();
    const [listVehicle, setListing] = useState({});
    const { handleClose, data, StationList } = props;
    const [action, setAction] = useState('');
    const [currentSelection, setCurrentSelection] = useState({});

    useEffect(() => {
        setLoading(true);
        VehicleList()
    }, []);
    const VehicleList = () => {
        setLoading(true);
        setListing([]);
        StationQuestionService.getStationQuestions(data.id).then((res) => {
            if (res.data) {
                const listVehicle = res.data;
                console.log('res.data', res.data)
                setListing(listVehicle)
            }
            setLoading(false);
        }).catch(() => {
            setLoading(false);
            message.showToastMessage({ message: "Something went wrong. Try Again!", variant: "error" })
        })
    }
    const handleVechicleClose = (sttaionData, id) => {
        let basesList = [];
        if (action === 'create' && sttaionData && sttaionData.id) {
            basesList = [sttaionData].concat(listVehicle);
            setListing(basesList);
        }
        else if (sttaionData && sttaionData.station_check_id) {
            basesList = listVehicle.map(base => {
                if (base.station_check_id === sttaionData.station_check_id) {
                    return sttaionData;
                }
                return base;
            })
            setListing(basesList);
        }
        else if (!sttaionData && id) {
            basesList = listVehicle.filter(base => base.station_check_id !== id);
            setListing(basesList);
        }
        setAction(null);
        setCurrentSelection({});
    }

    const [stationName, setStaionName] = useState(data.title);
    const [stationNumber, setStationNumber] = useState(data.number);
    const [editName, setEditName] = useState(false);
    const handleEditStationName = () => {
        setEditName(true)
    }
    const updateStationName = () => {
        StationService.updateStationList({
            id: data.id,
            title: stationName,
            number: stationNumber
        }).then(response => {
            StationList()
            handleClose()
        })
    }

    return (
        <React.Fragment>
            <MROFullScreenRightDrawer>
                <AppBar position="static" className={classes.appBar} elevation={0}>
                    <Toolbar>
                        <IconButton onClick={handleClose} edge="start" className={classes.backButton} color="inherit" aria-label="back">
                            <BackIcon />
                        </IconButton>
                        <Typography variant="subtitle2" className={classes.title}>
                            Station Report Editor - Global Template Edit
                    </Typography>

                    </Toolbar>
                    <Typography variant="subtitle1" color="secondary" style={{ paddingLeft: '76px' }} className={classes.title}>
                        {
                            !editName && <>
                                {data.title} &nbsp; &nbsp;
                                <IconButton onClick={handleEditStationName}>
                                    <EditIcon />
                                </IconButton>
                            </>
                        }
                        {
                            editName && <Grid container spacing={3} className={classes.alignCenter}>
                                <Grid item xs={3} className={classes.alignCenter}>
                                    <MROTextField varainat="outlined" className={classes.title}
                                        value={stationName} onChange={e => setStaionName(e.target.value)} />
                                </Grid>
                                <Grid item xs={1.5} className={classes.alignCenter}>
                                    <MROTextField  style={{width:"120px"}} type="number" varainat="outlined" className={classes.title}
                                        value={stationNumber} onChange={e => setStationNumber(e.target.value)} />
                                </Grid>
                                <Grid item xs={1} className={classes.alignCenter}>
                                    <IconButton onClick={updateStationName} title="Save" type="button" color="primary">
                                        <SaveRounded fontSize="" />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        }
                    </Typography>
                </AppBar>
                {!loading ? <React.Fragment> <Paper className={classes.paperWidth}>
                    <AppBar position="static" className={classes.AppBarWidth} elevation={0}>
                        <Typography variant="subtitle2" className={classes.title}>
                            Station Checks
                    </Typography>
                    </AppBar>
                    {/* <Grid container style={{ padding:'3%'}} spacing={2}> */}
                    {/* <Grid item md={6}>
                <Typography variant="subtitle2" className={classes.title}>
                       Questions
                    </Typography>
                    </Grid>
                    <Grid item md={3}>
                <Typography variant="subtitle2" className={classes.title}>
                        Field Type
                    </Typography>
                    </Grid> */}
                    {/* {(listVehicle && !loading )? listVehicle.map(row=>(
                        console.log('row', row)
                    )):<div className={"center"} style={{minHeight:'200px'}}>
                        <CircularProgress color="primary"/>
                    </div>} */}
                    {/* </Grid> */}
                    <Grid container style={{ padding: '3%' }} spacing={2}>
                        {!!listVehicle?.length && <Grid item md={6}>
                            <Typography variant="subtitle2" className={classes.title}>
                                Questions
                    </Typography>
                        </Grid>}
                        {!!listVehicle?.length && <Grid item md={3}>
                            <Typography variant="subtitle2" className={classes.title}>
                                Field Type
                    </Typography>
                        </Grid>}
                        {!loading ? !!listVehicle?.length && listVehicle?.map(row => (<React.Fragment>
                            <Grid item md={6}>
                                {/* <Paper component="form" className={classes.root}>
                        <InputBase
                            className={classes.input}
                        />
                        <IconButton type="submit" className={classes.iconButton} aria-label="search">
                            <EditIcon />
                        </IconButton>
                        <IconButton color="primary" className={classes.iconButton} aria-label="directions">
                            <DeleteIcon />
                        </IconButton>
                        </Paper> */}
                                <DarkerDisabledTextField
                                    className={classes.margin}
                                    disabled
                                    required={row.mandatory}
                                    label={"Question"}
                                    id="input-with-icon-textfield"
                                    defaultValue={row.question}
                                    InputProps={{
                                        endAdornment: (
                                            // <InputAdornment position="end">
                                            // <DeleteIcon />
                                            // </InputAdornment>
                                            <React.Fragment><IconButton onClick={() => { setAction('update'); console.log('row', row); setCurrentSelection(row) }}
                                                type="submit" className={classes.iconButton} aria-label="search">
                                                <EditIcon />
                                            </IconButton>
                                                <IconButton color="primary" onClick={() => { setAction('delete'); console.log('row', row); setCurrentSelection(row) }} className={classes.iconButton} aria-label="directions">
                                                    <DeleteIcon />
                                                </IconButton></React.Fragment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <DarkerDisabledTextField
                                    name={"field_type"}
                                    label={"Field Type"}
                                    disabled
                                    InputLabelProps={{ shrink: true }}
                                    defaultValue={row.field_type}
                                />
                            </Grid></React.Fragment>)) : ''}
                    </Grid>
                    <MROButton style={{ margin: '30px' }} variant={"contained"} color="secondary" startIcon={<AddIcon />} onClick={() => { setAction("create") }}>Add a question</MROButton>
                </Paper><div className={classes.actionButtons}>
                        <MROButton type="button" variant={"contained"} color="primary" style={{ backgroundColor: '', color: 'white' }} onClick={handleClose}>Done</MROButton></div></React.Fragment> : <div className={"center"} style={{ minHeight: '200px' }}>
                        <CircularProgress color="primary" />
                    </div>}
            </MROFullScreenRightDrawer>
            {action && <ManageQuestions VehicleList={VehicleList} listingData={data} setLoading={setLoading} action={action} currentSelection={currentSelection} handleVechicleClose={handleVechicleClose} />}
        </React.Fragment>
    )
}

export default VechicleReport;