import React from "react";
import Drawer from '@material-ui/core/Drawer';
import withStyles from "@material-ui/core/styles/withStyles";


const MROFullScreenRightDrawer = withStyles(theme => ({
    paperAnchorRight: {
        width: "calc(100% - 250px)",
        backgroundColor: "#F7F8FA"
    },
    modal:{
        left:'250px !important',
        '& .MuiBackdrop-root':{
            left:'250px'
        }
    }
}))((props) => <Drawer transitionDuration={800} elevation={0} anchor={"right"} open={true} {...props}>{props.children}</Drawer>)

export default MROFullScreenRightDrawer;