import React, { useState, useEffect } from "react";
import { makeStyles, Divider, createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import Typography from "@material-ui/core/Typography/Typography";
import Grid from "@material-ui/core/Grid/Grid";
import MaterialTable from "material-table";
import tableIcons, { options } from "../../components/Table/icons";
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import EditIcon from '@material-ui/icons/Edit';
import ViewIcon from '@material-ui/icons/Visibility';
import StatusDot from "../../components/Universal/status_dots";
import NewOrganisation from "./new_organisation";
import UpdateOrganisation from "./update_organisation";
import FormControl from "@material-ui/core/FormControl/FormControl";
import OrganisationApiClient from "../../lib/services/api/organisation_details";
import useDebounce from './debounce';
import UserDetails from "./view_organisation_details";
import { theme } from "../../App";

const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: '5%'
    },
    tableWrapper: {
        padding: '3%'
    },
    title: {
        fontSize: theme.spacing(3),
        fontWeight: 700,
        padding: theme.spacing(2)
    },
    subTitle: {
        fontSize: theme.spacing(2),
        fontWeight: 500,
    },
    paper: {
        boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
        minHeight: '200px'
    },
    search: {
        padding: '2%'
    },
    addNewButton: {
        float: 'right'
    }
}));


const iconColor = "#70707099";
export default function MyClients(props) {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [updateOrganisation, setUpdateOrganisation] = useState(false);
    const [search, setSearch] = useState(false);
    const [status, setStatus] = useState(false);
    const [pageSize, setPageSize] = useState(20);
    const [currentSelection, setCurrentSelection] = useState({});
    const [page, setPage] = useState(0);
    const [currentLocation, setCurrentLocation] = useState('');
    const [currentStatus, setCurrentStatus] = useState("");
    const [searchText, setSearchText] = useState('');
    const [organisations, setOrgansiations] = useState([]);
    const [frontEndCategory, setFrontEndCategory] = useState([]);
    const [locations, setLocations] = useState({});
    const [totalCount, setTotalCount] = useState(10);
    const [action, setAction] = useState('');
    const [updateAction, setUpdateAction] = useState('');
    const debouncedTitle = useDebounce(searchText.title, 1000);

    const tableOptions = {
        ...options,
        page: page,
        total: totalCount,
        pageSize: pageSize,
        search: false,
        actionsColumnIndex: 4,
    };
    const columns = [
        {
            title: 'Org ID',
            field: 'id',
        },
        {
            title: 'MRO ID',
            field: 'mro_id',
            width: '20%',
            headerStyle: {
                textAlign: 'left'
            },
            cellStyle: {
                textAlign: 'left',
            }
        },
        {
            title: 'Company Name',
            field: 'company_name',
            width: '30%',
            headerStyle: {
                textAlign: 'left'
            },
            cellStyle: {
                textAlign: 'left',
            }
        },
        {
            title: 'Location',
            field: 'country', width: '50%',
            headerStyle: {
                textAlign: 'left'
            },
            cellStyle: {
                textAlign: 'left',
            }
        },
        {
            title: 'Status',
            field: 'status',
            render: rowData => <div className="center"><StatusDot variant={rowData.status ? "positive" : "negative"} /></div>
        },
        {
            title: 'Organisation Login',
            cellStyle: { textAlign: 'center' }, width: '20%',
            headerStyle: {
                textAlign: 'center'
            },
            render: rowData => <Button variant="contained" color="secondary" onClick={() => loginOrg(rowData)}>Login</Button>
        },

    ];
    const loginOrg = (rowData) => {
        const authorization_res = JSON.parse(sessionStorage.getItem('authorization_headers'));
        var queryParam = "?access-token=" + authorization_res['access-token'] + "&client=" + authorization_res.client + "&uid=" + authorization_res.uid + "&orgId=" + rowData.id
        window.open(`${process.env.REACT_APP_WEB_REDIRECT_URL}/auth/signin` + queryParam, "_blank");
    }
    const organisationList = () => {
        setLoading(true);
        const params = {
            count_per_page: pageSize,
            page: page + 1,
            search_keyword: searchText || null,
            location: currentLocation || null,
            status: currentStatus || null,
        }
        OrganisationApiClient.getAllOrganisations(params).then((res) => {
            if (res.organisations) {
                let organisations = res['organisations'];
                let locations = res['locations']
                let status = res['status']
                setOrgansiations(organisations);
                setLocations(locations);
                setStatus(status)
                setTotalCount(res.total_count);
            }
            else {
                setOrgansiations([]);
            }
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        });
        //     OrganisationApiClient.getData(`/organisations?location=${currentLocation || null}&status=${currentStatus || null}&search_keyword=${searchText || null}&count_per_page=${pageSize}&page=${page+1}`).then((res)=>{
        //     if(res.organisations){
        //         let organisations=res['organisations'];
        //         let locations =res['locations']
        //         let status =res['status']
        //         setOrgansiations(organisations);
        //         setLocations(locations);
        //         setStatus(status)
        //         setTotalCount(res.total_count);
        //     }setLoading(false);
        // }).catch(()=>{
        //     // message.showToastMessage({open:true,message: "Something went wrong. Try again!",variant:"error"});
        //     setLoading(false);
        // })
    }
    const handleChange = (e) => {
        const val = e.target.value;
        setCurrentLocation(val);
        setLoading(true);
        setSearch(true)
        setTimeout(() => {
            setLoading(false);
        }, 1000)
        if (val === '') {
            setSearch(false)
        }
    }
    const handleStatusChange = (e) => {
        const val = e.target.value;
        setLoading(true);
        setCurrentStatus(val);
        setSearch(true)
        setTimeout(() => {
            setLoading(false);
        }, 500)
        if (val === '') {
            setSearch(false)
        }
    };
    const handleSearch = (e) => {
        const val = e.target.value;
        setLoading(true);
        setSearchText(val);
        setSearch(true)
        setTimeout(() => {
            setLoading(false);
        }, 500)
        if (val === '') {
            setSearch(false)
        }
    };
    useEffect(() => {
        setLoading(true);
        organisationList()
    }, [currentLocation, currentStatus, page, searchText, pageSize, search, debouncedTitle]);
    const handleClose = (data, showView) => {
        let currentUsers = organisations;
        if (action === 'create' && data && data.id) {
            currentUsers.unshift(data);
        }
        else if ((action === 'edit' || action === 'view_edit') && data && data.id) {
            let index = currentUsers.findIndex(usr => usr.id === data.id);
            if (index !== -1) {
                currentUsers[index] = data;
            }
        }
        if (!showView) {
            setCurrentSelection({});
        }
        setOrgansiations(currentUsers);
        setUpdateAction(showView ? 'view' : null);
    }
    console.log('currentStatus', currentStatus)

    const _theme = createMuiTheme({
        ...theme,
        overrides: {
            ...theme.overrides,
            MuiTableCell: {
                ...theme.overrides.MuiTableCell,
                root: {
                    ...theme.overrides.MuiTableCell.root,
                    paddingLeft: 20
                }
            }
        }
    });
    return (
        <div className={classes.wrapper}>
            <Button className={classes.addNewButton} component={"span"} startIcon={<AddIcon />} color="secondary" variant="contained" onClick={() => { setAction("create") }}> Setup a New Organisation</Button>
            <Grid container spacing={4}>
                <Grid item md={12}>
                    <Paper component="form" elevation={1}>
                        <IconButton className={classes.iconButton} >
                            <SearchIcon />
                        </IconButton>
                        <InputBase
                            className={classes.input}
                            placeholder="Search anything"
                            onChange={handleSearch}
                        />
                        <Divider />
                        <div className={classes.search}><Typography color="Secondary" variant="h2" component="h2" className={classes.subTitle}>
                            Filters
                        </Typography>
                            <FormControl className={"filter"}>
                                <Select
                                    defaultValue={undefined}
                                    displayEmpty
                                    onChange={handleChange}
                                    input={<InputBase />}
                                >
                                    <MenuItem value={undefined} >
                                        Location (All)
                                    </MenuItem>
                                    {locations && locations[0] && locations.map(base => <MenuItem key={base} value={base}>
                                        {base}
                                    </MenuItem>)}
                                </Select>
                            </FormControl>
                            &nbsp;&nbsp;&nbsp;
                            <FormControl className={"filter"}>
                                <Select
                                    defaultValue={undefined}
                                    displayEmpty
                                    onChange={handleStatusChange}
                                    input={<InputBase />}
                                >
                                    <MenuItem value={undefined} >
                                        Status (All)
                                    </MenuItem>
                                    <MenuItem value={'true'}>
                                        Active
                                    </MenuItem>
                                    <MenuItem value={'false'}>
                                        In-active
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </Paper>
                </Grid>
            </Grid>
            <Grid container spacing={4}>
                <Grid item md={12}>
                    <MuiThemeProvider theme={_theme}>
                        <MaterialTable
                            style={{ boxShadow: '0px 1px 3px #00000033' }}
                            icons={tableIcons}
                            title={""}
                            columns={columns}
                            data={organisations}
                            isLoading={loading}
                            options={tableOptions}
                            localization={{
                                toolbar: {
                                    searchPlaceholder: 'Search anything'
                                },
                                body: {
                                    emptyDataSourceMessage: 'No Organisation Avaliable',
                                    filterRow: {
                                        filterTooltip: 'Filter'
                                    },
                                },
                                header: {
                                    actions: "Actions"
                                }
                            }}
                            actions={[
                                // {
                                //     icon: () => <ViewIcon fontSize="small" htmlColor={iconColor} />,
                                //     tooltip: 'View',
                                //     onClick: (event, rowData) => {
                                //         setCurrentSelection(rowData);
                                //         setUpdateAction('view')
                                //     }
                                // },
                                {
                                    icon: () => <EditIcon fontSize="small" htmlColor={iconColor} />,
                                    tooltip: 'Edit',
                                    onClick: (event, rowData) => {
                                        setCurrentSelection(rowData);
                                        setUpdateAction('update')
                                    }
                                },
                            ]}
                            totalCount={totalCount}
                            onRowClick={(e, rowData) => {
                                setCurrentSelection(rowData);
                            }}
                            onChangePage={(page) => {
                                setPage(page);
                            }}
                            onChangeRowsPerPage={(pageSize) => {
                                setPageSize(pageSize);
                            }}
                            // onSearchChange={(search)=>{
                            //     setSearchText(search);
                            // }}
                            page={page}
                        />
                    </MuiThemeProvider>
                </Grid>
            </Grid>
            {action === 'create' && <NewOrganisation organisationList={organisationList} frontEndCategory={frontEndCategory} setLoading={setLoading} open={showModal} onClose={setShowModal} action={action} handleClose={() => setAction(null)} />}
            {updateAction && updateAction !== 'view' && <UpdateOrganisation organisationList={organisationList} frontEndCategory={frontEndCategory} data={currentSelection} setLoading={setLoading} open={updateOrganisation} handleClose={() => setUpdateAction(null)} onClose={setUpdateOrganisation} />}
            {updateAction === 'view' && <UserDetails data={currentSelection} action={action} handleClose={handleClose} handleEdit={() => setUpdateAction('view_edit')} />}
        </div>
    )
}