import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';

import LineReportClient from "../../lib/services/api/line_report";
import MROTextField from '../../components/form_components/TextField';
import MROButton from '../../components/buttons';

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
  },
}));
export default function AddSubSection(props) {
  const classes = useStyles();
  const [open, setOpen] = [props.open, props.onClose];
  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let formData = new FormData(e.target);
    formData.append('section_id', props.selectedIndex)
    LineReportClient.create(formData, '/line_reports').then((res) => {
      if (res.sub_section) {
        // message.showToastMessage({open:true,message:"Supplier successfully updated",variant:"success"});
        handleClose(res.sub_section);
        props.sectionList(null)

      }
    }).catch(() => {
    })
  }
  return (
    <React.Fragment>
      <div> <Dialog
        open={open}
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add Sub Section</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <MROTextField
              variant="outlined"
              inputProps={{ autoFocus: true }}
              label="Section Name"
              margin="dense"
              placeholder="Section Name"
              name="section_name"
              fullWidth
              required
              autoComplete="off"
            />
            <div style={{ textAlign: 'right' }}>

              <MROButton variant={"contained"} color="tertiary" onClick={handleClose}> Cancel</MROButton>
              <MROButton variant="contained" color="primary" type="submit" className={classes.margin}>
                Add
            </MROButton>
            </div>
          </form>
        </DialogContent>
      </Dialog>
      </div>
    </React.Fragment>
  );
}