import React, { useState, useEffect, useContext } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MROFullScreenRightDrawer from "../../../components/fullwidth_dialog";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import BackIcon from '@material-ui/icons/KeyboardBackspace';
import Grid from "@material-ui/core/Grid";
import MRORightDrawer from "../../../components/temporary_sidebar";
import CloseIcon from "@material-ui/icons/Close";
import { ToastMessageContext } from "../../../lib/contexts/message_context";
import MROTextField from "../../../components/form_components/TextField";
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormHelperText from '@material-ui/core/FormHelperText';
import PositiveSwitch from "../../../components/form_components/switch";
import { withStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import MROButton from "../../../components/buttons";
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import LoadingIndicator from "../../../components/Universal/loading_indicator";
import CircularProgress from "@material-ui/core/CircularProgress";
import VechicleService from '../../../lib/services/api/vechicle_service';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import { Paper, Chip, Box, Tooltip } from '@material-ui/core';
import ManageQuestions from "./manage_questions"

const useStyles = makeStyles(theme => ({
    content: {
        width: "calc(100% - 250px)"
    },
    appBar: {
        backgroundColor: "#fff",
        color: '#000000CC',
        boxShadow: "0px 3px 6px #0000001A",
    },
    backButton: {
        marginRight: theme.spacing(2),
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
        width: 50,
        height: 50,
        borderRadius: '50%'
    },
    wrapper: {
        margin: '3%',
        padding: '3%',
        background: '#fff',
        boxShadow: "0px 1px 4px #0000001A",
        '& form': {
            paddingTop: '25px',
            width: '85%',
            '& .MuiGrid-spacing-xs-5': {
                '& .MuiGrid-item': {
                    paddingBottom: '0'
                }
            }
        },
        '& .align-left-space-between': {
            paddingBottom: theme.spacing(1)
        },
        '& .MuiTypography-root': {
            fontSize: '14px'
        }
    },
    actionButtons: {
        paddingTop: '20px',
        paddingBottom: 50,
        textAlign: 'right',
        //  width: '93%',
        '& button': {
            marginRight: '3%'
        }
        // marginRight: '50px !important'
    },
    paperWidth: {
        margin: '50px !important',
        width: '93%',
    },
    AppBarWidth: {
        backgroundColor: '#70707033',
        color: '#000000CC',
        padding: '2%'
    },
    dialogBg: {
        backgroundColor: '#F7F8FA'

    },
    root: {
        // backgroundColor: theme.palette.background.paper,   
    },
    buttonpadding: {
        margin: '23px'
    },
    disableHover: {
        fontWeight: 'bold'
    }
}));
const DarkerDisabledTextField = withStyles({
    root: {
        marginRight: 8,
        "& .MuiInputBase-root.Mui-disabled": {
            color: "rgba(0, 0, 0, 1.6)" // (default alpha is 0.38)
        }
    }
})(MROTextField);
let updateOccured = false;
function VechicleReport(props) {
    const classes = useStyles();
    const message = useContext(ToastMessageContext);
    const [loading, setLoading] = useState(false);
    const [listVehicle, setListing] = useState({});
    const { handleClose, selectedOrganisation } = props;
    const [action, setAction] = useState('');
    const [bases, setBases] = useState([]);
    const [status, setStatus] = useState(false);
    const [baseStations, setBaseStations] = useState([]);
    const [currentSelection, setCurrentSelection] = useState({});
    const [selectedBases, setSelectedBases] = useState([]);

    const handleCloseBase = (refresh) => {
        if (refresh) {
            fetchVechicleBase();
        }
        setAction(null);
        setCurrentSelection({});
    }
    const toggleDrawer = () => {
        handleCloseBase(updateOccured);
    };
    useEffect(() => {
        setLoading(true);
        VehicleList()
        fetchVechicleBase()

    }, [])
    const fetchVechicleBase = () => {
        setLoading(true)
        VechicleService.fetchVechicleBase(selectedOrganisation.organisation_id).then((res) => {
            if (res.data) {
                const data = res.data;
                const bases = data.base_stations
                setBases(res.data);
                setBaseStations(bases)
                setLoading(false);
            }
        }).catch(() => {
            setBases([]);
            // setLoading(false);
        })
    }
    const handleAccessChange = (perm, checked, currentSelectionId, currentSelection) => {
        // console.log('currentSelection', perm, currentSelection, currentSelection)
        let _baseStations = [...currentSelection.base_stations]
        let index = _baseStations.findIndex(bs => bs.base_station_id === perm.base_station_id)

        _baseStations[index] = {
            ...perm,
            base_station_id: perm.base_station_id,
            status: checked
        }
        console.log(index, _baseStations)
        setCurrentSelection({
            ...currentSelection,
            base_stations: _baseStations
        })

        let updateData = {
            data: [{
                id: currentSelectionId,
                base_stations: [{
                    id: perm.base_station_id,
                    status: checked
                }]
            }]
        }

        VechicleService.mapBaseStations(updateData).then((res) => {
            if (res.success) {
                updateOccured = true;
                // setLoading(true)
                // setBases([])
                // setAction('')
                fetchVechicleBase();
                message.showToastMessage({ open: true, message: "Updated Base Station", variant: "success" })
            }
        }).catch(() => {
            message.showToastMessage({ open: true, message: "Something went wrong. Try again", variant: "error" })
        })
    }
    const handleVechicleOrg = (checked, currentSelection) => {
        // setLoading(true);
        // formData.append('id',currentSelection.id)
        // formData.append('organisation_id',selectedOrganisation.organisation_id)
        console.log('currentSelection', currentSelection)
        VechicleService.mapVehicleOrg({ id: currentSelection, organisation_id: selectedOrganisation.organisation_id, organisation_status: checked }).then((res) => {
            if (res.success) {
                message.showToastMessage({ message: "Question Status successfully changed", variant: "success" });
                // setStatus(currentSelection);
                // setLoading(true);
                // setTimeout(()=>{
                //   setLoading(false);
                // },300);
            }
            else {
                message.showToastMessage({ message: res.errors ? res.errors.join(',') : "Something went wrong. Try Again", variant: "error" });
                setLoading(false);
            }
        }).catch(() => {
            message.showToastMessage({ message: "Something went wrong. Try Again", variant: "error" });
            setLoading(false);
        })
    }
    const VehicleList = () => {
        VechicleService.getVehicleQuestions().then((res) => {
            if (res.data) {
                const listVehicle = res.data;
                setListing(listVehicle)
            }
            setLoading(false);
        }).catch(() => {
            setLoading(false);
            message.showToastMessage({ message: "Something went wrong. Try Again!", variant: "error" })
        })
    }
    const handleVechicleClose = (data, id) => {
        let basesList = [];
        if (action === 'create' && data && data.id) {
            basesList = [data].concat(bases);
            setBases(basesList);
        }
        else if (data && data.id) {
            basesList = bases.map(base => {
                if (base.id === data.id) {
                    return data;
                }
                return base;
            })
            setBases(basesList);
        }
        else if (!data && id) {
            basesList = bases.filter(base => base.id !== id);
            setBases(basesList);
        }
        setAction(null);
        setCurrentSelection({});
    }

    return (
        <React.Fragment>
            <MROFullScreenRightDrawer>
                <AppBar position="static" className={classes.appBar} elevation={0}>
                    <Toolbar>
                        <IconButton onClick={handleClose} edge="start" className={classes.backButton} color="inherit" aria-label="back">
                            <BackIcon />
                        </IconButton>
                        <Typography variant="subtitle2" className={classes.title}>
                            Vehicle Report Editor - Edit
                    </Typography>
                    </Toolbar>
                    <Typography variant="subtitle1" color="secondary" style={{ paddingLeft: '76px' }} className={classes.title}>
                        {selectedOrganisation.company_name}
                    </Typography>
                </AppBar>
                {!loading ? <React.Fragment><Paper className={classes.paperWidth}>
                    <AppBar position="static" className={classes.AppBarWidth} elevation={0}>
                        <Typography variant="subtitle2" className={classes.title}>
                            Vehicle Checks
                    </Typography>
                    </AppBar>
                    <Grid container style={{ padding: '3%' }} spacing={2}>
                        {bases.length > 0 && <Grid item md={4}>
                            <Typography variant="subtitle2" className={classes.title}>
                                Questions
                    </Typography>
                        </Grid>}
                        {bases.length > 0 && <Grid
                            item
                            container
                            justify="center" md={1}>
                            <Typography style={{ textAlign: 'center' }} variant="subtitle2" className={classes.title}>
                                Status
                    </Typography>
                        </Grid>}
                        {bases.length > 0 && <Grid item container justify="center" md={2}>
                            <Typography variant="subtitle2" className={classes.title}>
                                Select Base
                    </Typography>

                        </Grid>}
                        {bases.length > 0 && <Grid item md={2}>
                            <Typography variant="subtitle2" className={classes.title}>
                                Question type
                    </Typography>

                        </Grid>}
                        {bases.length > 0 && <Grid item md={3}>
                            <Typography variant="subtitle2" className={classes.title}>
                                Selected Bases
                    </Typography>
                        </Grid>
                        }
                        {bases[0] && !loading ? bases.map(row => (<React.Fragment>
                            <Grid item md={4}>
                                <DarkerDisabledTextField
                                    className={classes.margin}
                                    id="input-with-icon-textfield"
                                    defaultValue={row.question}
                                    disabled
                                    required={row.mandatory}
                                    label={"Question"}
                                    InputProps={{
                                        endAdornment: (
                                            row.parent_organisation === true && <React.Fragment>
                                                {/* <FormControlLabel
                                        control={
                                            <PositiveSwitch
                                                checked={status === row.id}
                                                defaultChecked={row.organisation_status}
                                                onChange={(e)=>{setStatus(row.id);handleVechicleOrg(e.target.checked,row.id)}}
                                                // name="organisation_status"
                                            />
                                        }
                                        labelPlacement="start"
                                        // name="organisation_status"
                                    /> */}

                                                <IconButton onClick={() => { setAction('update'); setCurrentSelection(row) }}
                                                    type="submit" className={classes.iconButton} aria-label="search">
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton color="primary" onClick={() => { setAction('delete'); setCurrentSelection(row) }} className={classes.iconButton} aria-label="directions">
                                                    <DeleteIcon />
                                                </IconButton></React.Fragment>
                                        ),
                                    }}
                                /> </Grid>  <Grid
                                    // style={{ padding: '21px' }}
                                    item
                                    container
                                    justify="center"
                                    alignItems="center"
                                    xs={1}>
                                <PositiveSwitch
                                    edge="end"
                                    defaultChecked={row.organisation_status}
                                    inputProps={{ 'aria-labelledby': 'on-or-off' }}
                                    onChange={(e) => { handleVechicleOrg(e.target.checked, row.id) }}
                                />
                            </Grid>
                            <Grid item container justify="center" alignItems="center" xs={2}>
                                <IconButton style={{ padding: '21px' }} onClick={() => { setAction('updateBase'); setCurrentSelection(row) }}
                                    type="submit" className={classes.iconButton} aria-label="search">
                                    <EditIcon />
                                </IconButton></Grid>
                            <Grid item container justify="flex-start" xs={2} style={{ display: 'flex', flexWrap: 'wrap' }} alignItems="center">
                                {
                                    <Chip label={row.parent_organisation === true ? 'Client' : 'Global'} />
                                }
                            </Grid>
                            <Grid item xs={3} style={{ display: 'flex', flexWrap: 'wrap' }} alignItems="center" >
                                {
                                    row?.base_stations?.filter(bs => bs.status === true).map(bs => {
                                        if (bs.status) {
                                            return <Box display="inline-block" mx={0.1}><Chip label={bs?.station} /></Box>
                                        } else {
                                            return 'No base selected'
                                        }
                                    })
                                }
                                {
                                    row?.base_stations?.every(bs => bs.status === false) && 'No Base Selected'
                                }
                            </Grid>


                        </React.Fragment>)) : ''}
                    </Grid>
                    <MROButton style={{ margin: '30px' }} variant={"contained"} color="secondary" startIcon={<AddIcon />} onClick={() => { setAction("create") }}>Add a question</MROButton>
                </Paper>   <div className={classes.actionButtons}>
                        <MROButton type="button" variant={"contained"} color="primary" onClick={handleClose}>Done</MROButton></div></React.Fragment> : <div className={"center"} style={{ minHeight: '200px' }}>
                        <CircularProgress color="primary" />
                    </div>}
            </MROFullScreenRightDrawer>
            {
                action === 'updateBase' && <MRORightDrawer onClose={toggleDrawer}>
                    <div className={"align-left-space-between"}>
                        <Typography variant="subtitle1" color="secondary" className={classes.title}>
                            {selectedOrganisation.company_name}
                        </Typography>
                        <IconButton onClick={toggleDrawer}>
                            <CloseIcon fontSize={"small"} />
                        </IconButton>
                    </div>
                    <div>
                        <List className={classes.list}>
                            <ListItem>
                                <ListItemText><div className={"align-left-space-between"}><Typography variant="subtitle2" gutterBottom>Base Station Name</Typography><Typography variant="subtitle2" gutterBottom>Status</Typography></div></ListItemText>
                            </ListItem>
                            {(currentSelection.base_stations && currentSelection.base_stations.length > 0) ? Object.values(currentSelection.base_stations).map((permission, i) => {
                                return (
                                    <React.Fragment key={currentSelection.id}>
                                        <ListItem>
                                            <ListItemText primary={permission.station} />
                                            <ListItemSecondaryAction>
                                                <PositiveSwitch
                                                    edge="end"
                                                    checked={permission.status}
                                                    inputProps={{ 'aria-labelledby': 'on-or-off' }}
                                                    onChange={(e) => { handleAccessChange(permission, e.target.checked, currentSelection.id, currentSelection); }}
                                                />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <Divider />
                                    </React.Fragment>
                                )
                            }) : <div className={"center"} style={{ minHeight: '200px' }}>
                                    <Typography variant="subtitle1" color="secondary" className={classes.title}>
                                        No Base Station Avaliable
                    </Typography>
                                </div>}
                        </List>
                    </div>

                </MRORightDrawer>
            }
            {action && action !== 'updateBase' && <ManageQuestions selectedOrganisation={selectedOrganisation} fetchVechicleBase={fetchVechicleBase} setLoading={setLoading} action={action} data={currentSelection} handleClose={handleVechicleClose} />}
        </React.Fragment >
    )
}

export default VechicleReport;