import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

function useIsGlobalReports() {
  const [isGlobal, setIsGlobal] = useState(true);
  const {
    location: { pathname },
  } = useHistory();

  useEffect(() => {
    if (pathname !== "/setup/global_reports") {
      console.log("Inside useeffect");
      setIsGlobal(false);
    }
  }, [pathname]);

  return isGlobal;
}

export default useIsGlobalReports;
