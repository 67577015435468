import React, { useState } from 'react';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import DataClient from "../../lib/services/api/get_data_service";

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
  },
}));
const theme = createMuiTheme({
  palette: {
    primary: red,
  },
});
export default function AddReportGroups(props) {
  const classes = useStyles();
  const [open, setOpen] = [props.open, props.onClose];
  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let formData = new FormData(e.target);

    DataClient.createData(formData).then((res) => {
      if (res.report_group) {
        handleClose()
        props.reportList()
      }
    })
  }
  return (
    <React.Fragment>
      <div> <Dialog
        open={open}
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add Group Name</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="dense"
              placeholder="Group Name"
              name="group_name"
              fullWidth
              required
              autoComplete="off"
            />
            <div style={{ textAlign: 'right' }}>

              <Button variant="outlined" color="primary" onClick={handleClose}> Cancel</Button>
              <Button variant="contained" color="primary" type="submit" className={classes.margin}>
                Add
            </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
      </div>
    </React.Fragment>
  );
}