import React, { useState, useEffect, useContext } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MRORightDrawer from "../../components/temporary_sidebar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import DataClient from "../../lib/services/api/get_data_service";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import PositiveSwitch from "../../components/form_components/switch";
import { ToastMessageContext } from "../../lib/contexts/message_context";
import { flattenObjectToArray } from "../../lib/common_utils"
import OrganisationApiClient from "../../lib/services/api/organisation_based_stations";

const useStyles = makeStyles(theme => ({
    list: {
        paddingLeft: 0,
        '& .MuiListItem-gutters': {
            paddingLeft: 0
        },
        '& .MuiListItemIcon-root': {
            minWidth: '35px',
            cursor: 'pointer'
        }
    },
    nestedList: {
        background: '#f5f5f5',
        paddingLeft: theme.spacing(4)
    }
}));
let updateOccured = false;

function CreateWebCategories(props) {
    const classes = useStyles();
    const { selectedOrganisation } = props;
    const [loading, setLoading] = useState(false);
    const [openSubCategory, setOpenSubcategory] = useState(null);
    const [groupCategories, setGroupCategories] = useState([]);
    const [frontEndCategory, setFrontEndCategory] = useState({});
    const message = useContext(ToastMessageContext);
    const toggleDrawer = () => {
        props.handleClose(updateOccured);
    };

    const organisationList = () => {
        OrganisationApiClient.mapStationChecks(props.selectedOrganisation.organisation_id).then((res) => {
            if (res.data) {
                setFrontEndCategory(res.data);
            }
            setLoading(false);
        }).catch(() => {
            setLoading(false);
            message.showToastMessage({ open: true, message: "Something went wrong. Try Again!", variant: "error" })
        })
    }
    // clo
    useEffect(() => {
        setLoading(true);
        organisationList()
    }, []);

    const handleAccessChange = (perm, checked) => {
        let updateData = {
            organisation_id: selectedOrganisation.organisation_id,
            data: [{
                id: perm.id,
                status: checked
            }]
        }
        OrganisationApiClient.changeStatus(updateData).then((res) => {
            if (res.success) {
                updateOccured = true;
                organisationList();
                props.StationList()
                message.showToastMessage({ message: ` Status updated successfully`, variant: "success" })
            }
        })
    }
    console.log('frontEndCategory', frontEndCategory)
    return (
        <MRORightDrawer onClose={toggleDrawer}>
            <div className={"align-left-space-between"}>
                <Typography variant="subtitle1" color="secondary" className={classes.title}>
                    {selectedOrganisation.company_name}
                </Typography>
                <IconButton onClick={toggleDrawer}>
                    <CloseIcon fontSize={"small"} />
                </IconButton>
            </div>
            <div>
                <List className={classes.list}>
                    <ListItem>
                        <ListItemText><div className={"align-left-space-between"}><Typography variant="subtitle2" gutterBottom>Station Name</Typography><Typography variant="subtitle2" gutterBottom>Yes/No</Typography></div></ListItemText>
                    </ListItem>
                    {(!loading && frontEndCategory) ? Object.values(frontEndCategory).map((permission, i) => {
                        return (
                            <React.Fragment key={i}>
                                <ListItem>
                                    <ListItemText primary={permission.title} />
                                    <ListItemSecondaryAction>
                                        <PositiveSwitch
                                            edge="end"
                                            checked={permission.status}
                                            inputProps={{ 'aria-labelledby': 'on-or-off' }}
                                            onChange={(e) => handleAccessChange(permission, e.target.checked)}
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Divider />
                            </React.Fragment>
                        )
                    }) : <div className={"center"} style={{ minHeight: '200px' }}>
                            <CircularProgress color="primary" />
                        </div>}
                </List>
            </div>

        </MRORightDrawer>
    )
}

export default CreateWebCategories;