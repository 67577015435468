import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';

import Skeleton from '@material-ui/lab/Skeleton';
import { Divider } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    card: {
        maxWidth: "80vw",
        height: '18vh',
        // margin: theme.spacing(2),
    },
    media: {
        height: '15vh',
    },
}));
function ConversationLoader() {

    const classes = useStyles();
    return (
        <>
            <Card className={classes.card}>
                <CardHeader
                    avatar={

                        <Skeleton animation="wave" variant="circle" width={40} height={40} />

                    }

                    title={

                        <Skeleton animation="wave" height={20} width="80%" style={{ marginBottom: 6 }} />

                    }
                    subheader={<Skeleton animation="wave" height={10} width="40%" />}
                />

                {/* <Divider /> */}
            </Card>
        </>
    )
}

export default ConversationLoader