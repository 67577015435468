
import { Grid, Box, Divider, Tabs, Paper, IconButton, Typography, withStyles } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import SearchMessage from './Messaging_New/search_mesage'

import makeStyles from "@material-ui/core/styles/makeStyles";
import Tab from "@material-ui/core/Tab";
import ListMessaging from './Messaging_New';
import useDebounce from '../My_clients/debounce'
import { MessageServices } from '../../lib/services/api/Messaging/messae_services'
import { TabPanel } from '@material-ui/lab';


const MROTab = withStyles((theme) => ({
    root: {
        overflowX: "hidden !important",
        textTransform: 'none',
        fontWeight: 600,
        color: "black",
        '& .nav_icon': {
            visibility: 'hidden'
        },
        '&$selected': {
            color: theme.palette.primary.main,
            fontWeight: 600,
            '& .nav_icon': {
                visibility: "visible"
            }
        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />)


const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        overflow: 'hidden !important',
        background: '#fff',
        display: 'block',
        // background:'#fff'
        // width: 400,
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    badge: {
        width: '22px',
        height: '22px',
        borderRadius: '14px',
        // backgroundColor: 'red',
        fontSize: '14px',
        marginTop: '-4px',
        marginLeft: '13px'
    },
    tab: {

        width: '200px',
        minWidth: '200px'
    }
}));

function Messaging(props) {
    const classes = useStyles()
    const [currentSection, setCurrentSection] = useState(0);
    const [searchText, handleSearchText] = useState(null);

    const debounceVal = useDebounce(searchText)
    const [details, setDetails] = useState({})
    const [messageRefresh, setMessageRefresh] = useState(false)
    const [readSignRefresh, setReadSignRefresh] = useState(false)

    //getunreadcount
    let userDetails = JSON.parse(sessionStorage.getItem("user"));

    const getTotalUnReadCount = async () => {
        // console.log("userDetails=====", userDetails?.user?.id)
        const params = {

            user_id: userDetails?.user?.id
        }
        MessageServices.getTotalUnReadCount(params).then((res) => {
            setDetails(res.data)
        }).catch(() => {

        })
    }

    useEffect(() => {
        getTotalUnReadCount()

    }, [currentSection, messageRefresh])



    return (
        <div className={classes.root}>
            {/* <Paper> */}
            <Grid container xs={12} direction='column' >
                <Grid container xs={12}  >
                    <Grid xs={4}>
                        <SearchMessage handleSearchText={handleSearchText} />
                    </Grid>
                </Grid>
                <Grid container xs={12} style={{
                    borderBottom: '1px solid #70707099',
                    width: '33.4%',
                    justifyContent: 'center'
                }}>

                    <Tabs

                        value={currentSection}
                        textColor="secondary"
                        centered
                        onChange={(e, tab) => { setCurrentSection(tab); }}
                        indicatorColor={"primary"}
                    >
                        <MROTab className={classes.tab} onClick={() => {
                            setMessageRefresh(true)
                        }} label={
                            <Grid>
                                Messages
                                {details?.message_count > 0 && <span>
                                    <IconButton size="small" style={{
                                        backgroundColor: details.message_color === "amber" ? "#FFB300" : "#FF0202"
                                    }} className={classes.badge}>{details?.message_count}</IconButton></span>}
                            </Grid>
                        }

                        />
                        {/* <MROTab className={classes.tab} onClick={() => {
                            setReadSignRefresh(true)
                        }} label={
                            <Grid>
                                Read and Sign
                                {details?.read_sign_count > 0 && <span>
                                    <IconButton size="small" style={{
                                        backgroundColor: details.read_sign_color === "amber" ? "#FFB300" : "#FF0202"
                                    }} className={classes.badge}>{details?.read_sign_count}</IconButton></span>}
                            </Grid>} /> */}

                    </Tabs>

                </Grid>

                {currentSection === 0 && <ListMessaging debounceVal={debounceVal} messageRefresh={messageRefresh} setMessageRefresh={setMessageRefresh} getTotalUnReadCount={getTotalUnReadCount} />}

            </Grid>
            {/* </Paper> */}
        </div>
    )
}

export default Messaging



















// // ------------old code------------------//



// import React, { useState, useEffect, useReducer } from "react";
// import ChatList from "./ChatList";
// import ChatDetail from "./ChatDetail";
// import { makeStyles } from "@material-ui/core/styles";
// import Grid from "@material-ui/core/Grid";
// import { ActionCableConsumer, ActionCableProvider } from 'react-actioncable-provider';
// import Divider from "@material-ui/core/Divider";
// import Tabs from "@material-ui/core/Tabs";
// import Tab from "@material-ui/core/Tab";

// import ListElement from "./ChatList/Message/list_element";
// import ListElementRead from "./ChatList/ReadSign/list_element_read";
// import SearchMessage from "./ChatList/search_message";
// import authReducer, { INITIAL_STATE } from "../../reducers/auth_reducer";
// import AuthActions from "../../actions/auth_actions";
// import { CircularProgress } from "@material-ui/core";
// const useStyles = makeStyles(theme => ({
//     root: {
//         height: '100vh',
//         overflow: 'hidden',
//         background: '#fff',
//         display: 'block',
//         '& .list': {
//             borderRight: '3px solid #f9f6f6'
//         }
//     },
//     selectMessage: {
//         display: 'grid',
//         alignItems: 'center',
//         verticalAlign: 'middle',
//         height: '100vh',
//         textAlign: 'center',
//         color: '#ccc',
//         fontSize: '18px',
//         background: '#fff'
//     }

// }));

// function Messaging(props) {
//     const [currentSection, setCurrentSection] = useState(0);
//     const [searchText, handleSearchText] = useState(null);
//     const [authState, dispatch] = useReducer(authReducer, INITIAL_STATE);
//     const [user, setUser] = useState({});
//     console.log(user)

//     useEffect(() => {
//         AuthActions.setUserData(dispatch, sessionStorage.getItem("authState"))
//     }, [sessionStorage.getItem("authState")])

//     useEffect(() => {
//         if (authState?.authState)
//             setUser(authState?.authState?.user)
//     }, [authState?.authState]);

//     const classes = useStyles();
//     const handleReceivedConversation = (response) => {
//         console.log("Sidebar", "ReceivedConversation", response);
//     };

//     return <>
//         {
//             user ? <ActionCableProvider url={`${process.env.SOCKET_APP_BASE_API_URL}/cable?uid=${user?.uid}&access-token=${user['access-token']}&client=${user?.client}`}>
//                 {
//                     currentSection == 0 &&
//                     <ActionCableConsumer
//                         channel={{ channel: 'ConversationChannel', organisation_id: 1 }}
//                         onReceived={handleReceivedConversation}
//                     />
//                 }
//                 {
//                     currentSection == 1 &&
//                     <ActionCableConsumer
//                         channel={{ channel: 'ReadSignChannel', organisation_id: 1 }}
//                         onReceived={handleReceivedConversation}
//                     />
//                 }
//                 <Grid container spacing={0} className={classes.root}>
//                     <Grid item xs={12}>
//                         {/* <ChatList parentCall={setChannelId} /> */}
//                         <div style={{ height: '96px' }}>
//                             <SearchMessage handleSearchText={handleSearchText} />
//                             <div style={{ width: '100%', background: '#fff' }}>
//                                 <Tabs
//                                     value={currentSection}
//                                     indicatorColor="secondary"
//                                     textColor="secondary"
//                                     centered
//                                     onChange={(e, tab) => { setCurrentSection(tab); }}
//                                 >
//                                     <Tab className={classes.tab} label="Messages" />
//                                     <Tab className={classes.tab} label="Read and Sign" />
//                                 </Tabs>
//                             </div>
//                         </div>
//                         <Divider />
//                         {/* {currentSection == 0 && <div className={classes.messageContainer}>
//                     <ListElement searchText={searchText} />
//                 </div>} */}
//                         {currentSection == 0 && <ListElement searchText={searchText} />}
//                         {currentSection == 1 && <ListElementRead searchText={searchText} />}

//                     </Grid>

//                     {/* <Grid item md={8} sm={7}>
//                 {parentId && <ChatDetail id={parentId} />}
//                 {!parentId && <div className={classes.selectMessage}><span>Select Message</span></div>}
//             </Grid> */}
//                 </Grid>
//             </ActionCableProvider> : <CircularProgress />
//         }
//     </>
// }

// export default Messaging;