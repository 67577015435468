import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Box,
    Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MROButton from "../../components/buttons";
import { Typography } from "@material-ui/core";
import Authy from "../../assets/authy.svg";
import Google from "../../assets/google.svg";
import QRCode from "qrcode.react";
import { ToastMessageContext } from "../../lib/contexts/message_context";
import { useContext } from "react";
import AuthClient from "../../lib/services/api/auth_service";
import TotpAuthStep2 from "./TotpAuthStep2";
import LoadingIndicator from "../../components/Universal/loading_indicator";

const useStyles = makeStyles((theme) => ({
    actionButtons: {
        paddingTop: "20px",
        paddingBottom: "33px",
        textAlign: "center",
        "& button:not(:last-child)": {
            marginRight: "15px",
        },
    },
    modal: {
        "& .MuiDialog-paperWidthMd": {
            width: "30%",
            marginRight: "24%"
        },
    },
    title: {
        "& h2": {
            fontSize: "40px",
            fontWeight: 600,
        },
    },
    card: {
        width: "82%",
        backgroundColor: "#fff",
        color: '#000000CC',
        boxShadow: "0px 3px 6px #0000001A",
        // padding: "35px",
        marginTop: "20px",
        paddingBottom: "15px",
        marginLeft: "32px",
        borderRadius: "10px"
    },
    cards: {
        width: "82%",
        backgroundColor: "#fff",
        color: '#000000CC',
        boxShadow: "0px 3px 6px #0000001A",
        // padding: "35px",
        marginTop: "20px",
        marginLeft: "32px",
        borderRadius: "10px",
        padding: "10px"
    },
}));


export default function TotpAuth(props) {
    const classes = useStyles();
    const message = useContext(ToastMessageContext)
    const { handleClose, open, setOtpMethod, factAndEntityId, setFactAndEntityId, authMethod } = { ...props };
    const [busy, setBusy] = useState(null);
    const [step_2, setStep_2] = useState(false)
    const [qrCodeLink, setQrCodeLink] = useState(null)
    const [qrcode, setQrCode] = useState("")
    const [isGettingDetails, setIsGettingDetails] = useState(false)

    useEffect(() => {
        window.process = {
          ...window.process,
        };
      }, []);

    const closeHandler = () => {
        setOtpMethod(true)
        handleClose()
    }
    const doneCloseHandler = () => {
        setStep_2(true)
    }

    useEffect(()=>{
        if(qrCodeLink != null){
            setQrCode(qrCodeLink)
        }
    },[qrCodeLink])

    useEffect(() => {
        setIsGettingDetails(true)
        AuthClient.sendQRCodeLink({ id: authMethod?.id})
          .then((res) => {
            if (res?.success) {
              setIsGettingDetails(false)
              setFactAndEntityId({ factorSid: res?.data?.factor_sid, entitySid: res?.data?.entity_sid, qrLink:res?.data?.qr_code_link })
              setQrCodeLink(res?.data?.qr_code_link)
            } else {
              setIsGettingDetails(false)
                closeHandler()
                message.showToastMessage({
                  message: "Maximum attempt reached!!",
                  variant: "error",
                });
            }
          })
          .catch((err) => {
            closeHandler()
            setIsGettingDetails(false)
            message.showToastMessage({
              message: "Maximum attempt reached!!",
              variant: "error",
            });
          })
      }, [])

    return (
        <div>
            <Dialog
                // fullWidth={true}
                maxWidth={"md"}
                open={open}
                className={classes.modal}
                onClose={handleClose}
                aria-labelledby="base-station-manage"
            >
                <DialogTitle style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Typography
                        // variant="subtitle2"
                        color="primary"
                        className={classes.title}
                    >
                        TOTP Code
                    </Typography>
                </DialogTitle>

                <form>
                    <DialogContent>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Typography>
                                Download your preferred “TOTP”<br></br>
                                &nbsp;&nbsp;&nbsp;&nbsp;APP and scan the QR code
                            </Typography>
                        </div>

                        <Box className={classes.card}>
                            <Typography color="primary" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>Example APPs</Typography>
                            <Grid style={{display:"flex", justifyContent:"space-around"}}>
                                <Box display="flex" justifyContent="center">
                                    {" "}
                                    <img src={Google} alt="Icon" style={{ height: "58px" }} />{" "}
                                </Box>
                                <Box display="flex" justifyContent="center">
                                    {" "}
                                    <img src={Authy} alt="Icon" style={{ height: "58px" }} />{" "}
                                </Box>

                            </Grid>
                            <Grid style={{display:"flex", justifyContent:"space-around"}} >
                                <Box style={{ fontSize: "10px", marginRight: "10px" }}>
                                    Google Authenticator
                                </Box>
                                <Box style={{ fontSize: "10px", marginRight: "16px" }}>
                                    Twillio Authy
                                </Box>
                            </Grid>
                        </Box>

                        {isGettingDetails ? <LoadingIndicator variant={"block"} /> :  qrCodeLink != null &&
                        <Box className={classes.cards}>
                            <Grid style={{display:"flex", justifyContent:"center"}} >
                                <QRCode
                                    value={qrcode}
                                    size={250} />
                            </Grid>
                        </Box>}

                    </DialogContent>

                    <div className={classes.actionButtons}>
                        <MROButton
                            // color="primary"
                            disabled={false}
                            type="button"
                            variant={"contained"}
                            onClick={closeHandler}
                        >
                            Cancel
                        </MROButton>
                        <MROButton
                            loading={busy === "loading"}
                            // color="secondary"
                            style={{ backgroundColor: "#4FC605", color: "#fff" }}
                            disabled={false}
                            type="button"
                            onClick={doneCloseHandler}
                            variant={"contained"}
                        >
                            Done
                        </MROButton>
                    </div>
                </form>
            </Dialog>
            {step_2 &&
                <TotpAuthStep2
                    {...props}
                    open={step_2}
                    handleClose={() => setStep_2(false)}
                    setOtpMethod={setOtpMethod}
                    setCheckBoxState={setStep_2}
                    qrCodeLink={qrCodeLink}
                    closeHandler={closeHandler}
                    factAndEntityId={factAndEntityId}
                    authMethod={authMethod}
                />
            }
        </div >
    );
}
