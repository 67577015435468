import React from 'react';
import {Route,Switch} from "react-router-dom";
import TopNavigationBar from "../../components/Universal/tab_component";
import ReportGroups from "../Report_groups";
import GlobalReports from '../Global_reports';
import ClientGroups from '../Client_reports'


export default function setupSection(props) {

    const topNavMenu = [
        {label:<div>Global Reports</div>,redirect:'/setup/global_reports',section:'/global_reports'},
        {label:<div>Client Reports</div>,redirect:'/setup/client_groups',section:'/client_groups'},
        {label:<div>Report Groups</div>,redirect:'/setup/report_groups',section:'/report_groups'},
    ];

    return (
        <div>
            <TopNavigationBar {...props} tabProperties={topNavMenu}/>
            <Switch>
                <Route exact path={'/setup/global_reports'} component={GlobalReports}/>
                <Route path={'/setup/client_groups'} component={ClientGroups}/>
                <Route path={'/setup/report_groups'} component={ReportGroups}/>
            </Switch>
        </div>
    )
}