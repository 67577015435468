import request from "../../../shared/api_client";

function getIssueReports(params) {
    return request({
        url: '/issue_reports',
        method: 'GET',
        params: params
    });
}

function getIssueReport(params) {
    return request({
        url: '/issue_reports/fetch_issue_report',
        method: 'GET',
        params: params
    });
}

function createIssueReport(formData) {
    return request({
        url: '/issue_reports',
        method: 'POST',
        data: formData
    })
}

function updateIssueReport(formData) {
    return request({
        url: `/issue_reports/update_issue_report`,
        method: 'PUT',
        data: formData
    })
}

const IssueReportingService = {
    getIssueReports, createIssueReport, getIssueReport, updateIssueReport
};

export { IssueReportingService };