import request from "../../shared/api_client";

function getData(url) {
    return request({
        url: url,
        method: 'GET',
    });
}
function postData(url) {
    return request({
        url: url,
        method: 'POST',
    });
}
function getAllReport(params) {
    return request({
        url: '/report_groups',
        method: 'GET',
        params:params
    });
}
    function putData(url,formData){
        return request({
            url: url,
            method: 'PUT',
            data:formData
        });
    }
    function createData(formData){
        return request({
            url: '/report_groups',
            method: 'POST',
            data:formData
        });
    }
    function updatePermissions(formData,id){
        return request({
            url:`/report_groups/${id}`,
            method: 'PUT',
            data:formData
        })
    }

const DataClient = {
    getData,postData,putData,updatePermissions,createData,getAllReport
};

export default DataClient;