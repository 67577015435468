
import React, { useState, useEffect, useContext, useMemo } from 'react'
import { Box, Typography, Divider, Grid, Paper, useTheme, makeStyles, FormControl, InputBase, MenuItem, Select, alpha, Button, Menu, IconButton, FormControlLabel } from '@material-ui/core'

import SearchBarWithFilters from '../../../../components/search_with_filters_new'
import ArrowDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import { DashboardServices } from '../../../../lib/services/api/Dashboard/dashboard';
import MaterialTable from "material-table";
import tableIcons, { options } from "../../../../components/Table/icons";
import { DatePicker } from 'antd';
import PieChart from './PieChart'
import PositiveSwitch from '../../../../components/form_components/switch';
import _without from "lodash/without";
import _ from 'lodash';
import moment from "moment";
import { ToastMessageContext } from '../../../../lib/contexts/message_context'
import ChartLoader from '../ChartLoader';

const { RangePicker } = DatePicker;

const useStyles = makeStyles((theme) => ({
    root: {
        width: "94%",
        margin: "4% 2.5%",
        paddingLeft: '4px',
        '&.MuiStepConnector-root': {
            borderTopWidth: '10px'
        }
    },
    title: {
        fontSize: "14px",
        color: "#2D2D2D",
        fontWeight: 600
    },

}))

export default function SMS() {
    const classes = useStyles()
    const [anchorClient, setAnchorClient] = useState(null);
    const [baseButton, setBaseButton] = useState(false)
    const [clientDetails, setClientDetails] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [checkedCustState, setCheckedCustState] = useState(new Array(clientDetails.length).fill(false));
    const message = useContext(ToastMessageContext)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [totalUser, setTotalUser] = useState(0)
    const [filterData, setFilterData] = useState({})
    const ITEM_HEIGHT = 78;






    //filters api

    const handleBaseClick = (event) => {
        setAnchorClient(event.currentTarget);
        setBaseButton(!baseButton)
    };

    const handleClose = (event) => {
        setAnchorClient(null);
        setBaseButton(!baseButton)
    };


    const filterClientChangeHandler = (e) => {
        const { name, value, checked } = e.target

        setSelectedFilters((prevState) => [...prevState, value])
        if (!checked) {
            setSelectedFilters((current) => _without(current, value));
        }

    }
    // console.log("data======", props)

    const tableOptions = {
        ...options,

        search: false,
        paging: false,
        rowStyle: { height: 42 },
    };
    const columns = [
        {
            title: 'Client',
            field: 'organisation_name',
            width: '80%',
            headerStyle: {
                textAlign: 'left',
                paddingLeft: '20px'
            },
            cellStyle: {
                textAlign: 'left',
                width: '80%',
                paddingLeft: '20px'
            }
        },
        {
            title: 'Sent',
            field: 'message_count',
            headerStyle: {
                textAlign: 'center'
            },
            cellStyle: {
                textAlign: 'center',
            }
        },


    ];


    const handleDateChange = (value) => {
        if (value) {
            const start_date = moment(value[0]).format("YYYY-MM-DD");
            const end_date = moment(value[1]).format("YYYY-MM-DD");

            setFilterData((prevState) => ({ ...prevState, start_date, end_date }));
        } else {
            const fData = { ...filterData };
            filterData?.start_date && delete fData.start_date;
            filterData?.end_date && delete fData.end_date;
            setFilterData({ ...fData });
        }
    };


    const getOrganisations = () => {
        DashboardServices.getAllActiveOrganization().then((res) => {
            if (res.data) {
                const organisations = res.data;

                setClientDetails(organisations)
            }

        }).catch(() => {

            message.showToastMessage({ message: "Something went wrong. Try Again!", variant: "error" })
        })
    }
    useEffect(() => {
        getOrganisations()
    }, [])


    const getData = async () => {
        setLoading(true)
        const params = {
            organisation_ids: selectedFilters.join(','),
            ...filterData

        }
        await DashboardServices.getSMSDetails(params).then((response) => {

            setData(response.user_reports)
            setTotalUser(response.total_all_msg_org)
        }).catch(() => {
            message.showToastMessage({ message: "Something went wrong. Try Again!", variant: "error" })


        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        getData()
    }, [selectedFilters, filterData])



    return (
        <div className={classes.root}>
            <SearchBarWithFilters noSearch padding={'4px 16px'}
            >
                <FormControl>
                    <Button variant={anchorClient ? "contained" : "text"} size="small" aria-haspopup="true" onClick={handleBaseClick} endIcon={baseButton ? <ArrowDropUp /> : <ArrowDown />}
                        style={{ fontSize: "14px", backgroundColor: anchorClient ? '#efefef' : "", color: 'black' }}
                    >
                        {selectedFilters.length > 0 ? `Client(${selectedFilters.length})` : 'Client(All)'}

                    </Button>
                    <Menu
                        id="Client"
                        anchorEl={anchorClient}
                        keepMounted
                        open={anchorClient}
                        onClose={handleClose}
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                        transformOrigin={{ vertical: "top", horizontal: "left" }}
                        style={{ marginTop: '41px' }}
                        TransitionProps={false}
                        PaperProps={{
                            style: {
                                maxHeight: ITEM_HEIGHT * 4.2,
                                width: '40ch',
                                position: 'fixed',
                                top: '237px',
                                transition: 'none',


                            },
                        }}
                    >
                        {/* <MenuItem value="" style={{ marginLeft: '7px' }}>Customer(All)</MenuItem> */}
                        {clientDetails?.map((option, index) => {
                            return <Box>
                                <Grid container spacing={2}>
                                    <Grid item xs={1}></Grid>
                                    <Grid item xs={9} style={{
                                        display: "flex", alignItems: "center", fontSize: "14px",

                                    }}>
                                        <label>{option.company_name}</label>
                                    </Grid>
                                    <Grid item xs={2} style={{ marginLeft: '-23px' }}>
                                        <FormControlLabel

                                            control={<PositiveSwitch
                                                checked={checkedCustState[index]}
                                                value={option.organisation_id}
                                                onChange={(e) => { filterClientChangeHandler(e) }}
                                                name={option.company_name} />}
                                        />
                                    </Grid>

                                </Grid>
                                <Grid><Divider variant="middle" /></Grid>
                            </Box>
                        })}
                    </Menu>
                </FormControl>

                <FormControl className={"filter"} style={{ paddingLeft: '10px' }}>

                    <RangePicker format="DD/MM/YYYY"
                        style={{ width: "291px", color: "black" }}

                        onChange={handleDateChange}
                        allowClear={true}
                    />
                </FormControl>

                <FormControl style={{ float: 'right', marginTop: '-7px' }}>

                    <Box
                        minWidth="250px"
                        bgcolor="#fff"
                        height="40px"
                        border="0.5px solid #A2A2A2"
                        borderLeft="3px solid #0EC3F7"
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        paddingX="17px"
                    >
                        <Typography variant="body1" className={classes.title} style={{ paddingRight: '5px' }}>
                            Total Sent
                        </Typography>
                        <Typography
                            variant="body1"
                            className={classes.title}
                            style={{
                                color: "#0EC3F7",
                                paddingLeft: "22px",
                                borderLeft: ".5px solid #BCBCBC",
                                fontSize: '17px'
                            }}
                        >
                            {totalUser}
                        </Typography>
                    </Box>

                </FormControl>
            </SearchBarWithFilters>

            <Grid container item xs={12} justify='space-between'>

                <Grid item xs={6} >
                    <Paper style={{ width: "100%", padding: "46px 59px", height: '445px' }}>
                        <Box width="100%" display="flex" justifyContent="space-between">

                            {loading ? <ChartLoader /> :
                                <PieChart data={data} />}

                        </Box>




                    </Paper>
                </Grid>

                <Grid item xs={6} style={{ paddingLeft: '13px' }}>     <Box>
                    <MaterialTable
                        style={{ boxShadow: '0px 1px 3px #00000033', paddingBottom: '48px' }}
                        icons={tableIcons}
                        title={""}
                        columns={columns}
                        data={data}
                        isLoading={""}
                        options={tableOptions}
                    />

                </Box>

                </Grid>
            </Grid>

        </div>
    )
}

