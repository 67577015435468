import { ButtonGroup, Grid, Dialog, DialogContent, DialogTitle, Typography, makeStyles, InputBase, FormControlLabel, InputAdornment, IconButton } from '@material-ui/core'
import FileUpload from './../fileUpload'
import React, { useContext, useEffect, useState } from 'react'
import MROButton from '../../../components/buttons'
import MROTextField from '../../../components/form_components/TextField'
import FileIcon from "@material-ui/icons/AttachFile";
import PositiveSwitch from '../../../components/form_components/switch';

import { Visibility as ViewIcon } from '@material-ui/icons';
import { ICON_COLOUR } from '../../../lib/shared/style_constant';
import Autocomplete from "@material-ui/lab/Autocomplete";
// import { getOrganisationId } from 'lib/utils/common_utils';
import { MessageServices } from '../../../lib/services/api/Messaging/messae_services'
import DependencyDetails from './DependencyDetails';
import SearchIcon from "@material-ui/icons/Search";
import { ToastMessageContext } from "../../../lib/contexts/message_context";


const useStyles = makeStyles((theme) => ({
    dialog: {
        "& .MuiDialog-paperWidthMd": {
            boxShadow: "0px 3px 6px #00000029",
            // position: "absolute",
            // left: "calc(50% - 250px)",
            width: '40%',

            borderRadius: '5px'
        }
    },
    dialogTitle: {
        color: theme.palette.primary.main,
        fontSize: '18px'
    },
    sendDiv: {
        backgroundColor: '#F5F6FA',
        padding: '5px',
        marginBottom: '10px',
        marginTop: '5px',
        height: 'max-content',
        borderRadius: '5px',
    },
    fileView: {
        marginBottom: '10px'
    },
    inputGroup: {
        height: '100%',
        width: "100%",
        zIndex: '7 !important',
    }


}))

export default function CreateNewMessage(props) {
    const classes = useStyles()
    const { handleClose, getMesssagesList, setSelectedChat } = props
    const [selectedTab, setSelectedTab] = useState(0)
    const [users, setUsers] = useState([])
    const [action, setAction] = useState('')
    const [individualUsers, setIndividualUsers] = useState([])
    const [allUsers, setAllUsers] = useState([])
    const [state, setState] = useState("individual")
    const [selectedUser, setSelectedUser] = useState('')
    const [files, setFiles] = useState([]);
    const [errors, setErrors] = useState({});
    const [checked, setChecked] = useState(false)
    const [selectedGroupUser, setSelectedgroupUser] = useState('')
    const message = useContext(ToastMessageContext)
    const [loading, setLoading] = useState(false)
    let userDetails = JSON.parse(sessionStorage.getItem("user"));

    // fetchdependency 


    const getFetchDependency = () => {
        const params = {
            // organisation_id: getOrganisationId(),

        }
        MessageServices.fetchDependency(params).then((response) => {
            if (response.success) {
                setUsers(response.data)
                setIndividualUsers(response.data.users.filter((item) => item.id !== userDetails?.user?.id))
                handleAllUsers(response.data)
            }
        }).catch(() => {

        })
    }

    // console.log('users====', allUsers.filter((item) => item.id !== userDetails?.user?.id))

    useEffect(() => {
        getFetchDependency()
    }, [])


    const handleAllUsers = (data) => {
        if (data !== null || data === []) {
            if (data.organisations !== null) {
                data.organisations.map((item, index) => {
                    setAllUsers(ps => ([...ps, { id: item.id, name: item.company_name, type: "organisation" }]))

                })

            }
            if (data.users !== null) {
                let userData = data.users.filter((item) => item.id !== userDetails?.user?.id)
                userData.map((item, index) => {
                    setAllUsers(ps => ([...ps, { id: item.id, name: item.user_name, type: "user" }]))

                })
            }
            // if (data.message_group !== null || []) {
            //     data.message_group.map((item, index) => {
            //         setAllUsers(ps => ([...ps, { id: item.id, name: item.group_name, type: "message_group" }]))

            //     })
            // }
            // if (data.base_station !== null || undefined) {

            //     data.base_station.map((item, index) => {
            //         setAllUsers(ps => ([...ps, { id: item.id, name: item.station, type: "base_station" }]))

            //     })
            // }
        }
    }

    const handleSelectedUser = (event, value) => {
        setSelectedUser(value)
    }

    const handleSelectedGroupUsers = (event, value) => {
        setSelectedgroupUser(value)
    }

    const resetErrorsHandler = (name) => {
        setErrors((e) => ({ ...e, [name]: false }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault()
        const formData = new FormData(e.target)

        let valid = true
        let error = {
            message: false,
            user: false,
            groupuser: false
        }

        if (formData && formData.get("message")?.length === 0) {
            error.message = true;
            valid = false;
        }
        if (formData && formData.get("user")?.length === 0) {
            error.user = true;
            valid = false;
        }
        if (formData && formData.get("groupuser")?.length === 0) {
            error.groupuser = true;
            valid = false;
        }
        if (!valid) {
            setErrors(error);
            return;
        }

        formData.append("message_type", "message")
        formData.append("chat_type", state)
        // formData.append('organisation_id', getOrganisationId())
        formData.append("sender_id", userDetails?.user?.id);
        if (state === 'individual') {
            formData.delete("user")
            formData.append("allow_user_to_reply", true)
            formData.append('group_type[0]', "user")
            formData.append('group_id[0]', selectedUser.id)
        }
        if (state === 'group') {
            formData.delete("user")
            formData.append("allow_user_to_reply", checked)

            selectedGroupUser.forEach((item, index) => {
                let key1 = "group_type[" + index + "]"
                let key2 = "group_id[" + index + "]"
                formData.append(key1, item.type)
                formData.append(key2, item.id)
            })
        }

        files.forEach(file => formData.append("attachments[]", file))
        setLoading(true)
        await MessageServices.createMessage(formData).then((response) => {
            if (response.success) {
                message.showToastMessage({
                    message: "Message Created Successfully ! ",
                    variant: "success",
                });
                handleClose()
                getMesssagesList()
                setSelectedChat(response.data)
            }
        }).catch(() => {
            message.showToastMessage({
                message: "Something went wrong. Try again!",
                variant: "error",
            });
            handleClose()
            getMesssagesList()
        }).finally(() => {
            setLoading(false)
        })
    }




    return (
        <div>
            <Dialog
                // fullWidth={true}
                maxWidth={'md'}
                open={true}
                onClose={handleClose}
                className={classes.dialog}
                aria-labelledby="new-message">
                <DialogTitle ><Typography variant='subtitle2' className={classes.dialogTitle}> New message </Typography> </DialogTitle>
                <DialogContent>
                    <ButtonGroup color="primary">
                        <MROButton style={{ width: "140px" }}
                            type={"button"}
                            onClick={() => setState("individual")}
                            variant={state === "individual" ? "contained" : "outlined"}
                        >Individual</MROButton>
                        <MROButton style={{ width: "140px" }}
                            type={"button"}
                            onClick={() => setState("group")}
                            variant={state === "group" ? "contained" : "outlined"}
                        >People / Groups</MROButton>
                    </ButtonGroup>
                    <br />
                    <form onSubmit={handleSubmit}>
                        <Grid container>

                            {state === "group" &&
                                <>       <Grid container direction='row' style={{ marginBottom: '10px' }}>
                                    <Grid item xs={12} >
                                        <Autocomplete
                                            classes={{
                                                inputRoot: classes.inputGroup
                                            }}

                                            multiple
                                            fullWidth
                                            getOptionSelected={(option, value) => option.id === value.id}
                                            filterSelectedOptions={true}
                                            onChange={handleSelectedGroupUsers}
                                            id="tags-user"
                                            options={allUsers}
                                            getOptionLabel={(option) => option.name}
                                            renderInput={(params) => (
                                                <MROTextField
                                                    {...params}
                                                    // variant="standard"
                                                    label=""
                                                    color={"primary"}
                                                    InputLabelProps={{ shrink: true }}
                                                    placeholder={"Search Users,Organisations"}
                                                // name='groupuser'
                                                // error={errors.groupuser}
                                                // onSelect={() => {
                                                //     resetErrorsHandler("groupuser");
                                                // }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    {/* <Grid item xs={1} style={{ paddingTop: '23px', paddingLeft: '10px' }}>
                                        <IconButton onClick={() => {
                                            setAction('viewDependency')
                                        }}>
                                            <ViewIcon htmlColor={ICON_COLOUR} />
                                        </IconButton>
                                    </Grid> */}
                                </Grid>
                                    <Grid item xs={12} style={{ marginBottom: '10px' }} >
                                        <MROTextField
                                            name={"subject"}
                                            label={"Subject"}
                                            InputLabelProps={{ shrink: true }}
                                            required
                                            inputProps={{ maxLength: 30 }}


                                        />
                                    </Grid>

                                    <Grid item xs={8} style={{ marginLeft: '-13px' }}>
                                        <FormControlLabel control={
                                            <PositiveSwitch
                                                checked={checked}
                                                onChange={(e) => setChecked(e.target.checked)}
                                                name=""
                                            />
                                        }
                                            label="Allow users to reply to your message"
                                            labelPlacement="start"
                                            name="" />
                                    </Grid>
                                </>}


                            {state === 'individual' &&

                                <Grid item xs={12} style={{ marginBottom: '10px' }}>
                                    {/* <SearchIcon htmlColor={ICON_COLOUR} style={{
                                        position: 'absolute',
                                        marginTop: ' 31px',
                                        fontSize: ' 16px',
                                        marginLeft: '5px'
                                    }} /> */}
                                    <Autocomplete


                                        fullWidth
                                        getOptionSelected={(option, value) => option.id === value.id}
                                        filterSelectedOptions={true}
                                        onChange={handleSelectedUser}
                                        id="tags-user"
                                        options={individualUsers}
                                        getOptionLabel={(option) => option.user_name}
                                        renderInput={(params) => (
                                            <MROTextField
                                                {...params}
                                                // variant="standard"
                                                label=""
                                                name="user"
                                                color={"primary"}
                                                InputLabelProps={{ shrink: true }}
                                                placeholder={"  Search Users"}
                                                error={errors.user}
                                                onSelect={() => {
                                                    resetErrorsHandler("user");
                                                }}

                                            // InputProps={{
                                            //     style: {
                                            //         height: 'max-content',
                                            //         // width: "100%",
                                            //         // paddingLeft: '5px'
                                            //     },
                                            // }}

                                            />
                                        )}
                                    />

                                </Grid>
                            }
                        </Grid>

                        <Grid container direction='row' style={{ marginBottom: '10px' }}>
                            <Grid item xs={12} className={classes.sendDiv}>

                                <InputBase
                                    fullWidth
                                    multiline
                                    placeholder="Type your message here…"
                                    name="message"
                                    error={errors.message}
                                    onChange={(e) => {
                                        resetErrorsHandler(e.target.name);
                                    }}

                                    inputProps={{ 'aria-label': 'Type your message here…', maxLength: 500 }}



                                />


                            </Grid>
                            <br />
                            {errors.message ? <span style={{ color: 'red' }}>*please enter your message</span> : ''}
                            <Grid item xs={12} className={classes.fileView}>
                                <FileUpload APIUrl={""}
                                    setFiles={setFiles}
                                    showBorderOnNoFiles={false}
                                    uploadButtonView={false}
                                    multiple={true}
                                    name={"attachments[]"}
                                    action={"Upload"} />

                            </Grid>
                            <br />
                            <Grid item xs={12} container direction='row' style={{ justifyContent: 'flex-end' }} >

                                <MROButton type='button' variant='contained' style={{ marginRight: '23px' }} onClick={() => {
                                    handleClose()
                                }}>

                                    Cancel
                                </MROButton>
                                <MROButton type='submit' variant='contained' color='primary' loading={loading} >

                                    Send
                                </MROButton>


                            </Grid>

                        </Grid>

                    </form>

                </DialogContent>
            </Dialog>
            {action && action === 'viewDependency' && <DependencyDetails selectedTab={selectedTab} handleClose={() => setAction(null)} setSelectedTab={setSelectedTab} users={users} />}
        </div >
    )
}
