import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import tableIcons from "../../components/Table/icons";
import { makeStyles, createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import StatusDot from "../../components/Universal/status_dots";
import AddIcon from '@material-ui/icons/AddBox';
import EditIcon from '@material-ui/icons/Edit';
import Typography from '@material-ui/core/Typography';
import DataClient from "../../lib/services/api/get_data_service";
import AddReportGroups from "./add_report_groups";
import GroupPermissions from "./group_permissions"
import { theme } from '../../App';
const useStyles = makeStyles(theme => ({
    tableWrapper: {
        width: '45%',
        margin: '3%',
    },
    subTitle: {
        fontSize: theme.spacing(2),
        fontWeight: 500,
    },
}))

const iconColor = "#00000099";

function ReportGroups(props) {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [searchText, setSearchText] = useState('');
    const [checked, setChecked] = useState({});
    const [reportGroup, setReportGroup] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalCount, setTotalCount] = useState(100);
    const [action, setAction] = useState(null);
    const [currentData, setCurrentData] = useState({});

    useEffect(() => {
    }, [])
    const options = {
        actionsColumnIndex: -1,
        emptyRowsWhenPaging: false,
        searchFieldAlignment: 'right',
        selectableRowsOnClick: true,
        page: page,
        total: totalCount,
        search: true,
        exportDelimiter: ',',
        rowStyle: {
            paddingLeft: 10
        },
        pageSize: pageSize,
        pageSizeOptions: [5, 10, 20, 25, 30, 50, 100],
        headerStyle: {
            fontWeight: 700
        },
        debounceInterval: 800,
        searchText: searchText
    };
    const columns = [
        {
            title: 'Group',
            field: 'group_name',
        },

    ];
    const reportList = () => {
        setLoading(true);
        const params = {
            count_per_page: pageSize,
            page: page + 1,
            search_keyword: searchText || null,
        }
        DataClient.getAllReport(params).then((res) => {
            if (res.report_groups) {
                let reportGroup = res['report_groups'];
                setReportGroup(reportGroup);
                setTotalCount(res.total_count);
            }
            else {
                setReportGroup([]);
            }
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        });
    }

    useEffect(() => {
        setLoading(true);
        reportList()
    }, [page, searchText, pageSize]);
    const handleCheckedCategory = (offeredServices) => {
        setChecked(offeredServices)
    };

    const _theme = createMuiTheme({
        ...theme,
        overrides: {
            MuiTableCell: {
                root: {
                    paddingLeft: 20
                }
            }
        }
    });
    return (
        <div className={classes.tableWrapper}>
            <MuiThemeProvider theme={_theme}>
                <MaterialTable
                    style={{ boxShadow: '0px 1px 3px #00000033', }}

                    icons={tableIcons}
                    title={<Typography variant="h5" component="h5" className={classes.subTitle}>
                        Report Groups
                 </Typography>}
                    columns={columns}
                    data={reportGroup}
                    isLoading={loading}
                    options={options}
                    localization={{
                        toolbar: {
                            searchPlaceholder: 'Search anything'
                        },
                        body: {
                            emptyDataSourceMessage: 'No Report Groups Avaliable',
                            filterRow: {
                                filterTooltip: 'Filter'
                            },
                        },
                        header: {
                            actions: "Actions"
                        }
                    }}
                    onChangePage={(page) => {
                        setPage(page);
                    }}
                    onChangeRowsPerPage={(pageSize) => {
                        setPageSize(pageSize);
                    }}
                    onSearchChange={(search) => {
                        setSearchText(search);
                    }}
                    actions={[
                        {
                            icon: () => <EditIcon fontSize="small" htmlColor={iconColor} />,
                            tooltip: 'Edit',
                            onClick: (event, rowData) => { setCurrentData(rowData); setAction('edit') }
                        },
                        {
                            icon: () => <AddIcon color="secondary" />,
                            tooltip: 'Add',
                            isFreeAction: true,
                            onClick: (event) => setShowModal(true)
                        }
                    ]}
                    totalCount={totalCount}
                    page={page}
                />
            </MuiThemeProvider>
            <AddReportGroups open={showModal} setLoading={setLoading} reportList={reportList} onClose={setShowModal} />
            {action === 'edit' && <GroupPermissions data={currentData} setLoading={setLoading} reportList={reportList} handleCheckedCategory={handleCheckedCategory} handleClose={() => { setAction(null); setCurrentData(null) }} />}
        </div>
    )
}

export default ReportGroups;
