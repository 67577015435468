import React, { useEffect } from 'react';
import withDefaultLayout from "../../Layout";

export const requireAuth = (allowedRoles) => (ComposedComponent) => {

    function Authentication(props) {
        useEffect(() => {
            const user = JSON.parse(sessionStorage.getItem('user'));
            const userRole = user ? user['role'] : 'admin';
            if (!userRole) {
                props.history.push('/auth/signin');
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        });
        const user = JSON.parse(sessionStorage.getItem('user'));
        const userRole = user ? user['role'] : 'admin';
        if (allowedRoles.includes(userRole)) {
            const ComponentWithLayout = withDefaultLayout(ComposedComponent);
            return <ComponentWithLayout {...props} />
        }
        else {
            //change to page not found
            return null;
        }
    }
    return (Authentication);
};
