import React, { useEffect } from "react";
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        background: '#fff'
        // width: 400,
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
}));


function SearchMessage(props) {
    const { handleSearchText } = props;
    const classes = useStyles();
    useEffect(() => {

    }, []);

    return (
        <div className={classes.root}>
            <IconButton type="submit" className={classes.iconButton} aria-label="search">
                <SearchIcon />
            </IconButton>
            <InputBase
                className={classes.input}
                placeholder="Search Message or Name"
                inputProps={{ 'aria-label': 'search messages and name' }}
                onChange={(e) => handleSearchText(e.target.value)}
            />
        </div>
    )
}

export default SearchMessage;