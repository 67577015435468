
import React, { useState, useEffect } from 'react'
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import { Dialog, DialogContent, DialogTitle, Box, MenuItem, Tabs, AppBar, ListItem, Typography, IconButton, CircularProgress } from "@material-ui/core";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import Tab from "@material-ui/core/Tab";
import { MessageServices } from '../../../lib/services/api/Messaging/messae_services'
import _ from 'lodash';


const useStyles = makeStyles((theme) => ({
    formWrapper: {
        background: "#fff",
        width: "100%",

    },
    title: {
        display: "flex",
        justifyContent: "left",
        "& h2": {
            //   color: theme.palette.primary.main,
            fontSize: "40px",
            fontWeight: 40,
        },
    },
    subTitle: {
        fontSize: "16px",
        fontWeight: "bold",
        marginBottom: "20px",
        color: "#4D4F5C",
    },
    card: {
        padding: "8px 29px",
    },
    actionButtons: {
        paddingBottom: "33px",
        textAlign: "end",
        marginRight: "6px",
        marginTop: '21px',
        "& button:not(:last-child)": {
            marginRight: "20px",
        },
    },

    modal: {
        "& .MuiDialog-paperWidthMd": {
            width: "550px",
        },
    },
    tab: {
        fontWeight: 600
    }
}));

function ViewParticipant(props) {
    const { handleClose, selectedChat } = props
    const classes = useStyles();
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])

    useEffect(() => {
        setLoading(true)
        const params = {
            chat_id: selectedChat?.chat_id
        }

        MessageServices.viewParticipantDetails(params).then((res) => {
            setData(res.data)
        }).catch(() => {

        }).finally(() => {
            setLoading(false)
        })

    }, [selectedChat])


    return (
        <div>  <Dialog
            maxWidth={"md"}
            open={true}
            className={classes.modal}
            onClose={handleClose}
            aria-labelledby="base-station-manage"
        >

            <DialogTitle>
                <Grid style={{ textAlign: 'left', cursor: 'pointer' }} container>
                    <BackIcon onClick={() => handleClose()} style={{}} size='small' /> <Typography color='primary' variant='subtitle2' style={{ paddingLeft: '20px' }}>View Participants</Typography>
                </Grid>
            </DialogTitle>

            <DialogContent style={{ paddingBottom: '20px', height: '50vh' }}>


                {loading && _.isEmpty(data) ? (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    // minHeight="700px"
                    >
                        <CircularProgress color="primary" size={40} />
                    </Box>
                ) : <Grid>
                    {data.length > 0 ? data.map((item, index) => (
                        <ListItem key={item.id}>
                            <Typography variant="body2">{item}</Typography>
                        </ListItem>

                    )) : <Typography>No Participant Found</Typography>}
                </Grid>}
            </DialogContent>


        </Dialog></div>
    )
}

export default ViewParticipant