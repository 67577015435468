import { Grid, MenuItem } from '@material-ui/core';
import { capitalize } from 'lodash';
import React, { useContext, useState, useEffect } from 'react';
import DateField from '../../../components/form_components/DateField';
import ManageDataDialog from '../../../components/form_components/manage_data_dialog';
import MROTextField from '../../../components/form_components/TextField';
import UploadButton from '../../../components/form_components/upload_multiple';
import { ToastMessageContext } from '../../../lib/contexts/message_context';
import { IssueReportingService } from '../../../lib/services/api/issue_reporting/issue_report';
import { BASE_API_URL } from '../../../lib/shared/constants';
import { category, level } from './';


const status = [
    'open', 'on_hold', 'completed'
]

function ManageIssue(props) {

    console.log(props)
    const message = useContext(ToastMessageContext);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({});
    const [files, setFiles] = useState(null)


    const { action, handleClose, handleCloseMenu, data } = props;


    const handleFileChange = (files) => {
        console.log(files)
        setFiles(files)
    }

    const handleCreate = (formData) => {
        setLoading(true);
        IssueReportingService.createIssueReport(formData).then((res) => {
            if (res.success) {
                handleClose();
                handleCloseMenu();
                message.showToastMessage({ message: "New Issue added successfully", variant: "success" });
                props.IssueList();
            }
            else {
                handleClose();
                handleCloseMenu();
                message.showToastMessage({ message: res.errors ? res.errors.join(',') : "Something went wrong. Try Again", variant: "error" });
                setLoading(false);
            }
        }).catch(() => {
            handleClose();
            handleCloseMenu();
            message.showToastMessage({ message: "Something went wrong. Try Again", variant: "error" });
            setLoading(false);
        })
    }

    const handleUpdate = formData => {
        formData.append('id', data.id);
        formData.append('organisation_id', data.organisation_id);
        if (formData.get('status') !== 'completed') {
            formData.delete('date_completed')
        }
        IssueReportingService.updateIssueReport(formData)
            .then(res => {
                if (res.success) {
                    handleClose();
                    handleCloseMenu();
                    message.showToastMessage({ message: 'Issues updated successfully', variant: "success" });
                    props.IssueList();
                }
                else {
                    handleClose();
                    handleCloseMenu();
                    message.showToastMessage({ message: res.errors ? res.errors.join(',') : "Something went wrong. Try Again", variant: "error" });
                    setLoading(false);
                }
            }).catch(() => {
                handleClose();
                handleCloseMenu();
                message.showToastMessage({ message: "Something went wrong. Try Again", variant: "error" });
                setLoading(false);
            })
    }

    const handleDelete = () => {
        console.log("Dlelted")
        message.showToastMessage({ message: "Issue deleted", variant: "error" });
        handleClose()

    }


    const handleSubmit = (formData) => {


        if (action === 'update') {
            handleUpdate(formData);
        }
        else {
            //   handleDelete()
        }
    }

    const [disabled, setFiledDisabled] = useState(false);
    useEffect(() => {
        if (action === 'view') {
            setFiledDisabled(true);
        }
        return () => {
            setFiledDisabled(false);
        }
    }, [action])

    const [levels, setLevels] = useState([]);
    const handleCategoryChange = ({ target: { value } }) => {
        setLevels(level.filter(lev => lev.type === value))
    }
    return (
        <>
            <ManageDataDialog files={files} handleSubmit={handleSubmit} loading={loading} title={action === 'update' ? 'Update Issue' : 'View Issue'} type={action} {...props}>
                {
                    (action === 'view') && <>

                        <Grid container spacing={1} alignItems={"center"} justify={"flex-start"}>

                            <Grid item xs={12}>
                                <MROTextField
                                    name={"category"}
                                    label={"Category"}
                                    select
                                    disabled={disabled}
                                    defaultValue={data.category}
                                    InputLabelProps={{ shrink: true }}
                                >
                                    {
                                        category.map(category =>
                                            <MenuItem value={category} >
                                                {capitalize(category)}
                                            </MenuItem>
                                        )
                                    }
                                </MROTextField>
                            </Grid>
                            <Grid item xs={12}>
                                <MROTextField
                                    name={"level"}
                                    label={"Level"}
                                    required
                                    select
                                    disabled={disabled}
                                    defaultValue={data.level}
                                    InputLabelProps={{ shrink: true }}
                                >
                                    {
                                        data.level ? level.map(level =>
                                            <MenuItem value={level.value} >
                                                {capitalize(level.value)}
                                            </MenuItem>
                                        ) : levels.map(level =>
                                            <MenuItem value={level.value} >
                                                {capitalize(level.value)}
                                            </MenuItem>
                                        )
                                    }
                                </MROTextField>
                            </Grid>
                            <Grid item xs={12}>
                                <MROTextField
                                    name={"subject"}
                                    label={"Subject"}
                                    disabled={disabled}
                                    defaultValue={data.subject}
                                    InputLabelProps={{ shrink: true }}
                                />

                            </Grid>
                            <Grid item xs={12}>
                                <MROTextField
                                    name={"message"}
                                    label={"Message"}
                                    textarea
                                    disabled={disabled}
                                    multiline
                                    rows={4}
                                    variant="filled"
                                    placeholder="Type your message here..."
                                    defaultValue={data.subject}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <UploadButton setFiles={setFiles} multiple={true} name={"documents[]"} action={"Upload Document"} defaultValue={data?.documents?.map(doc => ({ name: doc.document_url, url: BASE_API_URL + '' + doc.document_url }))} />
                            </Grid>
                        </Grid>
                    </>
                }
                {
                    action === 'update' && <>
                        <Grid container spacing={1} alignItems={"center"} justify={"flex-start"}>

                            <Grid item xs={12}>
                                <MROTextField
                                    name={"status"}
                                    label={"Status"}
                                    select
                                    disabled={disabled}
                                    defaultValue={data.status}
                                    InputLabelProps={{ shrink: true }}
                                >
                                    {
                                        status.map(status =>
                                            <MenuItem value={status} >
                                                {capitalize(status)}
                                            </MenuItem>
                                        )
                                    }
                                </MROTextField>
                            </Grid>
                            <Grid item xs={12}>
                                <DateField name="date_completed" defaultValue={data.date_completed} />
                            </Grid>
                        </Grid>
                    </>
                }
                {
                    action === 'delete' &&
                    <>
                        Confirm removal of Issue. Once Deleted data will be lost and couldn't be restored.
                </>
                }
            </ManageDataDialog>
        </>
    )
}


export default ManageIssue;