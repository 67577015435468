import request from "../../shared/api_client";

function getFetchStationChecks(id) {
    return request({
        url: `/station_checks/org_station_checks?organisation_id=${id}`,
        method: 'GET',
    });
}

function changeStatus(formData) {
    return request({
        url:`/station_checks/map_station_checks`,
        method: 'POST',
        data:formData
    })
}
function mapStationChecks(id) {
    return request({
        url: `/station_checks/fetch_station_checks?organisation_id=${id}`,
        method: 'GET',
    });
}
function updateStationQuestions(formData) {
    return request({
        url:`/station_questions/update_question`,
        method: 'PUT',
        data:formData
    })
}

function deleteStationQuestions(formData) {
    return request({
        url:`/station_questions/destroy_question`,
        method: 'DELETE',
        data:formData
    })
}

const StationQuestionService = {
    getFetchStationChecks,changeStatus, updateStationQuestions, deleteStationQuestions,mapStationChecks
};

export default StationQuestionService;